import { getSubCategoriesApi } from "../../../api/users/category"
import { GET_ALL_SUBCATEGORIES_FAILED, GET_ALL_SUBCATEGORIES_REQ, GET_ALL_SUBCATEGORIES_SUCC } from "../../constants/categories/getAllSubCategories"

export const getAllSubCategoriesAction = (categoryId="") => async(dispatch) => {
    try{
        dispatch({type:GET_ALL_SUBCATEGORIES_REQ})
        const res =  await getSubCategoriesApi(categoryId)



        dispatch({
            type:GET_ALL_SUBCATEGORIES_SUCC,
            payload:res.data.docs,

        })

    } catch(error){
        dispatch({
            type: GET_ALL_SUBCATEGORIES_FAILED,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}