import {
  GET_SINGLE_ASSET_FAIL,
  GET_SINGLE_ASSET_REQ,
  GET_SINGLE_ASSET_SUCC,
} from "../../constants/assets/SingleAssetOpener";

export const singleAssetReducer = (
  state = { asset: {}, loading: true, error: null },
  action
) => {
  switch (action.type) {
    case GET_SINGLE_ASSET_REQ:
      return { loading: true, ...state };

    case GET_SINGLE_ASSET_SUCC:
      return {
        loading: false,
        asset: action.payload,
      };

    case GET_SINGLE_ASSET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
