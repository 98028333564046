import {
  GET_SINGLE_PROEJCT_FAIL,
  GET_SINGLE_PROEJCT_REQ,
  GET_SINGLE_PROEJCT_SUCC,
} from "../../constants/projects/ProjectsConst";

export const singleProjectReducer = (
  state = { project: {}, loading: false, errro: null },
  action
) => {
  switch (action.type) {
    case GET_SINGLE_PROEJCT_REQ:
      return { loading: true, ...state };

    case GET_SINGLE_PROEJCT_SUCC:
      return {
        loading: false,
        project: action.payload,
      };

    case GET_SINGLE_PROEJCT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
