import { getUserApi } from "../../../api/users/users"
import { GET_SINGLE_USER_FROM_API_FAIL, GET_SINGLE_USER_FROM_API_REQ, GET_SINGLE_USER_FROM_API_SUCC } from "../../constants/users/getSingleUser"

export const getSingleUser = (userId) => async(dispatch) => {
   try{
    dispatch({type:GET_SINGLE_USER_FROM_API_REQ})

    const res = await getUserApi(userId)

    dispatch({
        type:GET_SINGLE_USER_FROM_API_SUCC,
        payload:res.data
    })

   }
    catch(error){
        dispatch({
            type: GET_SINGLE_USER_FROM_API_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message 
        })
    }


}