import { ArrowRight } from 'phosphor-react'
import React from 'react'

const AddProjectDropDown = ({p, onClick}) => {
  return (
    <>
        <div onClick={onClick} className='flex border-b border-x border-x-[#00000033] border-bottom-border bg-white px-4 py-3'>
            <div className='w-1/6 font-medium '>
                <p className='text-xs '>ID</p>
                <p className='text-very-tiny opacity-50'>{p?.uid}</p>
            </div>  

            <div className='w-1/2 '>
                <p className='text-xs font-medium '>{p?.name}</p>
                {/* <p className='text-very-tiny opacity-50 font-medium'>{p.projectDetails.address}</p> */}
            </div>  

            <div className='w-1/6  font-medium '>
                <p className='text-xs'>Status</p>
                <p className='text-very-tiny opacity-50 capitalize'>{p?.status}</p>
            </div>  

            <div className='text-xs flex flex-1 justify-end font-medium'>
                <ArrowRight className='bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded' size={28} weight='light'/>
            </div>
        </div>
    </>
  )
}

export default AddProjectDropDown