import { GET_ALL_SUBCATEGORIES_FAILED, GET_ALL_SUBCATEGORIES_REQ, GET_ALL_SUBCATEGORIES_SUCC } from "../../constants/categories/getAllSubCategories";

export const getAllSubCategoriesReducer = (state={subcategories:[], loading:false, error:null}, action) => {
    switch(action.type){
        case GET_ALL_SUBCATEGORIES_REQ:
            return {
                loading:true
            }
         case GET_ALL_SUBCATEGORIES_SUCC:
             return{
                 loading:false,
                 subcategories:action.payload,
             }
 
         case GET_ALL_SUBCATEGORIES_FAILED:
             return{
                 loading:false,
                 error:action.payload
             }
 
         default:
             return state;
    }
 }