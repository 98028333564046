import { getSingleProjectData } from "../../../api/users/projects";
import {
  GET_SINGLE_PROEJCT_FAIL,
  GET_SINGLE_PROEJCT_REQ,
  GET_SINGLE_PROEJCT_SUCC,
} from "../../constants/projects/ProjectsConst";

export const openSingleProjectAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_PROEJCT_REQ });

    // const data =  FakeProjects.filter(a => a.id === id)[0]
    const res = await getSingleProjectData(id);

    dispatch({
      type: GET_SINGLE_PROEJCT_SUCC,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_PROEJCT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
