/* eslint-disable no-unused-vars */
import classNames from "classnames";
import {
  ArrowRight,
  Buildings,
  ListBullets,
  MagnifyingGlass,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSingleProjectAdminAction } from "../../../../redux/actions/Admin/OpenSingleProjectAdmin";
import { getAssets } from "../../../../redux/actions/asset/assetAction";
import { getAssetsOfAProjectAction } from "../../../../redux/actions/asset/getAssetOfAProject";
import { getAllProjects } from "../../../../redux/actions/project/getProjects";
import { getSuppliersOfAProjectAction } from "../../../../redux/actions/suppliers/geSupplierOfAProject";
import {
  PROJECTS_CREATE_OPEN,
  PROJECTS_LIST_OPEN,
  PROJECTS_MAP_OPEN,
} from "../../../../redux/constants/AdminConsts/LeftNavAdminConst";
import { MapFilter } from "../../../../styles/MapFilter";
import Loading from "../../../components/loading/Loading";
import AssetsDropDown from "../../../components/Projects/comps/AssetsDropDown";
import DocumentsDropDown from "../../../components/Projects/comps/DocumentsDropDown";
import SupplierDropDown from "../../../components/Projects/comps/SupplierDropDown";
import { Assests } from "../../../fake/fakeAssets";
import { FakeProjects } from "../../../fake/fakeProject";
import { FakeSuppliers } from "../../../fake/fakeSuppliers";
import { documents } from "../../../fake/faleDoc";
import Pagination from "./Pagination";
import { openSingleProjectAction } from "../../../../redux/actions/project/SingleProject";
import { getAllDocsAction } from "../../../../redux/actions/documents/getAllDoc";
import { useNavigate } from "react-router-dom";

const ShowCase = () => {
  const [assetsDropDown, setassetsDropDown] = useState(false);
  const [documentsDropDown, setdocumentsDropDown] = useState(false);
  const [suppliersDropDown, setsuppliersDropDown] = useState(false);

  const [clickedId, setClickedId] = useState(null);

  const { projects } = useSelector((state) => state.AdminLeftNav);

  const assetDropDonwOPener = (s) => {
    dispatch(getAssetsOfAProjectAction(s._id));
    setClickedId(s._id);
    setassetsDropDown(s._id === clickedId ? !assetsDropDown : true);
    setdocumentsDropDown(false);
    setsuppliersDropDown(false);
  };

  const documentsDropDownOpener = (s) => {
    setClickedId(s._id);
    setdocumentsDropDown(s._id === clickedId ? !documentsDropDown : true);
    setsuppliersDropDown(false);
    setassetsDropDown(false);
    dispatch(getAllDocsAction({ projectId: s._id }));
  };

  const suppliersDropDownOpener = (p) => {
    dispatch(getSuppliersOfAProjectAction(p._id));
    setClickedId(p._id);
    setdocumentsDropDown(false);
    setassetsDropDown(false);
    setsuppliersDropDown(p._id === clickedId ? !suppliersDropDown : true);
  };
  const dipstach = useDispatch();

  const navigate = useNavigate();

  const openSingleProject = (p) => {
    dipstach(openSingleProjectAdminAction(p._id));
    navigate(`${p._id}`);
    // dispatch(openSingleProjectAction(p._id))
  };

  // integration

  const { assets: assetsOfAProject, loading: ProjectDropDownLoading } =
    useSelector((state) => state.getAssetsOFAProject);
  const { suppliers, loading: suppliersDropDownLOading } = useSelector(
    (state) => state.getSuppliersOFAProject
  );

  const dispatch = useDispatch();
  const {
    projects: allprojects,
    loading,
    allProjectDetails,
  } = useSelector((state) => state.getAllProjects);

  const { user } = useSelector((state) => state.me);

  const [searchInput, setSearchInput] = useState("");
  const [limit, setLimit] = useState(
    allProjectDetails?.limit ? allProjectDetails?.limit : 5
  );
  const [projectStatus, setProjectStatus] = useState("");

  useEffect(() => {
    dispatch(
      getAllProjects({ limit: limit, q: searchInput, status: projectStatus })
    );
  }, [limit, searchInput, dispatch, projectStatus]);

  //      useEffect(() => {
  //       dispatch(getAssets({}));
  //   }, [ dispatch, user.companyId]);

  const { companyId } = useSelector((state) => state.selectCompany);

  //   useEffect(() => {
  //       dispatch(getAssets({}))
  //   }, [limit, searchInput, companyId, dispatch])

  const { docs } = useSelector((state) => state.getAllDoc);

  return (
    <>
      <div className="bg-white mt-8 rounded-lg py-8 pb-16">
        <div className="flex  px-6 items-center justify-between">
          <div className="flex items-center">
            <div className="flex p-1 bg-white gap-1 right-56 shadow-xl rounded-full">
              <MapFilter
                active={projects.list}
                onClick={() => {
                  dipstach({ type: PROJECTS_LIST_OPEN });
                  navigate("/admin/projects");
                }}
                className="rounded-l-full"
              >
                <div
                  active
                  className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] py-2 px-4 rounded-l-full items-center gap-2"
                >
                  <p className="opacity-30">List</p>
                  <ListBullets size={12} className="opacity-30" weight="bold" />
                </div>
              </MapFilter>

              <MapFilter
                className="rounded-r-full"
                active={!projects.list}
                onClick={() => {
                  dipstach({ type: PROJECTS_MAP_OPEN });
                  navigate("mapview");
                }}
              >
                <div className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] rounded-r-full py-2 px-4 items-center gap-2">
                  <Buildings size={12} className="opacity-30" weight="bold" />
                  <p className="opacity-30">Map</p>
                </div>
              </MapFilter>
            </div>

            <div className="flex ml-6 text-xs font-semibold">
              {/* <div className='px-3 border-r'>
                                <select className='min-w-[98px] opacity-60 font-semibold  h-5 focus:outline-none' placeholder='Asset Type' name="" id="">
                                    <option value="">Project Type</option>
                                    <option value="">Ipsum</option>
                                    <option value="">Dolor</option>
                                </select>
                            </div> */}
              <div className="px-3">
                <select
                  value={projectStatus}
                  onChange={(e) => setProjectStatus(e.target.value)}
                  className="min-w-[98px] opacity-60 font-semibold  h-5 focus:outline-none"
                  placeholder="Asset Type"
                  name=""
                  id=""
                >
                  <option value="">All</option>
                  {/* <option value="New">New</option> */}
                  <option value="Ongoing">Ongoing</option>
                  {/* <option value="Halted">Halted</option> */}
                  <option value="Completed">Completed</option>
                </select>
              </div>
            </div>
          </div>

          {/* <button onClick={() => dipstach({type: PROJECTS_CREATE_OPEN})} className='px-5 uppercase border font-semibold border-theme-green hover:border-black text-xs py-2 rounded-md font-medium cursor-pointer select-none hover:bg-theme-green bg-theme-light-green text-theme-green hover:text-black'>create new project</button> */}
        </div>

        {/* Input and display */}
        <div className="flex  px-6 justify-between items-center mt-12">
          <div className="relative text-very-tiny w-1/3">
            <input
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
              placeholder="Search for Project Detail, Owner or Location"
              className="w-full bg-[#f7f6f6] border-[1.5px] focus:outline-none rounded-md border-[rgba(0, 0, 0, 0.1)] py-2.5 px-4"
              type="text"
            />
            <MagnifyingGlass
              className="absolute right-6 opacity-20 top-1/2 -translate-y-1/2"
              size={14}
            />
          </div>
          <div className="flex text-very-tiny gap-4 items-center">
            <p>
              Displaying {(allProjectDetails?.page - 1) * limit + 1} -{" "}
              {(allProjectDetails?.page - 1) * limit +
                allProjectDetails?.docs?.length}{" "}
              of {allProjectDetails?.totalDocs}
            </p>

            <div className="py-1 bg-[#f7f6f6] rounded-md px-1.5 border border-[rgba(0, 0, 0, 0.1)]">
              <select
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                className="h-7 bg-[#f7f6f6] focus:outline-none"
                name=""
                id=""
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </select>
            </div>
          </div>
        </div>

        {/* Table */}

        <div>
          <div className="flex  text-xs font-semibold px-6 mt-6 border-bottom-border border-b">
            <div className="w-[7%] opacity-50 py-6">UID</div>
            <div className="w-[18%] opacity-50 py-6">Project Details</div>
            <div className="w-[15%] opacity-50 py-6">Project Owner</div>
            <div className="w-[25%] opacity-50 py-6 text-center">
              Status/Progress
            </div>
            <div className="w-[10%] opacity-50 py-6">Assets</div>
            <div className="w-[10%] opacity-50 py-6">Suppliers</div>
            <div className="w-[10%] opacity-50 py-6">Documents</div>
            <div className=" opacity-50 py-6 text-right flex-1">View</div>
          </div>

          {loading ? (
            <Loading loading={loading} />
          ) : allprojects?.length === 0 ? (
            <div className="text-center pt-4">No Projects found</div>
          ) : (
            allprojects?.map((p, index) => {
              return (
                <div key={index}>
                  <div
                    className={classNames(
                      "flex py-4 px-6",
                      (assetsDropDown ||
                        documentsDropDown ||
                        suppliersDropDown) &&
                        clickedId === p._id
                        ? "bg-theme-light-green border-b border-t"
                        : "border-b border-bottom-border",
                      ""
                    )}
                  >
                    <div className="w-[7%] text-xs font-medium">
                      <p className="uppercase">id</p>
                      <p className="opacity-50">{p.uid}</p>
                    </div>
                    <div className="w-[18%] text-xs gap-2">
                      <p className="font-medium">{p.name}</p>
                      <p className="text-theme-black opacity-50 font-medium">
                        {p.address.city}, {p.address.state}
                      </p>
                    </div>

                    <div className="flex w-[15%] items-center text-xs gap-2">
                      <div className="h-full">
                        {/* <img className='w-8 p-[1px] border border-theme-green rounded-full' src="/images/user1.jpeg" alt="" /> */}
                        <p className="w-9 h-9 tracking-wide flex justify-center items-center text-white font-bold text-[18px] bg-theme-green p-0.5 border border-theme-green rounded-full">
                          {p.companyId?.name.split(" ")[0][0]}
                          {p.companyId?.name.split(" ")[1]
                            ? p.companyId?.name.split(" ")[1][0]
                            : ""}
                        </p>
                      </div>
                      {p.companyId && (
                        <div className="">
                          <p className="font-medium">{p.companyId?.name}</p>
                          <p className="uppercase text-very-tiny">
                            <span>ID</span> -{" "}
                            <span className="underline text-blue-400">
                              {p.companyId?.uid}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="flex w-[25%] items-center justify-center text-xs gap-6">
                      <div className="text-xs ">
                        <p className="font-medium">Status</p>
                        <p
                          className={classNames(
                            " cursor-pointer font-medium leading-3",
                            p.status === "Approved"
                              ? "text-theme-green"
                              : "text-theme-black opacity-50"
                          )}
                        >
                          {p.status}
                        </p>
                      </div>

                      <div className="flex flex-col ">
                        <p className="leading-3 mb-1">
                          Progress{" "}
                          <span className="text-theme-black opacity-50">
                            ({p.progress.completed + "/" + p.progress.total})
                          </span>
                        </p>
                        <div className="relative w-full h-2 rounded bg-theme-light-green ">
                          <div
                            style={{
                              width: `${(p.progress.completed / p.progress.total) * 100
                                }%`,
                            }}
                            className={classNames(
                              "absolute h-2 bg-theme-green rounded",
                              ``
                            )}
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div className=" w-[10%] text-xs">
                      <div className="">
                        <p className="leading-3">Assets</p>
                        <p
                          onClick={() => assetDropDonwOPener(p)}
                          className="text-theme-black opacity-50 cursor-pointer leading-3"
                        >
                          {p.stats.assets}{" "}
                          <i
                            className={classNames(
                              "fa-solid ml-1 ",
                              assetsDropDown && clickedId === p._id
                                ? "fa-angle-up"
                                : "fa-angle-down"
                            )}
                          ></i>
                        </p>
                      </div>
                    </div>

                    <div className=" w-[10%] text-xs">
                      <div className="">
                        <p className="leading-3">Suppliers</p>
                        <p
                          onClick={() => suppliersDropDownOpener(p)}
                          className="text-theme-black opacity-50 cursor-pointer leading-3"
                        >
                          {p.stats.suppliers}{" "}
                          <i
                            className={classNames(
                              "fa-solid ml-1 ",
                              suppliersDropDown && clickedId === p._id
                                ? "fa-angle-up"
                                : "fa-angle-down"
                            )}
                          ></i>
                        </p>
                      </div>
                    </div>

                    <div className=" w-[10%] text-xs">
                      <div className="">
                        <p className="leading-3">Documents</p>
                        <p
                          onClick={() => documentsDropDownOpener(p)}
                          className="text-theme-black opacity-50 cursor-pointer leading-3"
                        >
                          {p.stats.documents}{" "}
                          <i
                            className={classNames(
                              "fa-solid ml-1 ",
                              documentsDropDown && clickedId === p._id
                                ? "fa-angle-up"
                                : "fa-angle-down"
                            )}
                          ></i>
                        </p>
                      </div>
                    </div>

                    <div className="text-xs flex flex-1 justify-end font-medium">
                      <ArrowRight
                        onClick={() => openSingleProject(p)}
                        className="bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
                        size={28}
                        weight="light"
                      />
                    </div>
                  </div>

                  {assetsDropDown && clickedId === p._id && (
                    <div className="h-60 overflow-y-auto">
                      {assetsOfAProject.length !== 0 ? (
                        assetsOfAProject.map((asset, index) => {
                          return (
                            <AssetsDropDown
                              onClick={() =>
                                navigate(`/admin/assets/${asset._id}`)
                              }
                              asset={asset}
                              key={index}
                            />
                          );
                        })
                      ) : (
                        <p className="text-center pt-6">No Assets found</p>
                      )}
                    </div>
                  )}

                  {suppliersDropDown && clickedId === p._id && (
                    <div className="max-h-60 overflow-y-auto">
                      {suppliers.length !== 0 ? (
                        suppliers.map((s, idx) => {
                          return <SupplierDropDown s={s} key={idx} />;
                        })
                      ) : (
                        <p className="text-center pt-6">No Supplier found</p>
                      )}
                    </div>
                  )}

                  {documentsDropDown && clickedId === p._id && (
                    <div className="max-h-60 overflow-y-auto">
                      {docs && docs.length === 0 ? (
                        <p className="text-center py-6 text-xs">
                          No Document found
                        </p>
                      ) : (
                        docs?.map((s, index) => {
                          return (
                            <DocumentsDropDown
                              onClick={() =>
                                navigate(`/admin/documents/${s._id}`)
                              }
                              s={s}
                              key={index}
                            />
                          );
                        })
                      )}
                    </div>
                  )}
                </div>
              );
            })
          )}
        </div>
      </div>

      <Pagination />
    </>
  );
};

export default ShowCase;
