import classNames from "classnames";
import { ArrowRight, DownloadSimple, Eye } from "phosphor-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { downloadDoc, previewDoc } from "../../../../api/users/fileUpload";
import { getAllDocsVersions } from "../../../../redux/actions/documents/getAllVersions";
import { getSingleDocumentAction } from "../../../../redux/actions/documents/getSingleDocumentAction";
import ProjectsDropDown from "../../../components/Assets/comps/ProjectsDropDown";
import PeopleDropDown from "../../../components/Documents/ListComps/PeopleDropDown";
import VersionsDropDown from "../../../components/Documents/ListComps/VersionsDropDown";
import AssetsDropDown from "../../../components/Projects/comps/AssetsDropDown";
import { Assests } from "../../../fake/fakeAssets";
import { FakeProjects } from "../../../fake/fakeProject";

const SingleDocument = ({ d, admin }) => {
  const [versionDropDown, setVersionDropDown] = useState(false);

  const [assetsDropDown, setassetsDropDown] = useState(false);
  const [projectsDropDown, setprojectsDropDown] = useState(false);
  const [peopleDropDown, setpeopleDropDown] = useState(false);
  const [clickedId, setClickedId] = useState(null);

  const versionsDropDownHandler = (d) => {
    setClickedId(d.id);
    setVersionDropDown(!versionDropDown);
    setassetsDropDown(false);
    setpeopleDropDown(false);
    setprojectsDropDown(false);
    dispatch(getAllDocsVersions({ documentId: d._id }));
  };

  const { versions } = useSelector((state) => state.getAllVersions);

  const peopleDropDonwOpener = (d) => {
    setClickedId(d.id);
    setVersionDropDown(false);
    setassetsDropDown(false);
    setpeopleDropDown(!peopleDropDown);
    setprojectsDropDown(false);
    dispatch(getSingleDocumentAction(d._id));
  };

  const dispatch = useDispatch();

  const { document } = useSelector((state) => state.getSingleDocument);

  const navigate = useNavigate();

  const openSingleDocHandler = (d) => {
    // dispatch(openSingleDocumentAction(d.id))
    if (admin) {
      navigate(`/admin/documents/${d._id}`);
    } else {
      navigate(`/documents/${d._id}`);
    }
  };

  const downloadDocument = async (d) => {
    const url = await downloadDoc(d.data);

    window.open(url, "_blank");
  };

  const previewDocument = async (d) => {
    const url = await previewDoc(d.data);

    window.open(url, "_blank");
  };
  return (
    <>
      <div>
        <div
          className={classNames(
            "flex py-4 px-6",
            (assetsDropDown ||
              projectsDropDown ||
              peopleDropDown ||
              versionDropDown) &&
              clickedId === d.id
              ? "bg-theme-light-green border-b border-t"
              : "border-b border-bottom-border",
            ""
          )}
        >
          <div className="w-[10%] flex pl-3">
            <p className="px-3 text-center text-xs py-2 rounded-full bg-theme-green">
              Document
            </p>
          </div>
          <div className="w-[10%] text-xs font-medium">
            <p className="uppercase">id</p>
            <p className="opacity-50">{d.uid}</p>
          </div>

          <div className="w-[9%] text-xs gap-2">
            <p className="">Version</p>
            <p
              onClick={() => versionsDropDownHandler(d)}
              className="text-theme-black opacity-50 cursor-pointer leading-3"
            >
              {d.version}{" "}
              <i
                className={classNames(
                  "fa-solid ml-1 ",
                  versionDropDown && clickedId === d._id
                    ? "fa-angle-up"
                    : "fa-angle-down"
                )}
              ></i>
            </p>
          </div>

          <div className="w-[14%] text-xs gap-2">
            <p className=" leading-3">{d.name}</p>
            {d.type && (
              <p className="leading-4 font-light">
                <span className="opacity-50 mr-1">{d.type.type}</span>{" "}
                <span className="uppercase text-blue-300  underline">
                  {d.type.code}
                </span>
              </p>
            )}
          </div>

          <div className="text-xs w-[10%]">
            <p className="">Status</p>
            <p
              className={classNames(
                " cursor-pointer  leading-3",
                d.status === "Approved"
                  ? "text-theme-green"
                  : "text-theme-black opacity-50"
              )}
            >
              {d.status}
            </p>
          </div>

          <div className="text-xs w-[10%]">
            <p className="">Last Edit</p>
            <p className="opacity-50 font-semibold capitalize">
              {d.updatedAt?.substring(0, 10).replaceAll("-", "/")}
            </p>
          </div>

          {/* <div className=' w-[8%] text-xs'>
                            <div className=''>
                                <p className='leading-3 '>Assets</p>
                                <p onClick={() => assetDropDonwOPener(d)} className='text-theme-black opacity-50 cursor-pointer'>{Assests.length} <i className={classNames("fa-solid ml-1 ", (assetsDropDown && clickedId===d.id)?"fa-angle-up": 'fa-angle-down')}></i></p>
                            </div>
                        </div> */}

          <div className=" w-[14%] text-xs">
            <div className="">
              {/* <p className='leading-3 '>Projects</p> */}
              <p className="text-blue-500  underline cursor-pointer">
                {d.projectId?.name}{" "}
              </p>
            </div>
          </div>

          <div className=" w-[8%] text-xs">
            <div className="">
              <p className="leading-3 ">People</p>
              <p
                onClick={() => peopleDropDonwOpener(d)}
                className="text-theme-black opacity-50 cursor-pointer"
              >
                {d.people?.shared.length || 0}{" "}
                <i
                  className={classNames(
                    "fa-solid ml-1 ",
                    peopleDropDown && clickedId === d.id
                      ? "fa-angle-up"
                      : "fa-angle-down"
                  )}
                ></i>
              </p>
            </div>
          </div>

          <div className="text-xs flex flex-1 gap-2 justify-end  font-medium">
            <DownloadSimple
              onClick={() => downloadDocument(d)}
              className="bg-#F7F6F6  p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
              size={30}
            />
            <Eye
              onClick={() => previewDocument(d)}
              className="bg-#F7F6F6 p-2  cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
              size={30}
            />
            <ArrowRight
              onClick={() => openSingleDocHandler(d)}
              className="bg-#F7F6F6 p-2  cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
              size={30}
            />
          </div>
        </div>

        {/* Drop Dwons */}
        {versionDropDown && clickedId === d._id && (
          <div>
            {versions?.map((v, index) => {
              if (v !== versions[versions.length - 1])
                return <VersionsDropDown v={v} />;
            })}
          </div>
        )}
        {assetsDropDown && clickedId === d._id && (
          <div className="h-60 overflow-y-auto">
            {Assests.map((asset, index) => {
              return <AssetsDropDown asset={asset} key={index} />;
            })}
          </div>
        )}

        {projectsDropDown && clickedId === d._id && (
          <div className="h-60 overflow-y-auto">
            {FakeProjects.map((project, index) => {
              return <ProjectsDropDown project={project} key={index} />;
            })}
          </div>
        )}

        {peopleDropDown && clickedId === d.id && (
          <PeopleDropDown d={document} />
        )}
      </div>
    </>
  );
};

export default SingleDocument;
