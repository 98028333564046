import { SELECT_COMPANY, SELECT_COMPANY_REQ, SELECT_COMPANY_SUCC } from "../constants/selectCompany";

export const selectCompanyReducer =  (state={companyId :undefined, loading:true, error:null}, action)=> {
    switch(action.type){
        case SELECT_COMPANY_REQ:
            return {loading :true}

            case SELECT_COMPANY_SUCC:
                return{
                    loading:false,
                    companyId:action.companyId
                }
        
        default:
            return state;
    }
}