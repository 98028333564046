/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { DOCUMENTS_LIST_OPEN, DOCUMENTS_MAP_OPEN } from '../../../redux/constants/AdminConsts/LeftNavAdminConst'
import ShowCase from './comps/ShowCase'
import TopLinks from './comps/TopLinks'
import CreateNewDoc from './CreateNew'
import MapView from './DocMap/MapView'
import DocumentEdit from './EditScreen'
import SingleDocumentDetails from './singleDoc'

const MainDiv = styled.div`
  height: calc(100vh - 57px);

`

const MapDiv = styled.div`
    &:after{
      content: '';
      position: absolute;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      z-index: 10;
      pointer-events: none;
      box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
    -o-box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
    }
`


const Documents = () => {
  const dispatch = useDispatch()

  const { dashboard, companies, assets, documents, projects, employess, iso } = useSelector(state => state.AdminLeftNav)

  const { open, document } = useSelector(state => state.adminSingleDocument)


  const [showEditScreen, setEditScreen] = useState(false)

  const saveEdit = () => {
    setEditScreen(false)
  }

  const cancelEdit = () => {
    setEditScreen(false)
  }

  const navigate = useNavigate()
  const [limit, setLimit] = useState(5)

  return (
    <>


      <Routes>
        <Route
          path='/*'
          element={
            <MainDiv className='px-[32px] py-8 overflow-y-auto'>
              <TopLinks showEditScreen={showEditScreen} backToSingleAsset={() => setEditScreen(false)} editScreenHandler={() => setEditScreen(true)} />
              <Routes>
                <Route path='/' element={<ShowCase limit={limit} setLimit={setLimit} />} />
                <Route path='/:id' element={<SingleDocumentDetails />} />
              </Routes>
              {/* {documents.list && !open && 
                  <ShowCase 
                    limit={limit}
                    setLimit={setLimit}
                  />
                } */}
              {/* {documents.list && open && !showEditScreen && <SingleDocumentDetails/>} */}
            </MainDiv>
          }
        />

        <Route
          path='mapview'
          element={
            <MapDiv className='relative h-full'>
              <MapView list={false} listViewFunc={() => navigate('/admin/documents')} mapViewFunc={() => { return; }} />
            </MapDiv>
          }
        />
      </Routes>

      {/* {
            documents.map && 
            <MapDiv className='relative h-full'>
              <MapView list={documents.list} listViewFunc={() => dispatch({type:DOCUMENTS_LIST_OPEN}) } mapViewFunc={() => dispatch({type:DOCUMENTS_MAP_OPEN})}/>
          </MapDiv>
        } */}
    </>
  )
}

export default Documents