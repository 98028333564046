/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable no-unused-vars */
import {
  ArrowRight,
  Buildings,
  DownloadSimple,
  FunnelSimple,
  ListBullets,
  MapTrifold,
  Wrench,
  X,
} from "phosphor-react";
import React, { useCallback, useEffect, useState } from "react";
import { MapFilter } from "../../../../styles/MapFilter";
import MainMapProjects from "./Map";
import mapboxgl from "mapbox-gl";
import { useDispatch, useSelector } from "react-redux";
import { getAssetsOfAProjectAction } from "../../../../redux/actions/asset/getAssetOfAProject";
import { getSuppliersOfAProjectAction } from "../../../../redux/actions/suppliers/geSupplierOfAProject";
import { getAllDocsAction } from "../../../../redux/actions/documents/getAllDoc";
import { FilterRitghtBar } from "../../../../styles/FilterButton";
import { getSingleUser } from "../../../../redux/actions/users/getSingleUser";
import { useNavigate } from "react-router-dom";
import { downloadDoc } from "../../../../api/users/fileUpload";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MapView = ({ listViewFunc, mapViewFunc }) => {
  const [activeFilter, setActiveFilter] = useState({ map1: true, map2: false });
  const [vertical, setVertial] = useState(true);
  const [showFilters, setShowFilters] = useState(false);

  const [marker, setMarker] = useState({
    latitude: 33.99936527056204,
    longitude: -118.20556925453577,
  });

  const [events, logEvents] = useState({});

  const { project } = useSelector((state) => state.singleProject);
  const [active, setActive] = useState({
    assets: true,
    doc: false,
    suppliers: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (project) {
      dispatch(getAssetsOfAProjectAction(project._id));
      dispatch(getSuppliersOfAProjectAction(project._id));
      dispatch(getAllDocsAction({ projectId: project._id }));
    }
  }, [project, dispatch]);

  const { suppliers, loading: suppliersDropDownLOading } = useSelector(
    (state) => state.getSuppliersOFAProject
  );

  const { assets: assetsOfAProject, loading: ProjectDropDownLoading } =
    useSelector((state) => state.getAssetsOFAProject);

  const { docs } = useSelector((state) => state.getAllDoc);

  const [openSideMenuProject, setOpenSidemenuProject] = useState(false);

  // get project owner

  useEffect(() => {
    if (project) dispatch(getSingleUser(project.createdBy));
  }, [project, dispatch]);

  const { user } = useSelector((state) => state.getSingleUser);

  const navigate = useNavigate();

  const [projectStatus, setProjectStatus] = useState("");

  const downloadDocument = async (d) => {
    const url = await downloadDoc(d.data);

    window.open(url, "_blank");
  };
  return (
    <div>
      <div className="flex  absolute z-[9999]  left-10 top-10">
        <div className="flex p-1 items-center  gap-1 bg-white shadow-xl rounded-full">
          <MapFilter
            active={false}
            onClick={() => navigate("/admin/projects")}
            className="rounded-l-full"
          >
            <div
              active
              className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] py-2 px-4 rounded-l-full items-center gap-2"
            >
              <p className="opacity-30">List</p>
              <ListBullets size={12} className="opacity-30" weight="bold" />
            </div>
          </MapFilter>

          <MapFilter className="rounded-r-full" active={true}>
            <div className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] rounded-r-full py-2 px-4 items-center gap-2">
              <Wrench size={12} className="opacity-30" weight="bold" />
              <p className="opacity-30">Map</p>
            </div>
          </MapFilter>
        </div>
        <div className="flex relative ml-2">
          <div className="bg-white p-1 z-[999] shadow-lg rounded-full">
            <div
              onClick={() => setShowFilters(!showFilters)}
              className="bg-[#EBEBEB]  cursor-pointer py-2 px-[8px] h-full flex items-center justify-center  rounded-full"
            >
              {!showFilters ? (
                <FunnelSimple className="opacity-30" size={16} weight="bold" />
              ) : (
                <X className="opacity-30" size={16} weight="bold" />
              )}
            </div>
          </div>

          {showFilters && (
            <div className="absolute h-full shadow-lg rounded-full bg-white p-1 left-0">
              <div className="flex text-xs bg-[#F6F5F5] rounded-full pl-8  h-full font-semibold">
                <div className="px-3 flex items-center">
                  <select
                    value={projectStatus}
                    onChange={(e) => setProjectStatus(e.target.value)}
                    className="min-w-[98px] opacity-60 bg-[#F6F5F5] font-semibold  h-5 focus:outline-none"
                    placeholder="Asset Type"
                    name=""
                    id=""
                  >
                    <option value="">Project Status</option>
                    <option value="New">New</option>
                    <option value="Ongoing">Ongoing</option>
                    <option value="Halted">Halted</option>
                    <option value="Completed">Completed</option>
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <MainMapProjects
        openSideMenuProject={openSideMenuProject}
        setOpenSidemenuProject={setOpenSidemenuProject}
        status={projectStatus}
        threeD={vertical}
        map={activeFilter}
      />

      {/* <SelectionMap marker={marker} onMarkerDrag={onMarkerDrag} onMarkerDragEnd={onMarkerDragEnd} onMarkerDragStart={onMarkerDragStart} /> */}

      <div className="flex absolute top-10 p-1 bg-white gap-1 right-10 shadow-xl rounded-full">
        <MapFilter
          active={activeFilter.map1}
          onClick={() => setActiveFilter({ map1: true, map2: false })}
          className="rounded-l-full"
        >
          <div
            active
            className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] py-2 px-4 rounded-l-full items-center gap-2"
          >
            <MapTrifold size={12} className="opacity-30" weight="bold" />
            <p className="opacity-30">Map 1</p>
          </div>
        </MapFilter>

        <MapFilter
          className="rounded-r-full"
          active={activeFilter.map2}
          onClick={() => setActiveFilter({ map1: false, map2: true })}
        >
          <div className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] rounded-r-full py-2 px-4 items-center gap-2">
            <MapTrifold size={12} className="opacity-30" weight="bold" />
            <p className="opacity-30">Map 2</p>
          </div>
        </MapFilter>
      </div>

      {/* <div className='flex absolute top-10 p-1 bg-white gap-1 right-56 shadow-xl rounded-full'>
                    <MapFilter 
                        active={vertical} 
                        onClick={() => setVertial(true)} 
                        className='rounded-l-full'
                      >
                      <div active className='text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] py-2 px-4 rounded-l-full items-center gap-2'>
                        <Buildings size={12} className='opacity-30' weight="bold" />
                        <p className='opacity-30'>3D Asset</p>
                      </div>
                    </MapFilter>

                    <MapFilter 
                      className='rounded-r-full'
                      active={!vertical} 
                      onClick={() => setVertial(false)} 
                    >
                    <div className='text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] rounded-r-full py-2 px-4 items-center gap-2'>
                      <Buildings size={12} className='opacity-30' weight="bold" />
                        <p className='opacity-30'>2D Boundary</p>
                    </div>
                  </MapFilter>
                  </div> */}

      {openSideMenuProject && (
        <div className="fixed text-xs flex z-[99999999] top-14 py-3 flex-col right-0 w-72 h-full z-50 bg-[#EFEBE9] shadow-md">
          <div className="flex px-3 justify-between">
            <button
              onClick={() => setOpenSidemenuProject(false)}
              className="px-3 py-1.5 border bg-[#F7F6F6] rounded flex items-center gap-2"
            >
              <X size={12} /> close
            </button>
            <button
              onClick={() => {
                setOpenSidemenuProject(false);
                navigate(`/admin/projects/${project._id}`);
                listViewFunc();
              }}
              className="px-3 py-1.5 border bg-[#F7F6F6] rounded flex items-center gap-2"
            >
              {" "}
              view more details <ArrowRight size={12} />
            </button>
          </div>

          {project && (
            <div>
              <div className="grid gap-3">
                <div className="bg-[#F7F6F6] mx-3 mt-3 py-4 flex flex-col gap-2 p-4  rounded-md items-center">
                  <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                    Project Description
                  </p>
                  <div className="flex justify-between text-start w-full">
                    <div>
                      <p className="text-[10px] font-semibold">
                        {project.name}
                      </p>
                      <p className="text-[10px] ">
                        {" "}
                        {project.address?.street1}, {project.address?.city},{" "}
                        {project.address?.state}, {project.address?.country}
                      </p>
                    </div>
                    <p className="text-[10px] font-medium">ID: {project.uid}</p>
                  </div>

                  <div className="flex justify-between text-start w-full">
                    <div>
                      <p className="text-[10px] ">Status :</p>
                    </div>
                    <p className="text-[10px] font-medium">{project.status}</p>
                  </div>
                </div>

                <div className="bg-[#F7F6F6] mx-3 text-center py-4 flex flex-col gap-2  rounded-md items-center">
                  <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                    Project Owner
                  </p>
                  <div className="flex justify-between w-full px-4  py-3 items-center">
                    <div className="flex gap-2 items-center">
                      {project.companyId?.profilePic ? (
                        <img
                          src={`${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${project.companyId.profilePic}`}
                          className="w-8 rounded-full p-[2px] border border-theme-green"
                          alt=""
                        />
                      ) : (
                        project.companyId?.name && (
                          <p className="w-9 h-9 tracking-wide flex justify-center items-center text-white font-bold text-[18px] bg-theme-green p-0.5 border border-theme-green rounded-full">
                            {project.companyId.name.split(" ")[0][0]}
                            {project.companyId.name.split(" ")[1]
                              ? project.companyId.name.split(" ")[1][0]
                              : ""}
                          </p>
                        )
                      )}
                      <p className="font-medium text-[10px]">
                        {project.companyId?.name}
                      </p>
                    </div>
                    <p className="text-[10px] uppercase">
                      ID -{" "}
                      <span className="text-blue-400 underline">
                        {project.companyId?.uid}
                      </span>
                    </p>
                  </div>
                </div>

                {/* asset supplier doc */}
                <div className="px-3 py-8  bg-[#F7F6F6] border-t-4 border-bottom-border ">
                  <div className="flex gap-2 justify-between">
                    <FilterRitghtBar
                      onClick={() =>
                        setActive({
                          assets: true,
                          doc: false,
                          suppliers: false,
                        })
                      }
                      active={active.assets}
                    >
                      Assets
                    </FilterRitghtBar>
                    <FilterRitghtBar
                      onClick={() =>
                        setActive({
                          assets: false,
                          doc: true,
                          suppliers: false,
                        })
                      }
                      active={active.doc}
                    >
                      Documents
                    </FilterRitghtBar>
                    <FilterRitghtBar
                      onClick={() =>
                        setActive({
                          assets: false,
                          doc: false,
                          suppliers: true,
                        })
                      }
                      active={active.suppliers}
                    >
                      Companies
                    </FilterRitghtBar>
                  </div>

                  {/* asset */}
                  {active.assets && (
                    <div>
                      {assetsOfAProject?.length === 0 ? (
                        <p className="text-center py-6 text-xs">
                          No asset found.{" "}
                        </p>
                      ) : (
                        assetsOfAProject?.length !== 0 &&
                        assetsOfAProject !== undefined &&
                        assetsOfAProject?.map((p, index) => {
                          return (
                            <div className="flex border-b bg-white border-bottom-border px-6 py-5">
                              <div className="w-1/6 font-semibold ">
                                <p className="text-very-tiny ">ID</p>
                                <p className="text-very-tiny opacity-50">
                                  {p.uid}
                                </p>
                              </div>

                              <div className="w-1/2 text-xs">
                                <p className="font-medium">{p.name}</p>
                              </div>

                              <div className="w-1/6  font-semibold ">
                                <p className="text-very-tiny">Status</p>
                                <p className="text-very-tiny opacity-50 capitalize">
                                  {p.status}
                                </p>
                              </div>

                              <div className="text-very-tiny flex flex-1 justify-end font-medium">
                                <ArrowRight
                                  className="bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
                                  size={28}
                                  weight="light"
                                />
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  )}

                  {/* project */}
                  {active.doc && (
                    <div className="relative h-[85%]">
                      {docs?.length === 0 ? (
                        <p className="text-center py-6 text-xs">
                          No documents found.{" "}
                        </p>
                      ) : (
                        docs?.map((d, index) => {
                          let date = new Date(d.updatedAt);
                          let year = date.getFullYear();
                          let month = date.getMonth() + 1;
                          let dt = date.getDate();

                          if (dt < 10) {
                            dt = "0" + dt;
                          }
                          if (month < 10) {
                            month = "0" + month;
                          }
                          return (
                            <div className="flex border-b border-bottom-border px-6 py-5">
                              <div className="w-1/5 font-semibold ">
                                <p className="text-xs ">ID</p>
                                <p className="text-very-tiny opacity-50">
                                  {d.uid}
                                </p>
                              </div>

                              <div className="w-1/2 ">
                                <div className="flex gap-2 items-center">
                                  <div>
                                    <p className="font-medium text-xs leading-3">
                                      {d.name}
                                    </p>
                                    {d.type !== null && (
                                      <p className="leading-4 text-very-tiny font-medium">
                                        <span className="opacity-50 mr-1">
                                          {d.type.code}
                                        </span>{" "}
                                        <span className="uppercase text-blue-300 font-medium underline">
                                          {d.type.type}
                                        </span>
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="w-1/6 ">
                                <p className="text-xs font-medium">Last Edit</p>
                                <p className="text-very-tiny opacity-50 font-semibold capitalize">
                                  {dt}/{month}/{year}
                                </p>
                              </div>

                              <div className="text-xs flex flex-1 justify-end font-medium">
                                <DownloadSimple
                                  onClick={() => downloadDocument(d)}
                                  className="bg-#F7F6F6 p-1.5 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
                                  size={28}
                                  weight="light"
                                />
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  )}

                  {/* supplier */}

                  {active.suppliers && (
                    <div className="">
                      {suppliers?.length === (0 || undefined) ? (
                        <p className="text-center py-6 text-xs">
                          No suppliers found.{" "}
                        </p>
                      ) : (
                        suppliers?.length !== 0 &&
                        assetsOfAProject !== undefined &&
                        suppliers.map((s, idx) => {
                          return (
                            <div className="flex border-b justify-between  bg-white border-bottom-border px-6 py-5">
                              <div className="flex space-x-3 w-1/2">
                                <div className="w-1/6 font-semibold ">
                                  <p className="text-xs ">ID</p>
                                  <p className="text-very-tiny opacity-50">
                                    {s.uid}
                                  </p>
                                </div>

                                <div className="w-2/3 text-center">
                                  <div className="flex flex-1 gap-2   items-center">
                                    {/* <div>
                                                                  <img src={s?.profilePic ? s?.profilePic : "images/user1.jpeg"} className='w-8 p-0.5 border border-theme-green rounded-full' alt="" />
                                                              </div> */}
                                    <div>
                                      <p className="font-medium  text-xs">
                                        {s?.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="text-xs w-1/6 flex flex-1 justify-end font-medium">
                                <ArrowRight
                                  className="bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
                                  size={28}
                                  weight="light"
                                />
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MapView;
