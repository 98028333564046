import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import CreateNewNotifications from './CreateNewNotifications'
import ShowCase from './ShowCase'
import TopLink from './TopLinks'

const Notifications = () => {

const MainDiv = styled.div`
height: calc(100vh - 57px);

`
const {openAddNewNotifications} = useSelector(state => state.addNewNotifications)


  return (
    <>
        <MainDiv className='px-[32px] py-8 overflow-y-auto'>
            <TopLink/>
            {!openAddNewNotifications && <ShowCase/>}
            {openAddNewNotifications && <CreateNewNotifications/>}
        </MainDiv>
    </>
  )
}

export default Notifications