import classNames from "classnames";
import { ArrowRight } from "phosphor-react";
import React from "react";
import { useNavigate } from "react-router-dom";
const SupplierDropDown = ({ s, notExpandable }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={classNames(
          "flex py-4 ",
          "border-b border-bottom-border bg-very-light-gray",
          notExpandable ? "pl-24" : "px-6"
        )}
      >
        <div className="w-1/12 text-xs font-medium">
          <p className="uppercase">id</p>
          <p className="opacity-50">{s.uid}</p>
        </div>

        <div className="w-1/4 text-xs font-medium">
          <div className="flex gap-2 items-center">
            <div>
              {/* <img src={s.supplierDetails.imgUrl} className='w-7 p-0.5 border border-theme-green rounded-full' alt="" /> */}
            </div>
            <div>
              <p className="font-medium ">{s.name}</p>
              {/* <p className=' font-normal '>{s.supplierDetails.address}</p> */}
            </div>
          </div>
        </div>

        {/* <div className='w-1/6 text-xs font-medium'>
                                            <div className=''>
                                                <p className=''>Domain</p>
                                                <p  className='text-theme-black opacity-50 cursor-pointer '>{s.domain}</p>
                                            </div>
                                        </div> */}

        <div className="w-[14%] text-xs font-medium">
          <div className="">
            <p className="">Asset</p>
            <p className="text-theme-black opacity-50 cursor-pointer ">
              {s.stats.assets}
              {/* {!notExpandable && <i className={classNames("fa-solid ml-1 ", 'fa-angle-down')}></i>} */}
            </p>
          </div>
        </div>

        <div className="w-[14%] text-xs font-medium">
          <div className="">
            <p className="">Projects</p>
            <p className="text-theme-black opacity-50 cursor-pointer ">
              {s.stats.projects}
              {/* {!notExpandable && <i className={classNames("fa-solid ml-1 ", 'fa-angle-down')}></i>} */}
            </p>
          </div>
        </div>

        <div className="w-[14%] text-xs font-medium">
          <div className="">
            <p className="">Documents</p>
            <p className="text-theme-black opacity-50 cursor-pointer ">
              {s.stats.documents}
              {/* {!notExpandable && <i className={classNames("fa-solid ml-1 ", 'fa-angle-down')}></i>} */}
            </p>
          </div>
        </div>

        <div className="text-xs flex justify-end flex-1 font-medium">
          {/* <ArrowRight className='bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded' size={28} weight='light'/> */}
        </div>
      </div>
    </>
  );
};

export default SupplierDropDown;
