import classNames from "classnames";
import { Buildings, CaretDown, MagnifyingGlass, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies } from "../../../../redux/actions/companies/getCompanies";
import { getSuppliers } from "../../../../redux/actions/suppliers/getSuppliers";
import SingleSupplier from "../../../components/Projects/comps/singleProjectComps/projectLinks/SingleSupplier";
import { FakeSuppliers } from "../../../fake/fakeSuppliers";

const InviteSupplierTab = ({
  email,
  setEmail,
  name,
  setName,
  selectedSuppliersArr,
  setselectedSuppliersArr,
  value,
  setValue,
  closeInviteTab,
  inviteUser,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const showDropDownFunc = () => {
    setShowDropDown(!showDropDown);
  };

  const selectedSupplier = (p) => {
    if (!selectedSuppliersArr.includes(p)) {
      setselectedSuppliersArr([...selectedSuppliersArr, p]);
    }

    setShowDropDown(false);
  };

  const removeSupplier = (s) => {
    let newArr = [...selectedSuppliersArr];
    let newArr2 = newArr.filter((p) => p.id !== s.id);
    setselectedSuppliersArr(newArr2);
  };

  //

  const dispatch = useDispatch();

  const { suppliers } = useSelector((state) => state.suppliers);

  const changeHandler = (e) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    dispatch(getSuppliers());
  }, []);

  return (
    <>
      <div className="bg-[#EFEBE9] relative min-h-[34rem] rounded-md py-6 w-[45%] mt-6">
        <div className="flex justify-center py-4 gap-4">
          {/* <div class="flex  gap-1 items-center">
                <input onChange={changeHandler} type="radio" name="radio" checked={value === 'document'} value={'document'} id="radio1" />
                <label className='text-xs' for="radio1">Invite Exsting Supplier</label>
            </div> */}
          <div class="flex gap-1  items-center">
            {/* <input onChange={changeHandler} type="radio" name="radio" checked={value === 'no_document'} value={'no_document'} id="radio2" /> */}
            <label className="text-sm font-semibold" for="radio2">
              Invite New Company
            </label>
          </div>
        </div>

        {/* {value === 'document' && <>
            <p className='text-sm py-6 text-center'>Add an existing Supplier to the Project</p>

            <div className='mx-6 relative my-2'>
                <input onClick={showDropDownFunc} placeholder={showDropDown ? "Select Existing Projects" : 'Add Project'} type="text" className={classNames('w-full border border-[#00000033] bg-white cursor-pointer rounded-t-md focus:outline-none py-3 text-very-tiny', showDropDown ? "px-4" : 'px-[36px] rounded-b-md')} />
                {!showDropDown && <Buildings size={16} weight='bold' className='absolute text-theme-black opacity-80 left-3 top-[13px]' />}
                {showDropDown ? <MagnifyingGlass size={14} className='absolute text-theme-black opacity-80 right-3 top-[14px]' /> : <CaretDown size={14} className='absolute text-theme-black opacity-80 right-3 top-[14px]' />}
                {
                    showDropDown && <div className='border-b absolute z-[99] max-h-72 overflow-y-scroll w-full cursor-pointer min-h-64  border-[#00000033]'>
                        {
                            suppliers.map((p, index) => {
                                return <SingleSupplier onClick={() => selectedSupplier(p)} key={index} s={p} />
                            })
                        }
                    </div>
                }
                {<div className='grid px-12 gap-4 mt-6 rounded-lg'>
                    <p className='opacity-50 text-xs'>Added Projects:</p>
                    {
                        selectedSuppliersArr.map((s, idx) => {
                            return <div key={idx} className='bg-white border border-[#EFEBE9] rounded-lg'>
                                <p className='text-xs font-medium bg-[#DAD2D2] p-2 rounded-t-lg flex justify-between items-center'>{s?.name}<X onClick={() => removeSupplier(s)} className='cursor-pointer' size={12} /></p>
                                <div className='p-2'>
                                    <p className='text-xs py-1'>UID: <span className='opacity-50'>ID {s.uid}</span></p>
                                    <p className='text-xs py-1'>Owner: <span className='opacity-50'>Jhon Doe</span></p>
                                </div>
                            </div>
                        })
                    }
                </div>}
            </div>
        </>} */}

        {
          <div className="grid gap-3 px-6">
            <div className="grid gap-2 mt-5">
              <p className="text-sm">Name</p>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your company name"
                className="w-full text-xs bg-white w-full border-[1.5px] focus:outline-none rounded-md border-[rgba(0, 0, 0, 0.1)] py-2.5 px-4"
                type="text"
              />
            </div>

            <div className="grid gap-2 mt-5">
              <p className="text-sm">Email</p>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email to invite"
                className="w-full text-xs bg-white w-full border-[1.5px] focus:outline-none rounded-md border-[rgba(0, 0, 0, 0.1)] py-2.5 px-4"
                type="text"
              />
            </div>
          </div>
        }
        <div className="absolute flex w-full px-6 gap-2 bottom-3">
          <button
            onClick={closeInviteTab}
            className="flex-1 w-1/2 py-2 bg-transparent border-2 border-bottom-border uppercase text-sm font-bold rounded-lg hover:bg-white hover:border-[#00000066]"
          >
            <span className="opacity-40">Cancel</span>
          </button>
          <button
            onClick={inviteUser}
            className="flex-1 w-1/2 py-2 bg-theme-light-green text-theme-green border-2 border-theme-green uppercase text-sm font-bold rounded-lg hover:bg-theme-green hover:text-black"
          >
            <span className="">Invite</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default InviteSupplierTab;
