import classNames from "classnames";
import { useFormik } from "formik";
import {
  ArrowBendUpLeft,
  CaretLeft,
  Info,
  NotePencil,
  X,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { upload } from "../../../../api/users/fileUpload";
import { updateUser } from "../../../../api/users/inviteUsers";
import { functionalOptions } from "../../../../json/dropwownData";
import { getUsers } from "../../../../redux/actions/users/getUsers";
import { CLOSE_SINGLE_EMPLOYEE_ADMIN } from "../../../../redux/constants/AdminConsts/EmployeesAdminConst";
import { City } from "country-state-city";
import { openSingleEmployeeAdminAction } from "../../../../redux/actions/Admin/OpenSingleEmployeeAdmin";
import { useNavigate } from "react-router-dom";
import { reInviteUser } from "../../../../api/users/users";

const Infos = ({ user, initialEdit }) => {
  const [edit, setEdit] = useState(initialEdit);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // const [email, setEmail] = useState('dev.shrinivash@gmail.com')
  // const [phone, setphone] = useState('+44 7554539078')
  // const [name, setname] = useState('Guy Hawkins')
  // const [address, setaddress] = useState('121, Baker St., London')
  // const spatialOptions = [
  //     {value:"admin", label:'Project Admin' },
  //     {value:"user", label:'User' },
  // ]
  // const [role, setRole] = useState(spatialOptions[0])
  const spatialOptions = [
    { value: "627955212019dc8e78e71ba8", label: "CompanyAdmin" },

    { value: "627955212019dc8e78e71ba9", label: "User" },
  ];

  const formik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      profilePic: user.profilePic ? user.profilePic : "",
      phone: user.phone,
      address: {
        street1: user?.address?.street1,
        street2: user?.address?.street2,
        city: user?.address?.city,
        state: user?.address?.state,
        country: user?.address?.country,
      },
    },
    onSubmit: async (values, resetForm) => {
      toast.loading("updating user ");
      try {
        const res = await updateUser(user._id, values);

        toast.dismiss();
        toast.success("user updated successfully");
        dispatch(getUsers());
        setEdit(!edit);
      } catch (error) {
        setEdit(!edit);
        toast.dismiss();
        toast.error(error?.message);
      }
    },
  });

  const customStyles = {
    control: (base, state) => ({
      ...base,
      padding: "8px",
      background: "#0000000D",
      border: "0",
      borderRadius: state.isFocused ? "4px 4px 4px 4px" : 6,
      borderColor: state.isFocused ? "#0000000D" : "#0000000D",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "#0000000D" : "#0000000D",
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    options: (base) => ({
      ...base,
      padding: 0,
      "&:hover": {
        background: "#6ABEAE",
      },
    }),
  };

  // upload Image
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  useEffect(() => {
    const call = async () => {
      if (!selectedFile) {
        setPreview(undefined);
        return;
      }
      const url = await upload(selectedFile);
      formik.values.profilePic = url;
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);

      // free memory when ever this component is unmounted
      // return () => URL.revokeObjectURL(objectUrl);
    };
    call();
  }, [selectedFile]);

  const uploadImg = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  // button click handlers
  const backHandler = () => {
    dispatch({ type: CLOSE_SINGLE_EMPLOYEE_ADMIN });
    navigate("/admin/employees");
  };

  const suspendHandler = async () => {
    toast.loading("suspending user");
    try {
      const res = await updateUser(user._id, { status: "suspended" });
      toast.dismiss();
      toast.success("Employee suspended successfully");
      setOpenModal(false);
      // to refresh
      dispatch(openSingleEmployeeAdminAction(user._id, false));
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
  };

  const [openModal, setOpenModal] = useState(false);

  const revokeSuspension = async () => {
    toast.loading("Revoking the suspension");
    try {
      await updateUser(user._id, { status: "active" });
      toast.dismiss();
      toast.success("Suspension revoked successfully");
      setOpenModal(false);
      // to refresh
      dispatch(openSingleEmployeeAdminAction(user._id, false));
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
  };

  // reset password
  const [resetPassword, setResetPassword] = useState(false);

  const resetPasswordHandler = async () => {
    try {
      await reInviteUser(user._id);
      setResetPassword(true);
    } catch (error) {}
  };

  // state and city

  const stateOptions = [
    { label: "England", value: "ENG" },
    { label: "Scotland", value: "SCT" },
    { label: "Wales", value: "WLS" },
    { label: "Northern Ireland", value: "NIR" },
  ];

  const [selectedState, setSelectedState] = useState(
    stateOptions.filter((val) => val.label === formik.values.address?.state)[0]
      ?.value
  );

  const cities = City.getCitiesOfState("GB", selectedState);
  let citiesOptions = cities.map((s) => {
    return { value: s.name, label: s.name };
  });

  return (
    <>
      <div className="rounded-lg border-2 border-black-10">
        <div className="flex p-6 justify-between">
          <button
            onClick={backHandler}
            className="bg-[#F6F5F5] px-4 py-2 text-opacity-70 text-theme-black flex gap-2 text-xs items-center rounded-md border border-black-10 font-medium"
          >
            <CaretLeft size={16} className="opacity-70 text-theme-black" />
            Back
          </button>
          <div className="flex gap-3">
            {user.status === "pending" && (
              <button
                onClick={resetPasswordHandler}
                className="flex gap-2 items-center px-4 py-1 bg-[#F7F6F6] hover:bg-[#0000001a] hover:border-[#00000033] rounded-md border border-black10 text-xs text-theme-black text-opacity-80"
              >
                Reset Password
              </button>
            )}
            <button
              onClick={() => setOpenModal(true)}
              className="bg-[#FF38231A] hover:bg-[#FF382366] px-4 py-2 text-opacity-70 text-[#FF3823B2] flex gap-2 text-xs items-center rounded-md border border-black-10 font-medium"
            >
              {user.status === "suspended" ? "Revoke" : "Suspend"}
              {user.status === "suspended" ? (
                <ArrowBendUpLeft color="#FF382366" size={14} />
              ) : (
                <img src="/images/notAllowedRed.svg" alt="" />
              )}{" "}
            </button>
            <button
              onClick={() => {
                if (edit) {
                  formik.handleSubmit();
                } else {
                  setEdit(!edit);
                }
              }}
              className="flex gap-2 items-center px-4 py-1 bg-[#F7F6F6] hover:bg-[#0000001a] hover:border-[#00000033] rounded-md border border-black10 text-xs text-theme-black text-opacity-80"
            >
              <img src="/images/edit.svg" alt="" />{" "}
              {!edit ? "Edit Profile" : "Save Profile"}
            </button>
          </div>
        </div>

        <div className="grid grid-cols-3 border-t bg-[#32313208]">
          <div className="p-8 text-xs items-center border-r border-b flex gap-8">
            <p>Picture</p>
            <input
              accept=".png,.jpg,.jpeg,.svg"
              onChange={uploadImg}
              type="file"
              id="fileUp"
              style={{ display: "none" }}
            />
            {edit ? (
              <div className="relative">
                <label htmlFor="fileUp">
                  <img
                    src={
                      preview
                        ? preview
                        : `${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${formik.values.profilePic}`
                    }
                    className="p-[1px] rounded-full border h-11 w-11 object-cover border-theme-green"
                    alt=""
                  />
                  <div className="flex cursor-pointer absolute top-0 left-0 rounded-full w-full h-full justify-center items-center bg-black bg-opacity-20">
                    <NotePencil className="text-white " size={16} />
                  </div>
                </label>
              </div>
            ) : (
              <img
                src={`${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${formik.values.profilePic}`}
                className="p-[1px] rounded-full border h-11 border-theme-green"
                alt=""
              />
            )}
          </div>

          <div className="p-8 text-xs items-center border-r border-b flex gap-8">
            <p>Email</p>
            <input
              name="email"
              disabled={true}
              value={user.email}
              type="text"
              className={classNames(
                "focus:outline-none p-4 w-full rounded-md",
                edit ? "bg-[#0000000D]" : "bg-transparent"
              )}
            />
          </div>

          <div className="p-8 text-xs items-center border-b flex gap-8">
            <p>Phone</p>
            <input
              name="phone"
              disabled={!edit}
              value={formik.values.phone}
              onChange={formik.handleChange}
              type="text"
              className={classNames(
                "focus:outline-none p-4 w-full rounded-md",
                edit ? "bg-[#0000000D]" : "bg-transparent"
              )}
            />
          </div>

          <div className="p-8 text-xs items-center border-r flex gap-8">
            <p>Name</p>
            {edit ? (
              <div className={classNames("flex space-x-3")}>
                <input
                  name="firstName"
                  disabled={!edit}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  type="text"
                  className={classNames(
                    "focus:outline-none p-4 w-full rounded-md",
                    edit ? "bg-[#0000000D]" : "bg-transparent"
                  )}
                />
                <input
                  disabled={!edit}
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  type="text"
                  className={classNames(
                    "focus:outline-none p-4 w-full rounded-md",
                    edit ? "bg-[#0000000D]" : "bg-transparent"
                  )}
                />
              </div>
            ) : (
              <input
                disabled={!edit}
                value={`${formik.values.firstName} ${formik.values.lastName}`}
                type="text"
                className={classNames(
                  "focus:outline-none p-4 w-full rounded-md",
                  edit ? "bg-[#0000000D]" : "bg-transparent"
                )}
              />
            )}
          </div>

          <div className="p-8 text-xs items-center border-r flex gap-8">
            <p>Role</p>
            {
              <p
                type="text"
                className={classNames(
                  "focus:outline-none p-4 w-full rounded-md"
                )}
              >
                {user?.roleId?.name}
              </p>
            }
          </div>

          <div className={`p-8 text-xs items-center border-y flex gap-8`}>
            <p>{edit ? "Address Line 1" : "Address"}</p>
            {!edit ? (
              <p>
                {formik.values.address.street1},{" "}
                {formik.values.address.street2
                  ? formik.values.address.street2 + ","
                  : ""}{" "}
                {formik.values.address.city}, {formik.values.address.state},{" "}
                {formik.values.address.country}
              </p>
            ) : (
              <>
                {/* <input
                name ="address.street2"
                disabled={!edit}
                value={formik.values.address.street2}
                onChange={formik.handleChange}
                type="text"
                className={classNames(
                  "focus:outline-none p-4 w-full rounded-md",
                  edit ? "bg-[#0000000D]" : "bg-transparent"
                )}
              /> */}
                <input
                  name="address.street1"
                  disabled={!edit}
                  value={formik.values.address.street1}
                  onChange={formik.handleChange}
                  type="text"
                  className={classNames(
                    "focus:outline-none p-4 w-full rounded-md",
                    edit ? "bg-[#0000000D]" : "bg-transparent"
                  )}
                />
              </>
            )}
          </div>
          {edit && (
            <>
              <div className="p-8 text-xs items-center border-y flex gap-8">
                <p>Address Line 2</p>
                <input
                  name="address.street2"
                  disabled={!edit}
                  value={formik.values.address.street2}
                  onChange={formik.handleChange}
                  type="text"
                  className={classNames(
                    "focus:outline-none p-4 w-full rounded-md",
                    edit ? "bg-[#0000000D]" : "bg-transparent"
                  )}
                />
              </div>

              <div className="p-8 text-xs items-center border-y flex gap-8">
                <p>Country</p>
                <Select
                  onChange={(val) =>
                    formik.setFieldValue("address.country", "United Kingdom")
                  }
                  styles={customStyles}
                  className="text-xs w-full"
                  value={{ label: "United Kingdom", value: "United Kingdom" }}
                  options={[
                    { label: "United Kingdom", value: "United Kingdom" },
                  ]}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      text: "#000",
                      primary25: "#6ABEAE",
                      primary: "#6ABEAE",
                    },
                  })}
                  placeholder="Select"
                />
              </div>
              <div className="p-8 text-xs items-center border-y border-x flex gap-8">
                <p>State</p>

                <Select
                  onChange={(val) => {
                    formik.setFieldValue("address.state", val.label);
                    setSelectedState(val.value);
                  }}
                  styles={customStyles}
                  value={
                    stateOptions.filter(
                      (val) => val.label === formik.values.address.state
                    )[0]
                  }
                  className="text-xs w-full"
                  options={stateOptions}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      text: "#000",
                      primary25: "#6ABEAE",
                      primary: "#6ABEAE",
                    },
                  })}
                  placeholder="Select"
                />
              </div>
              <div className="p-8 text-xs items-center border-y flex gap-8">
                <p>City</p>

                <Select
                  onChange={(val) =>
                    formik.setFieldValue("address.city", val.label)
                  }
                  styles={customStyles}
                  value={
                    citiesOptions.filter(
                      (val) => val.label === formik.values.address.city
                    )[0]
                  }
                  className="text-xs w-full"
                  options={citiesOptions}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      text: "#000",
                      primary25: "#6ABEAE",
                      primary: "#6ABEAE",
                    },
                  })}
                  placeholder="Select"
                />
              </div>
            </>
          )}
        </div>
      </div>
      {/* reset password */}

      {resetPassword && (
        <div className="fixed text-xs right-[54px] top-20 bg-theme-green bg-opacity-50 border border-theme-green flex gap-2 p-2 rounded-md">
          <p>We have e-mailed your password reset link on your account.</p>
          <X
            onClick={() => setResetPassword(false)}
            className="cursor-pointer"
            size={14}
          />
        </div>
      )}

      {/* suspend user */}
      {/* suspend modal */}
      {openModal && (
        <div
          onClick={() => setOpenModal(false)}
          className="fixed w-[100vw] h-[100vh] top-0 left-0 z-[9999] bg-black bg-opacity-20"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute w-[25rem] p-4 bg-white rounded-md border-[3px] border-theme-black border-opacity-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <Info size={28} className="" color="#323132CC" weight="bold" />
            <div className="mt-4  pb-4 border-b">
              <p className="font-bold text-xs">
                {user?.status !== "suspended"
                  ? "Suspend user"
                  : "Revoke suspension"}
                ?
              </p>
              <p className="text-xs">
                Are you sure you want to{" "}
                {user?.status !== "suspended"
                  ? "suspend this user"
                  : "revoke the suspension"}
                ?{" "}
              </p>
            </div>

            <div className="py-4 grid gap-4 grid-cols-2">
              <button
                onClick={() => setOpenModal(false)}
                className="py-2 bg-[#F7F6F6] rounded-md hover:bg-[#0000001a] hover:border-[#00000033] border border-black10 text-xs text-theme-black text-opacity-80"
              >
                {" "}
                No
              </button>
              <button
                onClick={() => {
                  if (user.status !== "suspended") {
                    suspendHandler();
                  } else {
                    revokeSuspension();
                  }
                }}
                className="py-2 bg-theme-green text-xs rounded-md"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Infos;
// notAllowedRed
