import { GET_DASHBOARD_STATS_FAIL, GET_DASHBOARD_STATS_REQ, GET_DASHBOARD_STATS_SUCC } from "../constants/dashboard";

export const getDahboardDataReducer = (state={stats :{}, loading:true, error:null}, action)=> {
        switch(action.type){
            case GET_DASHBOARD_STATS_REQ:
                return {loading :true, ...state}

            case GET_DASHBOARD_STATS_SUCC:
                return{
                    loading: false,
                    stats: action.payload,
                }
            
            case GET_DASHBOARD_STATS_FAIL:
                return{
                    loading:false,
                    error:action.payload
                }
            
            default:
                return state;
        }
}