
import { getSingleDocumnet } from "../../../api/users/document";
import { GET_SINGLE_DOCUMENT_FAIL, GET_SINGLE_DOCUMENT_REQ, GET_SINGLE_DOCUMENT_SUCC } from "../../constants/documents/SingleDocumentOpener";
import { OPEN_SINGLE_VERSION_FAIL, OPEN_SINGLE_VERSION_REQ, OPEN_SINGLE_VERSION_SUCC } from "../../constants/documents/VersionOpenerConsts";


export const openSingleDocumentAction = (id) => async(dispatch) => {
    try{
        dispatch({type: GET_SINGLE_DOCUMENT_REQ})



             const res = await getSingleDocumnet(id)


        dispatch({
                type: GET_SINGLE_DOCUMENT_SUCC,
                payload:res.data
            })
    } catch(error){
        dispatch({
            type: GET_SINGLE_DOCUMENT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message 
        })
    }
}


export const opneSingleVersion = (version) => async(dispatch) => {
    try{
        dispatch({type:OPEN_SINGLE_VERSION_REQ})
        const res = await getSingleDocumnet(version._id)

        dispatch({
            type: OPEN_SINGLE_VERSION_SUCC,
            payload:res.data
        })

    }

    catch(error){
        dispatch({
            type: OPEN_SINGLE_VERSION_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message 
        })
    }
}