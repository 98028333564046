/* eslint-disable no-unused-vars */
import classNames from "classnames";
import { ArrowSquareRight, MagnifyingGlass, Plus } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getLoggedInUserApi } from "../../../api/users/users";
import { getLoggedInUser } from "../../../redux/actions/me/user";
import {
  ADVANCED_SEARCH_OPEN,
  NOTIFICATIONS_OPEN,
  SUPPORT_CHAT_DEV_OPEN,
  SUPPORT_CHAT_OPEN,
  SUPPORT_CHAT_USER_OPEN,
} from "../../../redux/constants/AdminConsts/LeftNavAdminConst";
import { Filter } from "../../../styles/FilterButton";
import ProfileDropDown from "./ProfileDropDown";
import { getAssetsApi, getAssetsProjectsApi } from "../../../api/users/asset";
import { getAllDocs } from "../../../api/users/document";
import { getAllProjectsApi } from "../../../api/users/projects";
import Loading from "../../components/loading/Loading";
import { getCompaniesApi } from "../../../api/users/company";
import { Link, useNavigate } from "react-router-dom";

const ImgDiv = styled.div`
  width: 132px;
`;

const InputDiv = styled.div`
  width: 40rem;
`;

const SearchResults = styled.div.attrs({
  className: "py-3 flex px-4 border-t-2 text-very-tiny items-center",
})``;

const MainDiv = styled.div`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  -o-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
`;

const TopNavAdmin = () => {
  const [dropDownActive, setDropDownActive] = useState(false);

  const [searchInputValue, setSearchInputValue] = useState("");

  const [searchFilterActive, setSearchFilterActive] = useState({
    everything: true,
    assets: false,
    project: false,
  });
  const [showSearchDropDown, setShowSearchDropDown] = useState(false);

  const { user } = useSelector((state) => state.me);

  useEffect(() => {
    if (showSearchDropDown) {
      document.body.onclick = function () {
        setShowSearchDropDown(false);
      };
    }
  }, [showSearchDropDown]);

  const [showSupportOptions, setShowSupportOptions] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (showSupportOptions) {
      document.body.onclick = function () {
        setShowSupportOptions(false);
      };
    }
  }, [showSupportOptions]);

  useEffect(() => {
    dispatch(getLoggedInUser());
  }, []);

  // search

  const [result, setResult] = useState([]);

  const assetsResults = useRef();
  const documentsResults = useRef();
  let projectsResults = useRef();
  let companiesResults = useRef();

  const [loadingAssets, setLoadingAssets] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingDocs, setLoadingDocs] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);

  const assetsSearchAPIHandle = async () => {
    try {
      setLoadingAssets(true);
      const res = await getAssetsApi("", 5, 1, "", "", searchInputValue);
      setLoadingAssets(false);
      assetsResults.current = res.data.docs;
    } catch (error) { }
  };

  const documentSearchAPIHandle = async () => {
    try {
      setLoadingDocs(true);

      const res = await getAllDocs(
        1,
        5,
        "",
        "",
        "",
        searchInputValue,
        1,
        user.companyId._id,
        []
      );
      setLoadingDocs(false);
      documentsResults.current = res.data.docs;
    } catch (error) { }
  };

  const projectSearchAPIHandle = async () => {
    try {
      setLoadingProjects(true);
      const res = await getAllProjectsApi("", 5, 1, searchInputValue, "", []);
      setLoadingProjects(false);
      projectsResults.current = res.data.docs;
    } catch (error) { }
  };

  const companySearchAPIHandle = async () => {
    try {
      setLoadingCompanies(true);
      const res = await getCompaniesApi(5, 1, searchInputValue);
      setLoadingCompanies(false);
      companiesResults.current = res.data.docs;
    } catch (error) { }
  };

  const [active, setActive] = useState({
    assets: true,
    documents: false,
    projects: false,
    companies: false,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchInputValue.trim() !== "") {
        assetsSearchAPIHandle();
        documentSearchAPIHandle();
        projectSearchAPIHandle();
        companySearchAPIHandle();
      }
    }, 300);
    return () => clearTimeout(timer);
  }, [searchInputValue]);

  useEffect(() => {
    if (active.projects) {
      setResult(projectsResults.current);
    }

    if (active.documents) {
      setResult(documentsResults.current);
    }

    if (active.assets) {
      setResult(assetsResults.current);
    }

    if (active.companies) {
      setResult(companiesResults.current);
    }

    if (searchInputValue.trim(" ") === "") {
      setResult([]);
    }
  }, [
    searchInputValue,
    active,
    projectsResults.current,
    documentsResults.current,
    assetsResults.current,
    companiesResults.current,
  ]);

  const results = active.projects
    ? projectsResults.current
    : active.documents
      ? documentsResults.current
      : active.assets
        ? assetsResults.current
        : companiesResults.current;

  let loading =
    loadingAssets || loadingCompanies || loadingProjects || loadingDocs;

  var today = new Date();
  var curHr = today.getHours();

  let time;

  if (curHr < 12) {
    time = "Good Morning";
  } else if (curHr < 18) {
    time = "Good Afternoon";
  } else {
    time = "Good Evening";
  }
  const navigate = useNavigate();

  const navigateFunc = (r) => {
    setShowSearchDropDown(false);
    if (active.assets) {
      navigate(`/admin/assets/${r._id}`);
    }

    if (active.projects) {
      navigate(`/admin/projects/${r._id}`);
    }

    if (active.documents) {
      navigate(`/admin/documents/${r._id}`);
    }

    if (active.companies) {
      navigate(`/admin/companies/${r._id}`);
    }
  };
  return (
    <>
      <MainDiv
        className={classNames(
          "flex z-50 w-full bg-white justify-between border-b-[0.5px] border-theme-green"
        )}
      >
        <div className="flex items-center">
          <ImgDiv className="border-theme-green  flex justify-center bg-opacity-[0.95] bg-theme-black">
            <img className="p-3 h-14" src="/images/admin/logo.svg" alt="" />
          </ImgDiv>
          <InputDiv className="flex h-full relative justify-center">
            <div className="w-[90%] px-8 border-r-0.5 border-l-0.5 border-theme-green bg-very-light-gray flex items-center ">
              <div className="w-full relative">
                <input
                  value={searchInputValue}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    setSearchInputValue(e.target.value);
                    setShowSearchDropDown(true);
                  }}
                  className="border-2 w-full border-border-input text-very-tiny focus:outline-none py-2 px-4 rounded-full"
                  type="text"
                  placeholder="Search for Suppliers, Assets, Projects or Documents"
                />
                <div className="absolute h-full justify-center items-center right-0 top-0 flex">
                  <div
                    onClick={(e) => {
                      setShowSearchDropDown(!showSearchDropDown);
                      e.stopPropagation();
                    }}
                    className="border-r-0.5 cursor-pointer border-l-0.5 w-16 bg-very-light-gray border-border-input h-full flex justify-center items-center"
                  >
                    <MagnifyingGlass className="" size={18} />
                  </div>
                  <div
                    onClick={() => {
                      dispatch({ type: ADVANCED_SEARCH_OPEN });
                      navigate(`advancedSearch?q=${searchInputValue}`);
                    }}
                    className="w-16 cursor-pointer bg-very-light-gray flex justify-center h-full rounded-r-full  items-center"
                  >
                    <MagnifyingGlass className="" size={18} />
                    <Plus size={14} />
                  </div>
                </div>
              </div>
            </div>

            {showSearchDropDown && (
              <div
                onClick={(e) => e.stopPropagation()}
                className="absolute w-5/6 bg-white z-[999999] rounded-b-md top-full max-w-52"
              >
                <div className=" pt-3 border-2 rounded-b-md">
                  <div className="px-4 flex justify-between">
                    <Filter
                      onClick={() => setActive({ assets: true })}
                      active={active.assets}
                    >
                      Assets
                    </Filter>
                    <Filter
                      onClick={() => setActive({ projects: true })}
                      active={active.projects}
                    >
                      Projects
                    </Filter>
                    <Filter
                      onClick={() => setActive({ documents: true })}
                      active={active.documents}
                    >
                      Documents
                    </Filter>
                    <Filter
                      onClick={() => setActive({ companies: true })}
                      active={active.companies}
                    >
                      Companies
                    </Filter>
                  </div>
                  <p className="px-4 text-very-tiny my-2">
                    <span className=" opacity-50">Searching for</span>{" "}
                    <span className="text-theme-green opacity-100">
                      {searchInputValue}
                    </span>
                  </p>
                  <div>
                    {loading ? (
                      <Loading loading={loading} />
                    ) : (
                      results?.map((r, index) => {
                        return (
                          <SearchResults key={index}>
                            <p className="flex-1">{r.name}</p>
                            <div className="flex-1 flex items-center justify-between">
                              <div className="flex gap-2">
                                <p className="italic opacity-50 ">in</p>
                                <p className="">
                                  {active.assets
                                    ? "Assets"
                                    : active.projects
                                      ? "Projects"
                                      : active.documents
                                        ? "Documents"
                                        : "Companies"}
                                </p>
                              </div>
                              {/* <ArrowSquareRight className='text-#F7F6F6' size={28} weight="bold" /> */}
                              <i
                                onClick={() => navigateFunc(r)}
                                class="fa-solid fa-arrow-right bg-#F7F6F6 px-2 rounded py-1 text-xs cursor-pointer"
                              ></i>
                            </div>
                          </SearchResults>
                        );
                      })
                    )}
                  </div>
                  <div className="w-full px-4  border-t-2 py-3">
                    <button
                      disabled={result?.length === 0}
                      onClick={() => {
                        navigate(`advancedSearch?q=${searchInputValue}`);
                        setShowSearchDropDown(false);
                      }}
                      className={classNames(
                        "text-very-tiny text-center py-2.5 font-medium  w-full rounded-md",
                        result?.length === 0
                          ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                          : "bg-theme-green"
                      )}
                    >
                      View All
                    </button>
                  </div>
                </div>
              </div>
            )}
          </InputDiv>
        </div>

        <div className="flex flex-1 justify-end items-center">
          <div
            onClick={(e) => {
              dispatch({ type: SUPPORT_CHAT_USER_OPEN });
              navigate("support");
            }}
            className="w-16 relative border-theme-green hover:bg-gray-200 cursor-pointer  bg-very-light-gray border-l-0.5 border-r-0.5 border-theme-green h-full flex justify-center items-center"
          >
            <div className="border border-theme-green rounded-full">
              <i className="fa-solid fa-question text-xs bg-theme-black m-0.5 text-white flex items-center justify-center w-7 h-7 rounded-full"></i>
            </div>
          </div>
          <div
            onClick={() => {
              dispatch({ type: NOTIFICATIONS_OPEN });
              navigate("notifications");
            }}
            className="w-16 bg-very-light-gray border-r-0.5 cursor-pointer hover:bg-gray-200 cursor-pointer border-theme-green h-full flex justify-center items-center"
          >
            <div className="border border-theme-green rounded-full">
              <i className="fa-solid fa-bell text-xs bg-theme-black m-0.5 text-white flex items-center justify-center w-7 h-7 rounded-full"></i>
            </div>
          </div>

          <div
            onClick={() => setDropDownActive(!dropDownActive)}
            className={classNames(
              "w-72 cursor-pointer h-full flex items-center select-none",
              dropDownActive && "bg-theme-green"
            )}
          >
            <div className="px-4">
              <div className="flex gap-3">
                <div className="border border-theme-green rounded-full">
                  <img
                    src={
                      !user.profilePic
                        ? `/images/profile.svg`
                        : `${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${user.profilePic}`
                    }
                    className="w-7 h-7 object-cover rounded-full m-0.5"
                    alt=""
                  />
                </div>
                <div>
                  <p className="text-very-tiny font-light">{time},</p>
                  <p className="text-very-tiny font-semibold">
                    {user.firstName} {user.lastName}{" "}
                    <i
                      className={classNames(
                        "fa-solid ml-1 ",
                        dropDownActive ? "fa-angle-up" : "fa-angle-down"
                      )}
                    ></i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainDiv>

      {dropDownActive && (
        <ProfileDropDown
          user={user}
          closeProfileDropDown={() => setDropDownActive(false)}
        />
      )}

      {showSupportOptions && (
        <div className="fixed z-[999] bg-white text-very-tiny top-[64px] w-32 right-72 border border-black-10 rounded-md">
          <p
            onClick={() => dispatch({ type: SUPPORT_CHAT_USER_OPEN })}
            className="p-1 cursor-pointer rounded-t-md border-b"
          >
            User Support Tickets
          </p>
          <p
            onClick={() => dispatch({ type: SUPPORT_CHAT_DEV_OPEN })}
            className="p-1 cursor-pointer"
          >
            Dev Support Tickets
          </p>
        </div>
      )}
    </>
  );
};

export default TopNavAdmin;
