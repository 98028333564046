import axios from "axios";
import { serialize } from "../../libs/serialize";
import { accessToken } from "../../libs/token";

export const createAsset = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/asset`,
    data,
    await accessToken()
  );
};

export const getSingleAssetApi = async (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/asset/${id}`,
    await accessToken()
  );
};

export const addProjectToAsset = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/asset/projects`,
    data,
    await accessToken()
  );
};

export const getAssetsApi = async (
  companyId,
  limit,
  page,
  assetType,
  assetStatus,
  search,
  excludedIds = [],
  projectId
) => {
  const uri = serialize({
    companyId,
    limit,
    page,
    assetType,
    status: assetStatus,
    q: search,
    projectId,
  });

  const excludeIds = excludedIds.join("&excludeIds[]=");

  if (excludedIds.length === 0) {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/asset?${uri}`,
      await accessToken()
    );
  } else {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/asset?${uri}&excludeIds[]=${excludeIds}`,
      await accessToken()
    );
  }
};

export const getAssetsProjectsApi = async (assetId) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/asset/projects?assetId=${assetId}`,
    await accessToken()
  );
};

export const editAsset = async (id, data) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/asset/${id}`,
    data,
    await accessToken()
  );
};
export const editProjectToAsset = async (data) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/asset/projects`,
    data,
    await accessToken()
  );
};

export const getAssetsOptions = async (
  q = "",
  categoryId = "",
  subCategoryId = ""
) => {
  const uri = serialize({ q, categoryId, subCategoryId });
  return axios.get(
    `${process.env.REACT_APP_API_URL}/master/assettype?page=1&limit=50&${uri}`,
    await accessToken()
  );
};

export const getSingleAsset = async (id) => {
  try {
    const res = await getSingleAssetApi(id);

    const finalData = await getAssetsProjectsApi(id);
    res.data.projects = finalData.data.docs;
    return res.data;
  } catch (err) {}
};

export const addAssetTypeApi = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/master/assettype`,
    data,
    await accessToken()
  );
};

export const editAssetTypeApi = async (id, data) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/master/assettype/${id}`,
    data,
    await accessToken()
  );
};

export const deleteAssetTypeApi = async (id) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/master/assettype/${id}`,
    await accessToken()
  );
};

export const getLocationsOfAssets = async (
  longitude,
  latitude,
  distance,
  status,
  companyId
) => {
  const uri = serialize({ longitude, latitude, distance, status, companyId });
  return axios.get(
    `${process.env.REACT_APP_API_URL}/asset/mapview?${uri}`,
    await accessToken()
  );
};

export const getDocumentsOfAAsset = async ({
  page,
  limit,
  uid,
  name,
  assetId,
}) => {
  const uri = serialize({ page, limit, uid, name, assetId });
  return axios.get(
    `${process.env.REACT_APP_API_URL}/asset/documents?${uri}`,
    await accessToken()
  );
};
