import classNames from "classnames";
import { ArrowRight } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAssetsProjectsApi } from "../../../../api/users/asset";
import { PF_BASE_URL } from "../../../../libs/pf";
import { openSingleAssetAdminAction } from "../../../../redux/actions/Admin/OpenSingleAssetAdmin";
import DocumentsDropDonw from "../../../components/Assets/comps/DocumentsDropDonw";
import ProjectsDropDown from "../../../components/Assets/comps/ProjectsDropDown";
import Loading from "../../../components/loading/Loading";
import { Assests } from "../../../fake/fakeAssets";
import { FakeProjects } from "../../../fake/fakeProject";
import { documents } from "../../../fake/faleDoc";

const Table = () => {
  const [projectsDropDown, setProjectsDropDown] = useState(false);
  const [documentsDropDown, setdocumentsDropDown] = useState(false);
  const [clickedId, setClickedId] = useState(null);

  const { assets, count, loading, assetStatus, assetType } = useSelector(
    (state) => state.assets
  );

  const projectsDropDownOpener = (s) => {
    setClickedId(s._id);
    setProjectsDropDown(s._id === clickedId ? !projectsDropDown : true);
    setdocumentsDropDown(false);
    setAssetId(s._id);
  };

  const [clickedIdProjects, setClickedIdProjects] = useState("");

  const documentsDropDownOpener = (s) => {
    setClickedId(s.id);
    setdocumentsDropDown(s.id === clickedId ? !documentsDropDown : true);
    setProjectsDropDown(false);
  };

  const dipstach = useDispatch();

  const navigate = useNavigate();

  const openSingleAsset = (a) => {
    dipstach(openSingleAssetAdminAction(a._id));
    navigate(`${a._id}`);
  };

  const [projectOfAAsset, setProjectOfAAsset] = useState();
  const [assetId, setAssetId] = useState();

  const fucn = async () => {
    try {
      const response = await getAssetsProjectsApi(assetId);
      setProjectOfAAsset(response.data.docs);
    } catch (error) {}
  };

  useEffect(() => {
    if (assetId) fucn();
  }, [assetId]);

  return (
    <>
      <div>
        <div className="flex  text-xs font-semibold px-6 mt-6 border-bottom-border border-b">
          <div className="w-[7%] opacity-50 py-6">UID</div>
          <div className="w-[18%] opacity-50 py-6">Asset Name</div>
          <div className="w-[22%] opacity-50 py-6">Details</div>
          <div className="w-[18%] opacity-50 py-6">Asset Owner</div>
          <div className="w-[13%] opacity-50 py-6">Status</div>
          <div className="w-[9%] opacity-50 py-6">Projects</div>
          <div className=" opacity-50 py-6 text-right flex-1">View</div>
        </div>

        {loading ? (
          <Loading loading={loading} />
        ) : assets.length === 0 ? (
          <>
            <div>
              <p className="text-center">No Assets found</p>
            </div>
          </>
        ) : (
          assets?.map((a, index) => {
            return (
              <div className="" key={index}>
                <div
                  className={classNames(
                    "flex py-5 px-6",
                    projectsDropDown && clickedId === a._id
                      ? "bg-theme-light-green border-b border-t"
                      : "border-b border-bottom-border",
                    ""
                  )}
                >
                  <div className="w-[7%] text-xs font-medium">
                    <p className="uppercase">id</p>
                    <p className="opacity-50">{a.uid}</p>
                  </div>
                  <div className="flex w-[18%] text-xs gap-2">
                    <div className="h-full">
                      <img src="images/charging-station.png" alt="" />
                    </div>
                    <div className="">
                      <p className="font-medium">{a?.name}</p>
                      <p>{a?.alias}</p>
                    </div>
                  </div>

                  <div className="w-[22%] text-xs">
                    <p className="font-medium">{a?.assetType?.name}</p>
                    <div className="flex gap-4">
                      <p className="text-theme-black opacity-50">
                        {a?.assetType?.id}
                      </p>
                      {/* <p className='uppercase text-theme-green font-semibold'>{a?.locationType}</p> */}
                    </div>
                  </div>

                  <div className="w-[18%] flex items-center gap-2 text-xs">
                    <div className="flex items-center">
                      <p className="w-9 h-9 tracking-wide flex justify-center items-center text-white font-bold text-[18px] bg-theme-green p-0.5 border border-theme-green rounded-full">
                        {a.companyId.name.split(" ")[0][0]}
                        {a.companyId.name.split(" ")[1]
                          ? a.companyId.name.split(" ")[1][0]
                          : ""}
                      </p>
                      {/* <img className='w-8 p-[1px] border-[0.5px] border-theme-green rounded-full' src={`${PF_BASE_URL}/${a?.companyId?.profilePic}`} alt="" /> */}
                    </div>
                    <div className="">
                      <p className="font-medium">{a?.companyId?.name}</p>
                      <p className="uppercase">
                        <span>ID</span> -{" "}
                        <span className="underline text-blue-400">
                          {a?.companyId?.uid}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="text-xs w-[13%]">
                    <p className="font-medium">{a?.status}</p>
                    <p className="italic font-light">{a?.status?.stage}</p>
                  </div>

                  <div className=" w-[9%] text-xs">
                    <div className="">
                      <p className="leading-3">Projects</p>
                      <p
                        onClick={() => projectsDropDownOpener(a)}
                        className="text-theme-black opacity-50 cursor-pointer leading-3"
                      >
                        {a.stats.projects}
                        <i
                          className={classNames(
                            "fa-solid ml-1 ",
                            projectsDropDown && clickedId === a._id
                              ? "fa-angle-up"
                              : "fa-angle-down"
                          )}
                        ></i>
                      </p>
                    </div>
                  </div>

                  {/* <div className=' w-[9%] text-xs'>
                                        <div className=''>
                                            <p className='leading-3'>Documents</p>
                                            <p onClick={() => documentsDropDownOpener(a)} className='text-theme-black opacity-50 cursor-pointer leading-3'>{documents.filter((d) => d.people.uploadedBy.companyName === a.assetOwner.name).length} <i className={classNames("fa-solid ml-1 ", (documentsDropDown && clickedId ===a.id)?"fa-angle-up": 'fa-angle-down')}></i></p>
                                        </div>
                                    </div> */}

                  <div className="text-xs flex flex-1 justify-end font-medium">
                    <ArrowRight
                      onClick={() => openSingleAsset(a)}
                      className="bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
                      size={28}
                      weight="light"
                    />
                  </div>
                </div>

                {projectsDropDown && clickedId === a._id && (
                  <div>
                    {projectOfAAsset?.map((project, index) => {
                      return (
                        <ProjectsDropDown
                          clickedId={clickedIdProjects}
                          setClickedId={setClickedIdProjects}
                          onClick={() =>
                            navigate(`/admin/projects/${project._id}`)
                          }
                          project={project}
                          key={index}
                        />
                      );
                    })}
                  </div>
                )}

                {/* {(documentsDropDown) && clickedId===a.id && <div>
                                        
                                        {
                                          documents.filter((d) => d.people.uploadedBy.companyName === a.assetOwner.name).map((s, index) => {
                                            return <DocumentsDropDonw s={s} key={index}/>
                                        })
                                        }
                                    </div>} */}
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default Table;
