import axios from "axios"
import { accessToken } from "../../libs/token"

export const getCategoriesApi =  async () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/master/category`, await accessToken())
}

export const createSingleCtegoryApi = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/master/category`, data, await accessToken())
}

export const editSingleCategoryApi = async (id, data) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/master/category/${id}`,data, await accessToken())
}

export const deleteSingleCategoryApi = async (id) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/master/category/${id}`, await accessToken())
}




export const getSubCategoriesApi =  async (categoryId) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/master/subcategory?categoryId=${categoryId}`, await accessToken())
}

export const createSingleSubCtegoryApi = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/master/subcategory`, data, await accessToken())
}

export const editSingleSubCategoryApi = async (id, data) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/master/subcategory/${id}`,data, await accessToken())
}

export const deleteSingleSubCategoryApi = async (id) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/master/subcategory/${id}`, await accessToken())
}