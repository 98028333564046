import { getSession } from "./cognito";

export const accessToken = async () => {
  try {
    const user = await getSession();

    if (user.accessToken.jwtToken) {
      return {
        headers: {
          Authorization: user.accessToken.jwtToken,
        },
      };
    } else { }
  } catch (error) { }
};
