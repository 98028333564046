import classNames from 'classnames'
import { Plus, X } from 'phosphor-react'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { CLOSE_ADD_NEW_SUBSCRIPTION } from '../../../../redux/reducers/Admin/AddNewSubscriptionReducer'
import SelectComp from '../../../components/Workspace/AddNewUser/SlectCompUserScren'

const AddSubscriptionPlan = () => {
    const options = [
        {value:"Yearly", label:'Yearly' },
        {value:"Monthly", label:'Monthly' },
    ]
    const [detail, setDetail] = useState('')

    const  [detailsArr, setDetailsArr] =useState([])

    const addDetails = () => {
        setDetailsArr([...detailsArr, {id:detailsArr.length+1., detail:detail}])
        setDetail('')
    }

    const removeDetail = (d) => {
        let arr = [...detailsArr]
        arr = arr.filter(a=> a.id !==d.id)
        setDetailsArr(arr)
    }


    const dispatch = useDispatch()

    const closeAddNewSubscriptionTab = () => {
        dispatch({type: CLOSE_ADD_NEW_SUBSCRIPTION})
    }

    const addSubscriptionPan = () => {
        dispatch({type: CLOSE_ADD_NEW_SUBSCRIPTION})

    }

  return (
    <>
        <div className='grid grid-cols-2 gap-4 mt-8 rounded-lg flex h-full'>
            <div className='bg-white p-6 grid gap-10  content-start rounded-md'>
                <div className='grid gap-2'>
                    <p className='font-medium text-theme-black text-sm'>Name of the Subscription Plan</p>
                    <input type="text" placeholder="Enter the name of the Subscription Plan" className={classNames('focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs' ,"bg-[#0000000D]")} />
                </div>

                <div className='grid gap-2'>
                    <p className='font-medium text-theme-black text-sm'>Price of the Subscription Plan</p>
                    <input type="text" placeholder="Enter the price of the Subscription Plan" className={classNames('focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs' ,"bg-[#0000000D]")} />
                </div>

                <div className='grid gap-2'>
                    <p className='font-medium text-theme-black text-sm'>Select Between Monthly and Yearly</p>
                    <SelectComp options={options} />
                </div>
            </div>



            <div className='bg-white flex flex-col justify-between p-6 rounded-md'>
                <div className=''>
                    <div className='grid gap-2'>
                        <p className='font-medium text-theme-black text-sm'>Add Details</p>
                        <div className='relative'>
                            <input onChange={(e) => setDetail(e.target.value)} value={detail} type="text" placeholder="Enter the price of the Subscription Plan" className={classNames('focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs' ,"bg-[#0000000D]")} />
                            <Plus onClick={addDetails} size={18} className='absolute top-1/2 right-4 -translate-y-1/2 cursor-pointer' />
                        </div>
                    </div>

                    <div className='grid gap-2 mt-6'>
                        {
                            detailsArr.length !==0 &&  
                            detailsArr.map((d, index) => {
                                return <div key={index} className='flex text-xs items-center justify-between px-4 py-3 border border-black-10 rounded'>
                                        <p>{d.detail}</p>
                                        <X onClick={() => removeDetail(d)} className='cursor-pointer' size={16}/>
                                </div>
                            })
                        }
                    </div>
                </div>

                <div className='grid gap-3'>
                <button onClick={addSubscriptionPan} className='flex-1 w-full py-2 bg-transparent border-2 border-bottom-border uppercase text-sm font-bold rounded-lg hover:bg-white hover:border-[#00000066]'><span className="opacity-40">CANCEL</span></button>
                    <button onClick={closeAddNewSubscriptionTab} className='flex-1 w-full py-2 bg-theme-light-green text-theme-green border-2 border-theme-green uppercase text-sm font-bold rounded-lg hover:bg-theme-green hover:text-black'><span className="">ADD CLIENT</span></button>
                </div>
            </div>
        </div>
    </>
  )
}

export default AddSubscriptionPlan