export const GET_SINGLE_USER_REQ = "GET_SINGLE_USER_REQ" 
export const GET_SINGLE_USER_SUCC = "GET_SINGLE_USER_SUCC" 
export const GET_SINGLE_USER_FAIL = "GET_SINGLE_USER_FAIL" 
export const HIDE_SINGLE_USER= 'HIDE_SINGLE_USER'
export const GET_SINGLE_USER_SUCC_WITH_EDIT = "GET_SINGLE_USER_SUCC_WITH_EDIT" 
export const GET_ALL_USERS = "GET_ALL_USERS"
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL"
export const GET_ALL_USERS_REQ = "GET_ALL_USERS_REQ"

export const GET_LOGGEDIN_USER = "GET_LOGGEDIN_USER"
export const GET_LOGGEDIN_USER_FAIL = "GET_LOGGEDIN_USER_FAIL"
export const GET_LOGGEDIN_USER_REQ = "GET_LOGGEDIN_USER_REQ"


export const GET_LOGGEDIN_USER_COMPANY = "GET_LOGGEDIN_COMPANY_USER"
export const GET_LOGGEDIN_USER_COMPANY_FAIL = "GET_LOGGEDIN_USER_COMPANY_FAIL"
export const GET_LOGGEDIN_USER_COMPANY_REQ = "GET_LOGGEDIN_USER_COMPANY_REQ"