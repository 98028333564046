import React from 'react'
import styled from 'styled-components'
import Overview from './comps/Overview'
import ProjectProgress from './comps/ProjectProgress'
import RecentActivityAdmin from './comps/RecentActivity'
import TopClients from './comps/TopClients'


const MainDiv = styled.div`
  height: calc(100vh - 57px);

`
const DashBoard = () => {
  return (
    <>
        <MainDiv className='px-8 py-9  overflow-y-auto'>
            <Overview/>
            <div className='flex gap-4 pt-6'>
                <RecentActivityAdmin/>
                <div className='grid flex-1 grid-cols-2 gap-6'>
                    <TopClients/>
                    <ProjectProgress/>
                </div>
            </div>
        </MainDiv>
    </>
  )
}

export default DashBoard