import { HomePage } from "./pages/HomePage/Loadable";
import "../assets/custom.css";
import { Routes, Route, HashRouter } from "react-router-dom";
import AdminHomePage from "./pages/Home-Admin";
import UserLogin from "./pages/UserLogin";
import UserSignUp from "./pages/UserSignUp";
import PrivateRoute from "../libs/PrivateRoute";
import ResetPassword from "./pages/resetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <HashRouter>
        <div className="">
          <Routes>
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <HomePage />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin/*"
              element={
                <PrivateRoute>
                  <AdminHomePage />
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<UserLogin />} />
            <Route path="/signup" element={<UserSignUp />} />
            <Route path="/reset_Password" element={<ResetPassword />} />
          </Routes>
        </div>
      </HashRouter>
      <ToastContainer />
    </>
  );
}

export default App;
