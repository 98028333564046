import { getTaskForAnyProject } from "../../../api/users/projects";
import {
  GET_TASK_FAIL,
  GET_TASK_REQ,
  GET_TASK_SUCC,
} from "../../constants/projects/getTask";

export const getAllTasksAction =
  (projectId, supplierId = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_TASK_REQ });
      const res = await getTaskForAnyProject(projectId, supplierId);

      dispatch({
        type: GET_TASK_SUCC,
        payload: res.data.docs,
      });
    } catch (error) {
      dispatch({
        type: GET_TASK_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
