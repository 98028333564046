import { MagnifyingGlass } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getCompanies } from '../../../../redux/actions/companies/getCompanies'
import { getSuppliers } from '../../../../redux/actions/suppliers/getSuppliers'
import { COMPANIES_ADD_COMPANY_VIEW_OPEN, COMPANIES_INVITE_VIEW_OPEN } from '../../../../redux/constants/AdminConsts/LeftNavAdminConst'
import Pagination from './Pagination'
import Table from './Table'

const ShowCase = () => {
    const dispatch = useDispatch()

    const [limit ,setLimit ] =  useState(5)
    const { pagination } = useSelector((state) => state.suppliers)


    const [search, setSearch] = useState("")
 
    const {user} = useSelector(state => state.me)
    useEffect(() => {
        
        const timer = setTimeout(() => {
            dispatch(getSuppliers(user.companyId._id,limit, 1, search));
          }, 300);
          return () => clearTimeout(timer);
      }, [limit, search, dispatch]);

      const navigate = useNavigate()
  return (
    <>
        <div className='bg-white mt-8 rounded-lg py-8 pb-16'>
            <div className='flex  px-6 items-center justify-between'>
                <div className='px-3 text-xs'>
                    {/* <select className='min-w-[80px] opacity-60 font-semibold  h-5 focus:outline-none' placeholder='Asset Type' name="" id="">
                        <option value="">Location</option>
                        <option value="">Ipsum</option>
                        <option value="">Dolor</option>
                    </select> */}
                </div>
                <div className='flex gap-3'>
                    <button onClick={() =>{ dispatch({type:COMPANIES_INVITE_VIEW_OPEN}); navigate('invite')}} className='px-5 uppercase border font-semibold border-theme-green hover:border-black text-xs py-2 rounded-md font-medium cursor-pointer select-none hover:bg-theme-green bg-theme-light-green text-theme-green hover:text-black'>Invite</button>
                    <button onClick={() =>{ dispatch({type:COMPANIES_ADD_COMPANY_VIEW_OPEN}); navigate('addNew')}} className='px-5 uppercase border font-semibold border-theme-green hover:border-black text-xs py-2 rounded-md font-medium cursor-pointer select-none hover:bg-theme-green bg-theme-light-green text-theme-green hover:text-black'>CREATE NEW COMPANY</button>
                </div>
            </div>

            {/* Input and display */}
            <div className='flex  px-6 justify-between items-center mt-12'>
                <div className='relative text-very-tiny w-1/3'>
                    <input value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search for company details, id or domain' className='w-full bg-[#f7f6f6] border-[1.5px] focus:outline-none rounded-md border-[rgba(0, 0, 0, 0.1)] py-2.5 px-4' type="text" />
                    <MagnifyingGlass className='absolute right-6 opacity-20 top-1/2 -translate-y-1/2' size={14}/>
                </div>
                <div className='flex text-very-tiny gap-4 items-center'>
                    {pagination && <p>Displaying {((pagination?.page - 1) * limit ) + 1} - {(pagination?.page - 1) * limit + pagination?.docs?.length} of {pagination?.totalDocs}</p>}
                    <div className='py-1 bg-[#f7f6f6] rounded-md px-1.5 border border-[rgba(0, 0, 0, 0.1)]'>
                        <select value={limit} onChange={(e) => setLimit(e.target.value)} className='h-7 bg-[#f7f6f6] focus:outline-none' name="" id="">
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                        </select>
                    </div>
                </div>
              </div>

              <Table/>

        </div>

        <Pagination limit={limit} setLimit={setLimit}/>

    </>
  )
}

export default ShowCase