import { getSuppliersApi } from "../../../api/users/company";

import {
  GET_SUPPLIERS_FAIL,
  GET_SUPPLIERS_REQ,
  GET_SUPPLIERS_SUCC,
} from "../../constants/suppliers/Suppliers";

export const getSuppliers =
  (companyId = "", limit = 5, page = 1, q = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_SUPPLIERS_REQ });

      const res = await getSuppliersApi(companyId, limit, page, q);

      dispatch({
        type: GET_SUPPLIERS_SUCC,
        payload: res.data.docs,
        pagnationDetails: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SUPPLIERS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
