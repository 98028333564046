import { accessToken } from "../../libs/token";
import axios from "axios";
import { serialize } from "../../libs/serialize";

export const getProjectsApi = async () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/project`,
    await accessToken()
  );
};

export const createProjectApi = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/project`,
    data,
    await accessToken()
  );
};

export const updateProjectApi = async (data, id) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/project/${id}`,
    data,
    await accessToken()
  );
};
export const addAssetToProjectApi = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/project/assets`,
    data,
    await accessToken()
  );
};

export const getAllProjectsApi = async (
  companyId,
  limit,
  page,
  q,
  status,
  excludeIds = [],
  userId = ""
) => {
  const uri = serialize({ companyId, limit, status, page, q, userId });

  const excludedIds = excludeIds.join("&excludeIds[]=");
  if (excludeIds.length === 0) {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/project?${uri}`,
      await accessToken()
    );
  } else {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/project?${uri}&excludeIds[]=${excludedIds}`,
      await accessToken()
    );
  }
};

export const getSingleProjectData = async (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/project/${id}`,
    await accessToken()
  );
};

export const deleteProjectApi = async (id) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/project/${id}`,
    await accessToken()
  );
};

export const editProject = async (id, data) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/project/${id}`,
    data,
    await accessToken()
  );
};
export const editAssetToProject = async (data) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/project/assets`,
    data,
    await accessToken()
  );
};

export const removeAdminFromProject = async (id, data) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/users/projects/${id}`,
    data,
    await accessToken()
  );
};
// export const getProjectsOptions = async ( ) => {
//   return axios.get(`${process.env.REACT_APP_API_URL}/master/projecttype`, await accessToken());
// };

export const assetsListOfAProject = async (projectId, limit = 10) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/project/assets?projectId=${projectId}&limit=${limit}`,
    await accessToken()
  );
};

export const addSupplierToProject = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/project/suppliers`,
    data,
    await accessToken()
  );
};

export const editSuppliersinProject = async (data) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/project/suppliers`,
    data,
    await accessToken()
  );
};

export const getSupplierListOfAProject = async (projectId) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/project/suppliers?projectId=${projectId}`,
    await accessToken()
  );
};

export const createTaskForAnyProject = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/task`,
    data,
    await accessToken()
  );
};

export const getTaskForAnyProject = async (projectId, supplierId) => {
  const uri = serialize({ projectId, supplierId });
  return axios.get(
    `${process.env.REACT_APP_API_URL}/task?${uri}`,
    await accessToken()
  );
};

export const updateTaskApi = async (taskId, data) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/task/${taskId}`,
    data,
    await accessToken()
  );
};

export const deleteTaskApi = async (taskId) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/task/${taskId}`,
    await accessToken()
  );
};

export const getProjectAdmins = async (projectId) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/users/projects?projectId=${projectId}`,
    await accessToken()
  );
};

export const getLocationsOfProjects = async (
  longitude,
  latitude,
  distance,
  status,
  companyId = ""
) => {
  const uri = serialize({ longitude, latitude, distance, status, companyId });
  return axios.get(
    `${process.env.REACT_APP_API_URL}/project/mapview?${uri}`,
    await accessToken()
  );
};
