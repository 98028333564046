import classNames from "classnames";
import { ArrowRight } from "phosphor-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAssetsOfAProjectAction } from "../../../../redux/actions/asset/getAssetOfAProject";
import { getAllDocsAction } from "../../../../redux/actions/documents/getAllDoc";
import { getSuppliersOfAProjectAction } from "../../../../redux/actions/suppliers/geSupplierOfAProject";
import AssetsDropDown from "../../../components/Projects/comps/AssetsDropDown";
import DocumentsDropDown from "../../../components/Projects/comps/DocumentsDropDown";
import SupplierDropDown from "../../../components/Projects/comps/SupplierDropDown";
import { Assests } from "../../../fake/fakeAssets";
import { FakeSuppliers } from "../../../fake/fakeSuppliers";
import { documents } from "../../../fake/faleDoc";

const SingleProject = ({ p, admin }) => {
  const [assetsDropDown, setassetsDropDown] = useState(false);
  const [documentsDropDown, setdocumentsDropDown] = useState(false);
  const [suppliersDropDown, setsuppliersDropDown] = useState(false);

  const [clickedId, setClickedId] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const assetDropDonwOPener = (s) => {
    dispatch(getAssetsOfAProjectAction(s._id));
    setClickedId(s._id);
    setassetsDropDown(s._id === clickedId ? !assetsDropDown : true);
    setdocumentsDropDown(false);
    setsuppliersDropDown(false);
  };

  const documentsDropDownOpener = (s) => {
    setClickedId(s._id);
    setdocumentsDropDown(s._id === clickedId ? !documentsDropDown : true);
    setsuppliersDropDown(false);
    setassetsDropDown(false);
    dispatch(getAllDocsAction({ projectId: s._id }));
  };

  const suppliersDropDownOpener = (p) => {
    dispatch(getSuppliersOfAProjectAction(p._id));
    setClickedId(p._id);
    setdocumentsDropDown(false);
    setassetsDropDown(false);
    setsuppliersDropDown(p._id === clickedId ? !suppliersDropDown : true);
  };

  const openSingleProject = (p) => {
    if (admin) {
      navigate(`/admin/projects/${p._id}`);
    } else {
      navigate(`/projects/${p._id}`);
    }
  };

  const { assets: assetsOfAProject, loading: ProjectDropDownLoading } =
    useSelector((state) => state.getAssetsOFAProject);
  const { suppliers, loading: suppliersDropDownLOading } = useSelector(
    (state) => state.getSuppliersOFAProject
  );
  const { docs } = useSelector((state) => state.getAllDoc);

  return (
    <>
      <div>
        <div
          className={classNames(
            "flex py-4 px-6",
            (assetsDropDown || documentsDropDown || suppliersDropDown) &&
              clickedId === p._id
              ? "bg-theme-light-green border-b border-t"
              : "border-b border-bottom-border",
            ""
          )}
        >
          <div className="w-[10%] flex pl-3">
            <p className="px-5 text-center text-xs py-2 rounded-full bg-theme-green">
              Project
            </p>
          </div>
          <div className="w-[7%] text-xs font-medium">
            <p className="uppercase">id</p>
            <p className="opacity-50">{p.uid}</p>
          </div>
          <div className="w-[15%] text-xs gap-2">
            <p className="font-medium">{p.name}</p>
            <p className="text-theme-black opacity-50 font-medium">
              {p.address.city}, {p.address.state}
            </p>
          </div>

          <div className="flex w-[15%] items-center text-xs gap-2">
            <div className="h-full">
              {/* <img className='w-8 p-[1px] border border-theme-green rounded-full' src={p.projectDetails.imgUrl} alt="" /> */}
              <p className="w-9 h-9 tracking-wide flex justify-center items-center text-white font-bold text-[18px] bg-theme-green p-0.5 border border-theme-green rounded-full">
                {p.companyId?.name.split(" ")[0][0]}
                {p.companyId?.name.split(" ")[1]
                  ? p.companyId?.name.split(" ")[1][0]
                  : ""}
              </p>
            </div>
            {p.companyId && (
              <div className="">
                <p className="font-medium">{p.companyId?.name}</p>
                <p className="uppercase text-very-tiny">
                  <span>ID</span> -{" "}
                  <span className="underline text-blue-400">
                    {p.companyId?.uid}
                  </span>
                </p>
              </div>
            )}
          </div>

          <div className="flex w-[23%] items-center justify-center text-xs gap-6">
            <div className="text-xs ">
              <p className="font-medium">Status</p>
              <p
                className={classNames(
                  " cursor-pointer font-medium leading-3",
                  p.status === "Approved"
                    ? "text-theme-green"
                    : "text-theme-black opacity-50"
                )}
              >
                {p.status}
              </p>
            </div>

            <div className="flex flex-col ">
              <p className="leading-3 mb-1">
                Progress{" "}
                <span className="text-theme-black opacity-50">
                  ({p.progress.completed + "/" + p.progress.total})
                </span>
              </p>
              <div className="relative w-full h-2 rounded bg-theme-light-green ">
                <div
                  style={{
                    width: `${
                      (p.progress.completed / p.progress.total) * 100
                    }%`,
                  }}
                  className={classNames(
                    "absolute h-2 bg-theme-green rounded",
                    ``
                  )}
                ></div>
              </div>
            </div>
          </div>

          <div className=" w-[8%] text-xs">
            <div className="">
              <p className="leading-3">Assets</p>
              <p
                onClick={() => assetDropDonwOPener(p)}
                className="text-theme-black opacity-50 cursor-pointer leading-3"
              >
                {p.stats.assets}{" "}
                <i
                  className={classNames(
                    "fa-solid ml-1 ",
                    assetsDropDown && clickedId === p._id
                      ? "fa-angle-up"
                      : "fa-angle-down"
                  )}
                ></i>
              </p>
            </div>
          </div>

          <div className=" w-[8%] text-xs">
            <div className="">
              <p className="leading-3">Suppliers</p>
              <p
                onClick={() => suppliersDropDownOpener(p)}
                className="text-theme-black opacity-50 cursor-pointer leading-3"
              >
                {p.stats.suppliers}{" "}
                <i
                  className={classNames(
                    "fa-solid ml-1 ",
                    suppliersDropDown && clickedId === p._id
                      ? "fa-angle-up"
                      : "fa-angle-down"
                  )}
                ></i>
              </p>
            </div>
          </div>

          <div className=" w-[8%] text-xs">
            <div className="">
              <p className="leading-3">Documents</p>
              <p
                onClick={() => documentsDropDownOpener(p)}
                className="text-theme-black opacity-50 cursor-pointer leading-3"
              >
                {p.stats.documents}{" "}
                <i
                  className={classNames(
                    "fa-solid ml-1 ",
                    documentsDropDown && clickedId === p._id
                      ? "fa-angle-up"
                      : "fa-angle-down"
                  )}
                ></i>
              </p>
            </div>
          </div>

          <div className="text-xs flex flex-1 justify-end font-medium">
            <ArrowRight
              onClick={() => openSingleProject(p)}
              className="bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
              size={28}
              weight="light"
            />
          </div>
        </div>

        {assetsDropDown && clickedId === p._id && (
          <div className="h-60 overflow-y-auto">
            {assetsOfAProject.length !== 0 ? (
              assetsOfAProject.map((asset, index) => {
                return <AssetsDropDown asset={asset} key={index} />;
              })
            ) : (
              <p className="text-center pt-6">No Assets found</p>
            )}
          </div>
        )}

        {suppliersDropDown && clickedId === p._id && (
          <div className="max-h-60 overflow-y-auto">
            {suppliers.length !== 0 ? (
              suppliers.map((s, idx) => {
                return <SupplierDropDown s={s} key={idx} />;
              })
            ) : (
              <p className="text-center pt-6">No Supplier found</p>
            )}
          </div>
        )}

        {documentsDropDown && clickedId === p._id && (
          <div className="max-h-60 overflow-y-auto">
            {docs && docs.length === 0 ? (
              <p className="text-center py-6 text-xs">No Document found</p>
            ) : (
              docs?.map((s, index) => {
                return <DocumentsDropDown s={s} key={index} />;
              })
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SingleProject;
