import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { X } from "phosphor-react";
import { format } from "date-fns";
import { PF_BASE_URL } from "../../../../libs/pf";
import { getAllEmployeesAction } from "../../../../redux/actions/users/getAllEmployeesAction";
import { reInviteUser } from "../../../../api/users/users";
import { GET_ALL_EMPLOYEES_RESET } from "../../../../redux/constants/users/getAllEmployees";
import Loading from "../../../components/loading/Loading";
import { toast } from "react-toastify";

const Attributes = ({ edit, setEdit }) => {
  const { company } = useSelector((state) => state.singleCompanyAdmin);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_ALL_EMPLOYEES_RESET });
  }, []);

  useEffect(() => {
    if (company?._id)
      dispatch(getAllEmployeesAction({ companyId: company?._id }));
  }, [company, dispatch]);

  const { employees: users, loading } = useSelector(
    (state) => state.getAllEmployees
  );

  const addr = company?.address;
  // reset password
  const [resetPassword, setResetPassword] = useState(false);

  const resetPasswordHandler = async (user) => {
    try {
      toast.loading("Re-invitig the user.");

      await reInviteUser(user._id);
      toast.dismiss();
      //   setResetPassword(true);
      toast.success("User re-invited successfully.");
    } catch (error) {
      toast.dismiss();

      toast.error("Some error occured while trying to re-invite.");
    }
  };

  return (
    <>
      <div className="bg-white p-2 w-1/3 rounded-md">
        <div className="rounded-md p-3  bg-[#EFEBE9]">
          <div className="bg-[#F7F6F6] py-4 flex flex-col gap-4  rounded-md items-center">
            <p className=" cursor-pointer font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
              {edit ? "Submit Details" : "Company Details"}
            </p>
            <div className="flex w-full px-4 text-xs items-center justify-between">
              <div className="flex gap-2  items-start w-[70%]">
                <div>
                  {company.profilePic ? (
                    <img
                      src={`${PF_BASE_URL}/${company.profilePic}`}
                      className="w-12 h-12 object-cover p-0.5 border border-theme-green rounded-full"
                      alt=""
                    />
                  ) : (
                    <p className="w-12 h-12 tracking-wide flex justify-center items-center text-white font-bold text-[18px] bg-theme-green p-0.5 border border-theme-green rounded-full">
                      {company?.name?.split(" ")[0][0]}
                      {company?.name?.split(" ")[1]
                        ? company?.name.split(" ")[1][0]
                        : ""}
                    </p>
                  )}
                </div>
                <div className={""}>
                  <p className="font-medium ">{company?.name}</p>
                  <div className="flex flex-col space-y-1">
                    {company?.address && (
                      <p className=" font-normal  opacity-50">
                        {(addr?.street1 ? addr?.street1 + "," : "") +
                          (addr?.street2 ? addr?.street2 + "," : "") +
                          (addr?.city ? addr?.city + "," : "")}
                      </p>
                    )}
                    {addr && (
                      <p className=" font-normal  opacity-50">
                        {addr?.state + "," + addr?.country}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <p className="">ID - {company?.uid}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-3 mt-4">
            <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
              <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                Company Domain
              </p>
              <p className="text-xs font-medium break-words px-1.5 w-[100%]">
                {company?.domain}
              </p>
            </div>
            <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
              <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                Company added on
              </p>
              <p className="text-xs font-medium capitalize">
                {company?.createdAt
                  ? format(new Date(company?.createdAt), "dd/MM/yyyy")
                  : "N A"}
              </p>
            </div>
          </div>
        </div>

        {/* 2nd one */}
        <div className="bg-[#F7F6F6] mt-2 py-[20px] min-h-[300px] flex flex-col gap-2  rounded-md items-center border">
          <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
            Users
          </p>
          <div className="w-full px-4 grid gap-3  overflow-y-auto">
            {loading ? (
              <Loading loading={loading} />
            ) : (
              users?.map((u, index) => {
                return (
                  <div key={index} className="flex items-center">
                    <div className="flex w-1/2 items-center text-xs gap-2">
                      <div className="h-full">
                        {u.profilePic ? (
                          <img
                            className="w-8 p-[1px] border border-theme-green rounded-full"
                            src={`${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${u.profilePic}`}
                            alt=""
                          />
                        ) : (
                          u?.firstName && (
                            <p className="w-8 h-8 tracking-wide flex justify-center items-center text-white font-bold text-[14px] bg-theme-green p-0.5 border border-theme-green rounded-full">
                              {u?.firstName[0]}
                              {u?.lastName ? u?.lastName[0] : ""}
                            </p>
                          )
                        )}
                      </div>
                      <div className="">
                        <p className="font-medium">
                          {u.firstName} {u.lastName}
                        </p>
                        <p className="capitalize opacity-50 text-very-tiny">
                          ({u.roleId.name})
                        </p>
                      </div>
                    </div>

                    <div className="w-1/3 text-xs">
                      ID - <span className="">{u.uid}</span>
                    </div>

                    {u.status === "pending" && (
                      <div className="w-[20%]">
                        <button
                          onClick={() => resetPasswordHandler(u)}
                          className="text-xs text-blue-400 underline"
                        >
                          Re-Invite
                        </button>
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>

      {/* reset password */}
      {resetPassword && (
        <div className="fixed text-xs right-[54px] top-20 bg-theme-green bg-opacity-50 border border-theme-green flex gap-2 p-2 rounded-md">
          <p>
            We have e-mailed the password reset link on the recipant's account.
          </p>
          <X
            onClick={() => setResetPassword(false)}
            className="cursor-pointer"
            size={14}
          />
        </div>
      )}
    </>
  );
};

export default Attributes;
