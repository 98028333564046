/* eslint-disable no-unused-vars */

import { MapPin, NavigationArrow } from "phosphor-react";
import React, { useCallback, useState } from "react";
import { FilterButton } from "../../../../../styles/FilterButton";

import SelectionMap from "./Map";
import TopProgress from "./TopProgress";

const Location = ({ goToAssetDetails, goToChooseOwner }) => {
  const [marker, setMarker] = useState({
    latitude: 33.99936527056204,
    longitude: -118.20556925453577,
  });

  const [events, logEvents] = useState({});

  const onMarkerDragStart = useCallback((event) => {
    logEvents((_events) => ({ ..._events, onDragStart: event.lngLat }));
  }, []);

  const onMarkerDragEnd = useCallback((event) => {
    logEvents((_events) => ({ ..._events, onDragEnd: event.lngLat }));
  }, []);

  const onMarkerDrag = useCallback((event) => {
    logEvents((_events) => ({ ..._events, onDrag: event.lngLat }));

    setMarker({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    });
  }, []);

  return (
    <>
      <div>
        <TopProgress step1 />
        <div className="my-8 justify-between flex gap-10 items-center w-full">
          <div className="relative w-2/5">
            <input
              type="text"
              placeholder="Enter Asset Location (address)"
              className="py-4 w-full pl-12 text-xs rounded-md focus:outline-none border border-bottom-border"
              name=""
              id=""
            />
            <NavigationArrow
              className="absolute top-1/2 -translate-y-1/2 left-2 bg-[#0000000d] p-2 rounded text-gray-500"
              size={30}
              weight="bold"
            />
          </div>

          <div className="flex justify-between items-center flex-1">
            <p className="text-sm">
              OR{" "}
              <span className="text-[#323132CC] text-xs">
                (Use coordinates to mark on map)
              </span>
            </p>

            <div className="flex gap-6">
              <div className="relative w-1/2">
                <input
                  onChange={(e) =>
                    setMarker({ ...marker, latitude: e.target.value })
                  }
                  value={marker.latitude}
                  type="number"
                  placeholder="Latitude"
                  className="py-4 w-full pl-12 text-xs rounded-md focus:outline-none border border-bottom-border"
                  name=""
                  id=""
                />
                <MapPin
                  className="absolute top-1/2 -translate-y-1/2 left-2 bg-[#0000000d] p-2 rounded text-gray-500"
                  size={30}
                  weight="bold"
                />
              </div>
              <div className="relative w-1/2">
                <input
                  onChange={(e) =>
                    setMarker({ ...marker, longitude: e.target.value })
                  }
                  value={marker.longitude}
                  type="number"
                  placeholder="Longitude"
                  className="py-4 w-full pl-12 text-xs rounded-md focus:outline-none border border-bottom-border"
                  name=""
                  id=""
                />
                <MapPin
                  className="absolute top-1/2 -translate-y-1/2 left-2 bg-[#0000000d] p-2 rounded text-gray-500"
                  size={30}
                  weight="bold"
                />
              </div>
            </div>
          </div>
        </div>

        {/* map */}
        <div onContextMenu={(e) => e.preventDefault()}>
          <div className="relative h-[28rem]">
            <SelectionMap
              marker={marker}
              onMarkerDrag={onMarkerDrag}
              onMarkerDragEnd={onMarkerDragEnd}
              onMarkerDragStart={onMarkerDragStart}
            />
          </div>
          <div className="flex mt-8 justify-between">
            <FilterButton onClick={goToChooseOwner}>Back</FilterButton>
            <FilterButton onClick={goToAssetDetails}>Next</FilterButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default Location;
