import axios from "axios";
import { serialize } from "../../libs/serialize";
import { accessToken } from "../../libs/token";

export const getDocTypes = async () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/master/documenttype`,
    await accessToken()
  );
};

export const getAllDocs = async (
  page,
  limit,
  status,
  type,
  projectId,
  q,
  order,
  companyId = "",
  projectIds = [],
  userId = ""
) => {
  let uri = serialize({
    page,
    limit,
    status,
    type,
    projectId,
    q,
    order,
    companyId,
    userId,
  });
  let str = "";
  for (var i = 0; i < projectIds.length; i++) {
    if (i !== projectIds.length) {
      str = str + `projectIds=${projectIds[i]}&`;
    } else {
      str = str + `projectIds=${projectIds[i]}`;
    }
  }
  if (projectIds?.length === 0) {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/document?${uri}`,
      await accessToken()
    );
  } else {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/document?${uri}&${str}`,
      await accessToken()
    );
  }
};

export const getSingleocType = async () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/master/documenttype`,
    await accessToken()
  );
};

export const createSingleDocType = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/master/documenttype`,
    data,
    await accessToken()
  );
};

export const editSingleDocType = async (id, data) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/master/documenttype/${id}`,
    data,
    await accessToken()
  );
};

export const deleteSingleDocType = async (id) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/master/documenttype/${id}`,
    await accessToken()
  );
};

export const getAllVersions = async (documentId) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/document/versions?documentId=${documentId}`,
    await accessToken()
  );
};

export const getSingleDocumnet = async (documentId) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/document/${documentId}`,
    await accessToken()
  );
};

export const createDocumentApi = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/document`,
    data,
    await accessToken()
  );
};

export const editDocumentApi = async (documentId, data) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/document/${documentId}`,
    data,
    await accessToken()
  );
};

export const shareDocument = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/document/suppliers`,
    data,
    await accessToken()
  );
};

export const getLocationsOfDocuments = async (
  longitude,
  latitude,
  distance,
  status,
  companyId
) => {
  const uri = serialize({ longitude, latitude, distance, status, companyId });
  return axios.get(
    `${process.env.REACT_APP_API_URL}/document/mapview?${uri}`,
    await accessToken()
  );
};

export const addAssetToTheDocumentApi = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/document/assets`,
    data,
    await accessToken()
  );
};

export const removeAssetToTheDocumentApi = async (data) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/document/assets`,
    data,
    await accessToken()
  );
};

export const getAssetsOfADocument = async ({
  page,
  limit,
  uid,
  name,
  docId,
}) => {
  const uri = serialize({ page, limit, uid, name, documentId: docId });
  return axios.get(
    `${process.env.REACT_APP_API_URL}/document/assets?${uri}`,
    await accessToken()
  );
};
