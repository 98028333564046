import { getCompanyApi } from "../../../api/users/company";
import { getLoggedInUserApi, getUsersApi } from "../../../api/users/users";
import { users } from "../../../app/fake/fakeUser";
import {
  GET_LOGGEDIN_USER,
  GET_LOGGEDIN_USER_FAIL,
  GET_LOGGEDIN_USER_REQ,
  GET_LOGGEDIN_USER_COMPANY,
  GET_LOGGEDIN_USER_COMPANY_FAIL,
  GET_LOGGEDIN_USER_COMPANY_REQ,
} from "../../constants/users/Users";

export const getLoggedInUser =
  (call = () => { }) =>
    async (dispatch) => {
      try {
        dispatch({ type: GET_LOGGEDIN_USER_REQ });
        const res = await getLoggedInUserApi();
        getLoggedInCompany(res.data.companyId._id);

        dispatch({
          type: GET_LOGGEDIN_USER,
          payload: res.data,
        });
        call(res.data);
      } catch (error) {
        dispatch({
          type: GET_LOGGEDIN_USER_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

const getLoggedInCompany = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_LOGGEDIN_USER_COMPANY_REQ });
    const res = await getCompanyApi(id);

    dispatch({
      type: GET_LOGGEDIN_USER_COMPANY,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_LOGGEDIN_USER_COMPANY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
