import { CaretDoubleLeft, CaretDoubleRight, CaretLeft, CaretRight } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllProjects } from '../../../../redux/actions/project/getProjects';

const Pagination = () => {


    const { allProjectDetails } = useSelector((state) => state.getAllProjects);
  const { user } = useSelector((state) => state.me);
  const [limit ,setLimit ] =  useState(allProjectDetails?.limit ? allProjectDetails?.limit : 5)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProjects({limit:limit}));
  }, [limit, dispatch, user.companyId]);
  return (
    <>
        <div className='mt-3 bg-white rounded-md'>
            <div className='px-6 flex justify-between items-center py-5'>
                <div className='flex gap-1'>
                    <CaretDoubleLeft 
                    onClick={() => {
                        dispatch(getAllProjects({limit:limit, page:1}))
                    }} 
                    className='p-1.5 border-[0.5px] border-bottom-border cursor-pointer bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded' size={26} weight="bold" />
                    <CaretLeft
                        onClick={() => {
                            if (allProjectDetails?.hasPrevPage === true){
                                dispatch(getAllProjects({limit:limit, page:allProjectDetails.prevPage}));
                            }
                        
                        }}
                     className='p-1.5 border-[0.5px] border-bottom-border cursor-pointer bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] hover:bg-theme-green rounded' size={26} weight="bold" />
                        {allProjectDetails?.hasPrevPage &&
                            <p 
                            onClick={() => {
                                if (allProjectDetails?.hasPrevPage === true){
                                    dispatch(getAllProjects({limit:limit, page:allProjectDetails.prevPage}));
                                }
                            
                            }}
                            
                            className='pt-[5px] leading-2  pb-0.5 px-2.5 cursor-pointer w-7 text-center rounded font-medium  border-[0.5px] border-[#00000029] text-very-tiny'>{allProjectDetails?.prevPage}</p>
                        }
                        <p className='pt-[5px] leading-2  bg-theme-green cursor-pointer  pb-0.5 w-7 text-center px-2.5 rounded hover:bg-theme-green font-medium hover:border-[#00000029] border-[0.5px] border-theme-green text-very-tiny'>
                        {allProjectDetails?.page}
                        </p>
                        {allProjectDetails?.hasNextPage &&
                            <p 
                            onClick={() => {
                                if (allProjectDetails?.hasNextPage){
                                    dispatch(getAllProjects({limit:limit, page:allProjectDetails.nextPage}));
                                }
                            
                            }}
                            className='pt-[5px] leading-2 pb-0.5 px-2.5 cursor-pointer w-7 text-center hover:bg-theme-green rounded font-medium  border-[0.5px] border-[#00000029] text-very-tiny'>{allProjectDetails?.nextPage}</p>
                        }
                    <CaretRight 
                        onClick={() => {
                            if (allProjectDetails?.hasNextPage){
                                dispatch(getAllProjects({limit:limit, page:allProjectDetails.nextPage}));
                            }
                        
                        }}
                    className='p-1.5 border-[0.5px] border-bottom-border cursor-pointer bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded' size={26} weight="bold" />
                    <CaretDoubleRight
                        onClick={() => {
                        dispatch(getAllProjects({limit:limit, page:allProjectDetails.totalPages}))
                    }} 
                     className='p-1.5 border-[0.5px] border-bottom-border cursor-pointer bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded' size={26} weight="bold" />
                </div>


{/*                
                    <div className='flex text-very-tiny gap-4 items-center'>
                    <p>Displaying {allProjectDetails?.docs?.length} of {allProjectDetails?.totalDocs}</p>
                    <div className='py-1 bg-[#f7f6f6] rounded-md px-1.5 border border-[rgba(0, 0, 0, 0.1)]'>
                        <select onChange={(e) => setLimit(e.target.value)} defaultValue={limit} className='h-7 bg-[#f7f6f6] focus:outline-none' name="" id="">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                        </select>
                    </div>
                </div> */}
            </div>
        </div>
    </>
  )
}

export default Pagination