import { ArrowRight, DownloadSimple } from "phosphor-react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { downloadDoc } from "../../../../../../api/users/fileUpload";
import { openSingleDocumentAction } from "../../../../../../redux/actions/documents/SingleDocumentAction";

const SingleDoc = ({ d }) => {
  let date = new Date(d.updatedAt);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  const download = async () => {
    const url = await downloadDoc(d.data);

    window.open(url, "_blank");
  };

  const navigate = useNavigate();
  let isAdmin = useLocation().pathname.split("/")[1] === "admin";
  const openSingleDocHandler = (d) => {
    // dispatch(openSingleDocumentAction(d._id))
    navigate(`${isAdmin ? "/admin" : ""}/documents/${d._id}`);
  };

  return (
    <>
      <div className="flex border-b border-bottom-border px-6 py-5">
        <div className="w-1/4 font-semibold ">
          <p className="text-xs ">ID</p>
          <p className="text-very-tiny opacity-50">{d.uid}</p>
        </div>

        <div className="w-2/5 ">
          <div className="flex gap-2 items-center">
            <div>
              <p className="font-medium text-xs ">
                {d.name} <span className="opacity-70">( v {d.version} )</span>{" "}
              </p>
              {d.type !== null && (
                <p className="leading-4 text-very-tiny font-medium">
                  <span className="opacity-50 mr-1">{d.type.type}</span>{" "}
                  <span className="uppercase opacity-50">({d.type.code})</span>
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="w-1/6 ">
          <p className="text-xs font-medium">Last Edit</p>
          <p className="text-very-tiny opacity-50 font-semibold capitalize">
            {dt}/{month}/{year}
          </p>
        </div>
        {/* <div className='w-1/6 '>
                <p className='text-xs font-medium'>Version</p>
                <p className='text-very-tiny opacity-50 font-semibold capitalize'>{d.version}</p>
            </div>   */}

        <div className="text-xs flex flex-1 gap-1 justify-end font-medium">
          <DownloadSimple
            onClick={download}
            className="bg-#F7F6F6 p-1.5 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
            size={28}
            weight="light"
          />
          <ArrowRight
            onClick={() => openSingleDocHandler(d)}
            className="bg-#F7F6F6 p-1.5 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
            weight="light"
            size={28}
          />
        </div>
      </div>
    </>
  );
};

export default SingleDoc;
