/* eslint-disable no-unused-vars */
import classNames from "classnames";
import { ArrowRight } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getDayBeforeYesterDay,
  getLastWeeksDate,
  getToday,
  getYesterDay,
} from "../../../../libs/getDates";
import { getRecentActivityAction } from "../../../../redux/actions/getRecentAcivityAction";
import { Filter } from "../../../../styles/FilterButton";
import { Activity } from "../../../../styles/RecentActivity";
import { recentActivity } from "../../../fake/fake";

const RecentActivityAdmin = () => {
  const [active, setActive] = useState({
    today: true,
    yesterday: false,
    thisWeek: false,
  });

  const dispatch = useDispatch();

  const [fromDate, setfromDate] = useState(getYesterDay());

  useEffect(() => {
    if (active.today) {
      setfromDate(getYesterDay());
    }
    if (active.thisWeek) {
      setfromDate(getLastWeeksDate());
    }
    if (active.yesterday) {
      setfromDate(getDayBeforeYesterDay());
    }
  }, [active]);
  useEffect(() => {
    dispatch(
      getRecentActivityAction({
        page: 1,
        limit: 50,
        companyId: "",
        fromDate,
        toDate: getToday,
      })
    );
  }, [fromDate]);

  const { activities, allData } = useSelector(
    (state) => state.getRecentActivity
  );

  const navigate = useNavigate();

  const activityNavigator = (ra) => {
    let screen = ra.document
      ? "documents"
      : ra.supplier
      ? "companies"
      : ra.asset
      ? "assets"
      : ra.project && "projects";

    navigate(
      `/admin/${screen}/${
        ra.document?.id || ra.supplier?.id || ra.asset?.id || ra.project?.id
      }`
    );
  };
  return (
    <>
      <div className="w-5/12  pt-6 bg-white rounded-md">
        <div className="flex px-6 justify-between items-center">
          <div className="text-theme-black">
            <p className="font-bold text-sm">Recent Activity</p>
            <p className="opacity-50 text-sm">
              {allData?.totalDocs} new{" "}
              {active.yesterday
                ? "yesterday"
                : active.thisWeek
                ? "this week"
                : "today"}
            </p>
          </div>
          <div className="flex justify-end gap-2">
            <Filter
              onClick={() =>
                setActive({ yesterday: false, thisWeek: false, today: true })
              }
              active={active.today}
            >
              Today
            </Filter>
            <Filter
              onClick={() =>
                setActive({ today: false, thisWeek: false, yesterday: true })
              }
              active={active.yesterday}
            >
              Yesterday
            </Filter>
            <Filter
              onClick={() =>
                setActive({ today: false, yesterday: false, thisWeek: true })
              }
              active={active.thisWeek}
            >
              This Week
            </Filter>
          </div>
        </div>
        <div className="mt-4 h-[400px] px-6 overflow-y-auto">
          {activities?.map((ra, index) => {
            let date = new Date(ra.createdAt);

            return (
              <Activity key={index}>
                <div className="w-10%">
                  <p className="uppercase">{date.toString().substring(0, 3)}</p>
                  <p className="uppercase opacity-50 text-theme-black">
                    {date.toString().substring(4, 10)}
                  </p>
                </div>

                <div className="">
                  <img
                    src={`${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${ra.createdBy.profilePic}`}
                    className="w-8 h-8 object-contain rounded-full  border border-theme-green p-0.5"
                    alt=""
                  />
                </div>

                <div className="w-1/3">
                  <p>{ra.action.description}</p>
                  <p className="text-blue-400 underline">
                    {ra.document?.name ||
                      ra.supplier?.name ||
                      ra.asset?.name ||
                      ra.project?.name}
                  </p>
                </div>

                {
                  <div className="w-1/4">
                    {ra.document && (
                      <>
                        <p>Project</p>
                        <p className="opacity-50 text-theme-black">
                          {ra.document?.id.projectId.name}
                        </p>
                      </>
                    )}
                  </div>
                }
                {/* <i className="fa-solid fa-arrow-right bg-#F7F6F6 px-3 rounded py-2 text-sm"></i> */}
                <ArrowRight
                  onClick={() => activityNavigator(ra)}
                  className={classNames(
                    "bg-#F7F6F6 p-2 rounded cursor-pointer hover:border ",
                    ra.supplier ? "visible" : ""
                  )}
                  size={32}
                  weight="light"
                />
              </Activity>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RecentActivityAdmin;
