import { getDocumentsOfASupplier } from "../../../api/users/company";
import {
  GET_DOCUMENTS_OF_A_SUPPLIER_FAIL,
  GET_DOCUMENTS_OF_A_SUPPLIER_REQ,
  GET_DOCUMENTS_OF_A_SUPPLIER_SUCC,
} from "../../constants/suppliers/getDocumentOfASupplier";

export const getDocumentsOfASupplierAction =
  (supplierId, companyId = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_DOCUMENTS_OF_A_SUPPLIER_REQ });

      const res = await getDocumentsOfASupplier(supplierId, 25, 1, companyId);
      dispatch({
        type: GET_DOCUMENTS_OF_A_SUPPLIER_SUCC,
        payload: res.data.docs,
      });
    } catch (error) {
      dispatch({
        type: GET_DOCUMENTS_OF_A_SUPPLIER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
