/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import styled from 'styled-components'
import { getUsers } from '../../../redux/actions/users/getUsers'
import AddNewEmployee from './AddNewEmployee'
import ShowCase from './comps/ShowCase'
import TopLinks from './comps/TopLinks'
import SingleEmployee from './singleEmployee'



const MainDiv = styled.div`
  height: calc(100vh - 57px);

`

const Employess = () => {

  const dispatch = useDispatch()

  const { employess } = useSelector(state => state.AdminLeftNav)
  const { open, employee } = useSelector(state => state.singleEmployeeAdmin)

  const [showEditScreen, setEditScreen] = useState(false)

  const saveEdit = () => {
    setEditScreen(false)
  }

  const cancelEdit = () => {
    setEditScreen(false)
  }
  useEffect(() => {

    dispatch(getUsers())


  }, [])

  return (
    <>

      <MainDiv className='px-[32px] py-8 overflow-y-auto'>
        <TopLinks showEditScreen={showEditScreen} backToSingleAsset={() => setEditScreen(false)} editScreenHandler={() => setEditScreen(true)} />
        <Routes>
          <Route path='/' element={<ShowCase />}/>
          <Route path='/addNew' element={<AddNewEmployee />}/>
          <Route path='/:id' element={<SingleEmployee />}/>
        </Routes>
        {/* {!employess.add && !open && <ShowCase />} */}
        {/* {!employess.add && open && <SingleEmployee />} */}
        {/* {employess.add && <AddNewEmployee />} */}
      </MainDiv>
    </>
  )
}

export default Employess