/* eslint-disable no-unused-vars */
import classNames from "classnames";
import { CaretRight, NotePencil } from "phosphor-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DASHBOARD_OPEN,
  PROJECTS_LIST_OPEN,
} from "../../../../redux/constants/AdminConsts/LeftNavAdminConst";
import { CLOSE_SINGLE_PROJECT_ADMIN } from "../../../../redux/constants/AdminConsts/ProjectsAdminConst";

const TopLinks = ({ showEditScreen, backToSingleAsset, editScreenHandler }) => {
  const dispatch = useDispatch();
  const { open, project } = useSelector((state) => state.singleProjectAdmin);
  const { projects } = useSelector((state) => state.AdminLeftNav);
  // const {project:SingleProject} = useSelector(state => state.singleProject)

  const navigate = useNavigate();

  const location = useLocation().pathname.split("/")[3];

  return (
    <>
      <div className="flex items-center">
        <p
          onClick={() => {
            dispatch({ type: DASHBOARD_OPEN });
            navigate("/");
          }}
          className="text-link-green text-very-tiny font-medium cursor-pointer"
        >
          Dashboard
        </p>
        <p className="text-very-tiny font-medium flex items-center">
          <CaretRight className="mx-1.5" size={10} />{" "}
          <span
            onClick={() => {
              dispatch({ type: CLOSE_SINGLE_PROJECT_ADMIN });
              dispatch({ type: PROJECTS_LIST_OPEN });
              navigate("/admin/projects");
            }}
            className={classNames("cursor-pointer", open && "text-link-green")}
          >
            Projects
          </span>{" "}
        </p>

        {location !== undefined && project && (
          <div className={classNames("flex", !showEditScreen && "gap-3")}>
            <p className="text-very-tiny font-medium flex items-center">
              <CaretRight className="mx-1.5" size={10} />
              <span
                onClick={backToSingleAsset}
                className={classNames(
                  "cursor-pointer",
                  showEditScreen && "text-link-green"
                )}
              >
                {project.name}
              </span>
            </p>
            {/* edit screen  */}
          </div>
        )}
        {/* {projects.add && <p className='text-very-tiny font-medium flex items-center'><CaretRight className='mx-1.5' size={10}  /> <span  className={classNames('cursor-pointer')}>Create new project</span> </p>} */}
      </div>
    </>
  );
};

export default TopLinks;
