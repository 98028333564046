/* eslint-disable no-unused-vars */
import classNames from 'classnames'
import { CaretRight, NotePencil } from 'phosphor-react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { DASHBOARD_OPEN, DOCUMENTS_LIST_OPEN } from '../../../../redux/constants/AdminConsts/LeftNavAdminConst'
import { HIDE_SINGLE_DOCUMENT_ADMIN } from '../../../../redux/constants/AdminConsts/SingleDocumentOpenerConst'
const TopLinks = ({showEditScreen, backToSingleAsset, editScreenHandler}) => {

    const dispatch = useDispatch()
    const {open, document} = useSelector(state => state.adminSingleDocument)
    const {  documents } = useSelector(state => state.AdminLeftNav)

    const navigate = useNavigate()


    const textGreen = useLocation().pathname.split('/')[3] !== undefined
  return (
    <>
        <div className='flex items-center'>
                <p onClick={() => {dispatch({type: DASHBOARD_OPEN}); navigate('/')}} className='text-link-green text-very-tiny font-medium cursor-pointer'>Dashboard</p>
                <p className='text-very-tiny font-medium flex items-center'><CaretRight className='mx-1.5' size={10}  /> <span onClick={() => {dispatch({type:HIDE_SINGLE_DOCUMENT_ADMIN}); dispatch({type:DOCUMENTS_LIST_OPEN}); navigate('/admin/documents')}} className={classNames('cursor-pointer', (textGreen) && "text-link-green")}>Documents</span> </p>

                {textGreen && <div className={classNames('flex',!showEditScreen && 'gap-3')}>
                        <p className='text-very-tiny font-medium flex items-center'><CaretRight className='mx-1.5' size={10}  /> 
                          <span onClick={backToSingleAsset} className={classNames('cursor-pointer', showEditScreen && "text-link-green")}>{document?.name}</span> 
                        </p>
                </div>}
                {/* {documents.add && <p className='text-very-tiny font-medium flex items-center'><CaretRight className='mx-1.5' size={10}  /> <span  className={classNames('cursor-pointer')}>Create new document</span> </p>} */}

            </div>
    </>
  )
}

export default TopLinks