/* eslint-disable react-hooks/exhaustive-deps */
import { NotePencil, Plus, Trash } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  createSingleDocType,
  deleteSingleDocType,
  editSingleDocType,
} from "../../../api/users/document";
import { getAllDocsTypes } from "../../../redux/actions/documents/getAllDocTypes";

const DocumentType = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllDocsTypes());
  }, []);

  const { docTypes } = useSelector((state) => state.getDocType);

  // const [documentTypes, setdocumentTypes] = useState([])
  const [openModal, setOpenModal] = useState(false);

  const [name, setName] = useState("");
  const [uid, setuId] = useState("");
  const addHandler = async () => {
    // setdocumentTypes([...documentTypes, { name: name, uid:uid}])
    try {
      toast.loading("Creating Document Type");
      await createSingleDocType({ type: name, code: uid });
      toast.dismiss();

      toast.success("Sucessfully Created.");
      setOpenModal(false);
      setuId("");
      setName("");

      // so that it loads the latest data
      dispatch(getAllDocsTypes());
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  const [editModal, setEditModal] = useState(false);
  const [tobeEdit, setTobeEdit] = useState("");
  const [editValuesName, setEditValuesName] = useState("");
  const [editValuesUId, setEditValuesUId] = useState("");

  const editHandler = (e) => {
    setEditModal(true);
    setEditValuesName(e.type);

    setEditValuesUId(e.code);
    setTobeEdit(e._id);
  };

  const saveEditedHandler = async () => {
    try {
      let find = {};
      find.code = editValuesUId;
      find.type = editValuesName;
      toast.loading("Saving the edited document type.");

      await editSingleDocType(tobeEdit, find);
      toast.dismiss();

      toast.success("Saved the edited doc type.");

      setEditModal(false);
      // so that it loads the latest data

      dispatch(getAllDocsTypes());
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  const deleteHandler = async (e) => {
    try {
      let find = {};
      find.code = editValuesUId;
      find.type = editValuesName;
      toast.loading("Deleting the document type");

      await deleteSingleDocType(e._id);
      toast.dismiss();

      toast.success(`Deleted the document type of type ${e.type}`);

      setEditModal(false);

      // so that it loads the latest data
      dispatch(getAllDocsTypes());
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  return (
    <>
      <div className="bg-white min-h-[80vh] mt-8 px-6 rounded-lg py-8 pb-16">
        <div className="flex">
          <div
            onClick={() => setOpenModal(true)}
            className="p-4 border cursor-pointer border-theme-green "
          >
            <Plus color="#323132B2" weight="bold" size={20} />
          </div>
        </div>

        <div className="grid grid-cols-5 gap-4 mt-8">
          {docTypes !== undefined &&
            docTypes?.map((b, index) => {
              return (
                <div className="rounded-md border" key={index}>
                  <div className="flex text-xs bg-[#DAD2D2] rounded-t-md justify-between items-center p-2.5">
                    <p>List {index + 1}</p>
                    <div className="text-theme-black flex items-center gap-3 opacity-80">
                      <NotePencil
                        onClick={() => editHandler(b)}
                        weight="bold"
                        className="cursor-pointer"
                        size={14}
                      />
                      <Trash
                        onClick={() => deleteHandler(b)}
                        weight="bold"
                        className="cursor-pointer"
                        size={14}
                      />
                    </div>
                  </div>

                  <div className="py-3 text-xs items-center flex justify-between px-2.5">
                    <p className="font-semibold">{b.type}</p>
                    <p>{b.code}</p>
                  </div>
                </div>
              );
            })}
        </div>

        {/* add modal */}
        {openModal && (
          <div
            onClick={() => setOpenModal(false)}
            className="fixed w-[100vw] h-[100vh] top-0 left-0 z-[9999] bg-black bg-opacity-20"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="absolute w-[25rem] p-4 bg-white rounded-md border-[3px] border-theme-black border-opacity-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            >
              <div className="mt-4  pb-4 border-b">
                <p className="font-medium text-xs">Add Document Type</p>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Document Type"
                  className="w-full focus:outline-none text-xs mt-3 px-4 py-2 border border-black-10 rounded"
                />
                <input
                  value={uid}
                  onChange={(e) => setuId(e.target.value)}
                  type="text"
                  placeholder="Document Code"
                  className="w-full focus:outline-none text-xs px-4 py-2 border border-black-10 rounded mt-2"
                />
              </div>

              <div className="py-4 grid gap-4 grid-cols-2">
                <button
                  onClick={() => setOpenModal(false)}
                  className="py-2 bg-[#F7F6F6] rounded-md hover:bg-[#0000001a] hover:border-[#00000033] border border-black10 text-xs text-theme-black text-opacity-80"
                >
                  {" "}
                  No
                </button>
                <button
                  onClick={addHandler}
                  className="py-2 bg-theme-green text-xs rounded-md"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        )}

        {/* edit modal */}
        {editModal && (
          <div
            onClick={() => setOpenModal(false)}
            className="fixed w-[100vw] h-[100vh] top-0 left-0 z-[9999] bg-black bg-opacity-20"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="absolute w-[25rem] p-4 bg-white rounded-md border-[3px] border-theme-black border-opacity-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            >
              <div className="mt-4  pb-4 border-b">
                <p className="font-medium text-xs">Edit the Document Type</p>
                <input
                  value={editValuesName}
                  onChange={(e) => setEditValuesName(e.target.value)}
                  type="text"
                  className="w-full focus:outline-none text-xs mt-3 px-4 py-2 border border-black-10 rounded"
                />
                <input
                  value={editValuesUId}
                  onChange={(e) => setEditValuesUId(e.target.value)}
                  type="text"
                  className="w-full focus:outline-none text-xs px-4 py-2 border border-black-10 rounded mt-2"
                />
              </div>

              <div className="py-4 grid gap-4 grid-cols-2">
                <button
                  onClick={() => setOpenModal(false)}
                  className="py-2 bg-[#F7F6F6] rounded-md hover:bg-[#0000001a] hover:border-[#00000033] border border-black10 text-xs text-theme-black text-opacity-80"
                >
                  {" "}
                  No
                </button>
                <button
                  onClick={saveEditedHandler}
                  className="py-2 bg-theme-green text-xs rounded-md"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DocumentType;
