import { ADMIN_OPEN, ADVANCED_SEARCH_OPEN, ASSETS_CREATE_VIEW_OPEN, ASSETS_LIST_VIEW_OPEN, ASSETS_MAP_VIEW_OPEN, COMPANIES_ADD_COMPANY_VIEW_OPEN, COMPANIES_INVITE_VIEW_OPEN, COMPANIES_LIST_VIEW_OPEN, DASHBOARD_OPEN, DOCUMENTS_CREATE_OPEN, DOCUMENTS_LIST_OPEN, DOCUMENTS_MAP_OPEN, EMPLOYESS_ADD_OPEN, EMPLOYESS_LIST_OPEN, ISO_OPEN, NOTIFICATIONS_OPEN, PROFILE_OPEN, PROJECTS_CREATE_OPEN, PROJECTS_LIST_OPEN, PROJECTS_MAP_OPEN, SUPPORT_CHAT_DEV_OPEN, SUPPORT_CHAT_USER_OPEN } from "../../constants/AdminConsts/LeftNavAdminConst"


const defaultState = {
    dashboard:true,
    companies: {
        add:false,
        invite:false,
        list:true
    },
    assets: {
        add:false,
        map:false,
        list:true
    },
    projects: {
        add:false,
        map:false,
        list:true
    },
    documents: {
        add:false,
        map:false,
        list:true
    },
    employess: {
        add:false,
        list:true
    },
    iso:false,
    profile:false,
    adminOptions:false,
    notifications:false,
    support:{
        dev:false,
        user:false
    },
    advancedSearch:false
}

export const AdminLeftNavRedcer = (state= defaultState, action)=>{
    switch(action.type){
        case DASHBOARD_OPEN:
            return{
                dashboard:true,
            }
        case COMPANIES_LIST_VIEW_OPEN:
            return{
                companies: {
                    list:true
                }
            }
        
        case COMPANIES_INVITE_VIEW_OPEN:
            return{
                companies: {
                    invite:true,
                }
            }

        case COMPANIES_ADD_COMPANY_VIEW_OPEN:
            return{
                companies: {
                    add:true,
                }
            }
        
        case ASSETS_LIST_VIEW_OPEN:
            return{
                assets:{
                    list:true
                }
            }
        
        case ASSETS_MAP_VIEW_OPEN:
            return{
                assets:{
                    map:true,
                }
            }

        case ASSETS_CREATE_VIEW_OPEN:
            return{
                assets:{
                    add:true,
                }
            }

        case PROJECTS_LIST_OPEN:
            return{
                projects:{
                    list:true
                }
            }

       case PROJECTS_MAP_OPEN:
           return{
               projects:{
                   map:true
               }
           }

        case PROJECTS_CREATE_OPEN:
        return{
            projects:{
                add:true
            }
        }

        case DOCUMENTS_LIST_OPEN:
            return {
                documents:{
                    list:true
                }
            }


        case DOCUMENTS_MAP_OPEN:

            return{
                documents:{
                    map:true
                }
            }

        case DOCUMENTS_CREATE_OPEN:
        return{
            documents:{
                add:true
            }
        }

        
        case EMPLOYESS_LIST_OPEN:
            return {
                employess:{
                    list:true
                }
            }


        case EMPLOYESS_ADD_OPEN:
            return{
                employess:{
                    add:true
                }
            }

        case ISO_OPEN:
            return{
                iso:true
            }

        case PROFILE_OPEN:
            return{
                profile:true
            }

        case ADMIN_OPEN:
            return{
                adminOptions:true
            }

        case NOTIFICATIONS_OPEN:
            return{
                notifications:true
            }

        case ADVANCED_SEARCH_OPEN:
            return{
                advancedSearch:true
            }

        case SUPPORT_CHAT_USER_OPEN:
            return{
                support:{
                    user:true
                }
            }

        case SUPPORT_CHAT_DEV_OPEN:
            return{
                support:{
                    dev:true
                }
            }

        


        default:
            return state;
    }
}
