import styled from "styled-components";
import tw from "twin.macro";

export const Filter = styled.button.attrs({
    className: "text-xs py-2 rounded-md font-medium cursor-pointer select-none hover:bg-theme-green hover:text-black"
})`
    ${props => props.active ? `
  background-color: #6ABEAE;

  ` : `
  background-color: rgba(106, 190, 174, 0.2);
  color:  #6ABEAE;
  `
    }
`

export const FilterRitghtBar = styled.button.attrs({
    className: "px-4 text-[10px] py-2 rounded-md font-medium cursor-pointer select-none hover:bg-theme-green hover:text-black"
})`
    ${props => props.active ? `
  background-color: #6ABEAE;

  ` : `
  background-color: rgba(106, 190, 174, 0.2);
  color:  #6ABEAE;
  `
    }
`

export const FilterButton = styled.button.attrs({
    className: "px-10 py-3 rounded text-sm  select-none "
})`
    ${props => (props.active ? `
    background-color: #6ABEAE;

    ` : `
    background-color: rgba(106, 190, 174, 0.2);
    color:  #6ABEAE;
    `
    )
    }

 ${props => props.working ? `
        &:hover{
            background-color: #6ABEAE;
            color:#000;
        }
     `: `
     cursor: not-allowed;
     background: rgb(229 231 235);
     color: rgb(156 163 175);
     `
    }

`