/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { PROJECTS_LIST_OPEN, PROJECTS_MAP_OPEN } from '../../../redux/constants/AdminConsts/LeftNavAdminConst'
import MapView from './comps/MapView'

import ShowCase from './comps/ShowCase'
import TopLinks from './comps/TopLinks'
import CreateProject from './CreateProject/CreateProject'
import MainEditProject from './editProject'
import SingleProject from './singleProject'

const MainDiv = styled.div`
  height: calc(100vh - 57px);

`

const MapDiv = styled.div`
    &:after{
      content: '';
      position: absolute;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      z-index: 10;
      pointer-events: none;
      box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
    -o-box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
    }
`
const ProjectsAdmin = () => {

    const {projects } = useSelector(state => state.AdminLeftNav)

    const dispatch = useDispatch()
    const {open, project} = useSelector(state => state.singleProjectAdmin)


    const [showEditScreen, setEditScreen] = useState(false)

    const saveEdit = () => {
        setEditScreen(false)
    }

    const cancelEdit = () => {
        setEditScreen(false)
    }

    const navigate = useNavigate()

  return (
    <>


    <Routes>
      <Route path='/*' element={
      
      <MainDiv className='px-[32px] py-8 overflow-y-auto'>
            <TopLinks showEditScreen={showEditScreen} backToSingleAsset={() => setEditScreen(false)} editScreenHandler={() => setEditScreen(true)}/>
            <Routes>
              <Route path='/' element={<ShowCase/>} />
              <Route path='/:id' element={<SingleProject/>}/>
            </Routes>
        </MainDiv>} />

      <Route path='mapview' element={
            <MapDiv className='relative h-full'>
              <MapView  mapViewFunc={() => dispatch({type:PROJECTS_MAP_OPEN})}/>
          </MapDiv>}/>
</Routes>
    </>
  )
}

export default ProjectsAdmin