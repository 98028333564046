import classNames from 'classnames'
import { CaretRight } from 'phosphor-react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DASHBOARD_OPEN } from '../../../../redux/constants/AdminConsts/LeftNavAdminConst'
import { CLOSE_ALL_CONVERSATIONS } from '../../../../redux/constants/singleConversation'
import { CLOSE_CREATE_NEW_SUPPORT_TICKET } from '../../../../redux/reducers/Admin/CreateNewHelpTicket'

const TopLinks = () => {
  const { support } = useSelector(state => state.AdminLeftNav)
  const {opencreateNewSupportTicket} = useSelector(state=> state.createNewSupportTicket)
  const dispatch = useDispatch()
  const {open, subject} = useSelector(state => state.getConversations)
  const navigate = useNavigate()

  return (
    <>
         <div className='flex items-center'>
            <p onClick={() =>{dispatch({type:DASHBOARD_OPEN}) ; navigate('/')}} className='text-link-green text-very-tiny font-medium cursor-pointer'>Dashboard</p>
            <p onClick={() =>{dispatch({type:CLOSE_CREATE_NEW_SUPPORT_TICKET}) ; dispatch({type:CLOSE_ALL_CONVERSATIONS})}} className='text-very-tiny font-medium flex items-center'><CaretRight className='mx-1.5' size={10}  /> <span className={classNames('cursor-pointer',(opencreateNewSupportTicket || open) && 'text-theme-green')}>Help & Support {support.dev && '(dev)'}</span> </p>
            {opencreateNewSupportTicket && <p className='text-very-tiny font-medium flex items-center'><CaretRight className='mx-1.5' size={10}  /> <span className={classNames('cursor-pointer',)}>Create new ticket</span> </p>}
            {open && <p className='text-very-tiny font-medium flex items-center'><CaretRight className='mx-1.5' size={10}  /> <span className={classNames('cursor-pointer',)}>{subject}</span> </p>}

        </div>
    </>
  )
}

export default TopLinks