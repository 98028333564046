import classNames from "classnames";
import { ArrowRight } from "phosphor-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const AssetsDropDown = ({ asset, onClick }) => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <div className="px-6 bg-very-light-gray py-5 border-b border-color-border flex">
          <div className="w-[12%] text-xs font-medium">
            <p className="uppercase">id</p>
            <p className="opacity-50 text-theme-black">{asset.uid}</p>
          </div>

          <div className="flex w-[22%] pr-4 text-xs gap-2">
            <div className="h-full">
              <img src="images/charging-station.png" alt="" />
            </div>
            <div className="">
              {/* <p className='font-medium'>{asset.assetType}</p> */}
              <p>{asset.name}</p>
            </div>
          </div>

          {/* <div className='w-[27%] text-xs pr-4'>
                <p className='font-medium'>{asset.details.type}</p>
                <div className='flex gap-4'>
                    <p className='text-theme-black opacity-50'>{asset.details.uniqueId}</p>
                    <p className='uppercase text-theme-green font-semibold'>{asset.details.where}</p>
                </div>
            </div> */}

          <div className="text-xs w-[18%]">
            <p className="font-medium">Status</p>
            <p className="italic font-light">{asset.status}</p>
          </div>

          {/* <div className=' w-[9%] text-xs'>
                <div className=''>
                    <p className='leading-3'>Documents</p>
                    <p className='text-theme-black opacity-50 cursor-pointer leading-3'>{documents.length} <i className={classNames("fa-solid ml-1 ", 'fa-angle-down')}></i></p>
                </div>
            </div> */}

          <div className="text-xs flex flex-1 justify-end font-medium">
            <ArrowRight
              onClick={onClick}
              className="bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
              size={28}
              weight="light"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetsDropDown;
