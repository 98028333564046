import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { openSingleDocumentAdminAction } from "../../../../redux/actions/Admin/SingleDocumentAction";
import AssetsProjectsAndCompanies from "../../../components/Documents/SingleDocumentDetails/AssetsProjectsAndCompanies";

import Attributes from "./Attributes";
import VersionHistory from "./VersionsHis";

const SingleDocumentDetails = () => {
  const { document } = useSelector((state) => state.adminSingleDocument);
  const location = useLocation().pathname;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openSingleDocumentAdminAction(location.split("/")[3]));
  }, []);

  return (
    <>
      {document && (
        <div className="grid mt-4 grid-cols-3 gap-4">
          <Attributes />
          <VersionHistory />
          <AssetsProjectsAndCompanies isAdmin />
        </div>
      )}
    </>
  );
};

export default SingleDocumentDetails;
