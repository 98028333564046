import {
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployeesAction } from "../../../../redux/actions/users/getAllEmployeesAction";

const Pagination = ({ limit, setLimit }) => {
  const { paginationDetails } = useSelector((state) => state.getAllEmployees);
  const noOfPages = paginationDetails?.totalDocs;

  const { user } = useSelector((state) => state.me);

  const dispatch = useDispatch();

  //

  const prevPageOnClick = () => {
    dispatch(
      getAllEmployeesAction({
        limit: limit,
        page: paginationDetails?.prevPage,
        companyId: user?.companyId._id,
      })
    );
  };

  const firstPageOnClick = () => {
    dispatch(
      getAllEmployeesAction({
        limit: limit,
        page: 1,
        companyId: user?.companyId._id,
      })
    );
  };

  const nextPageOnClick = () => {
    dispatch(
      getAllEmployeesAction({
        limit: limit,
        page: paginationDetails?.nextPage,
        companyId: user?.companyId._id,
      })
    );
  };

  const lastPageOnClick = () => {
    dispatch(
      getAllEmployeesAction({
        limit: limit,
        page: paginationDetails?.totalPages,
        companyId: user?.companyId._id,
      })
    );
  };

  return (
    <>
      <div className="mt-3 bg-white rounded-md">
        <div className="px-6 flex justify-between items-center py-5">
          <div className="flex gap-1">
            <CaretDoubleLeft
              onClick={firstPageOnClick}
              className="p-1.5 border-[0.5px] border-bottom-border cursor-pointer bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded"
              size={26}
              weight="bold"
            />
            <CaretLeft
              onClick={prevPageOnClick}
              className="p-1.5 border-[0.5px] border-bottom-border cursor-pointer bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded"
              size={26}
              weight="bold"
            />

            {paginationDetails?.hasPrevPage && (
              <p
                onClick={prevPageOnClick}
                className="pt-[5px] leading-2  pb-0.5 px-2.5 cursor-pointer w-7 text-center rounded font-medium  border-[0.5px] border-[#00000029] text-very-tiny"
              >
                {paginationDetails?.prevPage}
              </p>
            )}
            <p className="pt-[5px] leading-2  bg-theme-green cursor-pointer  pb-0.5 w-7 text-center px-2.5 rounded hover:bg-theme-green font-medium hover:border-[#00000029] border-[0.5px] border-theme-green text-very-tiny">
              {paginationDetails?.page}
            </p>
            {paginationDetails?.hasNextPage && (
              <p
                onClick={nextPageOnClick}
                className="pt-[5px] leading-2 pb-0.5 px-2.5 cursor-pointer w-7 text-center hover:bg-theme-green rounded font-medium  border-[0.5px] border-[#00000029] text-very-tiny"
              >
                {paginationDetails?.nextPage}
              </p>
            )}

            <CaretRight
              onClick={nextPageOnClick}
              className="p-1.5 border-[0.5px] border-bottom-border cursor-pointer bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded"
              size={26}
              weight="bold"
            />
            <CaretDoubleRight
              onClick={lastPageOnClick}
              className="p-1.5 border-[0.5px] border-bottom-border cursor-pointer bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded"
              size={26}
              weight="bold"
            />
          </div>

          <div className="flex text-very-tiny gap-4 items-center">
            {paginationDetails && (
              <p>
                Displaying {(paginationDetails?.page - 1) * limit + 1} -{" "}
                {(paginationDetails?.page - 1) * limit +
                  paginationDetails?.docs?.length}{" "}
                of {paginationDetails?.totalDocs}
              </p>
            )}

            {/* <p>Displaying {paginationDetails?.totalDocs>limit? limit:  paginationDetails?.totalDocs}  of {noOfPages}</p> */}
            <div className="py-1 bg-[#f7f6f6] rounded-md px-1.5 border border-[rgba(0, 0, 0, 0.1)]">
              <select
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                className="h-7 bg-[#f7f6f6] focus:outline-none"
                name=""
                id=""
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pagination;
