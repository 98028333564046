import { ArrowRight } from 'phosphor-react'
import React from 'react'

const AddDocumentDropDown = ({d, onClick}) => {
  return (
    <>
        <div onClick={onClick} className='flex border-b border-bottom-border bg-white border-x border-[#00000033] px-6 py-5'>
            <div className='w-1/4 font-semibold '>
                <p className='text-xs '>ID</p>
                <p className='text-very-tiny opacity-50'>{d.uid}</p>
            </div>  

            <div className='w-1/3 '>
                <div className='flex gap-2 items-center'>
                    <div>
                        <p className='font-medium text-xs leading-3'>{d.name}</p>
                        <p className='leading-4 text-very-tiny font-medium'><span className='opacity-50 mr-1'>{d?.type?.type}</span> <span className='uppercase text-blue-300 font-medium underline'>{d?.type?.code}</span></p>
                    </div>
                </div>
            </div>  

            <div className='w-1/6 '>
                <p className='text-xs font-medium'>Last Edit</p>
                <p className='text-very-tiny opacity-50 font-semibold capitalize'>{d.updatedAt.substring(0,10)}</p>
            </div>  

            <div className='text-xs flex flex-1 justify-end font-medium'>
                <ArrowRight className='bg-#F7F6F6 p-1.5 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded' size={28} weight='light'/>
            </div>
        </div>
    </>
  )
}

export default AddDocumentDropDown