import { GET_ALL_VERSION_FAILED, GET_ALL_VERSION_REQ, GET_ALL_VERSION_SUCC } from "../../constants/documents/getAllVersions";

export const getAllVersionsReducer = (state={versions:[], loading:false, error:null}, action) => {
    switch(action.type){
        case GET_ALL_VERSION_REQ:
            return {
                loading:true
            }
         case GET_ALL_VERSION_SUCC:
             return{
                 loading:false,
                 versions:action.payload,
             }
 
         case GET_ALL_VERSION_FAILED:
             return{
                 loading:false,
                 error:action.payload
             }
 
         default:
             return state;
    }
 }