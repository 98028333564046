/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import styled from 'styled-components'
import { Filter } from '../../../styles/FilterButton'
import Discipline from './comps/Discipline'
import Form from './comps/Form'
import Functional from './comps/Functional'
import Spatial from './comps/SpatialBreakdown'

const MainDiv = styled.div`
  height: calc(100vh - 57px);

`
const ISO = () => {
    const [active, setActive] = useState({functional: true, spatial:false, from:false, discipline:false})

    const [spatiallBreakdowns, setspatiallBreakdowns] = useState([])
    const [forms, setforms] = useState([])
    const [disciplines, setdisciplines] = useState([])

  return (
    <>
        <MainDiv className='px-[32px] py-8 overflow-y-auto'>
            <div className='flex gap-2'>
                <Filter onClick={() => setActive({functional: true, spatial:false, from:false, discipline:false})} active={active.functional}>Functional Breakdown</Filter>
                <Filter onClick={() => setActive({functional: false, spatial:true, from:false, discipline:false})} active={active.spatial}>Spatial Breakdown</Filter>
                <Filter onClick={() => setActive({functional: false, spatial:false, from:true, discipline:false})} active={active.from}>Form</Filter>
                <Filter onClick={() => setActive({functional: false, spatial:false, from:false, discipline:true})} active={active.discipline}>Discipline</Filter>
            </div>

            <div className='bg-white min-h-[80vh] mt-8 px-6 rounded-lg py-8 pb-16'>
                {active.functional && <Functional/> }
                {active.spatial && <Spatial/>}
                {active.from && <Form/>}
                {active.discipline && <Discipline/>}
            </div>
        </MainDiv>
    </>
  )
}

export default ISO