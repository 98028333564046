import classNames from "classnames";
import { Buildings, CaretDown, MagnifyingGlass, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects } from "../../../../../redux/actions/project/getProjects";

const GrantAccessTab = ({ selectedProjectsArr, setselectedProjectsArr }) => {
  const [searchInput, setSeachInput] = useState("");
  const [showDropDown, setShowDropDown] = useState("");
  const { companyId } = useSelector((state) => state.selectCompany);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProjects({ companyId, page: 1, limit: 5, q: searchInput }));
  }, [companyId, searchInput, dispatch]);

  const selectedProjects = (p) => {
    if (!selectedProjectsArr.includes(p)) {
      setselectedProjectsArr([...selectedProjectsArr, p]);
    }

    setShowDropDown(false);
  };

  const removeProject = (s) => {
    let newArr = [...selectedProjectsArr];
    let newArr2 = newArr.filter((p) => p._id !== s._id);
    setselectedProjectsArr(newArr2);
  };

  const { projects } = useSelector((state) => state.getAllProjects);

  return (
    <>
      <div className="px-[20%] py-5 min-h-[20rem]">
        <div className="grid gap-2">
          <p className="font-medium text-sm">Projects</p>
          {/* <SelectComp value={selectedProject} onChange={(e) => setSelectedProject(e._id)} options={projectOptions} /> */}
          <div className="relative my-2">
            <input
              value={searchInput}
              onClick={() => setShowDropDown(!showDropDown)}
              onChange={(e) => setSeachInput(e.target.value)}
              placeholder={
                showDropDown ? "Select Existing Projects" : "Add Project"
              }
              type="text"
              className={classNames(
                "w-full border border-[#00000033] bg-[#0000000D] cursor-pointer rounded-t-md focus:outline-none py-3 text-very-tiny",
                showDropDown ? "px-4" : "px-[36px] rounded-b-md"
              )}
            />
            {!showDropDown && (
              <Buildings
                size={16}
                weight="bold"
                className="absolute text-theme-black opacity-80 left-3 top-[13px]"
              />
            )}
            {showDropDown ? (
              <MagnifyingGlass
                size={14}
                className="absolute text-theme-black opacity-80 right-3 top-[14px]"
              />
            ) : (
              <CaretDown
                size={14}
                className="absolute text-theme-black opacity-80 right-3 top-[14px]"
              />
            )}

            {showDropDown && (
              <div className="absolute z-[99] w-full cursor-pointer h-64 overflow-y-auto border-[#00000033]">
                {projects?.map((p, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => selectedProjects(p)}
                      className="px-2  bg-white  text-xs py-2 border-b border-x"
                    >
                      <p className="bg-white px-2 py-2">
                        {" "}
                        {p.name} - {p.uid}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}

            <div className="grid grid-cols-2 gap-3 mt-4">
              {selectedProjectsArr?.map((s, idx) => {
                return (
                  <div className="bg-white rounded-lg border border-bottom-border">
                    <p className="text-xs font-medium bg-[#DAD2D2] p-2 rounded-t-lg flex justify-between items-center">
                      {s?.name}
                      <X
                        onClick={() => removeProject(s)}
                        className="cursor-pointer"
                        size={12}
                      />
                    </p>
                    <div className="p-2">
                      <p className="text-xs py-1">
                        UID: <span className="opacity-50">ID {s?.uid}</span>
                      </p>
                      <p className="text-xs py-1">
                        Owner:{" "}
                        <span className="opacity-50">{s?.companyId?.name}</span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GrantAccessTab;
