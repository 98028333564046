import { getDocTypes } from "../../../api/users/document"
import { GET_DOCTYPE_FAILED, GET_DOCTYPE_REQ, GET_DOCTYPE_SUCC } from "../../constants/documents/getDocumentTypes"

export const getAllDocsTypes = () => async(dispatch) => {
    try{
        dispatch({type:GET_DOCTYPE_REQ})
        const res = await getDocTypes()

        dispatch({
            type:GET_DOCTYPE_SUCC,
            payload:res.data.docs,

        })

    } catch(error){
        dispatch({
            type: GET_DOCTYPE_FAILED,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}