import React from 'react'
import Select from 'react-select'


const SelectComp = ({ options, onChange, value }) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      padding: '0',
      background: "#fff",
      borderRadius: state.isFocused ? "4px 4px 4px 4px" : 4,
      borderColor: state.isFocused ? "#fff" : "#fff",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "#fff" : "#fff"
      }
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,

    }),
    options: (base) => ({
      ...base,
      padding: 0,
      "&:hover": {
        background: "#6ABEAE"
      }
    })
  };


  return (
    <>
      <Select
        value={options.find((val) => val.value === value)}
        onChange={onChange}
        styles={customStyles}
        className='text-xs w-full'
        options={options}
        defaultValue={options.length === 1 ? options[0] : null}
        components={{
          IndicatorSeparator: () => null
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            text: '#000',
            primary25: '#6ABEAE',
            primary: '#6ABEAE',
          },
        })}
        placeholder="Select"
      />
    </>
  )
}

export default SelectComp