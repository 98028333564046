import axios from "axios";
import { serialize } from "../../libs/serialize";
import { accessToken } from "../../libs/token";

export const addCompany = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/company`,
    data,
    await accessToken()
  );
};
export const editCompany = async (id, data) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/company/${id}`,
    data,
    await accessToken()
  );
};
export const addCompanyProjects = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/company/projects`,
    data,
    await accessToken()
  );
};
export const getCompaniesApi = async (limit, page, q) => {
  const uri = serialize({ limit, page, q });
  return axios.get(
    `${process.env.REACT_APP_API_URL}/company?${uri}`,
    await accessToken()
  );
};

export const getSuppliersApi = async (companyId, limit, page, q) => {
  const uri = serialize({ companyId, limit, page, q });
  return axios.get(
    `${process.env.REACT_APP_API_URL}/company/suppliers?${uri}`,
    await accessToken()
  );
};
export const getCompanyApi = async (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/company/${id}`,
    await accessToken()
  );
};

export const inviteSupplier = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/company/suppliers`,
    data,
    await accessToken()
  );
};

export const getProjectsOfASupplier = async (
  supplierId,
  limit,
  page,
  companyId
) => {
  const uri = serialize({ supplierId, limit, page, companyId });
  return axios.get(
    `${process.env.REACT_APP_API_URL}/company/projects?${uri}`,
    await accessToken()
  );
};

export const getSingleSupplierApi = async (supplierId) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/company/${supplierId}`,
    await accessToken()
  );
};

export const getDocumentsOfASupplier = async (
  supplierId,
  limit,
  page,
  companyId
) => {
  const uri = serialize({ supplierId, limit, page, companyId });

  return axios.get(
    `${process.env.REACT_APP_API_URL}/company/documents?${uri}`,
    await accessToken()
  );
};

export const addDocuMentToASupplier = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/company/documents`,
    data,
    await accessToken()
  );
};

export const getClientsOfACompany = async ({ page, limit, companyId }) => {
  const uri = serialize({ page, limit, companyId });
  return axios.get(
    `${process.env.REACT_APP_API_URL}/company/clients?${uri}`,
    await accessToken()
  );
};
