import React from 'react'
import FadeLoader from "react-spinners/FadeLoader"

const Loading = (
    { loading=false }
) => {
    return (
        <div className="w-full h-64 flex flex-col items-center justify-center space-y-3 bg-transparent">
            <FadeLoader color='#6ABEAE' loading={loading} height={12}	width={10}	radius={3}	margin={3} />
            <span>
                loading
            </span>
        </div>
    )
}

export default Loading

