/* eslint-disable no-unused-vars */
import { MagnifyingGlass, Plus } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  addProjectToAsset,
  getAssetsProjectsApi,
} from "../../../../api/users/asset";
import { Filter } from "../../../../styles/FilterButton";
import AddProjectToAsset from "../../../components/Assets/comps/singleAssetComps/AddProjectToAsset";
import SingleComp from "../../../components/Assets/comps/singleAssetComps/assetLinks/SingleComp";
import SingleDoc from "../../../components/Assets/comps/singleAssetComps/assetLinks/SingleDoc";
import SingleProject from "../../../components/Assets/comps/singleAssetComps/assetLinks/SingleProject";
import AddDocument from "../../../components/Supplier/SingleSupplier/AddDocument";
import { FakeProjects } from "../../../fake/fakeProject";
import { FakeSuppliers } from "../../../fake/fakeSuppliers";
import { documents } from "../../../fake/faleDoc";

const MainDiv = styled.div`
  height: 45.5rem;
`;

const ProjectsDocumentsAndSupplier = () => {
  const [active, setActive] = useState({
    project: true,
    doc: false,
    comp: false,
  });
  const { asset } = useSelector((state) => state.singleAssetAdmin);

  const location = useLocation().pathname;

  const assetId = location.split("/")[3];

  // projectsArr.projects.filter((val)=>selectedAsset.includes(val._id))

  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);

  const fucn = async () => {
    try {
      const response = await getAssetsProjectsApi(assetId);
      setProjects(response.data.docs);
    } catch (error) {}
  };

  useEffect(() => {
    fucn();
  }, [dispatch, assetId]);

  const [excludeIds, setExcludeIds] = useState([]);
  useEffect(() => {
    const eIDs = projects?.map((p) => {
      return p._id;
    });
    setExcludeIds([...eIDs]);
  }, [projects]);

  const [addNewProject, setAddNewProject] = useState(false);

  const addNewProjectToAsset = () => {
    setAddNewProject(true);
  };

  const closeAddNewProjectTab = () => {
    setAddNewProject(false);
  };

  const onClick = (e) => {};

  // add projects

  const [showDropDown, setShowDropDown] = useState(false);

  const [selectedProjectsArr, setselectedProjectsArr] = useState([]);

  const selectedProjects = (p) => {
    if (!selectedProjectsArr.includes(p)) {
      setselectedProjectsArr([...selectedProjectsArr, p]);
    }

    setShowDropDown(false);
  };

  const removeProject = (s) => {
    let newArr = [...selectedProjectsArr];
    let newArr2 = newArr.filter((p) => p._id !== s._id);
    setselectedProjectsArr(newArr2);
  };

  const addProjects = async () => {
    toast.loading("adding project");
    const filterd = selectedProjectsArr.map((item) => {
      return item._id;
    });

    if (filterd.length > 0) {
      let data = {
        projectIds: [...filterd],
        assetId: asset._id,
      };
      await addProjectToAsset(data);
      // const res = await getSingleAsset(asset._id)
      // openSingleAsset(res)
      fucn();
      toast.dismiss();
      toast.success("project added ");
      closeAddNewProjectTab();
    } else {
      toast.dismiss();
      toast.success("project added ");
      closeAddNewProjectTab();
    }
  };

  return (
    <>
      <>
        {!addNewProject ? (
          <MainDiv className=" bg-white rounded-md">
            <div className="px-10 py-8  bg-[#F7F6F6] border-b border-bottom-border rounded-t-md">
              <div className="flex gap-2 justify-between">
                <Filter
                  onClick={() =>
                    setActive({ project: true, doc: false, comp: false })
                  }
                  active={active.project}
                >
                  Projects
                </Filter>
                {/* <Filter onClick={() => setActive({project: false, doc:true, comp:false})} active={active.doc}>Documents</Filter>
                    <Filter onClick={() => setActive({project: false, doc:false, comp:true})} active={active.comp}>Companies</Filter> */}
              </div>
            </div>

            {active.project && (
              <div className="h-[calc(100%-97px)] overflow-y-auto pb-6">
                {projects?.map((p, index) => {
                  return (
                    <SingleProject
                      onClick={(id) => onClick()}
                      key={index}
                      p={p}
                    />
                  );
                })}
                <div className="px-6 flex w-full justify-end mt-6">
                  <Plus
                    onClick={addNewProjectToAsset}
                    className="bg-[#00000033] p-1 cursor-pointer text-white border border-transparent hover:bg-[#DDDDDD] hover:text-black hover:border-[#00000033] rounded"
                    size={28}
                    weight="light"
                  />
                </div>
              </div>
            )}
            {/* {
                active.doc && <div className='relative h-[85%]'>
                        <div className='w-full relative px-6 py-4 bg-[#EFEBE9] border-b border-bottm-border'>
                            <input placeholder='Search Documents' type="text" className='w-full border border-[#00000033] bg-white rounded-md focus:outline-none px-4 py-3 text-very-tiny'/>
                            <MagnifyingGlass size={16} className='absolute right-10 opacity-20 top-1/2 -translate-y-1/2' />
                        </div>
                    {
                        documents.map((d, index) => {
                            return <SingleDoc key={index} d={d}/>
                        })
                    }
                    <div className='absolute w-full bottom-0 px-3'>
                        <button onClick={openAllDocs} className='w-full py-2 bg-transparent border-2 border-bottom-border uppercase text-sm font-bold rounded-lg hover:bg-white hover:border-[#00000066]'><span className="opacity-40">View All Documents</span></button>
                    </div>
                </div>
            }

            {
                active.comp && <div className=''>
                    {
                        FakeSuppliers.map((s, idx) => {
                            return <SingleComp key={idx} s={s}/>
                        })
                    }
                </div>
            } */}
          </MainDiv>
        ) : (
          <AddProjectToAsset
            excludeIds={excludeIds}
            showDropDown={showDropDown}
            setShowDropDown={setShowDropDown}
            selectedProjectsArr={selectedProjectsArr}
            setselectedProjectsArr={setselectedProjectsArr}
            addProjects={addProjects}
            removeProject={removeProject}
            openSingleAsset={() => {}}
            selectedAsset={asset}
            purpose={"asset"}
            closeAddNewProjectTab={closeAddNewProjectTab}
          />
        )}
      </>
    </>
  );
};

export default ProjectsDocumentsAndSupplier;
