import classNames from "classnames";
import { CaretDown, CaretUp, Check } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { updateTaskApi } from "../../../../api/users/projects";
import { getAllTasksAction } from "../../../../redux/actions/project/getTasks";
import { getSuppliers } from "../../../../redux/actions/suppliers/getSuppliers";
import { openSingleSupplierAction } from "../../../../redux/actions/suppliers/openSingleSupplier";
import { getSingleUser } from "../../../../redux/actions/users/getSingleUser";

import ProgressBar from "@ramonak/react-progress-bar";
import { useLocation } from "react-router-dom";

const MainDiv = styled.div`
  height: 52rem;
`;

const ProjectCheckList = () => {
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.singleProjectAdmin);

  const { tasks } = useSelector((state) => state.getAllTasks);

  const location = useLocation().pathname;

  const projectId = location.split("/")[3];

  useEffect(() => {
    dispatch(getAllTasksAction(projectId));
  }, [dispatch, project]);

  const [clickedId, setClickedId] = useState("");
  const [showTaskDropDown, setshowTaskDropDown] = useState(false);

  const taskclickHandler = (t) => {
    setClickedId(t._id);
    setshowTaskDropDown(clickedId === t._id ? !showTaskDropDown : true);

    dispatch(getSingleUser(t.createdBy));
  };

  const { user: uploadedBy } = useSelector((state) => state.getSingleUser);
  const { suppliers } = useSelector((state) => state.suppliers);

  const { supplier } = useSelector((state) => state.singleSupplier);

  const { user } = useSelector((state) => state.me);
  // const {companyId} = useSelector(state=> state.selectCompany)

  useEffect(() => {
    dispatch(getSuppliers(user.companyId._id, 10));
  }, [user, dispatch]);

  useEffect(() => {
    if (tasks) dispatch(openSingleSupplierAction(tasks.supplierId, suppliers));
  }, [tasks, dispatch, suppliers]);

  const checkHandler = async (t) => {
    try {
      await updateTaskApi(t._id, { status: "Completed" });
    } catch (error) {}
  };

  let doneTasks = tasks.filter((t) => t.status === "Completed");
  return (
    <>
      <MainDiv className=" bg-white rounded-md relative">
        <div className="bg-[#F7F6F6] rounded-t-md py-6 flex flex-col gap-2 px-6 ">
          <h2 className="font-bold text-theme-black text-sm  text-center">
            Project Checklist
          </h2>
          <div className="  flex items-center gap-4">
            <p className="text-sm">
              {doneTasks.length} / {tasks.length}
            </p>
            <div className="w-[85%]">
              <ProgressBar
                completed={(doneTasks.length / tasks.length) * 100}
                bgColor="#6ABEAE"
                isLabelVisible={false}
                baseBgColor="rgba(106, 190, 174, 0.2)"
                labelColor="#000"
              />
            </div>
          </div>
        </div>

        {tasks?.length !== 0 &&
          tasks?.map((t, index) => {
            return (
              <div key={t._id}>
                <div
                  className={classNames(
                    "p-4 relative border-y border-[#DAD2D2] cursor-pointer",
                    showTaskDropDown && clickedId === t._id ? "" : ""
                  )}
                >
                  <div
                    onClick={() => taskclickHandler(t)}
                    className="flex justify-between items-center"
                  >
                    <div className="w-1/3">
                      <p className="font-medium text-xs">{t.name}</p>
                      <p className="text-xs opacity-50 font-semibold">
                        {index + 1}
                      </p>
                    </div>
                    {t.isDocument ? (
                      <div className="w-[55%]">
                        <div className="w-1/2">
                          <p className="text-xs font-bold">{t.documentName}</p>
                          <p className="text-[11px] font-medium opacity-60">
                            {t.documentType}
                          </p>
                        </div>

                        {/* <div className='flex items-center w-1/2 gap-3'>
                                              <img className='w-8 border border-theme-green rounded-full' src={supplier.profilePic} alt="" />
                                              <p className='font-medium  text-[10px]'>{supplier.name}</p>
                                          </div> */}
                      </div>
                    ) : (
                      <div className="flex items-center w-[55%] gap-3">
                        {/* <img className='w-8 border border-theme-green rounded-full' src={`${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${supplier?.profilePic}`} alt="" />
                                              <p className='font-medium  text-[10px]'>{supplier?.name}</p> */}
                      </div>
                    )}
                    {showTaskDropDown && clickedId === t._id ? (
                      <CaretUp size={16} />
                    ) : (
                      <CaretDown size={16} />
                    )}
                  </div>

                  {showTaskDropDown && clickedId === t._id && (
                    <div className="absolute bg-[#EFEBE9] z-[99] top-full -left-[1px] w-[calc(100%+2px)] p-4 border-borrom-border border rounded-b-md text-xs pt-6">
                      {/* {t.documentName && <div className='flex justify-between pb-6'>
                                            <div className=''>
                                                <p className='font-semibold'>Type of Document</p>
                                                <p>{t.docType}</p>
                                            </div>
                                            <div>
                                                <p className='font-semibold'>Attached Asset</p>
                                                <p>{t.attachedAsset.uid}</p>
                                                
                                            </div>
                                        </div>} */}

                      <div className="flex items-center gap-3 pb-4">
                        {t.status !== "Completed" ? (
                          <>
                            <Check
                              onClick={() => checkHandler(t)}
                              size={18}
                              className={classNames(
                                "text-white border border-[#3231324D] rounded p-[2px] cursor-pointer hover:bg-[#3231324D]",
                                t.status === "Completed"
                                  ? "bg-[#3231324D]"
                                  : "bg-white"
                              )}
                            />
                            <p className="text-xs text-theme-black">
                              Mark as completed
                            </p>
                          </>
                        ) : (
                          <p className="font-medium text-theme-green">
                            Task Completed
                          </p>
                        )}
                      </div>
                      <p>{t.description}</p>

                      {uploadedBy && (
                        <div className="grid grid-cols-2 pt-4">
                          <div>
                            <p className="font-semibold text-xs pb-2">
                              Uploaded By
                            </p>
                            <div className="flex  items-center text-xs gap-2">
                              <div className="h-full">
                                <img
                                  className="w-8 p-[1px] border border-theme-green rounded-full"
                                  src={`${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${uploadedBy?.profilePic}`}
                                  alt=""
                                />
                              </div>
                              <div className="">
                                <p className=" text-[12px]">
                                  {uploadedBy.firstName} {uploadedBy.lastName}
                                </p>
                                <p className="uppercase text-very-tiny">
                                  {uploadedBy.roleId.name}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div>
                            <p className="font-semibold text-xs pb-2">
                              Uploaded At
                            </p>
                            <div className="flex  items-center text-xs gap-2">
                              <div className="">
                                <p className=" text-[12px]">
                                  {uploadedBy.createdAt
                                    .substring(0, 10)
                                    .replaceAll("-", "/")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </MainDiv>
    </>
  );
};

export default ProjectCheckList;
