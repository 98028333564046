import React, { useEffect } from "react";
import Infos from "./Infos";
import AssetProjectAndDocuments from "./AssetProjectAndDocuments";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { openSingleEmployeeAdminAction } from "../../../../redux/actions/Admin/OpenSingleEmployeeAdmin";

const SingleEmployee = () => {
  const { open, employee } = useSelector((state) => state.singleEmployeeAdmin);

  const location = useLocation().pathname;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openSingleEmployeeAdminAction(location.split("/")[3], false));
  }, []);

  return (
    <>
      {employee?.data && (
        <div className="p-2 bg-white mt-8 rounded-lg grid gap-2">
          <Infos user={employee?.data} initialEdit={employee?.edit} />
          <AssetProjectAndDocuments />
        </div>
      )}
    </>
  );
};

export default SingleEmployee;
