import { GET_DOCUMENTS_OF_A_SUPPLIER_FAIL, GET_DOCUMENTS_OF_A_SUPPLIER_REQ, GET_DOCUMENTS_OF_A_SUPPLIER_SUCC } from "../../constants/suppliers/getDocumentOfASupplier";


export const getDocumentsOfASupplierReducer = (state={documents :[], loading:true, error:null}, action)=> {
        switch(action.type){
            case GET_DOCUMENTS_OF_A_SUPPLIER_REQ:
                return {loading :true, ...state}

            case GET_DOCUMENTS_OF_A_SUPPLIER_SUCC:
                return{
                    loading: false,
                    documents: action.payload,
                }
            
            case GET_DOCUMENTS_OF_A_SUPPLIER_FAIL:
                return{
                    loading:false,
                    error:action.payload
                }
            
            default:
                return state;
        }
}