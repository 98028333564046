import classNames from "classnames";
import { ArrowBendUpLeft, ArrowRight, Info } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSingleEmployeeAdminAction } from "../../../../redux/actions/Admin/OpenSingleEmployeeAdmin";
import ProjectsDropDown from "../../../components/Assets/comps/ProjectsDropDown";
import AssetsDropDown from "../../../components/Projects/comps/AssetsDropDown";
import { Assests } from "../../../fake/fakeAssets";
import { FakeProjects } from "../../../fake/fakeProject";

import { documents } from "../../../fake/faleDoc";
import DocumentsDropDown from "../../../components/Projects/comps/DocumentsDropDown";
import { FakeSuppliers } from "../../../fake/fakeSuppliers";
import ClientDropDown from "./ClientDropDown";
import { toast } from "react-toastify";
import { updateUser } from "../../../../api/users/inviteUsers";
import { getUsersApi } from "../../../../api/users/users";
import { getAllEmployeesAction } from "../../../../redux/actions/users/getAllEmployeesAction";
import { getProjectOfASupplierAction } from "../../../../redux/actions/suppliers/getProjectOfASupplier";
import Loading from "../../../components/loading/Loading";
import { useNavigate } from "react-router-dom";

const Table = ({ allUsers, limit, search, dropDown }) => {
  const [assetsDropDown, setassetsDropDown] = useState(false);
  const [documentsDropDown, setdocumentsDropDown] = useState(false);
  const [projectDropDown, setsprojectDropDown] = useState(false);
  const [clientDropDown, setClientDropDown] = useState(false);
  let [id, setID] = useState(null);
  const [clickedId, setClickedId] = useState(null);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.me);

  const { users } = useSelector((state) => state);
  const assetDropDonwOPener = (s) => {
    setClickedId(s._id);
    setassetsDropDown(s._id === clickedId ? !assetsDropDown : true);
    setdocumentsDropDown(false);
    setsprojectDropDown(false);
    setClientDropDown(false);
  };

  const documentsDropDownOpener = (s) => {
    setClickedId(s._id);
    setdocumentsDropDown(s._id === clickedId ? !documentsDropDown : true);
    setsprojectDropDown(false);
    setClientDropDown(false);
    setassetsDropDown(false);
  };

  const suppliersDropDownOpener = (p) => {
    setClickedId(p._id);
    setdocumentsDropDown(false);
    setClientDropDown(false);
    setassetsDropDown(false);
    setsprojectDropDown(p._id === clickedId ? !projectDropDown : true);
    dispatch(getProjectOfASupplierAction(p._id));
  };

  const { projects } = useSelector((state) => state.getProjectsOfASupplier);

  const clientDropDownOpener = (c) => {
    setClickedId(c._id);
    setdocumentsDropDown(false);
    setsprojectDropDown(false);
    setassetsDropDown(false);
    setClientDropDown(c._id === clickedId ? !clientDropDown : true);
  };

  // suspend modal
  const [openModal, setOpenModal] = useState(false);

  const suspendUser = async () => {
    toast.loading("suspending user");
    try {
      const res = await updateUser(id._id, { status: "suspended" });
      toast.dismiss();
      toast.success("user suspended successfully");
      setOpenModal(false);
      // to refresh
      dispatch(
        getAllEmployeesAction({
          companyId: user?.companyId._id,
          limit,
          q: search,
          status: dropDown,
          page: 1,
        })
      );
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
      setID(null);
    }
  };

  const revokeSuspension = async () => {
    toast.loading("Revoking the suspension");
    try {
      await updateUser(id._id, { status: "active" });
      toast.dismiss();
      toast.success("Suspension revoked successfully");
      setOpenModal(false);
      // to refresh
      dispatch(
        getAllEmployeesAction({
          companyId: user?.companyId._id,
          limit,
          q: search,
          status: dropDown,
          page: 1,
        })
      );
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
      setID(null);
    }
  };

  const { employees, loading } = useSelector((state) => state.getAllEmployees);

  const navigate = useNavigate();

  return (
    <>
      <div className="flex  text-xs font-semibold px-6 mt-6 border-bottom-border border-b">
        <div className="w-[12%] opacity-50 py-6">UID</div>
        <div className="w-[16%] opacity-50 py-6">Employee Details</div>
        <div className="w-[20.5%] opacity-50 py-6">Status</div>
        <div className="w-[20.5%] opacity-50 py-6">Clients</div>
        {/* <div className='w-[10.5%] opacity-50 py-6'>Assets</div>
                <div className='w-[10.5%] opacity-50 py-6'>Projects</div>
                <div className=' w-[10.5%] opacity-50 py-6'>Documents</div> */}
        <div className=" flex-1 flex justify-end gap-3 opacity-50 py-6">
          <p className="w-1/2">Suspend</p>
          <p className="w-2/5">Edit</p>
          <p className="w-[20%]">View</p>
        </div>
      </div>

      {loading ? (
        <Loading loading={loading} />
      ) : (
        employees?.map((u, index) => {
          return (
            <div key={index}>
              <div
                className={classNames(
                  "py-4 flex items-center px-6 border-b",
                  (assetsDropDown ||
                    documentsDropDown ||
                    projectDropDown ||
                    clientDropDown) &&
                    clickedId === u._id
                    ? "bg-theme-light-green border-b border-t"
                    : "border-b border-bottom-border"
                )}
              >
                <div className="w-[12%] text-xs font-medium">
                  <p className="uppercase">id</p>
                  <p className="opacity-50">{u.uid}</p>
                </div>

                <div className="flex w-[16%] items-center text-xs gap-2">
                  <div className="h-full">
                    {u.profilePic &&
                    u.profilePic !== "https://picsum.photos/200" ? (
                      <img
                        className="w-8 p-[1px] h-8 object-cover border border-theme-green rounded-full"
                        src={`${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${u.profilePic}`}
                        alt=""
                      />
                    ) : (
                      u.firstName &&
                      u.firstName.length > 0 &&
                      u.lastName &&
                      u.lastName.length > 0 && (
                        <p className="w-9 h-9 tracking-wide flex justify-center items-center text-white font-bold text-[16px] bg-theme-green p-0.5 border border-theme-green rounded-full">
                          {u.firstName[0]}
                          {u.lastName[0]}
                        </p>
                      )
                    )}
                  </div>
                  <div className="">
                    <p className="font-medium">
                      {u.firstName} {u.lastName}
                    </p>
                    <p className="capitalize opacity-50 text-very-tiny">
                      {u.roleId.name === "AdminUser"
                        ? "Apex Manager"
                        : u.roleId.name}
                    </p>
                  </div>
                </div>

                <div className="text-xs w-[20.5%] ">
                  <p className="font-medium">Status</p>
                  <p
                    className={classNames(
                      "capitalize cursor-pointer font-medium leading-3",
                      u.status === "active"
                        ? "text-theme-green"
                        : "text-rose-400"
                    )}
                  >
                    {u.status === "pending" ? "Invitation Pending" : u.status}
                  </p>
                </div>

                {/* <div className=' w-[10.5%] text-xs'>
                                <div className=''>
                                    <p className='leading-3'>Clients</p>
                                    <p onClick={() => clientDropDownOpener(u)} className='text-theme-black opacity-50 cursor-pointer leading-3'>{FakeSuppliers.length} <i className={classNames("fa-solid ml-1 ", (clientDropDown && clickedId === u._id) ? "fa-angle-up" : 'fa-angle-down')}></i></p>
                                </div>
                            </div> */}

                {/* <div className=' w-[10.5%] text-xs'>
                                <div className=''>
                                    <p className='leading-3'>Assets</p>
                                    <p onClick={() => assetDropDonwOPener(u)} className='text-theme-black opacity-50 cursor-pointer leading-3'>{Assests.length} <i className={classNames("fa-solid ml-1 ", (assetsDropDown && clickedId === u._id) ? "fa-angle-up" : 'fa-angle-down')}></i></p>
                                </div>
                            </div> */}

                <div className=" w-[20.5%] text-xs">
                  <div className="">
                    <p className="leading-3">Projects</p>
                    <p
                      onClick={() => suppliersDropDownOpener(u)}
                      className="text-theme-black opacity-50 cursor-pointer leading-3"
                    >
                      {u.stats.projects}{" "}
                      <i
                        className={classNames(
                          "fa-solid ml-1 ",
                          projectDropDown && clickedId === u._id
                            ? "fa-angle-up"
                            : "fa-angle-down"
                        )}
                      ></i>
                    </p>
                  </div>
                </div>
                {/* 
                            <div className=' w-[10.5%] text-xs'>
                                <div className=''>
                                    <p className='leading-3'>Documents</p>
                                    <p onClick={() => documentsDropDownOpener(u)} className='text-theme-black opacity-50 cursor-pointer leading-3'>{documents.length} <i className={classNames("fa-solid ml-1 ", (documentsDropDown && clickedId === u._id) ? "fa-angle-up" : 'fa-angle-down')}></i></p>
                                </div>
                            </div> */}

                <div className="flex-1 flex justify-end gap-3 ">
                  <button
                    onClick={() => {
                      setID(u);
                      setOpenModal(true);
                    }}
                    className="flex gap-2 items-center justify-center w-1/2 py-1 bg-[#F7F6F6] hover:bg-[#0000001a] hover:border-[#00000033] border border-black10 text-xs text-theme-black text-opacity-80"
                  >
                    {u.status !== "suspended" ? (
                      <img src="/images/notAllowed.svg" alt="" />
                    ) : (
                      <ArrowBendUpLeft size={14} />
                    )}{" "}
                    {u.status !== "suspended" ? "Suspend" : "Revoke"}
                  </button>
                  <button
                    onClick={() =>
                      dispatch(
                        openSingleEmployeeAdminAction(u._id, true, users.users)
                      )
                    }
                    className="flex w-2/5 gap-2 items-center  justify-center px-4 py-1 bg-[#F7F6F6] hover:bg-[#0000001a] hover:border-[#00000033] border border-black10 text-xs text-theme-black text-opacity-80"
                  >
                    <img src="/images/edit.svg" alt="" /> Edit
                  </button>
                  <ArrowRight
                    onClick={() => {
                      dispatch(
                        openSingleEmployeeAdminAction(u._id, false, users.users)
                      );
                      navigate(`${u._id}`);
                    }}
                    className="bg-#F7F6F6 p-2 w-[20%]  justify-center cursor-pointer border border-black-10 hover:bg-[#0000001a] hover:border-[#00000033]"
                    size={28}
                    weight="light"
                  />
                </div>
              </div>

              {assetsDropDown && clickedId === u._id && (
                <div className="h-60 overflow-y-auto">
                  {Assests.map((asset, index) => {
                    return <AssetsDropDown asset={asset} key={index} />;
                  })}
                </div>
              )}

              {clientDropDown && clickedId === u._id && (
                <div className="h-60 overflow-y-auto">
                  {FakeSuppliers.map((s, index) => {
                    return <ClientDropDown p={s} key={index} />;
                  })}
                </div>
              )}

              {projectDropDown && clickedId === u._id && (
                <div className="max-h-60 overflow-y-auto">
                  {projects?.map((project, index) => {
                    return <ProjectsDropDown project={project} key={index} />;
                  })}
                </div>
              )}

              {documentsDropDown && clickedId === u._id && (
                <div className="max-h-60 overflow-y-auto">
                  {documents.map((s, index) => {
                    return <DocumentsDropDown s={s} key={index} />;
                  })}
                </div>
              )}
            </div>
          );
        })
      )}

      {/* suspend modal */}
      {openModal && (
        <div
          onClick={() => setOpenModal(false)}
          className="fixed w-[100vw] h-[100vh] top-0 left-0 z-[9999] bg-black bg-opacity-20"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute w-[25rem] p-4 bg-white rounded-md border-[3px] border-theme-black border-opacity-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <Info size={28} className="" color="#323132CC" weight="bold" />
            <div className="mt-4  pb-4 border-b">
              <p className="font-bold text-xs">
                {id.status !== "suspended"
                  ? "Suspend user"
                  : "Revoke suspension"}
                ?
              </p>
              <p className="text-xs">
                Are you sure you want to{" "}
                {id.status !== "suspended"
                  ? "suspend this user"
                  : "revoke the suspension"}
                ?{" "}
              </p>
            </div>

            <div className="py-4 grid gap-4 grid-cols-2">
              <button
                onClick={() => setOpenModal(false)}
                className="py-2 bg-[#F7F6F6] rounded-md hover:bg-[#0000001a] hover:border-[#00000033] border border-black10 text-xs text-theme-black text-opacity-80"
              >
                {" "}
                No
              </button>
              <button
                onClick={() => {
                  if (id.status !== "suspended") {
                    suspendUser();
                  } else {
                    revokeSuspension();
                  }
                }}
                className="py-2 bg-theme-green text-xs rounded-md"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
