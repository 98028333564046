import classNames from "classnames";
import { Buildings, CaretDown, MagnifyingGlass, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddProjectDropDown from "./AddProjectDropDown";
import { getAllProjects } from "../../../../../redux/actions/project/getProjects";

const AddProjectToAsset = ({
  excludeIds,
  setShowDropDown,
  showDropDown,
  closeAddNewProjectTab,
  purpose,
  selectedProjectsArr,
  selectedProjects,
  addProjects,
  removeProject,
}) => {
  const { projects } = useSelector((state) => state.getAllProjects);
  const showDropDownFunc = () => {
    setShowDropDown(!showDropDown);
  };

  const { companyId } = useSelector((state) => state.selectCompany);

  const [searchInput, setSearchInput] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllProjects({ limit: 10, q: searchInput, companyId, excludeIds })
    );
  }, [dispatch, searchInput, companyId]);

  const changeDropDownHandler = (e) => {
    setShowDropDown(true);
    setSearchInput(e.target.value);
  };
  return (
    <>
      <div className="bg-[#EFEBE9] flex-1 relative rounded-md ">
        <div className="px-6 flex">
          <p className="bg-theme-black rounded-b-md text-white px-5 text-very-tiny font-medium py-1.5">
            Add {purpose} to a project{" "}
          </p>
        </div>

        <div className="mt-16 ">
          <p className="text-theme-black text-xs text-center font-medium">
            {" "}
            Add this {purpose} to a project
          </p>

          <div className="mx-6 relative my-2">
            <input
              value={searchInput}
              onClick={showDropDownFunc}
              onChange={changeDropDownHandler}
              placeholder={
                showDropDown ? "Select Existing Projects" : "Add Project"
              }
              type="text"
              className={classNames(
                "w-full border border-[#00000033] bg-white cursor-pointer rounded-t-md focus:outline-none py-3 text-very-tiny",
                showDropDown ? "px-4" : "px-[36px] rounded-b-md"
              )}
            />
            {!showDropDown && (
              <Buildings
                size={16}
                weight="bold"
                className="absolute text-theme-black opacity-80 left-3 top-[13px]"
              />
            )}
            {showDropDown ? (
              <MagnifyingGlass
                size={14}
                className="absolute text-theme-black opacity-80 right-3 top-[14px]"
              />
            ) : (
              <CaretDown
                size={14}
                className="absolute text-theme-black opacity-80 right-3 top-[14px]"
              />
            )}
            {showDropDown && (
              <div className="border-b absolute z-[99] w-full cursor-pointer h-64 overflow-y-auto border-[#00000033]">
                {projects?.map((p, index) => {
                  return (
                    <AddProjectDropDown
                      onClick={() => selectedProjects(p)}
                      key={index}
                      p={p}
                    />
                  );
                })}
              </div>
            )}
            <div className="grid grid-cols-2 gap-2 mt-6 rounded-lg">
              {selectedProjectsArr?.map((s, idx) => {
                return (
                  <div className="bg-white rounded-lg">
                    <p className="text-xs font-medium bg-[#DAD2D2] p-2 rounded-t-lg flex justify-between items-center">
                      {s?.name}
                      <X
                        onClick={() => removeProject(s)}
                        className="cursor-pointer"
                        size={12}
                      />
                    </p>
                    <div className="p-2">
                      <p className="text-xs py-1">
                        UID: <span className="opacity-50">ID {s?.uid}</span>
                      </p>
                      <p className="text-xs py-1">
                        Owner:{" "}
                        <span className="opacity-50">{s?.companyId?.name}</span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="absolute flex w-full px-3 gap-2 bottom-3">
          <button
            onClick={closeAddNewProjectTab}
            className="flex-1 w-1/2 py-2 bg-transparent border-2 border-bottom-border uppercase text-sm font-bold rounded-lg hover:bg-white hover:border-[#00000066]"
          >
            <span className="opacity-40">Cancel</span>
          </button>
          <button
            onClick={addProjects}
            className="flex-1 w-1/2 py-2 bg-theme-light-green text-theme-green border-2 border-theme-green uppercase text-sm font-bold rounded-lg hover:bg-theme-green hover:text-black"
          >
            <span className="">Save</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default AddProjectToAsset;
