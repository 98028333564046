import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { openSingleProjectAdminAction } from "../../../../redux/actions/Admin/OpenSingleProjectAdmin";
import AssetsDocAndSupplies from "./AssetDocSupplies";
import MapAndOwner from "./MapAndOwner";
import ProjectCheckList from "./ProjectCheckList";

const SingleProject = () => {
  const { open, project } = useSelector((state) => state.singleProjectAdmin);
  const location = useLocation().pathname;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(openSingleProjectAdminAction(location.split("/")[3]));
  }, [location]);

  return (
    <>
      {project && (
        <div className="grid grid-cols-3 gap-4 mt-4">
          <MapAndOwner />
          <ProjectCheckList />
          <AssetsDocAndSupplies />
        </div>
      )}
    </>
  );
};

export default SingleProject;
