import { GET_DOCTYPE_FAILED, GET_DOCTYPE_REQ, GET_DOCTYPE_SUCC } from "../../constants/documents/getDocumentTypes";

export const getDocTypeReducer = (state={docTypes:[], loading:false, error:null}, action) => {
   switch(action.type){
       case GET_DOCTYPE_REQ:
           return {
               loading:true
           }
        case GET_DOCTYPE_SUCC:
            return{
                loading:false,
                docTypes:action.payload,
            }

        case GET_DOCTYPE_FAILED:
            return{
                loading:false,
                error:action.payload
            }

        default:
            return state;
   }
}