import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSingleProjectAction } from "../../../../redux/actions/project/SingleProject";

const Attributes = () => {
  const { document } = useSelector((state) => state.adminSingleDocument);

  return (
    <>
      <div className="bg-white p-2 rounded-md">
        <div className="rounded-md p-3  bg-[#EFEBE9]">
          <div className="bg-[#F7F6F6] text-center py-4 flex flex-col gap-2  rounded-md items-center">
            <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
              Document Name
            </p>
            <p className="text-xs font-bold">{document.name}</p>
          </div>
          <div className="grid grid-cols-2 gap-3 mt-4">
            <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
              <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                ID
              </p>
              <p className="text-xs font-bold">{document.uid}</p>
            </div>
            <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
              <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                Document Type
              </p>
              <p className="text-xs font-bold capitalize">
                {document.type ? document.type.type : "Not Defined"}
              </p>
            </div>
            <div className="bg-[#F7F6F6] text-center py-[20px] px-4 flex flex-col gap-2  rounded-md items-center border">
              <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                ISO19650 Reference
              </p>
              <p className="text-xs font-bold">{document.documentCode}</p>
            </div>
            <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
              <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                Type Code
              </p>
              <p className="text-xs font-bold">
                {document.type ? document.type.code : "Not Defined"}
              </p>
            </div>
          </div>
        </div>

        {/* 2nd one */}
        <div className="rounded-md py-3  bg-white">
          {/* <div className='bg-[#F7F6F6] border py-4 flex justify-between px-4 gap-2  rounded-md items-center'>
                    <p className='font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full'>Originator</p>
                    <div>
                        <p className='font-semibold text-xs'>ID</p>
                        <p className='text-[#32313280] text-[10px]'>{user.uid}</p>
                    </div>

                    <div className='flex gap-2 items-center'>
                        <div>
                            <img src={user.profilePic} className='w-7 p-0.5 border border-theme-green rounded-full' alt="" />
                        </div>
                        <div>
                            <p className='font-medium  text-xs'>{user.companyId.name}</p>
                        </div>
                    </div>
                </div> */}
          <div className="grid grid-cols-2 gap-3 mt-4">
            <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
              <p className="font-medium text-very-tiny text-[#00000099] py-1.5 mx-2 px-4 bg-white rounded-full">
                Company Project Reference ID
              </p>
              <p className="text-xs font-bold">
                {document.projectId?.projectReferenceId}
              </p>
            </div>
            <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
              <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                Functional Breakdown
              </p>
              <p className="text-xs font-bold capitalize">
                {document.functionalBreakdown}
              </p>
            </div>
            <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
              <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                Spatial Breakdown
              </p>
              <p className="text-xs font-bold">{document.spatialBreakdown}</p>
            </div>
            <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
              <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                Form
              </p>
              <p className="text-xs font-bold">{document.form}</p>
            </div>
            <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
              <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                Discipline{" "}
              </p>
              <p className="text-xs font-bold">{document.discipline}</p>
            </div>
            <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
              <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                Number
              </p>
              <p className="text-xs font-bold">{2}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Attributes;
