import { GET_TASK_FAIL, GET_TASK_REQ, GET_TASK_SUCC } from "../../constants/projects/getTask";

export const getAllTasksReducer = (state={tasks: [], loading:true, error:null, allProjectDetails:{}}, action) => {
    switch(action.type){
        case GET_TASK_REQ:
            return{
                loading:true,
                ...state
            }

        case GET_TASK_SUCC:
            return{
                loading:false,
                tasks:action.payload,
            }


        case GET_TASK_FAIL:
        return{
            loading:false,
            error:action.payload
        }

        default:
            return state;
    }
}