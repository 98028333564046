
import { GET_SUPPLIERS_OF_A_PROJECT_FAIL, GET_SUPPLIERS_OF_A_PROJECT_REQ, GET_SUPPLIERS_OF_A_PROJECT_SUCC } from "../../constants/suppliers/getSuppliersOfAProject";

export const getSuppliersOFAProjectReducer = (state={suppliers :[], loading:true, error:null}, action)=> {
        switch(action.type){
            case GET_SUPPLIERS_OF_A_PROJECT_REQ:
                return {loading :true, ...state}

            case GET_SUPPLIERS_OF_A_PROJECT_SUCC:
                return{
                    loading: false,
                    suppliers: action.payload,
                }
            
            case GET_SUPPLIERS_OF_A_PROJECT_FAIL:
                return{
                    loading:false,
                    error:action.payload
                }
            
            default:
                return state;
        }
}