import classNames from "classnames";
import { File, X } from "phosphor-react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CLOSE_CREATE_NEW_SUPPORT_TICKET } from "../../../../redux/reducers/Admin/CreateNewHelpTicket";
import SelectComp from "../../../components/Workspace/AddNewUser/SlectCompUserScren";

const CreateNewTicket = () => {
  const options = [
    { value: "Technical Help", label: "Technical Help" },
    { value: "Billing", label: "Billing" },
  ];

  // uploadPdf
  const [selectedFile, setSelectedFile] = useState([]);

  const uploadPdf = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile([...selectedFile, e.target.files[0]]);
  };

  const removeDoc = (f) => {
    let newArr = [...selectedFile];
    let newArr2 = newArr.filter((p) => p.name !== f.name);
    setSelectedFile(newArr2);
  };

  const dispatch = useDispatch();
  const closeAddNewNotificationsTab = () => {
    dispatch({ type: CLOSE_CREATE_NEW_SUPPORT_TICKET });
  };

  const AddNewNotifications = () => {
    dispatch({ type: CLOSE_CREATE_NEW_SUPPORT_TICKET });
  };

  return (
    <>
      <div className="grid grid-cols-2 min-h-[80vh] mt-8 gap-6">
        {/* Left side */}
        <div className="bg-white p-6 rounded-md">
          <div className="grid gap-2">
            <p className="font-medium text-sm">Post Code</p>
            <SelectComp options={options} />
          </div>

          <div className="grid pt-10 gap-2">
            <p className="font-medium text-sm">Ticket Subject</p>
            <input
              type="text"
              placeholder="Write Subject here"
              className={classNames(
                "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                "bg-[#0000000D]"
              )}
            />
          </div>

          <div className="grid gap-2 mt-12">
            <input
              accept=".doc,.pdf,.docx,.pages"
              id="fileInput"
              onChange={uploadPdf}
              name="fileInput"
              type="file"
              style={{ display: "none" }}
            />

            <p className="font-medium text-sm">Attach Files (upto 5)</p>
            <label htmlFor="fileInput">
              <div
                className={classNames(
                  "focus:outline-none px-4 p-3 w-full text-center flex justify-center rounded-lg  border border-black-10 text-xs gap-2",
                  "bg-[#0000000D]"
                )}
              >
                <File size={16} /> Attach File(max 5mb)
              </div>
            </label>
          </div>

          <div className="grid grid-cols-5 mt-6 gap-3">
            {selectedFile.map((f, index) => {
              return (
                <p
                  key={index}
                  className="gap-3 text-sm px-3 py-1 flex items-center border border-border-input justify-between rounded-md bg-very-light-gray"
                >
                  {f.name.length > 10 ? f.name.substr(0, 10) + "..." : f.name}{" "}
                  <X onClick={() => removeDoc(f)} size={14} />
                </p>
              );
            })}
          </div>
        </div>
        {/* Left side end*/}

        {/* right side */}
        <div className="bg-white p-6 flex justify-between flex-col rounded-md">
          <textarea
            type="text"
            rows={17}
            placeholder="Describe issue here"
            className={classNames(
              "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
              "bg-[#0000000D]"
            )}
          ></textarea>

          <div className="grid gap-3 mt-12">
            <button
              onClick={closeAddNewNotificationsTab}
              className="flex-1 w-full py-2 bg-transparent border-2 border-bottom-border uppercase text-sm font-bold rounded-lg hover:bg-white hover:border-[#00000066]"
            >
              <span className="opacity-40">CANCEL</span>
            </button>
            <button
              onClick={AddNewNotifications}
              className="flex-1 w-full py-2 bg-theme-light-green text-theme-green border-2 border-theme-green uppercase text-sm font-bold rounded-lg hover:bg-theme-green hover:text-black"
            >
              <span className="">SUBMIT</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewTicket;
