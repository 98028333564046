import { assetsListOfAProject } from "../../../api/users/projects";
import {
  GET_ASSETS_OF_A_PROJECT_FAIL,
  GET_ASSETS_OF_A_PROJECT_REQ,
  GET_ASSETS_OF_A_PROJECT_SUCC,
} from "../../constants/assets/getAssetsOfaProject";

export const getAssetsOfAProjectAction = (projectId, limit = 100) => async (dispatch) => {
  try {
    dispatch({ type: GET_ASSETS_OF_A_PROJECT_REQ });
    const res = await assetsListOfAProject(projectId, limit);

    dispatch({
      type: GET_ASSETS_OF_A_PROJECT_SUCC,
      payload: res.data.docs,
    });
  } catch (error) {
    dispatch({
      type: GET_ASSETS_OF_A_PROJECT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
