import { NotePencil, Plus } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { toast } from "react-toastify";
import { updateUser } from "../../../../api/users/inviteUsers";
import { getLoggedInUser } from "../../../../redux/actions/me/user";
import { OPEN_ADD_NEW_SUBSCRIPTION } from "../../../../redux/reducers/Admin/AddNewSubscriptionReducer";
import { Filter } from "../../../../styles/FilterButton";
import AllTransactions from "./AllTransactions";
import Profile from "./Profile";
import Settings from "./Settings";
import Subscriptions from "./Subscriptions";

const ProfileSettingsTransaction = () => {
  const [active, setActive] = useState({
    myProfile: true,
    settings: false,
    subscriptions: false,
    transactions: false,
  });
  const dispatch = useDispatch();

  const [editProfile, setEditProfile] = useState(false);
  const [editSubsctiptions, setEditSubsctiptions] = useState(false);

  const { user } = useSelector((state) => state.me);

  const [email, setEmail] = useState(user.email);
  const [fName, setFName] = useState(user.firstName);
  const [lName, setLName] = useState(user.lastName);
  const [Account, setAccount] = useState(user.roleId.name);

  const editCLickHandler = () => {
    setEditSubsctiptions(!editSubsctiptions);
  };

  const saveSubscriptionHandler = () => {
    setEditSubsctiptions(false);
  };

  const saveProfileHandler = async () => {
    try {
      toast.loading("Updating User details");
      await updateUser(user._id, { firstName: fName, lastName: lName });
      toast.dismiss();
      toast.success("Updated User successfully");
      setEditProfile(false);

      dispatch(getLoggedInUser());
    } catch (error) {
      toast.error(error);
    }

    // dispatch(getLoggedInUser())
  };

  const navigate = useNavigate();

  const location = useLocation();

  let settingsActive = location.pathname.split("/")[3] === "settings";
  let profileActive = location.pathname.split("/")[3] === undefined;

  return (
    <>
      <div className="h-full">
        <div className="border-b border-black-10">
          <div className="flex items-center p-6  justify-between gap-4">
            {editSubsctiptions ? (
              <>
                <button
                  onClick={() => dispatch({ type: OPEN_ADD_NEW_SUBSCRIPTION })}
                  className="text-opacity-70 text-theme-black gap-3 bg-[#0000001A] rounded-md flex items-center gap-3 px-4 py-2 text-xs border border-black-10"
                >
                  Add New Subscriptions <Plus size={14} />{" "}
                </button>
                <div className="flex gap-2 text-xs">
                  <button
                    onClick={saveSubscriptionHandler}
                    className="text-opacity-70 text-theme-black gap-3 bg-[#0000001A] rounded-md flex items-center gap-3 px-4 py-2 text-xs border border-black-10"
                  >
                    SAVE
                  </button>
                  <button
                    onClick={() => setEditSubsctiptions(false)}
                    className="text-opacity-70 text-theme-black gap-3 bg-[#0000001A] rounded-md flex items-center gap-3 px-4 py-2 text-xs border border-black-10"
                  >
                    CANCEL
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center gap-4">
                  <Filter
                    onClick={() => {
                      setActive({
                        myProfile: true,
                        settings: false,
                        subscriptions: false,
                        transactions: false,
                      });
                      navigate("/admin/profile");
                    }}
                    active={profileActive}
                  >
                    My Profile
                  </Filter>
                  <Filter
                    onClick={() => {
                      setActive({
                        myProfile: false,
                        settings: true,
                        subscriptions: false,
                        transactions: false,
                      });
                      navigate("/admin/profile/settings");
                    }}
                    active={settingsActive}
                  >
                    Settings
                  </Filter>
                  {/* <Filter
                    onClick={() =>
                      setActive({
                        myProfile: false,
                        settings: false,
                        subscriptions: true,
                        transactions: false,
                      })
                    }
                    active={active.subscriptions}
                  >
                    Subscriptions
                  </Filter>
                  <Filter
                    onClick={() =>
                      setActive({
                        myProfile: false,
                        settings: false,
                        subscriptions: false,
                        transactions: true,
                      })
                    }
                    active={active.transactions}
                  >
                    All Transactions
                  </Filter> */}
                </div>
                {profileActive && (
                  <div>
                    {editProfile ? (
                      <div className="flex text-xs gap-2">
                        <button
                          onClick={saveProfileHandler}
                          className="text-opacity-70 text-theme-black gap-3 bg-[#0000001A] rounded-md flex items-center gap-3 px-4 py-2 text-xs border border-black-10"
                        >
                          SAVE
                        </button>
                        <button
                          onClick={() => {
                            setEditProfile(false);
                          }}
                          className="text-opacity-70 text-theme-black gap-3 bg-[#0000001A] rounded-md flex items-center gap-3 px-4 py-2 text-xs border border-black-10"
                        >
                          CANCEL
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() => setEditProfile(!editProfile)}
                        className="text-opacity-70 text-theme-black gap-3 bg-[#0000001A] rounded-md flex items-center gap-3 px-4 py-2 text-xs border border-black-10"
                      >
                        Edit Profile <NotePencil size={14} />
                      </button>
                    )}
                  </div>
                )}
                {active.subscriptions && (
                  <button
                    onClick={editCLickHandler}
                    className="text-opacity-70 text-theme-black gap-3 bg-[#0000001A] rounded-md flex items-center gap-3 px-4 py-2 text-xs border border-black-10"
                  >
                    Edit Subscriptions <NotePencil size={14} />
                  </button>
                )}
              </>
            )}
          </div>
        </div>

        <div className="h-[calc(100%-80px)]">
          <Routes>
            <Route
              path="/"
              element={
                <Profile
                  email={email}
                  fName={fName}
                  setFName={setFName}
                  lName={lName}
                  setLName={setLName}
                  Account={Account}
                  edit={editProfile}
                />
              }
            />
            <Route path="/settings" element={<Settings />} />
          </Routes>
          {/* {active.myProfile && (
            <Profile
              email={email}
              fName={fName}
              setFName={setFName}
              lName={lName}
              setLName={setLName}
              Account={Account}
              edit={editProfile}
            />
          )} */}
          {/* {active.settings && <Settings />} */}
          {/* {active.subscriptions && <Subscriptions edit={editSubsctiptions}/>}
                {active.transactions && <AllTransactions/>} */}
        </div>
      </div>
    </>
  );
};

export default ProfileSettingsTransaction;
