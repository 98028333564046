export const OPEN_CREATE_NEW_SUPPORT_TICKET_USER = 'CREATE_NEW_SUPPORT_TICKET_USER'
export const CLOSE_CREATE_NEW_SUPPORT_TICKET_USER ='CLOSE_CREATE_NEW_SUPPORT_TICKET_USER'

export const createNewSupportTicketUserReducer = (state={opencreateNewSupportTicket:false}, action) => {
    switch(action.type){
        case OPEN_CREATE_NEW_SUPPORT_TICKET_USER:
            return{
                opencreateNewSupportTicket:true
            }
        case CLOSE_CREATE_NEW_SUPPORT_TICKET_USER:
            return{
                opencreateNewSupportTicket:false
            }
        default:
            return state;
        
    }
}


