import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  forgotPassword,
  getCurrentUser,
  sendCode,
  signInWithEmail,
} from "../../../libs/cognito";
import { toast } from "react-toastify";

import { Map, Marker, NavigationControl, ScaleControl } from "react-map-gl";
import { AmbientLight, PointLight, LightingEffect } from "@deck.gl/core";
import DeckGL from "@deck.gl/react";
import { PolygonLayer } from "@deck.gl/layers";
import StaticMapForLogin from "./StaticMapForLogin";
import VideoBG from "../UserLogin/VideoBG";

const ResetPassword = () => {
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [sent, setSent] = useState(false);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const ResetPassword = async () => {
    try {
      if (code && password) {
        const res = await forgotPassword(username, code, password);
        navigate("/login");
      } else {
        toast.error("enter code and password");
      }
    } catch (error) {
      toast.error(error.message);
      setError(error.message);
    }
  };

  const SendCode = async () => {
    try {
      if (
        username &&
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(username)
      ) {
        const code = await sendCode(username);

        setSent(true);
      } else {
        toast.error("enter valid email");
      }
    } catch (error) {
      toast.error(error.message);
      setError(error.message);
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      {/* <StaticMapForLogin /> */}
      <VideoBG />

      <div className="flex absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 justify-center z-[9999] flex-col items-center w-[50vh] h-[50vh]">
        <div>
          <img className="w-[14rem]" src="/images/ApexLogo_PNG.png" alt="" />
        </div>
        <div className="bg-white mt-3 w-full rounded shadow-md p-4">
          <p className="font-semibold mt-3 text-[#000000CC]">
            Reset Your Password {sent && `(${username})`}
          </p>

          <div className="grid text-xs pt-6 gap-2">
            {!sent ? (
              <input
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Email"
                type="email"
                className="focus:outline-none p-3 bg-[#FAFBFC] border rounded"
              />
            ) : (
              <>
                <input
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Code"
                  type="text"
                  className="focus:outline-none p-3 bg-[#FAFBFC] border rounded"
                />
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="new Password"
                  autoComplete={false}
                  type="password"
                  className="focus:outline-none p-3 bg-[#FAFBFC] border rounded"
                />
              </>
            )}
          </div>

          <button
            onClick={() => {
              if (sent) {
                ResetPassword();
              } else {
                SendCode();
              }
            }}
            className="w-full mt-5 font-medium text-xs py-2 bg-[#2ED9C3]"
          >
            {sent ? "Reset Password" : "Send Code"}
          </button>
        </div>

        <div className="mt-4 items-center text-xs text-[#00000099] flex gap-2 justify-center">
          <p className="cursor-pointer">Privacy Policy</p>
          <div className="w-[2px] h-[2px] rounded-full bg-black"></div>
          <p className="cursor-pointer">Terms of service</p>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
