import { GET_PROJECT_ADMIN_FAILED, GET_PROJECT_ADMIN_REQ, GET_PROJECT_ADMIN_SUCC } from "../../constants/projects/getProjectAdmins";

export const getProjectAdminReducer = (state={projectAdmin: [], loading:true, error:null, allProjectDetails:{}}, action) => {
    switch(action.type){
        case GET_PROJECT_ADMIN_REQ:
            return{
                loading:true,
                ...state
            }

        case GET_PROJECT_ADMIN_SUCC:
            return{
                loading:false,
                projectAdmin:action.payload,
            }


        case GET_PROJECT_ADMIN_FAILED:
        return{
            loading:false,
            error:action.payload
        }

        default:
            return state;
    }
}