import { getSingleProjectData } from "../../../api/users/projects";
import { FakeProjects } from "../../../app/fake/fakeProject";
import {
  GET_SINGLE_PROJECT_FAIL_ADMIN,
  GET_SINGLE_PROJECT_REQ_ADMIN,
  GET_SINGLE_PROJECT_SUCC_ADMIN,
} from "../../constants/AdminConsts/ProjectsAdminConst";

export const openSingleProjectAdminAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_PROJECT_REQ_ADMIN });

    // const data =  FakeProjects.filter(a => a.id === id)[0]
    const res = await getSingleProjectData(id);

    dispatch({
      type: GET_SINGLE_PROJECT_SUCC_ADMIN,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_PROJECT_FAIL_ADMIN,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
