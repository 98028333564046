import { GET_SINGLE_DOCUMENT_FAI_ADMINL, GET_SINGLE_DOCUMENT_REQ_ADMIN, GET_SINGLE_DOCUMENT_SUCC_ADMIN, HIDE_SINGLE_DOCUMENT_ADMIN } from "../../constants/AdminConsts/SingleDocumentOpenerConst"

export const adminSingleDocumentReducer = (state={document : {}, loading:true, errro:null, open:false}, action)=> {
    switch(action.type){
        case GET_SINGLE_DOCUMENT_REQ_ADMIN:
            return {loading :true, ...state}

        case GET_SINGLE_DOCUMENT_SUCC_ADMIN:
            return{
                loading: false,
                document: action.payload,
                open:true
            }
        
        case GET_SINGLE_DOCUMENT_FAI_ADMINL:
            return{
                loading:false,
                error:action.payload
            }

        case HIDE_SINGLE_DOCUMENT_ADMIN:
            return{
                open:false,
                document:undefined
            }
        
        default:
            return state;
    }
}
