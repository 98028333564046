import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllProjects } from "../../../../redux/actions/project/getProjects";
import { Filter } from "../../../../styles/FilterButton";
import Loading from "../../../components/loading/Loading";
import { FakeProjects } from "../../../fake/fakeProject";

const ProjectProgress = () => {
  const [active, setActive] = useState({ onGoing: true, completed: false });

  const { projects, loading, allProjectDetails } = useSelector(
    (state) => state.getAllProjects
  );

  const [searchInput, setSearchInput] = useState("");
  const [limit, setLimit] = useState(5);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllProjects({
        companyId: "",
        limit: limit,
        status: active.onGoing ? "Ongoing" : "Completed",
        q: searchInput,
      })
    );
  }, [limit, searchInput, dispatch, active]);

  const navigate = useNavigate();
  return (
    <>
      <div className="py-6 bg-white rounded-md  suppplier">
        <div className=" px-6 border-b pb-4 ">
          <p className="text-sm font-bold pb-2">Project Progress</p>
          <div className="flex gap-2">
            <Filter
              onClick={() => setActive({ onGoing: true, completed: false })}
              active={active.onGoing}
            >
              Ongoing
            </Filter>
            <Filter
              onClick={() => setActive({ onGoing: false, completed: true })}
              active={active.completed}
            >
              Completed
            </Filter>
          </div>
        </div>

        <div className="h-[300px] overflow-y-auto">
          {loading ? (
            <Loading loading={loading} />
          ) : projects?.length === 0 || projects === undefined ? (
            <div className="text-center text-sm pt-4">No Projects found</div>
          ) : (
            projects?.map((s, index) => {
              return (
                <div
                  onClick={() => navigate(`/admin/projects/${s._id}`)}
                  key={index}
                  className=""
                >
                  <div
                    className={classNames(
                      "flex py-2.5 items-center px-6",
                      "hover:bg-[#E5E5E5] hover:bg-opacity-40",
                      "border-b "
                    )}
                  >
                    <div className="w-[50%] pr-2 text-xs font-medium">
                      <div>
                        <p className="font-medium underline text-xs ">
                          {s.name}
                        </p>
                        <p className="text-[11px] text-opacity-50 text-theme-black">
                          {s.address.city}, {s.address.state}
                        </p>
                      </div>
                    </div>

                    <div className="flex-1 pr-2 text-xs font-medium">
                      <div>
                        <p className="font-medium leading-3">Status</p>
                        <p className="leading-3 text-[11px] text-opacity-50 text-theme-black">
                          {s.status}
                        </p>
                      </div>
                    </div>

                    <div className="w-[26%] text-xs font-medium pr-6">
                      <div className="flex flex-col">
                        <p className="leading-3 mb-1">
                          Progress{" "}
                          <span className="text-theme-black opacity-50">
                            ({s.progress.completed + "/" + s.progress.total})
                          </span>
                        </p>
                        <div className="relative w-full h-2 rounded bg-theme-light-green ">
                          <div
                            style={{
                              width: `${
                                (s?.progress.completed / s?.progress.total) *
                                100
                              }%`,
                            }}
                            className={classNames(
                              "absolute h-2 bg-theme-green rounded"
                            )}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default ProjectProgress;
