import React from "react";
import { useSelector } from "react-redux";

const AssetDesc = () => {
  const { asset: selectedAsset } = useSelector(
    (state) => state.singleAssetAdmin
  );

  return (
    <>
      <div className=" bg-white p-2 rounded-md">
        <div className=" bg-white p-2 rounded-md">
          <div className="rounded-md p-3 h-full bg-[#EFEBE9]">
            <div className="bg-[#F7F6F6] text-center py-8 flex flex-col gap-2  rounded-md items-center">
              <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                Asset Type Description
              </p>
              <p className="text-xs font-bold">
                {selectedAsset?.assetType?.name}
              </p>
            </div>
            <div className="grid grid-cols-2 gap-3 mt-4">
              <div className="bg-[#F7F6F6] text-center py-[40px] flex flex-col gap-2  rounded-md items-center">
                <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                  Asset Type Code
                </p>
                <p className="text-xs font-bold">
                  {selectedAsset?.assetType?.id}
                </p>
              </div>

              {selectedAsset?.details?.map((item) => (
                <div className="bg-[#F7F6F6] text-center py-[40px] flex flex-col gap-2  rounded-md items-center">
                  <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                    {item?.displayName}
                  </p>
                  <p className="text-xs font-bold">{item?.value}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetDesc;
