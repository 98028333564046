import axios from "axios";

import { serialize } from "../../libs/serialize";
import { accessToken } from "../../libs/token";

export const getDashBoardData = async (arr, fromDate, toDate, companyId) => {
    let uriString= ""
     arr?.map(a => {
         uriString = uriString+`entities=${a}&`
    })

    const uri = serialize({fromDate, toDate, companyId})

    return axios.get(`${process.env.REACT_APP_API_URL}/dashboard/stats?entities=projects&entities=documents&entities=assets&entities=suppliers&${uri}`, await accessToken());
};


export const getDashBoardDataAdmin = async (param, fromDate, toDate) => {

    const uri = serialize({fromDate, toDate, entities:param})

    return axios.get(`${process.env.REACT_APP_API_URL}/dashboard/stats?${uri}`, await accessToken());
};