import classNames from "classnames";
import { XCircle } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OPEN_ADD_NEW_NOTIFICATIONS } from "../../../redux/reducers/Admin/CreateNewNotificatioReducer";
import { FakeNotifications } from "../../fake/FakeNotifications";
import {
  getAllNotifications,
  updateReadStatus,
} from "../../../api/users/notifications";
import { formatAMPM } from "../../../libs/formatAMPM";

const ShowCase = () => {
  const dipstach = useDispatch();

  const [allNot, setAllNot] = useState();

  const { user } = useSelector((state) => state.me);

  useEffect(() => {
    const func = async () => {
      try {
        const res = await getAllNotifications(1, 25, user._id);
        setAllNot(res.data.docs);
      } catch (err) {}
    };

    func();
  }, []);

  const [allId, setAllId] = useState([]);

  useEffect(() => {
    const func = async () => {
      if (allId.length === allNot.length) {
        await updateReadStatus({ userNotificationIds: allId, read: true });
      }
    };

    func();
  }, [allId, allNot]);

  return (
    <>
      <div className="bg-white mt-8 min-h-[80vh] rounded-lg pb-16">
        <div className="flex justify-end p-6 border-b">
          <button
            onClick={() => dipstach({ type: OPEN_ADD_NEW_NOTIFICATIONS })}
            className="px-5 uppercase border font-semibold border-theme-green hover:border-black text-xs py-2 rounded-md font-medium cursor-pointer select-none hover:bg-theme-green bg-theme-light-green text-theme-green hover:text-black"
          >
            create new Notification
          </button>
        </div>
        {allNot?.map((n, index) => {
          const dateObj = new Date(n.createdAt);
          if (!allId.includes(n._id)) {
            let arr = [...allId];
            setAllId([...arr, n._id]);
          }

          const time = formatAMPM(dateObj);
          return (
            <div
              key={index}
              className={classNames(
                "flex text-xs px-6 py-4 border-b items-center",
                n.read ? "text-opacity-50 teaxt-theme-black" : "font-semibold"
              )}
            >
              <div className="w-1/6">
                <p>
                  {dateObj.getDate() +
                    "/" +
                    (dateObj.getMonth() + 1) +
                    "/" +
                    dateObj.getFullYear()}
                </p>
                <p>{time}</p>
              </div>

              <div className="w-1/4 flex items-center gap-3">
                <img
                  src={
                    !user.profilePic
                      ? "/images/user1.jpeg"
                      : `${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${n.createdBy.profilePic}`
                  }
                  className="w-10 h-10 object-cover rounded-full p-[1px] border border-theme-green"
                  alt=""
                />
                <p>
                  {n.createdBy.firstName} {n.createdBy.lastName}
                </p>
              </div>

              <div className="flex-1">{n.notificationId.content}</div>

              <div className="w-[40px] p-1 h-[40px] flex justify-center items-center  bg-[#F7F6F6] rounded-full">
                <XCircle className="text-[#32313280]" size={28} />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ShowCase;
