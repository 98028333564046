import { GET_SINGLE_USER_FAIL, GET_SINGLE_USER_REQ, GET_SINGLE_USER_SUCC, GET_SINGLE_USER_SUCC_WITH_EDIT, HIDE_SINGLE_USER } from "../../constants/users/Users"

export const singleUserReducer = (state={user : {}, loading:true, edit:false, errro:null, open:false}, action)=> {
    switch(action.type){
        case GET_SINGLE_USER_REQ:
            return {loading :true, ...state}

        case GET_SINGLE_USER_SUCC:
            return{
                loading: false,
                user: action.payload,
                open:true,
                edit:false,
            }
        
        case GET_SINGLE_USER_FAIL:
            return{
                loading:false,
                error:action.payload,
                edit:false,
            }

        case HIDE_SINGLE_USER:
            return{
                open:false,
                user:undefined,
                edit:false,
            }
        
        
        
        default:
            return state;
    }
}