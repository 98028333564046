import { getConversations } from "../../api/users/support"
import { GET_ALL_CONVERSATIONS_FAIL, GET_ALL_CONVERSATIONS_REQ, GET_ALL_CONVERSATIONS_SUCC } from "../constants/singleConversation"


export const getConversationsAction = ({supportId="", limit = 50, page = 1, subject=""}) => async(dispatch) => {
    try{
        dispatch({type: GET_ALL_CONVERSATIONS_REQ})
        const res = await getConversations(supportId, page , limit )

        dispatch({
            type:GET_ALL_CONVERSATIONS_SUCC,
            payload:res.data.docs,
            subject:subject
        })
    } catch(error){
        dispatch({
            type: GET_ALL_CONVERSATIONS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}