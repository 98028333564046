import {
  GET_SINGLE_DOCUMENT_API_FAILED,
  GET_SINGLE_DOCUMENT_API_REQ,
  GET_SINGLE_DOCUMENT_API_SUCC,
} from "../../constants/documents/getSingleDocument";

export const getSingleDocumentReducer = (
  state = { document: {}, loading: true, errro: null },
  action
) => {
  switch (action.type) {
    case GET_SINGLE_DOCUMENT_API_REQ:
      return { loading: true, ...state };

    case GET_SINGLE_DOCUMENT_API_SUCC:
      return {
        loading: false,
        document: action.payload,
      };

    case GET_SINGLE_DOCUMENT_API_FAILED:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
