import { getSupplierListOfAProject } from "../../../api/users/projects";
import {
  GET_SUPPLIERS_OF_A_PROJECT_FAIL,
  GET_SUPPLIERS_OF_A_PROJECT_REQ,
  GET_SUPPLIERS_OF_A_PROJECT_SUCC,
} from "../../constants/suppliers/getSuppliersOfAProject";

export const getSuppliersOfAProjectAction = (projectId) => async (dispatch) => {
  try {
    dispatch({ type: GET_SUPPLIERS_OF_A_PROJECT_REQ });
    const res = await getSupplierListOfAProject(projectId);
    dispatch({
      type: GET_SUPPLIERS_OF_A_PROJECT_SUCC,
      payload: res.data.docs,
    });
  } catch (error) {
    dispatch({
      type: GET_SUPPLIERS_OF_A_PROJECT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
