/* eslint-disable no-unused-vars */
import classNames from "classnames";
import { CaretRight, NotePencil } from "phosphor-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";
import {
  COMPANIES_LIST_VIEW_OPEN,
  DASHBOARD_OPEN,
} from "../../../../redux/constants/AdminConsts/LeftNavAdminConst";
import { CLOSE_SINGLE_COMPANY_ADMIN } from "../../../../redux/constants/AdminConsts/SingleComapnyAdminConst";
const TopLinks = ({ showEditScreen, backToSingleAsset, editScreenHandler }) => {
  const dispatch = useDispatch();
  const { open, company } = useSelector((state) => state.singleCompanyAdmin);
  const { companies } = useSelector((state) => state.AdminLeftNav);

  const navigate = useNavigate();

  const location = useLocation().pathname;

  const addNew = location.split("/")[3] === "addNew";
  const invite = location.split("/")[3] === "invite";

  return (
    <>
      <div className="flex items-center">
        <p
          onClick={() => {
            navigate("/");
          }}
          className="text-link-green text-very-tiny font-medium cursor-pointer"
        >
          Dashboard
        </p>
        <p className="text-very-tiny font-medium flex items-center">
          <CaretRight className="mx-1.5" size={10} />{" "}
          <span
            onClick={() => {
              dispatch({ type: CLOSE_SINGLE_COMPANY_ADMIN });
              dispatch({ type: COMPANIES_LIST_VIEW_OPEN });
              navigate("/admin/companies");
            }}
            className={classNames(
              "cursor-pointer",
              (open || addNew || invite) && "text-link-green"
            )}
          >
            Companies
          </span>{" "}
        </p>

        {!addNew && !invite && open && (
          <div className={classNames("flex", !showEditScreen && "gap-3")}>
            <p className="text-very-tiny font-medium flex items-center">
              <CaretRight className="mx-1.5" size={10} />
              <span
                onClick={backToSingleAsset}
                className={classNames(
                  "cursor-pointer",
                  showEditScreen && "text-link-green"
                )}
              >
                {company?.name}
              </span>
            </p>
            {/* edit screen  */}
            {/* { (showEditScreen? 
                          <p className='text-very-tiny font-medium flex items-center'><CaretRight className='mx-1.5' size={10}  /> <span className={classNames('cursor-pointer',)}>Edit</span> </p>
                        :
                        <div className='bg-white p-[2px] rounded-full'>
                          <div onClick={()=>editScreenHandler(true)} active className='text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] py-1.5 px-3 rounded-full items-center gap-2'>
                              <NotePencil size={12} className='text-[#323132b3]' weight="bold" />
                              <p className=' text-[#323132b3]'>Edit</p>
                          </div>
                        </div>)
                        } */}
          </div>
        )}
        {addNew && (
          <p className="text-very-tiny font-medium flex items-center">
            <CaretRight className="mx-1.5" size={10} />{" "}
            <span className={classNames("cursor-pointer")}>
              Create new company
            </span>{" "}
          </p>
        )}
        {invite && (
          <p className="text-very-tiny font-medium flex items-center">
            <CaretRight className="mx-1.5" size={10} />{" "}
            <span className={classNames("cursor-pointer")}>
              Invite a company
            </span>{" "}
          </p>
        )}
      </div>
    </>
  );
};

export default TopLinks;
