 import { getSingleSupplierApi } from "../../../api/users/company";
import { GET_SINGLE_SUPPLIERS_FAIL, GET_SINGLE_SUPPLIERS_REQ, GET_SINGLE_SUPPLIERS_SUCC } from "../../constants/suppliers/Suppliers";

export const openSingleSupplierAction = (id,suppliers) => async(dispatch) => {
    try{
        dispatch({type: GET_SINGLE_SUPPLIERS_REQ})


        // const res =  await getSingleSupplierApi(id)

        const data = suppliers.filter(s => s._id ===id)[0]

        dispatch({
                type: GET_SINGLE_SUPPLIERS_SUCC,
                payload:data
            })
    } catch(error){
        dispatch({
            type: GET_SINGLE_SUPPLIERS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message 
        })
    }
}
