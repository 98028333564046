/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import MainMapAssets from "../../../components/Assets/comps/Map";
import MiniMap from "../../../components/Assets/comps/singleAssetComps/MiniMap";

const MainDiv = styled.div`
  height: 45.5rem;
`;
const MapAndOwner = () => {
  const [activeFilter, setActiveFilter] = useState({ map1: true, map2: false });
  const [vertical, setVertial] = useState(true);

  const { asset: selectedAsset } = useSelector(
    (state) => state.singleAssetAdmin
  );

  return (
    <>
      <MainDiv className=" bg-white rounded-md relative">
        <div className="flex pt-6 px-6 pb-40 bg-theme-black items-center rounded-t-md justify-between">
          <div className="flex items-center gap-3">
            <div className="bg-white p-4 rounded-full">
              <img src="images/charging-station.png" className="" alt="" />
            </div>
            <div className="text-sm">
              <p className="text-white leading-[17px] font-semibold">
                {selectedAsset?.assetType?.name}
              </p>
              <p className="text-white opacity-40 leading-[17px]">
                {selectedAsset?.name}
              </p>
            </div>
          </div>

          <p className="font-semibold text-sm text-white">
            ID - {selectedAsset?.uid}
          </p>
        </div>
        <div className="absolute z-[999]  top-36 rounded-md w-full">
          {selectedAsset?.location && (
            <div className=" h-72 mx-6 rounded-md relative">
              {/* <MainMapAssets map={activeFilter} threeD={vertical}/> */}
              <MiniMap
                marker={{
                  longitude: selectedAsset.location.coordinates[0],
                  latitude: selectedAsset.location.coordinates[1],
                }}
              />
            </div>
          )}
          <div className="mx-6 my-4 bg-bottom-border rounded-md">
            <div className="px-4 flex">
              <p className="bg-theme-black rounded-b-md text-white px-5 text-very-tiny font-medium py-1.5">
                Asset Owner
              </p>
            </div>
            <div className="flex justify-between px-4  py-8 items-center">
              <div className="flex gap-2 items-center">
                {selectedAsset?.companyId?.profilePic ? (
                  <img
                    src={`${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${selectedAsset?.companyId?.profilePic}`}
                    className="w-10 rounded-full p-[2px] border border-theme-green"
                    alt=""
                  />
                ) : (
                  <p className="w-9 h-9 tracking-wide flex justify-center items-center text-white font-bold text-[18px] bg-theme-green p-0.5 border border-theme-green rounded-full">
                    {selectedAsset?.companyId?.name.split(" ")[0][0]}
                    {selectedAsset?.companyId?.name?.split(" ")[1]
                      ? selectedAsset?.companyId?.name?.split(" ")[1][0]
                      : ""}
                  </p>
                )}
                <p className="font-medium text-sm">
                  {selectedAsset?.companyId?.name}
                </p>
              </div>
              <p className="text-sm uppercase">
                ID - <span className="">{selectedAsset?.companyId?.uid}</span>
              </p>
            </div>
          </div>

          <div className="mx-6 my-4 bg-bottom-border rounded-md">
            <div className="px-4 flex">
              <p className="bg-theme-black rounded-b-md text-white px-5 text-very-tiny font-medium py-1.5">
                Asset Status
              </p>
            </div>
            <div className="flex justify-between px-4  py-8 items-center">
              <p className="text-sm">Status :</p>

              <div className="flex gap-2 font-semibold text-sm opacity-60 items-center">
                {selectedAsset?.status}
              </div>
              {/* <p className='text-sm italic font-medium'>{selectedAsset?.status?.stage}</p> */}
            </div>
          </div>
        </div>
      </MainDiv>
    </>
  );
};

export default MapAndOwner;
