import { GET_ALL_CATEGORIES_FAILED, GET_ALL_CATEGORIES_REQ, GET_ALL_CATEGORIES_SUCC } from "../../constants/categories/Categories";

export const getCategoriesReducer = (state={categories:[], loading:false, error:null}, action) => {
    switch(action.type){
        case GET_ALL_CATEGORIES_REQ:
            return {
                loading:true
            }
         case GET_ALL_CATEGORIES_SUCC:
             return{
                 loading:false,
                 categories:action.payload,
             }
 
         case GET_ALL_CATEGORIES_FAILED:
             return{
                 loading:false,
                 error:action.payload
             }
 
         default:
             return state;
    }
 }