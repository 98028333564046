import { getAssetsOfADocument } from "../../../api/users/document";
import {
  GET_DOCUMENT_ASSETS_FAIL,
  GET_DOCUMENT_ASSETS_REQ,
  GET_DOCUMENT_ASSETS_SUCC,
} from "../../constants/documents/getDocumentsOfAsset";

export const getSingleDocumentAssets =
  ({ page, limit, uid = "", name = "", docId }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_DOCUMENT_ASSETS_REQ });

      const res = await getAssetsOfADocument({
        page,
        limit,
        uid,
        name,
        docId,
      });

      dispatch({
        type: GET_DOCUMENT_ASSETS_SUCC,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_DOCUMENT_ASSETS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
