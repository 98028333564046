/* global window */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { render } from "react-dom";
import { Map, Marker, NavigationControl } from "react-map-gl";
import { AmbientLight, PointLight, LightingEffect } from "@deck.gl/core";
import DeckGL from "@deck.gl/react";
import { PolygonLayer } from "@deck.gl/layers";
import Buildings from "../../../components/Global/map/buildings.json";
import { getLocationsOfProjects } from "../../../../api/users/projects";
import { Wrench } from "phosphor-react";
import { useDispatch } from "react-redux";
import { openSingleProjectAction } from "../../../../redux/actions/project/SingleProject";

// Source data CSV
const DATA_URL = {
  BUILDINGS: Buildings,
  // 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/trips/buildings.json', // eslint-disable-line
  // TRIPS: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/trips/trips-v7.json' // eslint-disable-line
};

const ambientLight = new AmbientLight({
  color: [0, 132, 255],
  intensity: 1.0,
});

const pointLight = new PointLight({
  color: [0, 132, 255],
  intensity: 2.0,
  position: [-74.05, 40.7, 8000],
});

const lightingEffect = new LightingEffect({ ambientLight, pointLight });

const material = {
  ambient: 0.1,
  diffuse: 0.6,
  shininess: 32,
  specularColor: [60, 64, 70],
};

const DEFAULT_THEME = {
  buildingColor: [0, 94, 255],
  material,
  effects: [lightingEffect],
};

const MAP_STYLE1 = "mapbox://styles/mapbox/streets-v11";
const MAP_STYLE2 = "mapbox://styles/mapbox/light-v10";

const landCover = [
  [
    [-74.0, 40.7],
    [-74.02, 40.7],
    [-74.02, 40.72],
    [-74.0, 40.72],
  ],
];

export default function MainMapProjects({
  buildings = DATA_URL.BUILDINGS,
  theme = DEFAULT_THEME,
  loopLength = 1800, // unit corresponds to the timestamp in source data
  animationSpeed = 1,
  map,
  threeD,
  locations,
  multipleLocations,
  status,
  setOpenSidemenuProject,
}) {
  const [time, setTime] = useState(0);
  const [animation] = useState({});
  const animate = () => {
    setTime((t) => (t + animationSpeed) % loopLength);
    animation.id = window.requestAnimationFrame(animate);
  };

  useEffect(() => {
    animation.id = window.requestAnimationFrame(animate);
    return () => window.cancelAnimationFrame(animation.id);
  }, [animation]);

  const layers = [
    // This is only needed when using shadow effects
    new PolygonLayer({
      id: "ground",
      data: landCover,
      getPolygon: (f) => f,
      stroked: false,
      getFillColor: [0, 0, 0, 0],
    }),
    new PolygonLayer({
      id: "buildings",
      data: buildings,
      extruded: true,
      wireframe: false,
      stroked: true,
      opacity: 0.6,
      getPolygon: (f) => f.polygon,
      // getElevation: f => 0,
      getElevation: 90,
      getFillColor: [107, 112, 120],
      material: theme.material,
    }),
  ];

  const layers2 = [
    // This is only needed when using shadow effects
    new PolygonLayer({
      id: "ground",
      data: landCover,
      getPolygon: (f) => f,
      stroked: false,
      getFillColor: [0, 0, 0, 0],
    }),
    new PolygonLayer({
      id: "buildings",
      data: buildings,
      extruded: true,
      wireframe: false,
      stroked: true,
      opacity: 1,
      getPolygon: (f) => f.polygon,
      getElevation: 0,
      getFillColor: theme.buildingColor,
      material: theme.material,
    }),
  ];

  const INITIAL_VIEW_STATE = {
    latitude: 51.507322,
    longitude: -0.127647,
    zoom: 16,
    pitch: 45,
    bearing: 0,
  };

  const mapRef = React.useRef();
  const [locationss, setLocationss] = useState([]);

  useEffect(() => {
    const func = async () => {
      const res = await getLocationsOfProjects(
        INITIAL_VIEW_STATE.longitude,
        INITIAL_VIEW_STATE.latitude,
        500,
        status
      );
      setLocationss(res.data);
    };
    func();
  }, [status]);

  const dispatch = useDispatch();

  return (
    // <DeckGL
    //   layers={threeD ? layers : layers2}
    //   effects={theme.effects}
    //   initialViewState={INITIAL_VIEW_STATE}
    //   controller={true}
    // >
    <Map
      initialViewState={INITIAL_VIEW_STATE}
      style={{ height: "calc(100vh - 57px)" }}
      ref={mapRef}
      reuseMaps
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      mapStyle={map.map1 ? MAP_STYLE1 : MAP_STYLE2}
    >
      {locationss?.map((city, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={city.location.coordinates[0]}
          latitude={city.location.coordinates[1]}
          anchor="bottom"
          onClick={(e) => {
            // If we let the click event propagates to the map, it will immediately close the popup
            e.originalEvent.stopPropagation();
            // setPopupInfo(city);
            setOpenSidemenuProject(true);

            dispatch(openSingleProjectAction(city._id));
          }}
        >
          <div className="p-2 rounded-full bg-theme-black ">
            <Wrench size={22} className=" text-white" weight="bold" />
          </div>
        </Marker>
      ))}
      <NavigationControl
        style={{ marginBottom: 20, marginRight: 40 }}
        position="bottom-right"
      />
    </Map>

    // </DeckGL>
  );
}

// export function renderToDOM(container) {
//   render(<MainMapProjects />, container);
// }
