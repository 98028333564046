import { FakeProjects } from "./fakeProject";
import { documents } from "./faleDoc";

export const Assests = [
    {
        id:1,
        uid:'APC10',
        assetsName:'Alias-Fulmer EV',
        assetType:'Fulmer EV Station',
        details:{
            type:'Electric Motor Vehicle Charging Point',
            uniqueId:'SL_20_45_27',
            where:'on street',
            capacity:'140 kwh',
            charginPoints:12
        },
        assetOwner:{
            imgUrl:"images/user1.jpeg",
            uid:"APC203",
            name:"Sherlock Piping Ltd"
        },
        status:{
            statusType:'Construction',
            stage:'currently being built'
        },
        // projects:FakeProjects,
        // documents: documents
    },
    {
        id:2,
        uid:'APC10',
        assetsName:'Alias-Fulmer EV',
        assetType:'Fulmer EV Station',
        details:{
            type:'Electric Motor Vehicle Charging Point',
            uniqueId:'SL_20_45_27',
            where:'',
            capacity:'140 kwh'
        },
        assetOwner:{
            imgUrl:"images/user1.jpeg",
            uid:"APC203",
            name:'John Constructions'
        },
        status:{
            statusType:'Construction',
            stage:'currently being built'
        },
        // projects:FakeProjects,
        // documents: documents
    },
    {
        id:3,
        uid:'APC10',
        assetsName:'Alias-Fulmer EV',
        assetType:'Fulmer EV Station',
        details:{
            type:'Electric Motor Vehicle Charging Point',
            uniqueId:'SL_20_45_27',
            where:'public car park',
            capacity:'140 kwh',
            charginPoints:12
        },
        assetOwner:{
            imgUrl:"images/user1.jpeg",
            uid:"APC203",
            name:'Valerie Fittings Inc'
        },
        status:{
            statusType:'Construction',
            stage:'currently being built'
        },
        // projects:FakeProjects,
        // documents: documents
    },
    {
        id:4,
        uid:'APC10',
        assetsName:'Alias-Fulmer EV',
        assetType:'Fulmer EV Station',
        details:{
            type:'Electric Motor Vehicle Charging Point',
            uniqueId:'SL_20_45_27',
            where:'public car park',
            capacity:'140 kwh',
            charginPoints:12
        },
        assetOwner:{
            imgUrl:"images/user1.jpeg",
            uid:"APC203",
            name:'Valerie Fittings Inc'
        },
        status:{
            statusType:'Construction',
            stage:'currently being built'
        },
        // projects:FakeProjects,
        // documents: documents
    },
    {
        id:5,
        uid:'APC10',
        assetsName:'Alias-Fulmer EV',
        assetType:'Fulmer EV Station',
        details:{
            type:'Electric Motor Vehicle Charging Point',
            uniqueId:'SL_20_45_27',
            where:'public car park',
            capacity:'140 kwh',
            charginPoints:12
        },
        assetOwner:{
            imgUrl:"images/user1.jpeg",
            uid:"APC203",
            name:'Valerie Fittings Inc'
        },
        status:{
            statusType:'Construction',
            stage:'currently being built'
        },
        // projects:FakeProjects,
        // documents: documents
    }
]