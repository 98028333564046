/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getSingleUser } from "../../../../redux/actions/users/getSingleUser";
import MainMapProjects from "../../../components/Projects/comps/Map";
import MiniMap from "../../../components/Projects/comps/MiniMap";

const MainDiv = styled.div`
  height: 52rem;
`;
const MapAndOwner = () => {
  const [activeFilter, setActiveFilter] = useState({ map1: true, map2: false });
  const [vertical, setVertial] = useState(true);

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.getSingleUser);

  const { open, project } = useSelector((state) => state.singleProjectAdmin);

  useEffect(() => {
    dispatch(getSingleUser(project.createdBy));
  }, [project.createdBy, dispatch]);

  return (
    <>
      <MainDiv className=" bg-white rounded-md relative">
        <div className="flex pt-6 px-6 pb-40 bg-theme-black items-center rounded-t-md justify-between">
          <div className="flex items-center gap-3">
            <div className="text-sm">
              <p className="text-white leading-[17px] font-semibold">
                {project.name}
              </p>
              {project.address && (
                <p className="text-white opacity-40 leading-[17px]">
                  {project.address.street1}, {project.address.street2},{" "}
                  {project.address.city}, {project.address.state},{" "}
                  {project.address.country}
                </p>
              )}
            </div>
          </div>

          <p className="font-semibold text-sm text-white">ID - {project.uid}</p>
        </div>
        <div className="absolute z-[999]  top-36 rounded-md w-full">
          {project.location && (
            <div className=" h-72 mx-6 rounded-md relative">
              <MiniMap
                marker={{
                  longitude: project.location.coordinates[0],
                  latitude: project.location.coordinates[1],
                }}
              />
            </div>
          )}

          {project.companyId && (
            <div className="mx-6 my-4 bg-bottom-border rounded-md">
              <div className="px-4 flex">
                <p className="bg-theme-black rounded-b-md text-white px-5 text-very-tiny font-medium py-1.5">
                  Project Owner
                </p>
              </div>
              <div className="flex justify-between px-4  py-6 items-center">
                <div className="flex gap-2 items-center">
                  {project.companyId?.profilePic ? (
                    <img
                      src={`${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${project.companyId.profilePic}`}
                      className="w-10 rounded-full p-[2px] border border-theme-green"
                      alt=""
                    />
                  ) : (
                    <p className="w-9 h-9 tracking-wide flex justify-center items-center text-white font-bold text-[18px] bg-theme-green p-0.5 border border-theme-green rounded-full">
                      {project?.companyId?.name.split(" ")[0][0]}
                      {project.companyId?.name.split(" ")[1]
                        ? project.companyId?.name.split(" ")[1][0]
                        : ""}
                    </p>
                  )}
                  <p className="font-medium text-sm">
                    {project.companyId.name}
                  </p>
                </div>
                <p className="text-sm uppercase">
                  ID -{" "}
                  <span className="text-blue-400 underline">
                    {project.companyId?.uid}
                  </span>
                </p>
              </div>
            </div>
          )}

          {/* <div className='mx-6 my-4 bg-bottom-border rounded-md'>
                        <div className='px-4 flex'>
                            <p className='bg-theme-black rounded-b-md text-white px-5 text-very-tiny font-medium py-1.5'>My Client</p>
                        </div>
                        <div className='flex justify-between px-4  py-6 items-center'>
                            <div className='flex gap-2 items-center'>
                                <img src={project.projectDetails.imgUrl} className='w-10 rounded-full p-[2px] border border-theme-green' alt="" />
                                <p className='font-medium text-sm'>{project.projectDetails.name}</p>
                            </div>
                            <p className='text-sm uppercase'>Admin</p>
                        </div>
                    </div> */}

          <div className="mx-6 my-4 bg-bottom-border rounded-md">
            <div className="px-4 flex">
              <p className="bg-theme-black rounded-b-md text-white px-5 text-very-tiny font-medium py-1.5">
                Project Status
              </p>
            </div>
            <div className="flex justify-between px-4  py-6 items-center">
              <div className="flex gap-2 font-semibold text-sm opacity-60 items-center">
                Status :
              </div>
              <p className="text-sm capitalize italic font-medium">
                {project.status}
              </p>
            </div>
          </div>
        </div>
      </MainDiv>
    </>
  );
};

export default MapAndOwner;
