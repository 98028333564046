
import { getSingleAssetApi } from "../../../api/users/asset";
import { Assests } from "../../../app/fake/fakeAssets";
import { GET_SINGLE_ASSET_FAIL_ADMIN, GET_SINGLE_ASSET_REQ_ADMIN, GET_SINGLE_ASSET_SUCC_ADMIN } from "../../constants/AdminConsts/AssetsAdminConst";

export const openSingleAssetAdminAction = (id) => async(dispatch) => {
    try{
        dispatch({type: GET_SINGLE_ASSET_REQ_ADMIN})


        const res = await getSingleAssetApi(id)

        dispatch({
                type: GET_SINGLE_ASSET_SUCC_ADMIN,
                payload:res.data
            })
    } catch(error){
        dispatch({
            type: GET_SINGLE_ASSET_FAIL_ADMIN,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message 
        })
    }
}