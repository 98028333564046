
import { FakeSuppliers } from "./fakeSuppliers";
import { documents } from "./faleDoc";

export const FakeProjects = [
    {
        id:1,
        uid:'APC20',
        projectDetails:{
            owner:'Sherlock Piping Ltd',
            name:'EV Charging Station',
            address:"121, Baker St., London 2Z",
            imgUrl:'images/user1.jpeg'
        },
        progress: '3/4',
        asset:'Abc',
        status:'ongoing',
        // suppliers: FakeSuppliers,
        // documents:documents,
    },
    {
        id:2,
        uid:'APC20',
        projectDetails:{
            owner:'Valerie Fittings Inc',
            name:'EV Charging Station',
            address:"121, Baker St., London 2Z",
            imgUrl:'images/user1.jpeg'

        },
        progress: '1/3',
        asset:'Abc',
        status:'ongoing',
        // suppliers: FakeSuppliers,
        // documents:documents,
    },
    {
        id:3,
        uid:'APC20',
        projectDetails:{
            owner:'Stark Industry Ltd',
            name:'EV Charging Station',
            address:"121, Baker St., London 2Z",
            imgUrl:'images/user1.jpeg'

        },
        progress: '2/3',
        asset:'Abc',
        status:'ongoing',
        // suppliers: FakeSuppliers,
        // documents:documents,
    },
    {
        id:4,
        uid:'APC20',
        projectDetails:{
            owner:'Stark Industry Ltd',
            name:'EV Charging Station',
            address:"121, Baker St., London 2Z",
            imgUrl:'images/user1.jpeg'

        },
        progress: '2/3',
        asset:'Abc',
        status:'ongoing',
        // suppliers: FakeSuppliers,
        // documents:documents,
    },
    {
        id:5,
        uid:'APC20',
        projectDetails:{
            owner:'John Constructions',
            name:'EV Charging Station',
            address:"121, Baker St., London 2Z",
            imgUrl:'images/user1.jpeg'

        },
        progress: '2/3',
        asset:'Abc',
        status:'ongoing',
        // suppliers: FakeSuppliers,
        // documents:documents,
    },
    {
        id:6,
        uid:'APC20',
        projectDetails:{
            owner:'Sherlock Piping Ltd',
            name:'EV Charging Station',
            address:"121, Baker St., London 2Z",
            imgUrl:'images/user1.jpeg'

        },
        progress: '2/3',
        asset:'Abc',
        status:'ongoing',
        // suppliers: FakeSuppliers,
        // documents:documents,
    },
]