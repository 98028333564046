import React from 'react'
import Map, {Marker, NavigationControl} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css'
import Pin from '../../createAsset/comps/pin';



const MiniMap = ({marker}) => {
    const initialViewState = {
        longitude:marker.longitude,
        latitude:marker.latitude,
        zoom:16
      };
  return (
    <>
         <Map
            initialViewState={initialViewState}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        >
        <Marker
            longitude={marker.longitude}
            latitude={marker.latitude}
            anchor="bottom"
            >
            <Pin size={20} />
            </Marker>

            <NavigationControl />
        </Map>
    </>
  )
}

export default MiniMap