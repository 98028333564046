import classNames from "classnames";
import { ArrowRight } from "phosphor-react";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllDocsAction } from "../../../../redux/actions/documents/getAllDoc";
import { getSuppliersOfAProjectAction } from "../../../../redux/actions/suppliers/geSupplierOfAProject";
import DocumentsDropDown from "../../Projects/comps/DocumentsDropDown";
import SupplierDropDown from "../../Projects/comps/SupplierDropDown";

const ProjectsDropDown = ({ project, onClick, clickedId, setClickedId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [documentsDropDown, setdocumentsDropDown] = useState(false);
  const [supplierDropDown, setsuppliersDropDown] = useState(false);

  // document dropdown
  const { companyId } = useSelector((state) => state.selectCompany);
  const { user } = useSelector((state) => state.me);

  const openDocOfAProject = (p) => {
    setdocumentsDropDown(p._id === clickedId ? !documentsDropDown : true);
    setClickedId(p._id);
    dispatch(
      getAllDocsAction({
        projectId: p._id,
        companyId: companyId ? companyId : "",
      })
    );
    setsuppliersDropDown(false);
  };

  const { docs } = useSelector((state) => state.getAllDoc);
  const openDocumentDropDown = clickedId === project._id && documentsDropDown;

  // supplier dropdown

  const openSuppliersOfAProject = (p) => {
    setsuppliersDropDown(!supplierDropDown);
    setClickedId(p._id);
    dispatch(getSuppliersOfAProjectAction(p._id));
    setdocumentsDropDown(false);
  };

  const { suppliers, loading: suppliersDropDownLOading } = useSelector(
    (state) => state.getSuppliersOFAProject
  );

  const openSupplierDropDown = clickedId === project._id && supplierDropDown;

  return (
    <div>
      <div
        className={classNames(
          "px-6 py-5  flex",
          openDocumentDropDown || openSupplierDropDown
            ? "bg-theme-green bg-opacity-10 border-b border-t"
            : "border-b border-bottom-border"
        )}
      >
        <div className="w-1/6 text-xs font-medium">
          <p className="uppercase">id</p>
          <p className="opacity-50 text-theme-black">{project?.uid}</p>
        </div>
        <div className="w-4/12 text-xs font-medium">
          <div className="flex gap-2 items-center">
            <div>
              <p className="font-medium">{project?.name}</p>
              <p className="  font-light">
                {project?.address?.street1 +
                  ", " +
                  project?.address?.street2 +
                  ", " +
                  project?.address?.city +
                  ", " +
                  project?.address?.state +
                  ", " +
                  project?.address?.country}
              </p>
            </div>
          </div>
        </div>

        <div className="w-1/12 text-xs font-medium">
          {/* <div className='flex gap-2 items-center'>
                                    <div>
                                        <p className='font-medium leading-3'>XYZ</p>
                                        <p className='  font-light leading-3'>---</p>
                                    </div>
                                </div> */}
        </div>

        <div className="w-1/6 pl-4 text-xs font-medium">
          <div className="">
            <p className="leading-3">Suppliers</p>
            <p
              onClick={() => openSuppliersOfAProject(project)}
              className="text-theme-black opacity-50 cursor-pointer leading-3"
            >
              {project?.stats?.suppliers}{" "}
              <i className={classNames("fa-solid ml-1 ", "fa-angle-down")}></i>
            </p>
          </div>
        </div>

        <div className="w-1/6 pl-4 text-xs font-medium">
          <div className="">
            <p className="leading-3">Documents</p>
            <p
              onClick={() => openDocOfAProject(project)}
              className="text-theme-black opacity-50 cursor-pointer leading-3"
            >
              {project?.stats?.documents}{" "}
              <i
                className={classNames(
                  "fa-solid ml-1 ",
                  clickedId === project._id && openDocumentDropDown
                    ? "fa-angle-up"
                    : "fa-angle-down"
                )}
              ></i>
            </p>
          </div>
        </div>

        <div className="text-xs flex-1 flex justify-end font-medium">
          <ArrowRight
            onClick={() => navigate(`/projects/${project._id}`)}
            className="bg-#F7F6F6 p-2 cursor-pointer border rounded"
            size={28}
            weight="light"
          />
        </div>
      </div>

      {openDocumentDropDown && (
        <div className="max-h-60 overflow-y-auto">
          {docs && docs.length === 0 ? (
            <p className="text-center py-6 text-xs border-b">
              No Document found
            </p>
          ) : (
            docs?.map((s, index) => {
              return (
                <DocumentsDropDown
                  notExpandable
                  onClick={() => navigate(`/admin/documents/${s._id}`)}
                  s={s}
                  key={index}
                />
              );
            })
          )}
        </div>
      )}

      {openSupplierDropDown && (
        <div className="max-h-60 overflow-y-auto">
          {suppliers.length !== 0 ? (
            suppliers.map((s, idx) => {
              return <SupplierDropDown notExpandable s={s} key={idx} />;
            })
          ) : (
            <p className="text-center py-6 text-xs border-b">
              No Supplier found
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectsDropDown;
