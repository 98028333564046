import React from 'react'
import styled from 'styled-components'
import ShowCase from './ShowCase'
import TopLinks from './TopLinks'


const MainDiv = styled.div`
  height: calc(100vh - 57px);

`
const AdvancedSearch = () => {
  return (
    <>
        <MainDiv className='px-[54px] py-8 overflow-y-auto'>
            <TopLinks/>
            <ShowCase/>
        </MainDiv>
    </>
  )
}

export default AdvancedSearch