/* eslint-disable no-unused-vars */
import {
  Buildings,
  CaretDown,
  CaretRight,
  File,
  SquaresFour,
  Users,
  UsersFour,
  UsersThree,
  Wrench,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  ASSETS_CREATE_VIEW_OPEN,
  ASSETS_LIST_VIEW_OPEN,
  ASSETS_MAP_VIEW_OPEN,
  COMPANIES_ADD_COMPANY_VIEW_OPEN,
  COMPANIES_INVITE_VIEW_OPEN,
  COMPANIES_LIST_VIEW_OPEN,
  DASHBOARD_OPEN,
  DOCUMENTS_CREATE_OPEN,
  DOCUMENTS_LIST_OPEN,
  DOCUMENTS_MAP_OPEN,
  EMPLOYESS_ADD_OPEN,
  EMPLOYESS_LIST_OPEN,
  ISO_OPEN,
  NOTIFICATIONS_OPEN,
  PROJECTS_CREATE_OPEN,
  PROJECTS_LIST_OPEN,
  PROJECTS_MAP_OPEN,
  SUPPORT_CHAT_DEV_OPEN,
} from "../../../redux/constants/AdminConsts/LeftNavAdminConst";
import { OPEN_CREATE_NEW_SUPPORT_TICKET } from "../../../redux/reducers/Admin/CreateNewHelpTicket";
import { OPEN_ADD_NEW_NOTIFICATIONS } from "../../../redux/reducers/Admin/CreateNewNotificatioReducer";

const SinglePageDiv = styled.div.attrs({
  className:
    "flex flex-col hover:bg-[#FFFFFF08] items-start  gap-0.5 cursor-pointer justify-center border-b border-[#FFFFFF1A]",
})`
  ${(props) =>
    props.active
      ? `
  background-color: #FFFFFF08;
  color:#fff

  `
      : `
  background-color: #323132;
  color:  #FFFFFF80;

  `}
`;

const SinglePageDivFirstAndLast = styled.div.attrs({
  className:
    "flex flex-col hover:bg-[#FFFFFF08] items-start py-4 px-3 gap-0.5 cursor-pointer justify-center border-b border-[#FFFFFF1A]",
})`
  ${(props) =>
    props.active
      ? `
  background-color: #FFFFFF08;
  color:#fff

  `
      : `
  background-color: #323132;
  color:  #FFFFFF80;

  `}
`;

const UL = styled.ul.attrs({
  className: "text-very-tiny ",
})`
  list-style-position: inside;
  list-style-type: disc;

  & li {
    padding: 4px 8px;
    border-radius: 6px;
  }
`;
const MainDiv = styled.div`
  width: 132px;
  height: calc(100vh - 57px);
  ${"" /* margin-top:57px; */}
  box-shadow:  0px 4px 12px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  -o-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
`;
const LeftNavAdmin = () => {
  const active = "bg-theme-green text-theme-black";
  const notActive = "text-[#FFFFFF80]";

  const { dashboard, companies, assets, documents, projects, employess, iso } =
    useSelector((state) => state.AdminLeftNav);

  const location = useLocation().pathname;

  let isCompaniesActie = location.split("/")[2] === "companies";

  let isAssetsActive = location.split("/")[2] === "assets";

  let isprojectsActive = location.split("/")[2] === "projects";
  let isdocumentsActive = location.split("/")[2] === "documents";

  let isemployessActive = location.split("/")[2] === "employees";

  const dispatch = useDispatch();

  // quick action
  const [quickActions, setQuickAction] = useState(false);

  useEffect(() => {
    if (quickActions)
      document.body.onclick = function () {
        setQuickAction(false);
      };
  }, [quickActions]);

  const navigate = useNavigate();

  return (
    <>
      <MainDiv className="flex z-50 bg-theme-black  flex-col justify-between">
        <div>
          <SinglePageDivFirstAndLast active={global}>
            <button
              onClick={(e) => {
                setQuickAction(!quickActions);
                e.stopPropagation();
              }}
              className="bg-theme-green rounded-md text-very-tiny px-4 py-2 text-theme-black font-medium"
            >
              Quick Actions
            </button>
          </SinglePageDivFirstAndLast>

          <Link to="/">
            <SinglePageDivFirstAndLast
              onClick={() => dispatch({ type: DASHBOARD_OPEN })}
              active={location === "/admin"}
            >
              <SquaresFour size={20} weight="fill" />
              <p className="text-very-tiny ">Dashboard</p>
            </SinglePageDivFirstAndLast>
          </Link>

          {/* Companies */}
          {/* <Link to='companies'> */}
          <SinglePageDiv active={isCompaniesActie}>
            <div
              onClick={() => {
                dispatch({ type: COMPANIES_LIST_VIEW_OPEN });
                navigate("companies");
              }}
              className="w-full py-4 px-3 grid gap-0.5"
            >
              <Users size={20} weight="fill" />
              <p className="text-very-tiny w-full flex justify-between items-center">
                Companies
                {/* {isCompaniesActie? <CaretDown size={10}/> :<CaretRight size={10}/>} */}
              </p>
            </div>
            {/* {isCompaniesActie && <div className='-mt-2 px-3 pb-4 -ml-1'>
                  <UL >
                    <li onClick={(e) => {navigate('companies/addNew')}} className={location.split('/')[3]==="addNew" ?active : notActive} >Add Company</li>
                    <li onClick={() => {navigate('companies/invite')}} className={location.split('/')[3]==="invite"?active : notActive}>Invite</li>
                    <li onClick={() => {dispatch({type:COMPANIES_LIST_VIEW_OPEN}); navigate('/admin/companies')}} className={(location.split('/')[3]!== "addNew" && location.split('/')[3]!=="invite") ?active : notActive}>List View</li>
                  </UL>
              </div>} */}
          </SinglePageDiv>
          {/* </Link> */}

          {/* Assets */}
          {/* <Link to='assets'> */}
          <SinglePageDiv active={isAssetsActive}>
            <div
              onClick={() => {
                dispatch({ type: ASSETS_LIST_VIEW_OPEN });
                navigate("/admin/assets");
              }}
              className="w-full py-4 px-3 grid gap-0.5"
            >
              <Buildings size={20} weight="fill" />
              <p className="text-very-tiny w-full flex justify-between items-center">
                Assets
                {/* {isAssetsActive? <CaretDown size={10}/> :<CaretRight size={10}/>} */}
              </p>
            </div>
            {/* {isAssetsActive && <div className='-mt-2 px-3 pb-4 -ml-1'>
                  <UL >
                    <li onClick={() => navigate('/admin/assets/mapview')} className={location.split('/')[3]==="mapview" ?active : notActive}>Map view</li>
                    <li onClick={() => navigate('/admin/assets')} className={location.split('/')[3]!=="mapview"?active : notActive}>List View</li>
                  </UL>
              </div>} */}
          </SinglePageDiv>
          {/* </Link> */}

          {/* Projects */}
          <SinglePageDiv active={isprojectsActive}>
            <div
              onClick={() => {
                dispatch({ type: PROJECTS_LIST_OPEN });
                navigate("/admin/projects");
              }}
              className="w-full py-4 px-3 grid gap-0.5"
            >
              <Wrench size={20} weight="fill" />
              <p className="text-very-tiny w-full flex justify-between items-center">
                Projects
                {/* {isprojectsActive? <CaretDown size={10}/> :<CaretRight size={10}/>} */}
              </p>
            </div>
            {/* {isprojectsActive && <div className='-mt-2 px-3 pb-4 -ml-1'>
                  <UL>
                    <li onClick={() =>{ dispatch({type:PROJECTS_MAP_OPEN}); navigate('/admin/projects/mapview')}} className={location.split('/')[3]==="mapview" ? active : notActive}>Map view</li>
                    <li onClick={() => {dispatch({type:PROJECTS_LIST_OPEN}); navigate('/admin/projects')}} className={location.split('/')[3]!=="mapview"  ?active : notActive}>List View</li>
                  </UL>
              </div>} */}
          </SinglePageDiv>

          {/* Documents */}
          <Link to="documents">
            <SinglePageDiv active={isdocumentsActive}>
              <div
                onClick={() => dispatch({ type: DOCUMENTS_LIST_OPEN })}
                className="w-full py-4 px-3 grid gap-0.5"
              >
                <File size={20} weight="fill" />
                <p className="text-very-tiny w-full flex justify-between items-center">
                  Documents
                  {/* {isdocumentsActive? <CaretDown size={10}/> :<CaretRight size={10}/>} */}
                </p>
              </div>
              {/* {isdocumentsActive && <div className='-mt-2 px-3 pb-4 -ml-1'>
                  <UL > */}
              {/* <li onClick={() => {dispatch({type:DOCUMENTS_CREATE_OPEN})}} className={documents.add ?active : notActive} >Add Document</li> */}
              {/* <li onClick={() => dispatch({type:DOCUMENTS_MAP_OPEN})} className={documents.map ?active : notActive}>Map view</li> */}
              {/* <li onClick={() => dispatch({type:DOCUMENTS_LIST_OPEN})} className={active}>List View</li>
                  </UL>
              </div>} */}
            </SinglePageDiv>
          </Link>

          {/* employyes */}
          {/* <Link to='employees'> */}
          <SinglePageDiv active={isemployessActive}>
            <div
              onClick={() => {
                dispatch({ type: EMPLOYESS_LIST_OPEN });
                navigate("/admin/employees");
              }}
              className="w-full py-4 px-3 grid gap-0.5"
            >
              <UsersThree size={20} weight="fill" />
              <p className="text-very-tiny w-full flex justify-between items-center">
                Employees
                {/* {isemployessActive ? <CaretDown size={10}/> :<CaretRight size={10}/>} */}
              </p>
            </div>
            {/* {isemployessActive && <div className='-mt-2 px-3 pb-4 -ml-1'>
                  <UL >
                    <li onClick={() => {dispatch({type:EMPLOYESS_ADD_OPEN}); navigate('/admin/employees/addNew')}} className={location.split('/')[3]==='addNew' ?active : notActive} >Add Employee</li>
                    <li onClick={() =>{ dispatch({type:EMPLOYESS_LIST_OPEN}); navigate('/admin/employees')}} className={location.split('/')[3]!=='addNew' ?active : notActive}>List View</li>
                  </UL>
              </div>} */}
          </SinglePageDiv>
          {/* </Link> */}
          {/* <SinglePageDivFirstAndLast onClick={() => dispatch({type:ISO_OPEN})} active={iso}>
              <img src={iso ? '/images/admin/isoWhite.svg' :"/images/admin/isoBlack.svg"} alt="" />
              <p className='text-very-tiny pt-1'>ISO19650</p>
            </SinglePageDivFirstAndLast> */}
        </div>
      </MainDiv>

      {quickActions && (
        <div className="bg-theme-black text-center fixed text-very-tiny cursor-pointer text-white text-opacity-80 rounded left-[147px] z-[9999999] top-[70px]">
          <p
            onClick={() => {
              navigate("companies/addNew");
              dispatch({ type: COMPANIES_ADD_COMPANY_VIEW_OPEN });
            }}
            className="py-2 border-b px-4"
          >
            Add Company
          </p>
          <p
            onClick={() => {
              navigate("companies/invite");
              dispatch({ type: COMPANIES_INVITE_VIEW_OPEN });
            }}
            className="py-2 border-b px-4"
          >
            Invite Company
          </p>
          {/* <p onClick={() => dispatch({type: ASSETS_CREATE_VIEW_OPEN})} className='py-2 border-b px-4'>Add Asset</p>
                <p onClick={() => dispatch({type: PROJECTS_CREATE_OPEN})} className='py-2 border-b px-4'>Add Project</p>
                <p onClick={() => dispatch({type: DOCUMENTS_CREATE_OPEN})} className='py-2 border-b px-4'>Add Document</p> */}
          <p
            onClick={() => {
              navigate("employees");
              dispatch({ type: EMPLOYESS_ADD_OPEN });
            }}
            className="py-2 border-b px-4"
          >
            Add Employee
          </p>
          {/* <p onClick={() => {dispatch({type: SUPPORT_CHAT_DEV_OPEN});dispatch({type: OPEN_CREATE_NEW_SUPPORT_TICKET}) }} className='py-2 border-b px-4'>Create Ticket</p> */}
          <p
            onClick={() => {
              navigate("notifications");
              dispatch({ type: NOTIFICATIONS_OPEN });
              dispatch({ type: OPEN_ADD_NEW_NOTIFICATIONS });
            }}
            className="py-2 px-4"
          >
            Send Notification
          </p>
        </div>
      )}
    </>
  );
};

export default LeftNavAdmin;
