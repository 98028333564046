import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCurrentUser, signInWithEmail } from "../../../libs/cognito";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUser } from "../../../redux/actions/me/user";
import { editCompany } from "../../../api/users/company";
import classNames from "classnames";
import VideoBG from "./VideoBG";

const UserLogin = () => {
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [changePasswordInput, setChangePassword] = useState("");
  const [attributes, setAttributes] = useState(null);
  const [resetPassword, setResetPassword] = useState(false);
  const [cognito, setCognito] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loggingIn, setLoggingIn] = useState(false);
  const [changeCompanyName, setChangeCompanyName] = useState(false);
  const [companyName, setCompanyName] = useState("");

  const [companyId, setCompanyId] = useState("");

  const Login = async (event, second = false) => {
    // For changing the comapnyName

    // if(event){

    event?.preventDefault();
    // }

    try {
      setLoggingIn(true);

      if (
        username &&
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(username) &&
        password
      ) {
        const user = await signInWithEmail(
          username,
          second ? changePasswordInput : password
        );

        setLoggingIn(false);
        if (user.firstLogin) { // TODO: I don't think this actually does anything...
          setAttributes(user.userAttributes);
          setCognito(user.currentUser);
          setResetPassword(true);
          toast.warning("change your password before login ");
        } else {
          dispatch(
            getLoggedInUser((user) => {
              if (user._id === user.companyId.adminUserId) {
                if (user.email === user.companyId.name) {
                  setChangeCompanyName(true);
                  setCompanyId(user.companyId._id);
                } else {
                  if (user.roleId._id === "627955212019dc8e78e71ba7") {
                    navigate("/admin");
                  } else if (user.roleId._id) {
                    navigate("/");
                  } else {
                    toast.error("login failed");
                  }
                }
              } else {
                if (user.roleId._id === "627955212019dc8e78e71ba7") {
                  navigate("/admin");
                } else if (user.roleId._id) {
                  navigate("/");
                } else {
                  toast.error("login failed");
                }
              }
            })
          );
        }
      } else {
        setLoggingIn(false);
        toast.error("enter valid email and password");
      }
    } catch (error) {
      setLoggingIn(false);
      toast.error(error.message);
      setError(error.message);
    }
  };
  const changePassword = async (e) => {
    try {
      if (changePasswordInput && cognito) {
        const res = await cognito.completeNewPasswordChallenge(
          changePasswordInput
        );

        navigate("/login");
        setResetPassword(false);
        setPassword("");
        toast.info("Login with the new password");
      } else {
        toast.error("enter  password");
      }
    } catch (error) {
      toast.error(error.message);
      setError(error.message);
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (location.search !== "") {
      setUsername(location.search.split("&")[0].split("=")[1]);
      setPassword(location.search.split("pw=")[1]);
    }
  }, [location]);

  const changeCompanyNameHandler = async () => {
    try {
      setLoggingIn(true);
      await editCompany(companyId, { name: companyName });
      Login();
      setLoggingIn(false);
    } catch (error) {
      setLoggingIn(false);
    }
  };

  return (
    <>
      <div className="h-screen w-screen">
        {/* <StaticMapForLogin /> */}
        <VideoBG />
        <div className="flex absolute bg-white top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 justify-center z-[9999] flex-col items-center w-[50vh] pt-6">
          <div>
            <img className="w-[14rem]" src="/images/ApexLogo_PNG.png" alt="" />
          </div>
          {!changeCompanyName &&
            (resetPassword ? (
              <div className="bg-white mt-3 w-full rounded shadow-md p-4">
                <p className="font-semibold mt-3 text-[#000000CC]">
                  Enter Your new Password
                </p>

                <div className="grid text-xs pt-6 gap-2">
                  <input
                    value={changePasswordInput}
                    onChange={(e) => setChangePassword(e.target.value)}
                    placeholder="Password"
                    type="password"
                    className="focus:outline-none p-3 bg-[#FAFBFC] border rounded"
                  />
                </div>

                <button
                  onClick={() => changePassword()}
                  className="w-full mt-5 font-medium text-xs py-2 bg-[#2ED9C3]"
                >
                  Enter Password
                </button>
              </div>
            ) : (
              <>
                <form
                  onSubmit={(e) => Login(e, false)}
                  className="bg-white mt-3 w-full rounded shadow-md p-4"
                >
                  <p className="font-medium text-center mt-3 text-[#000000CC]">
                    Log in to your account
                  </p>

                  <div className="grid text-xs pt-6 gap-2">
                    <input
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Email"
                      type="email"
                      className="focus:outline-none p-3 bg-[#FAFBFC] border rounded"
                    />
                    <input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      type="password"
                      className="focus:outline-none p-3 bg-[#FAFBFC] border rounded"
                    />
                  </div>

                  <div className="flex mt-4 justify-between text-xs items-center">
                    <div className="flex gap-2 items-center ">
                      <input type="checkbox" id="xyz" />
                      <label htmlFor="xyz">
                        <p className="opacity-50">Stay logged in</p>
                      </label>
                    </div>

                    <p
                      onClick={() => navigate("/reset_password")}
                      className="underline cursor-pointer opacity-50"
                    >
                      Forgot your password?
                    </p>
                  </div>
                  <button
                    type="submit"
                    className="w-full mt-5 font-medium text-xs py-2 bg-[#2ED9C3]"
                  >
                    {loggingIn && <i class="fa fa-spinner fa-spin"></i>}{" "}
                    {loggingIn ? "Logging you in" : "Log in"}
                  </button>
                </form>
              </>
            ))}

          {changeCompanyName && (
            <div className="bg-white mt-3 w-full rounded shadow-md p-4">
              <p className="font-semibold mt-3 text-[#000000CC]">
                Enter your company name
              </p>

              <div className="grid text-xs pt-6 gap-2">
                <input
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Enter your company name"
                  type="text"
                  className="focus:outline-none p-3 bg-[#FAFBFC] border rounded"
                />
              </div>

              <button
                disabled={companyName.trim() === ""}
                onClick={() => changeCompanyNameHandler()}
                className={classNames(
                  "w-full mt-5 font-medium text-xs py-2 bg-[#2ED9C3]",
                  companyName.trim() === "" && "opacity-40"
                )}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserLogin;
