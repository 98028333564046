export const OPEN_ADD_NEW_USER = 'ADD_NEW_USER'
export const CLOSE_ADD_NEW_USER ='CLOSE_ADD_NEW_USER'

export const addNewUserReducer = (state={opneAddNewUser:false}, action) => {
    switch(action.type){
        case OPEN_ADD_NEW_USER:
            return{
                opneAddNewUser:true
            }
        case CLOSE_ADD_NEW_USER:
            return{
                opneAddNewUser:false
            }
        default:
            return state;
        
    }
}