import { Gear, NotePencil, Power, User } from "phosphor-react";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { signOut } from "../../../libs/cognito";
import {
  ADMIN_OPEN,
  PROFILE_OPEN,
} from "../../../redux/constants/AdminConsts/LeftNavAdminConst";

const MainDiv = styled.div`
  height: calc(100vh - 56px);
`;

const ProfileDropDown = ({ closeProfileDropDown, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileOpenHandler = () => {
    dispatch({ type: PROFILE_OPEN });
    closeProfileDropDown();
    navigate("profile");
  };

  const settingsOpenHandler = () => {
    dispatch({ type: PROFILE_OPEN });
    closeProfileDropDown();
    navigate("profile/settings");
  };

  const AdminOpener = () => {
    dispatch({ type: ADMIN_OPEN });
    closeProfileDropDown();
    navigate("adminOptions");
  };

  return (
    <>
      <MainDiv className="fixed flex top-14 justify-between flex-col right-0 w-72 h-full z-50 bg-#F7F6F6 shadow-md">
        {/* <div className='relative h-full'> */}
        <div>
          <div className="flex justify-center mt-8 mb-3">
            <img
              src={
                !user.profilePic
                  ? `/images/profile.svg`
                  : `${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${user.profilePic}`
              }
              className="w-12 h-12 object-cover rounded-full p-0.5 border border-theme-green"
              alt=""
            />
          </div>
          <div className="text-center mb-4">
            <p className="text-very-tiny font-semibold">
              {user?.firstName} {user?.lastName}
            </p>
            <p className="text-very-tiny font-semibold opacity-50">
              {user?.companyId.name}
            </p>
          </div>

          <div className="my-2 mx-6 bg-white  py-2 px-4 rounded-lg">
            <p className="text-very-tiny font-semibold">Account Role</p>
            <p className="text-very-tiny font-semibold opacity-50">
              {user?.roleId.name}
            </p>
          </div>

          {/*  */}

          <div className="flex justify-between mt-4 mx-6 gap-2">
            <div
              onClick={profileOpenHandler}
              className="flex bg-white flex-1 hover:bg-gray-200 hover:border-gray-300 cursor-pointer justify-center flex-col items-center border-gray-200 p-2 border-2 rounded-lg gap-1"
            >
              <NotePencil size={16} />
              <p className="uppercase text-very-tiny font-semibold">Profile</p>
            </div>

            <div
              onClick={settingsOpenHandler}
              className="flex bg-white flex-1 hover:bg-gray-200 hover:border-gray-300 cursor-pointer justify-center flex-col items-center border-gray-200 p-2 border-2 rounded-lg gap-1"
            >
              <Gear size={16} />
              <p className="uppercase text-very-tiny font-semibold">Setting</p>
            </div>
          </div>

          <div className="px-20 mt-2">
            <div
              onClick={AdminOpener}
              className="flex bg-white flex-1 hover:bg-gray-200 hover:border-gray-300 cursor-pointer justify-center flex-col items-center border-gray-200 p-2 border-2 rounded-lg gap-1"
            >
              <User size={16} />
              <p className="uppercase text-very-tiny font-semibold">
                Admin Options
              </p>
            </div>
          </div>
        </div>

        {/* <div onClick={()=>{signOut()}} className='flex flex-col justify-cneter items-center'>
                    <Power size={20} />
                    <p className='text-very-tiny uppercase '>Log out</p>
                </div> */}

        <div className=" flex jsutify-center flex-col items-center">
          <button
            className="px-5 mb-6 text-[12px] py-2 rounded font-medium cursor-pointer select-none hover:bg-theme-green hover:text-black bg-theme-light-green hover:bg-theme-green text-theme-green"
            onClick={() => signOut()}
          >
            Log Out
          </button>

          <p className="text-very-tiny">Let's build it together</p>
          <img className="w-32" src="images/ApexLogo_Name_PNG.png" alt="" />
          <img className="w-68" src="images/sky.png" alt="" />
        </div>
      </MainDiv>
      {/* </div> */}
    </>
  );
};

export default ProfileDropDown;
