import { ArrowRight } from 'phosphor-react'
import React from 'react'

const SingleAsset = ({p, onClick}) => {
  return (
    <>
        <div onClick={onClick} className='flex border-b bg-white border-bottom-border px-6 py-5'>
            <div className='w-1/6 font-semibold '>
                <p className='text-very-tiny '>ID</p>
                <p className='text-very-tiny opacity-50'>{p.uid}</p>
            </div>  

            <div className='w-1/2 text-xs'>
                <p className='font-medium'>{p.name}</p>
                {/* <div className='flex gap-1 text-very-tiny'>
                    <p className='text-theme-black opacity-50'>{p.companyId.name}</p>
                    <p className='uppercase text-theme-green '>{p.locationType}</p>
                </div> */}
            </div>  

            <div className='w-1/6  font-semibold '>
                <p className='text-very-tiny'>Status</p>
                <p className='text-very-tiny opacity-50 capitalize'>{p.status}</p>
            </div>  

            <div className='text-very-tiny flex flex-1 justify-end font-medium'>
                <ArrowRight className='bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded' size={28} weight='light'/>
            </div>
        </div>
    </>
  )
}

export default SingleAsset