import { getRecentActivity } from "../../api/users/activity";
import { GET_RECENT_ACTIVITY_FAILED, GET_RECENT_ACTIVITY_REQ, GET_RECENT_ACTIVITY_SUCC } from "../constants/getRecentActivityConsts";



export const getRecentActivityAction = ({companyId="", limit = 5, page = 1, fromDate="", toDate=""}) => async(dispatch) => {
    try{
        dispatch({type: GET_RECENT_ACTIVITY_REQ})
        const res = await getRecentActivity(page, limit , companyId, fromDate, toDate )

        dispatch({
            type:GET_RECENT_ACTIVITY_SUCC,
            payload:res.data.docs,
            allData:res.data
        })
    } catch(error){
        dispatch({
            type: GET_RECENT_ACTIVITY_FAILED,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}