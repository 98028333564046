import React from 'react'
import styled from 'styled-components'
import TopLinks from './comps/TopLinks'
import ShowCase from './ShowCase'



const MainDiv = styled.div`
  height: calc(100vh - 57px);

`
const MyAccount = () => {
  return (
    <>
        <MainDiv className='px-[32px] py-8 overflow-y-auto'>
            <TopLinks/>
            <ShowCase/>
        </MainDiv>
    </>
  )
}

export default MyAccount