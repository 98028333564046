import { GET_PROJECTS_OF_A_USER_FAIL, GET_PROJECTS_OF_A_USER_REQ, GET_PROJECTS_OF_A_USER_SUCC } from "../../constants/projects/getProjectOfAUserConsts";

export const getAllProjectsOfAUserReducer = (state={projectsOfAUser: [], loadingUser:false, error:null, allUserProjectDetails:{}}, action) => {
    switch(action.type){
        case GET_PROJECTS_OF_A_USER_REQ:
            return{
                loading:true,
                ...state
            }

        case GET_PROJECTS_OF_A_USER_SUCC:
            return{
                loadingUser:false,
                projectsOfAUser:action.payload,
                allUserProjectDetails:action.allUserProjectDetails
            }


        case GET_PROJECTS_OF_A_USER_FAIL:
        return{
            loadingUser:false,
            error:action.payload
        }

        default:
            return state;
    }
}