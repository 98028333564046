import classNames from "classnames";
import { Check } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getAdminPermissions,
  getUserPermissions,
  putUserPermissions,
  setUserPermissions,
} from "../../../../../api/users/inviteUsers";
import { getSpecificUserPermissions } from "../../../../../api/users/users";

const UserPermissions = ({ userData }) => {
  const [permissions, setPermssions] = useState([]);

  const [selectedPermissions, setselectedPermissions] = useState([]);

  const getPermissins = async () => {
    try {
      const res = await getUserPermissions();

      const filterd = res.data.map((item) => {
        return {
          value: item._id,
          label: item.name,
        };
      });

      return [...filterd];
    } catch (err) {
      toast.error(err.response.data?.message);
    }
  };

  const getAdminPermissins = async () => {
    try {
      const res = await getAdminPermissions();

      const filterd = res.data.map((item) => {
        return {
          value: item._id,
          label: item.name,
        };
      });
      return [...filterd];
    } catch (err) {
      toast.error(err.response.data?.message);
    }
  };

  useEffect(() => {
    const func = async () => {
      const res1 = await getAdminPermissins();
      const res2 = await getPermissins();

      if (userData.roleId.name === "Admin") {
        setPermssions(res1);
      } else {
        setPermssions(res2);
      }
    };

    func();
  }, [userData]);

  const selectAll = () => {
    if (selectedPermissions.length === permissions.length) {
      setselectedPermissions([]);
    } else {
      setselectedPermissions(permissions);
    }
  };

  const selectHandler = async (p) => {
    let newArr = [...selectedPermissions];
    const contains = newArr.filter((a) => a.value === p.value);

    if (contains.length === 1) {
      newArr = newArr.filter((a) => a.value !== p.value);
    } else {
      newArr = [...selectedPermissions, p];
    }

    setselectedPermissions(newArr);
  };

  // update
  useEffect(() => {
    const func = async () => {
      try {
        const permissions = selectedPermissions.map((val) => {
          return val.value;
        });
        let data = {
          userId: userData._id,
          permissions: [...permissions],
        };
        await putUserPermissions(data);
      } catch (error) {}
    };
    if (selectedPermissions.length !== 0) {
      func();
    }
  }, [selectedPermissions, userData]);

  const getPermission = async () => {
    try {
      const res = await getSpecificUserPermissions(userData._id);
      const fd = res.data.map((item) => {
        return {
          value: item._id,
          label: item.name,
        };
      });
      setselectedPermissions(fd);
    } catch (error) {}
  };

  useEffect(() => {
    getPermission();
  }, []);

  return (
    <>
      {
        <div className="p-6">
          <p className="text-sm">Permissions</p>

          <div className="grid gap-3 mt-3 grid-cols-4">
            <div className="flex items-center gap-3">
              <Check
                onClick={selectAll}
                size={18}
                className={classNames(
                  "text-white border border-[#3231324D] rounded p-[2px] cursor-pointer",
                  selectedPermissions.length === permissions?.length
                    ? "bg-[#3231324D]"
                    : "bg-white"
                )}
              />
              <p className="text-xs text-theme-black">All</p>
            </div>
            {permissions.length !== 0 &&
              permissions?.map((p, index) => {
                return (
                  <div key={index} className="flex items-center gap-3">
                    <Check
                      onClick={() => selectHandler(p)}
                      size={18}
                      className={classNames(
                        "text-white border border-[#3231324D] rounded p-[2px] cursor-pointer",
                        selectedPermissions.filter((s) => s.value === p.value)
                          .length === 1
                          ? "bg-[#3231324D]"
                          : "bg-white"
                      )}
                    />
                    <p className="text-xs text-theme-black">{p.label}</p>
                  </div>
                );
              })}
          </div>
        </div>
      }
    </>
  );
};

export default UserPermissions;
