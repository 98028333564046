import { GET_ALL_PROJECTS_FAIL, GET_ALL_PROJECTS_REQUEST, GET_ALL_PROJECTS_SUCC } from "../../constants/projects/getAllProjects";
import { GET_PROEJCTS_FAIL, GET_PROEJCTS_REQ, GET_PROEJCTS_SUCC } from "../../constants/projects/ProjectsConst";


export const  ProjectsReducer = (state={projects : [], loading:false, errro:null}, action)=> {
        switch(action.type){
            case GET_PROEJCTS_REQ:
                return {loading :true, ...state}

            case GET_PROEJCTS_SUCC:
                return{
                    loading: false,
                    projects: action.payload
                }
            
            case GET_PROEJCTS_FAIL:
                return{
                    loading:false,
                    error:action.payload
                }
            
            default:
                return state;
        }
}
export const getAllProjectsReducer = (state={projects: null, loading:false, error:null, allProjectDetails:{}}, action) => {
    switch(action.type){
        case GET_ALL_PROJECTS_REQUEST:
            return{
                loading:true
            }

        case GET_ALL_PROJECTS_SUCC:
            return{
                loading:false,
                projects:action.payload,
                allProjectDetails:action.allProjectDetails
            }


        case GET_ALL_PROJECTS_FAIL:
        return{
            loading:false,
            error:action.payload
        }

        default:
            return state;
    }
}