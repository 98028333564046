import { GET_SINGLE_USER_FROM_API_FAIL, GET_SINGLE_USER_FROM_API_REQ, GET_SINGLE_USER_FROM_API_SUCC } from "../../constants/users/getSingleUser";

export const getSingleUserReducer = (state={user:{}, loading:true, error:false}, action) => {
    switch(action.type){
        case GET_SINGLE_USER_FROM_API_REQ:
            return{
                loading:true,
                ...state
            }

        case GET_SINGLE_USER_FROM_API_SUCC:
            return{
                loading:false,
                user:action.payload
            }

        case GET_SINGLE_USER_FROM_API_FAIL:
            return{
                loading:false,
                user:action.payload
            }


        default:
            return state;
    }
}