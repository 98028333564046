
import axios from "axios";
import { serialize } from "../../libs/serialize";
import { accessToken } from "../../libs/token";

export const getAllSupplortApi = async (page, limit, companyId, status, q, fromDate, toDate) => {
    const uri = serialize({page, limit, companyId, status, q, fromDate, toDate})
    return axios.get(`${process.env.REACT_APP_API_URL}/support?${uri}`, await accessToken());
};

export const createSupport = async (data) => {
    
    return axios.post(`${process.env.REACT_APP_API_URL}/support`, data, await accessToken());
}

export const getConversations = async(supportId, page, limit) => {
    const uri = serialize({page, limit, supportId})
    return axios.get(`${process.env.REACT_APP_API_URL}/supportconversation?${uri}`, await accessToken())
}

export const sendMessageApi = async(data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/supportconversation`, data,  await accessToken())

}

export const updateSupportTicket = async(id, data) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/support/${id}`, data, await accessToken());

}