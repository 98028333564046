// /* eslint-disable no-unused-vars */
import classNames from "classnames";
import {
  ArrowRight,
  Buildings,
  List,
  ListBullets,
  MagnifyingGlass,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openSingleAssetAdminAction } from "../../../../redux/actions/Admin/OpenSingleAssetAdmin";
import {
  getAssets,
  setAssetStatus,
  setAssetType,
  setCount,
} from "../../../../redux/actions/asset/assetAction";
import {
  ASSETS_LIST_VIEW_OPEN,
  ASSETS_MAP_VIEW_OPEN,
} from "../../../../redux/constants/AdminConsts/LeftNavAdminConst";
import { MapFilter } from "../../../../styles/MapFilter";
import Pagination from "./Pagination";
import Table from "./Table";

const ShowCaseAdminAssets = () => {
  const [assetOptions, setAssetOptions] = useState([]);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const { pagination, assets, count, loading, assetStatus, assetType } =
    useSelector((state) => state.assets);

  useEffect(() => {
    dispatch(
      getAssets({ limit: count, page: 1, assetType, assetStatus, search })
    );
  }, [assetType, assetStatus, count, search]);

  const dipstach = useDispatch();

  const navigate = useNavigate();

  return (
    <>
      <div className="bg-white mt-8 rounded-lg py-8 pb-16">
        <div className="flex  px-6 items-center justify-between">
          <div className="flex items-center">
            <div className="flex p-1 bg-white gap-1 right-56 shadow-xl rounded-full">
              <MapFilter
                active={true}
                onClick={() => dipstach({ type: ASSETS_LIST_VIEW_OPEN })}
                className="rounded-l-full"
              >
                <div
                  active
                  className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] py-2 px-4 rounded-l-full items-center gap-2"
                >
                  <p className="opacity-30">List</p>
                  <ListBullets size={12} className="opacity-30" weight="bold" />
                </div>
              </MapFilter>

              <MapFilter
                className="rounded-r-full"
                active={false}
                onClick={() => {
                  dipstach({ type: ASSETS_MAP_VIEW_OPEN });
                  navigate("mapview");
                }}
              >
                <div className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] rounded-r-full py-2 px-4 items-center gap-2">
                  <Buildings size={12} className="opacity-30" weight="bold" />
                  <p className="opacity-30">Map</p>
                </div>
              </MapFilter>
            </div>

            <div className="flex ml-6 text-xs font-semibold">
              <div className="px-3 border-r">
                <select
                  value={assetStatus}
                  onChange={(e) => dispatch(setAssetStatus(e.target.value))}
                  className="min-w-[98px] opacity-60 font-semibold  h-5 focus:outline-none"
                  placeholder="Asset Type"
                  name=""
                  id=""
                >
                  <option value="All">All</option>
                  <option value="New">New</option>
                  <option value="Ongoing">Ongoing</option>
                  <option value="Halted">Halted</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
              <div className="px-3 border-r">
                <select
                  value={assetType}
                  onChange={(e) => dispatch(setAssetType(e.target.value))}
                  className="min-w-[98px] opacity-60 font-semibold  h-5 focus:outline-none"
                  placeholder="Asset Type"
                  name=""
                  id=""
                >
                  <option value="All">All</option>
                  {assetOptions.map((item) => (
                    <option value={item?._id}>{item?.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* <button onClick={() => dipstach({type: ASSETS_CREATE_VIEW_OPEN})} className='px-5 uppercase border font-semibold border-theme-green hover:border-black text-xs py-2 rounded-md font-medium cursor-pointer select-none hover:bg-theme-green bg-theme-light-green text-theme-green hover:text-black'>create new asset</button> */}
        </div>

        {/* Input and display */}
        <div className="flex  px-6 justify-between items-center mt-12">
          <div className="relative text-very-tiny w-1/3">
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search for Asset ID, Alias or Location"
              className="w-full bg-[#f7f6f6] border-[1.5px] focus:outline-none rounded-md border-[rgba(0, 0, 0, 0.1)] py-2.5 px-4"
              type="text"
            />
            <MagnifyingGlass
              className="absolute right-6 opacity-20 top-1/2 -translate-y-1/2"
              size={14}
            />
          </div>
          <div className="flex text-very-tiny gap-4 items-center">
            {pagination && (
              <p>
                Displaying {(pagination?.page - 1) * count + 1} -{" "}
                {(pagination?.page - 1) * count + pagination?.docs?.length} of{" "}
                {pagination?.totalDocs}
              </p>
            )}
            <div className="py-1 bg-[#f7f6f6] rounded-md px-1.5 border border-[rgba(0, 0, 0, 0.1)]">
              <select
                onChange={(e) => {
                  dispatch(setCount(e.target.value));
                }}
                value={count}
                className="h-7 bg-[#f7f6f6] focus:outline-none"
                name=""
                id=""
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </select>
            </div>
          </div>
        </div>

        {/* Table */}

        <Table />
      </div>
      <Pagination />
    </>
  );
};

export default ShowCaseAdminAssets;
