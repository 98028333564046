import { Plus } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addDocuMentToASupplier } from "../../../../api/users/company";
import { getDocumentsOfASupplierAction } from "../../../../redux/actions/suppliers/getDocumentsOfaSupplier";
import { getProjectOfASupplierAction } from "../../../../redux/actions/suppliers/getProjectOfASupplier";
import { Filter } from "../../../../styles/FilterButton";
import AddProjectToAsset from "../../../components/Assets/comps/singleAssetComps/AddProjectToAsset";
import SingleDoc from "../../../components/Assets/comps/singleAssetComps/assetLinks/SingleDoc";
import SingleProject from "../../../components/Assets/comps/singleAssetComps/assetLinks/SingleProject";
import AddAssetToProject from "../../../components/Projects/comps/singleProjectComps/AddAssetsToProject";
import SingleAsset from "../../../components/Projects/comps/singleProjectComps/projectLinks/SingleAsset";
import AddDocument from "../../../components/Supplier/SingleSupplier/AddDocument";
import { Assests } from "../../../fake/fakeAssets";
import { FakeProjects } from "../../../fake/fakeProject";

const AssetsAndProjects = () => {
  const [active, setActive] = useState({
    project: false,
    doc: true,
    assets: false,
  });

  const { company } = useSelector((state) => state.singleCompanyAdmin);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProjectOfASupplierAction(company?._id));
    dispatch(getDocumentsOfASupplierAction(company?._id));
  }, [company]);

  const { projects } = useSelector((state) => state.getProjectsOfASupplier);
  const { documents } = useSelector((state) => state.getDocumentsOfASupplier);

  const [addNewProject, setAddNewProject] = useState(false);

  const addNewProjectToAsset = () => {
    setAddNewProject(true);
  };

  const [addNewAsset, setAddNewAsset] = useState(false);

  const addNewAssetTo = () => {
    setAddNewAsset(true);
  };

  const closeAddNewProjectTab = () => {
    setAddNewProject(false);
    setAddNewAsset(false);
  };

  // add document
  const [selecteddocsArr, setselecteddocsArr] = useState([]);

  const addDocument = async () => {
    try {
      const documentIds = selecteddocsArr.map((e) => {
        return e._id;
      });
      toast.loading("Adding Document to the company");
      await addDocuMentToASupplier({ documentIds, supplierId: company._id });
    } catch (err) {}
  };

  return (
    <>
      {!(addNewProject || addNewAsset) && (
        <div className=" bg-white flex-1 rounded-md">
          <div className="px-10 py-8 flex justify-center  bg-[#F7F6F6] border-b border-bottom-border rounded-t-md">
            <div className="flex gap-2 justify-between">
              <Filter
                onClick={() =>
                  setActive({ project: false, doc: true, assets: false })
                }
                active={active.doc}
              >
                Documents
              </Filter>
              <Filter
                onClick={() =>
                  setActive({ project: true, doc: false, assets: false })
                }
                active={active.project}
              >
                Projects
              </Filter>
            </div>
          </div>

          {active.doc && (
            <div>
              {documents?.map((d, index) => {
                return <SingleDoc key={index} d={d} />;
              })}
              <div className="px-6 flex w-full justify-end mt-6">
                {/* <Plus onClick={addNewAssetTo} className='bg-[#00000033] p-1 cursor-pointer text-white border border-transparent hover:bg-[#DDDDDD] hover:text-black hover:border-[#00000033] rounded' size={28} weight='light'/> */}
              </div>
            </div>
          )}

          {active.project && (
            <div>
              {projects?.map((p, index) => {
                return <SingleProject key={index} p={p} />;
              })}
              <div className="px-6 flex w-full justify-end mt-6">
                {/* <Plus onClick={addNewProjectToAsset} className='bg-[#00000033] p-1 cursor-pointer text-white border border-transparent hover:bg-[#DDDDDD] hover:text-black hover:border-[#00000033] rounded' size={28} weight='light'/> */}
              </div>
            </div>
          )}
        </div>
      )}
      {addNewProject && (
        <AddProjectToAsset
          purpose={"supplier"}
          closeAddNewProjectTab={closeAddNewProjectTab}
        />
      )}
      {addNewAsset && (
        <AddDocument
          selecteddocsArr={selecteddocsArr}
          setselecteddocsArr={setselecteddocsArr}
          purpose={"supplier"}
          closeAddNewDocTab={closeAddNewProjectTab}
        />
      )}
    </>
  );
};

export default AssetsAndProjects;
