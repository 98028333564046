
export const DASHBOARD_OPEN = "DASHBOARD_OPEN"

export const COMPANIES_LIST_VIEW_OPEN = "COMPANIES_LIST_VIEW_OPEN"
export const COMPANIES_INVITE_VIEW_OPEN = "COMPANIES_INVITE_VIEW_OPEN"
export const COMPANIES_ADD_COMPANY_VIEW_OPEN = "COMPANIES_ADD_COMPANY_VIEW_OPEN"

export const ASSETS_LIST_VIEW_OPEN = "ASSETS_LIST_VIEW_OPEN"
export const ASSETS_MAP_VIEW_OPEN = "ASSETS_MAP_VIEW_OPEN"
export const ASSETS_CREATE_VIEW_OPEN = "ASSETS_CREATE_VIEW_OPEN"

export const PROJECTS_LIST_OPEN = "PROJECTS_LIST_OPEN"
export const PROJECTS_MAP_OPEN = "PROJECTS_MAP_OPEN"
export const PROJECTS_CREATE_OPEN = "PROJECTS_CREATE_OPEN"

export const DOCUMENTS_LIST_OPEN = "DOCUMENTS_LIST_OPEN"
export const DOCUMENTS_MAP_OPEN = "DOCUMENTS_MAP_OPEN"
export const DOCUMENTS_CREATE_OPEN = "DOCUMENTS_CREATE_OPEN"

export const EMPLOYESS_LIST_OPEN = "EMPLOYESS_LIST_OPEN"
export const EMPLOYESS_ADD_OPEN = "EMPLOYESS_ADD_OPEN"

export const ISO_OPEN = "ISO_OPEN"

export const PROFILE_OPEN = "PROFILE_OPEN"

export const SETTINGS_OPEN = "SETTINGS_OPEN"

export const ADMIN_OPEN = "ADMIN_OPEN"

export const NOTIFICATIONS_OPEN = 'NOTIFICATIONS_OPEN'

export const SUPPORT_CHAT_USER_OPEN = 'SUPPORT_CHAT_USER_OPEN'
export const SUPPORT_CHAT_DEV_OPEN = 'SUPPORT_CHAT_DEV_OPEN'

export const ADVANCED_SEARCH_OPEN = 'ADVANCED_SEARCH_OPEN'
