/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable no-unused-vars */
import {
  ArrowRight,
  Buildings,
  FunnelSimple,
  ListBullets,
  MapTrifold,
  X,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { MapFilter } from "../../../../styles/MapFilter";
import MainMapAssets from "./Map";
import mapboxgl from "mapbox-gl";
import { useDispatch, useSelector } from "react-redux";
import { getAssetsProjectsApi } from "../../../../api/users/asset";
import { Filter } from "../../../../styles/FilterButton";
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MapView = ({ listViewFunc, mapViewFunc }) => {
  const [activeFilter, setActiveFilter] = useState({ map1: true, map2: false });
  const [vertical, setVertial] = useState(true);
  const [showFilters, setShowFilters] = useState(false);

  const [status, setStatus] = useState("");

  const { companyId } = useSelector((state) => state.selectCompany);

  const [assetRightDropDown, setAssetRightDropDown] = useState(false);
  const { asset: selectedAsset } = useSelector((state) => state.singleAsset);

  const [projectOfAAsset, setProjectOfAAsset] = useState();

  const fucn = async () => {
    try {
      const response = await getAssetsProjectsApi(selectedAsset._id);
      setProjectOfAAsset(response.data.docs);
    } catch (error) {}
  };

  useEffect(() => {
    fucn();
  }, [selectedAsset]);

  const navigate = useNavigate();

  return (
    <div>
      <div className="flex  absolute z-[9999]  left-10 top-10">
        <div className="flex p-1 items-center  gap-1 bg-white shadow-xl rounded-full">
          <MapFilter
            active={false}
            onClick={() => navigate(`/admin/assets`)}
            className="rounded-l-full"
          >
            <div
              active
              className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] py-2 px-4 rounded-l-full items-center gap-2"
            >
              <p className="opacity-30">List</p>
              <ListBullets size={12} className="opacity-30" weight="bold" />
            </div>
          </MapFilter>

          <MapFilter className="rounded-r-full" active={true}>
            <div className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] rounded-r-full py-2 px-4 items-center gap-2">
              <Buildings size={12} className="opacity-30" weight="bold" />
              <p className="opacity-30">Map</p>
            </div>
          </MapFilter>
        </div>
        <div className="flex relative ml-2">
          <div className="bg-white p-1 z-[999] shadow-lg rounded-full">
            <div
              onClick={() => setShowFilters(!showFilters)}
              className="bg-[#EBEBEB]  cursor-pointer py-2 px-[8px] h-full flex items-center justify-center  rounded-full"
            >
              {!showFilters ? (
                <FunnelSimple className="opacity-30" size={16} weight="bold" />
              ) : (
                <X className="opacity-30" size={16} weight="bold" />
              )}
            </div>
          </div>

          {showFilters && (
            <div className="absolute h-full shadow-lg rounded-full bg-white p-1 left-0">
              <div className="flex text-xs bg-[#F6F5F5] rounded-full pl-8  h-full font-semibold">
                <div className="px-3 border-r flex items-center">
                  <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="min-w-[98px] opacity-60 bg-[#F6F5F5] font-semibold  h-5 focus:outline-none"
                    placeholder="Asset Type"
                    name=""
                    id=""
                  >
                    <option value="">Asset Status</option>
                    <option value="New">New</option>
                    <option value="Ongoing">Ongoing</option>
                    <option value="Halted">Halted</option>
                    <option value="Completed">Completed</option>
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <MainMapAssets
        setAssetRightDropDown={setAssetRightDropDown}
        status={status}
        threeD={vertical}
        map={activeFilter}
      />

      <div className="flex absolute top-10 p-1 bg-white gap-1 right-10 shadow-xl rounded-full">
        <MapFilter
          active={activeFilter.map1}
          onClick={() => setActiveFilter({ map1: true, map2: false })}
          className="rounded-l-full"
        >
          <div
            active
            className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] py-2 px-4 rounded-l-full items-center gap-2"
          >
            <MapTrifold size={12} className="opacity-30" weight="bold" />
            <p className="opacity-30">Map 1</p>
          </div>
        </MapFilter>

        <MapFilter
          className="rounded-r-full"
          active={activeFilter.map2}
          onClick={() => setActiveFilter({ map1: false, map2: true })}
        >
          <div className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] rounded-r-full py-2 px-4 items-center gap-2">
            <MapTrifold size={12} className="opacity-30" weight="bold" />
            <p className="opacity-30">Map 2</p>
          </div>
        </MapFilter>
      </div>

      {/* <div className='flex absolute top-10 p-1 bg-white gap-1 right-56 shadow-xl rounded-full'>
        <MapFilter
          active={vertical}
          onClick={() => setVertial(true)}
          className='rounded-l-full'
        >
          <div active className='text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] py-2 px-4 rounded-l-full items-center gap-2'>
            <Buildings size={12} className='opacity-30' weight="bold" />
            <p className='opacity-30'>3D Asset</p>
          </div>
        </MapFilter>

        <MapFilter
          className='rounded-r-full'
          active={!vertical}
          onClick={() => setVertial(false)}
        >
          <div className='text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] rounded-r-full py-2 px-4 items-center gap-2'>
            <Buildings size={12} className='opacity-30' weight="bold" />
            <p className='opacity-30'>2D Boundary</p>
          </div>
        </MapFilter>
      </div> */}

      {assetRightDropDown && selectedAsset && (
        <>
          <div className="fixed text-xs flex z-[99999999] top-14 py-3 flex-col right-0 w-72 h-full z-50 bg-[#EFEBE9] shadow-md">
            <div className="flex px-3 justify-between">
              <button
                onClick={() => setAssetRightDropDown(false)}
                className="px-3 py-1.5 border bg-[#F7F6F6] rounded flex items-center gap-2"
              >
                <X size={12} /> close
              </button>
              <button
                onClick={() => {
                  setAssetRightDropDown(false);
                  navigate(`/admin/assets/${selectedAsset._id}`);
                  listViewFunc();
                }}
                className="px-3 py-1.5 border bg-[#F7F6F6] rounded flex items-center gap-2"
              >
                {" "}
                view more details <ArrowRight size={12} />
              </button>
            </div>
            <div>
              <div className="grid gap-3">
                <div className="bg-[#F7F6F6] mx-3 mt-3 py-4 flex flex-col gap-2 p-4  rounded-md items-center">
                  <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                    Asset Description
                  </p>
                  <div className="flex justify-between text-start w-full">
                    <div>
                      <p className="text-[10px] font-semibold">
                        {selectedAsset.name}
                      </p>
                      <p className="text-[10px] ">
                        Alias : {selectedAsset.alias}
                      </p>
                    </div>
                    <p className="text-[10px] font-medium">
                      ID: {selectedAsset.uid}
                    </p>
                  </div>

                  <div className="flex justify-between text-start w-full">
                    <div>
                      <p className="text-[10px] ">Status :</p>
                    </div>
                    <p className="text-[10px] font-medium">
                      {selectedAsset.status}
                    </p>
                  </div>
                </div>

                <div className="bg-[#F7F6F6] mx-3 text-center py-8 flex flex-col gap-2  rounded-md items-center">
                  <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                    Asset Type Description
                  </p>
                  <p className="text-[10px] font-semibold">
                    {selectedAsset?.assetType?.name}
                  </p>
                </div>

                <div className="grid grid-cols-2 px-3 gap-3">
                  <div className="bg-[#F7F6F6] text-center py-6 flex flex-col gap-2  rounded-md items-center">
                    <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                      Asset Type Code
                    </p>
                    <p className="text-[10px] font-semibold">
                      {selectedAsset?.assetType?.id}
                    </p>
                  </div>

                  {selectedAsset?.details?.map((item) => (
                    <div className="bg-[#F7F6F6] text-center py-6 flex flex-col gap-2  rounded-md items-center">
                      <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                        {item?.displayName}
                      </p>
                      <p className="text-[10px] font-semibold">{item?.value}</p>
                    </div>
                  ))}
                </div>

                <div className="px-4 py-2 border-t border-t-4  bg-[#F7F6F6] border-bottom-border ">
                  <div className="flex gap-2 justify-between">
                    <Filter active={true}>Projects</Filter>
                    {/* <Filter onClick={() => setActive({project: false, doc:true, comp:false})} active={active.doc}>Documents</Filter>
                    <Filter onClick={() => setActive({project: false, doc:false, comp:true})} active={active.comp}>Companies</Filter> */}
                  </div>

                  <div className=" overflow-y-auto pb-6">
                    {projectOfAAsset?.map((p, index) => {
                      return (
                        <div
                          key={index}
                          className="flex border-b border-bottom-border py-3"
                        >
                          <div className="w-1/3 font-semibold ">
                            <p className="text-[10px] ">ID</p>
                            <p className="text-[9px] opacity-50">{p.uid}</p>
                          </div>

                          <div className="w-1/2 ">
                            <p className="text-[10px] font-semibold ">
                              {p?.name}
                            </p>
                            <p className="text-[9px] opacity-50 font-medium">
                              {p?.projectDetails?.address}
                            </p>
                          </div>

                          <div className="w-1/6  font-semibold ">
                            <p className="text-[10px]">Status</p>
                            <p className="text-[9px] opacity-50 capitalize">
                              {p?.status}
                            </p>
                          </div>

                          <div className="text-[10px] flex flex-1 justify-end font-medium">
                            <ArrowRight
                              className="bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
                              size={28}
                              weight="light"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MapView;
