import { GET_ALL_USERS,GET_ALL_USERS_FAIL,GET_ALL_USERS_REQ } from "../../constants/users/Users"

export const UsersReducer = (state={users : [], loading:false,   error:null  }, action)=> {
    switch(action.type){
        case GET_ALL_USERS_REQ:
            return {loading :true, ...state}
        case GET_ALL_USERS_FAIL:
            return {error :action.payload, ...state}

        case GET_ALL_USERS:
            return{
                loading: false,
                users: action.payload, 
                error:null
            }
        default:
            return state;
    }
}