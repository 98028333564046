import { getSingleDocumnet } from "../../../api/users/document";
import { documents } from "../../../app/fake/faleDoc";
import { GET_SINGLE_DOCUMENT_FAI_ADMINL, GET_SINGLE_DOCUMENT_REQ_ADMIN, GET_SINGLE_DOCUMENT_SUCC_ADMIN } from "../../constants/AdminConsts/SingleDocumentOpenerConst";

export const openSingleDocumentAdminAction = (id) => async(dispatch) => {
    try{
        dispatch({type: GET_SINGLE_DOCUMENT_REQ_ADMIN})


        const res = await getSingleDocumnet(id)


        dispatch({
                type: GET_SINGLE_DOCUMENT_SUCC_ADMIN,
                payload:res.data
            })
    } catch(error){
        dispatch({
            type: GET_SINGLE_DOCUMENT_FAI_ADMINL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message 
        })
    }
}

