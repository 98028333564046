import axios from "axios";

import { serialize } from "../../libs/serialize";
import { accessToken } from "../../libs/token";

export const getRecentActivity = async (
  page,
  limit,
  companyId,
  fromDate,
  toDate
) => {
  const uri = serialize({ page, limit, companyId, fromDate, toDate });

  return axios.get(
    `${process.env.REACT_APP_API_URL}/activity?${uri}`,
    await accessToken()
  );
};
