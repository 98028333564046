import { getAllVersions } from "../../../api/users/document"
import { GET_ALL_VERSION_FAILED, GET_ALL_VERSION_REQ, GET_ALL_VERSION_SUCC } from "../../constants/documents/getAllVersions"

export const getAllDocsVersions = ({documentId}) => async(dispatch) => {
    try{
        dispatch({type:GET_ALL_VERSION_REQ})
        const res = await getAllVersions(documentId)

        dispatch({
            type:GET_ALL_VERSION_SUCC,
            payload:res.data,

        })

    } catch(error){
        dispatch({
            type: GET_ALL_VERSION_FAILED,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}