import { GET_PROJECTS_OF_A_SUPPLIER_FAIL, GET_PROJECTS_OF_A_SUPPLIER_REQ, GET_PROJECTS_OF_A_SUPPLIER_SUCC } from "../../constants/suppliers/getProjectOfASupplier";


export const getProjectsOfASupplierReducer = (state={projects :[], loading:true, error:null}, action)=> {
        switch(action.type){
            case GET_PROJECTS_OF_A_SUPPLIER_REQ:
                return {loading :true, ...state}

            case GET_PROJECTS_OF_A_SUPPLIER_SUCC:
                return{
                    loading: false,
                    projects: action.payload,
                }
            
            case GET_PROJECTS_OF_A_SUPPLIER_FAIL:
                return{
                    loading:false,
                    error:action.payload
                }
            
            default:
                return state;
        }
}