/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'
import AddSubscriptionPlan from './comps/AddSubscriptionPlan'
import AccountDetails from './showcaseComps/AccountDetails'
import ProfileSettingsTransaction from './showcaseComps/ProfileSettingsTransaction'

const ShowCase = () => {

    const {openAddNewSubscription} = useSelector(state => state.addNewSubscription)

  return (
    <>
        {!openAddNewSubscription && <div className='py-2 pl-2 bg-white mt-8 rounded-lg flex h-full'>
            <div className='w-1/3 px-4 bg-[#EFEBE9] h-full rounded-lg'>
                <AccountDetails/>
            </div>

            <div className='flex-1'>
                <ProfileSettingsTransaction/>
            </div>
            
        </div>}
        {
          openAddNewSubscription && <AddSubscriptionPlan/>
        }
    </>
  )
}

export default ShowCase