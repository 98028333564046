import { GET_LOGGEDIN_USER,GET_LOGGEDIN_USER_FAIL,GET_LOGGEDIN_USER_REQ,GET_LOGGEDIN_USER_COMPANY,GET_LOGGEDIN_USER_COMPANY_FAIL,GET_LOGGEDIN_USER_COMPANY_REQ } from "../../constants/users/Users"

export const LoggedInUserReducer = (state={company:{},user : {}, loading:false,   error:null  }, action)=> {
    switch(action.type){
        case GET_LOGGEDIN_USER_REQ:
            return { ...state,loading :true}
        case GET_LOGGEDIN_USER_FAIL:
            return { ...state,error :action.payload}

        case GET_LOGGEDIN_USER:
           
            return{
                ...state,
                loading: false,
                user: action.payload, 
                error:null,
                
            }
            case GET_LOGGEDIN_USER_COMPANY_REQ:
                return { ...state,loading :true,}
            case GET_LOGGEDIN_USER_COMPANY_FAIL:
                return {...state,error:action.payload}
    
            case GET_LOGGEDIN_USER_COMPANY:
                return{
                    ...state,
                    loading: false,
                    company: action.payload, 
                    error:null,
                    
                }
        default:
            return state;
    }
}