import classNames from "classnames";
import { ArrowRight } from "phosphor-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSingleEmployeeAdminAction } from "../../../../redux/actions/Admin/OpenSingleCompanyAdmin";
import ProjectsDropDown from "../../../components/Assets/comps/ProjectsDropDown";
import AssetsDropDown from "../../../components/Projects/comps/AssetsDropDown";
import DocumentsDropDown from "../../../components/Projects/comps/DocumentsDropDown";
import { Assests } from "../../../fake/fakeAssets";
import { getProjectOfASupplierAction } from "../../../../redux/actions/suppliers/getProjectOfASupplier";
import { getDocumentsOfASupplierAction } from "../../../../redux/actions/suppliers/getDocumentsOfaSupplier";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/loading/Loading";

const Table = () => {
  const [assetsDropDown, setassetsDropDown] = useState(false);
  const [documentsDropDown, setdocumentsDropDown] = useState(false);
  const [projectDropDown, setsprojectDropDown] = useState(false);

  const [clickedId, setClickedId] = useState(null);

  const dispatch = useDispatch();

  const {
    suppliers: companies,
    pagination,
    loading,
  } = useSelector((state) => state.suppliers);

  const navigate = useNavigate();

  const assetDropDonwOPener = (s) => {
    setClickedId(s._id);
    setassetsDropDown(s._id === clickedId ? !assetsDropDown : true);
    setdocumentsDropDown(false);
    setsprojectDropDown(false);
  };

  const documentsDropDownOpener = (s) => {
    setClickedId(s._id);
    setdocumentsDropDown(s._id === clickedId ? !documentsDropDown : true);
    setsprojectDropDown(false);
    setassetsDropDown(false);
    dispatch(getDocumentsOfASupplierAction(s._id));
  };

  const projectsDropDownOpener = (p) => {
    setClickedId(p._id);
    setdocumentsDropDown(false);
    setassetsDropDown(false);
    setsprojectDropDown(p._id === clickedId ? !projectDropDown : true);
    dispatch(getProjectOfASupplierAction(p._id));
  };

  const openSingleSupplier = (p) => {
    dispatch(openSingleEmployeeAdminAction(p._id, companies));
    navigate(`${p._id}`);
  };

  const { projects } = useSelector((state) => state.getProjectsOfASupplier);
  const { documents } = useSelector((state) => state.getDocumentsOfASupplier);

  return (
    <>
      <div className="flex  text-xs font-semibold px-6 mt-6 border-bottom-border border-b">
        <div className="w-[12%] opacity-50 py-6">UID</div>
        <div className="w-[32%] opacity-50 py-6">Company Details</div>
        <div className="w-[18%] opacity-50 py-6">Company Domain</div>
        {/* <div className='w-[12%] opacity-50 py-6'>Assets</div> */}
        <div className="w-[16%] opacity-50 py-6">Projects</div>
        <div className="w-[16%] opacity-50 py-6">Documents</div>
        <div className=" opacity-50 py-6 text-right flex-1">View</div>
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        companies?.map((p, index) => {
          return (
            <div key={index}>
              <div
                className={classNames(
                  "flex items-center py-4 px-6",
                  (assetsDropDown || documentsDropDown || projectDropDown) &&
                    clickedId === p._id
                    ? "bg-theme-light-green border-b border-t"
                    : "border-b border-bottom-border",
                  ""
                )}
              >
                <div className="w-[12%] text-xs font-medium">
                  <p className="uppercase">id</p>
                  <p className="opacity-50">{p?.uid}</p>
                </div>

                <div className="flex w-[32%] items-center text-xs gap-2 pr-2">
                  <div className="text-xs font-medium">
                    <div className="flex gap-2 items-center">
                      <div>
                        {p.profilePic ? (
                          <img
                            src={`${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${p.profilePic}`}
                            className="w-9 p-[1px] border border-theme-green rounded-full"
                            alt=""
                          />
                        ) : (
                          <p className="w-9 h-9 tracking-wide flex justify-center items-center text-white font-bold text-[18px] bg-theme-green p-0.5 border border-theme-green rounded-full">
                            {p.name.split(" ")[0][0]}
                            {p.name.split(" ")[1]
                              ? p.name.split(" ")[1][0]
                              : ""}
                          </p>
                        )}
                      </div>
                      <div>
                        <p className="font-medium ">{p?.name}</p>
                        {p?.companyAdmin && (
                          <p className="opacity-60">
                            {p?.companyAdmin}(company admin)
                          </p>
                        )}
                        <p className=" font-normal  opacity-50">
                          {p?.address?.street1 ? p?.address?.street1 + "," : ""}{" "}
                          {p?.address?.street2 ? p?.address?.street2 + "," : ""}{" "}
                          {p?.address?.city ? p?.address?.city + "," : ""}{" "}
                          {p?.address?.state ? p?.address?.state + "," : ""}
                          {p?.address?.country ? p?.address?.country : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-[18%] text-xs gap-2 pr-2">
                  <p className="font-medium">Domain</p>
                  <p className="text-theme-black opacity-50 font-medium break-words">
                    {p?.domain}
                  </p>
                </div>

                <div className=" w-[16%] text-xs">
                  <div className="">
                    <p className="leading-3">Projects</p>
                    <p
                      onClick={() => projectsDropDownOpener(p)}
                      className="text-theme-black opacity-50 cursor-pointer leading-3"
                    >
                      {p.stats.projects}{" "}
                      <i
                        className={classNames(
                          "fa-solid ml-1 ",
                          projectDropDown && clickedId === p._id
                            ? "fa-angle-up"
                            : "fa-angle-down"
                        )}
                      ></i>
                    </p>
                  </div>
                </div>

                <div className=" w-[16%] text-xs">
                  <div className="">
                    <p className="leading-3">Documents</p>
                    <p
                      onClick={() => documentsDropDownOpener(p)}
                      className="text-theme-black opacity-50 cursor-pointer leading-3"
                    >
                      {p.stats.documents}{" "}
                      <i
                        className={classNames(
                          "fa-solid ml-1 ",
                          documentsDropDown && clickedId === p._id
                            ? "fa-angle-up"
                            : "fa-angle-down"
                        )}
                      ></i>
                    </p>
                  </div>
                </div>

                <div className="text-xs flex flex-1 justify-end font-medium">
                  <ArrowRight
                    onClick={() => openSingleSupplier(p)}
                    className="bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
                    size={28}
                    weight="light"
                  />
                </div>
              </div>

              {assetsDropDown && clickedId === p._id && (
                <div className="h-60 overflow-y-auto">
                  {Assests.map((asset, index) => {
                    return <AssetsDropDown asset={asset} key={index} />;
                  })}
                </div>
              )}

              {projectDropDown && clickedId === p._id && (
                <div className="max-h-60 overflow-y-auto">
                  {projects?.map((project, index) => {
                    return <ProjectsDropDown project={project} key={index} />;
                  })}
                </div>
              )}

              {documentsDropDown && clickedId === p._id && (
                <div className="max-h-60 overflow-y-auto">
                  {documents?.map((s, index) => {
                    return <DocumentsDropDown s={s} key={index} />;
                  })}
                </div>
              )}
            </div>
          );
        })
      )}
    </>
  );
};

export default Table;
