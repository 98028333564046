import classNames from 'classnames'
import { CaretRight } from 'phosphor-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const TopLinks = () => {
  const nvaigate = useNavigate()
  return (
    <>
        <div className='flex items-center'>
            <p onClick={() => nvaigate('/admin')} className='text-link-green text-very-tiny font-medium cursor-pointer'>Dashboard</p>
            <p className={classNames('text-very-tiny font-medium flex items-center',)}><CaretRight color='#000' className='mx-1.5' size={10}  /> <span className={classNames('cursor-pointer', )}>Advanced Search</span> </p>
        </div>
    </>
  )
}

export default TopLinks