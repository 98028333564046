/* eslint-disable react-hooks/exhaustive-deps */
/* global window */
import React, { useState, useEffect } from "react";
import { render } from "react-dom";
import { Map, Marker, NavigationControl } from "react-map-gl";
import { AmbientLight, PointLight, LightingEffect } from "@deck.gl/core";
import { PolygonLayer } from "@deck.gl/layers";
import { useDispatch, useSelector } from "react-redux";
import { getLocationsOfDocuments } from "../../../../api/users/document";
import { File } from "phosphor-react";
import { openSingleDocumentAction } from "../../../../redux/actions/documents/SingleDocumentAction";
// Source data CSV
const DATA_URL = {
  BUILDINGS:
    "https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/trips/buildings.json", // eslint-disable-line
  // TRIPS: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/trips/trips-v7.json' // eslint-disable-line
};

const ambientLight = new AmbientLight({
  color: [0, 132, 255],
  intensity: 1.0,
});

const pointLight = new PointLight({
  color: [0, 132, 255],
  intensity: 2.0,
  position: [-74.05, 40.7, 8000],
});

const lightingEffect = new LightingEffect({ ambientLight, pointLight });

const material = {
  ambient: 0.1,
  diffuse: 0.6,
  shininess: 32,
  specularColor: [60, 64, 70],
};

const DEFAULT_THEME = {
  buildingColor: [0, 94, 255],
  material,
  effects: [lightingEffect],
};

const INITIAL_VIEW_STATE = {
  longitude: -74.0131,
  latitude: 40.70479,
  zoom: 16,
  pitch: 45,
  bearing: 0,
};

const MAP_STYLE1 = "mapbox://styles/mapbox/streets-v11";
const MAP_STYLE2 = "mapbox://styles/mapbox/light-v10";

const landCover = [
  [
    [-74.0, 40.7],
    [-74.02, 40.7],
    [-74.02, 40.72],
    [-74.0, 40.72],
  ],
];

export default function MainMapDocuments({
  buildings = DATA_URL.BUILDINGS,
  initialViewState = INITIAL_VIEW_STATE,
  theme = DEFAULT_THEME,
  loopLength = 1800, // unit corresponds to the timestamp in source data
  animationSpeed = 1,
  map,
  threeD,
  status,
  setDocumentRightDropDown,
}) {
  const [time, setTime] = useState(0);
  const [animation] = useState({});
  const animate = () => {
    setTime((t) => (t + animationSpeed) % loopLength);
    animation.id = window.requestAnimationFrame(animate);
  };

  useEffect(() => {
    animation.id = window.requestAnimationFrame(animate);
    return () => window.cancelAnimationFrame(animation.id);
  }, [animation]);

  const layers = [
    // This is only needed when using shadow effects
    new PolygonLayer({
      id: "ground",
      data: landCover,
      getPolygon: (f) => f,
      stroked: false,
      getFillColor: [0, 0, 0, 0],
    }),
    new PolygonLayer({
      id: "buildings",
      data: buildings,
      extruded: true,
      wireframe: false,
      stroked: true,
      opacity: 0.6,
      getPolygon: (f) => f.polygon,
      // getElevation: f => 0,
      getElevation: 90,
      getFillColor: [107, 112, 120],
      material: theme.material,
    }),
  ];

  const layers2 = [
    // This is only needed when using shadow effects
    new PolygonLayer({
      id: "ground",
      data: landCover,
      getPolygon: (f) => f,
      stroked: false,
      getFillColor: [0, 0, 0, 0],
    }),
    new PolygonLayer({
      id: "buildings",
      data: buildings,
      extruded: true,
      wireframe: false,
      stroked: true,
      opacity: 1,
      getPolygon: (f) => f.polygon,
      getElevation: 0,
      getFillColor: theme.buildingColor,
      material: theme.material,
    }),
  ];

  // integration

  const mapRef = React.useRef();
  const [locationss, setLocationss] = useState([]);
  const { user } = useSelector((state) => state.me);
  const { docs, loading } = useSelector((state) => state.getAllDoc);

  const dispatch = useDispatch();

  const [INITIAL_VIEW_STATE, setINITIAL_VIEW_STATE] = useState();

  useEffect(() => {
    if (docs) {
      if (docs.length !== 0) {
        setINITIAL_VIEW_STATE({
          // latitude:  docs[0].location.coordinates[1],
          // longitude:  docs[0].location.coordinates[0],
          latitude: 51.507322,
          longitude: -0.127647,
          zoom: 8,
          pitch: 45,
          bearing: 0,
        });
      } else {
        setINITIAL_VIEW_STATE({
          latitude: 51.507322,
          longitude: -0.127647,
          zoom: 10,
          pitch: 45,
          bearing: 0,
        });
      }
    }
  }, [docs]);

  useEffect(() => {
    const func = async () => {
      const res = await getLocationsOfDocuments(
        INITIAL_VIEW_STATE.longitude,
        INITIAL_VIEW_STATE.latitude,
        5000000000,
        status,
        ""
      );
      setLocationss(res.data);
      // mapRef.current.on('move', () => {
      //
      // });
    };
    if (INITIAL_VIEW_STATE) func();
  }, [status, INITIAL_VIEW_STATE]);

  return (
    // <DeckGL
    //   layers={threeD ? layers : layers2}
    //   effects={theme.effects}
    //   initialViewState={initialViewState}
    //   controller={true}
    // >
    //   <Map reuseMaps mapboxAccessToken='pk.eyJ1Ijoic2FuZHktNzciLCJhIjoiY2wwajI3MmNwMDFsbjNlcnY0bjJtdXJtdCJ9.yDqEh7ba7bWcDlSn3Dx87Q' mapStyle={map.map1? MAP_STYLE1:MAP_STYLE2} />
    // </DeckGL>
    <>
      {INITIAL_VIEW_STATE ? (
        <Map
          initialViewState={INITIAL_VIEW_STATE}
          style={{ height: "calc(100vh - 57px)" }}
          ref={mapRef}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          mapStyle={map.map1 ? MAP_STYLE1 : MAP_STYLE2}
        >
          {locationss?.map((city, index) => (
            <Marker
              key={`marker-${index}`}
              longitude={city.location.coordinates[0]}
              latitude={city.location.coordinates[1]}
              anchor="bottom"
              onClick={(e) => {
                // If we let the click event propagates to the map, it will immediately close the popup
                e.originalEvent.stopPropagation();
                // setPopupInfo(city);
                setDocumentRightDropDown(true);
                dispatch(openSingleDocumentAction(city._id));
              }}
            >
              <div className="p-2 rounded-full bg-theme-black ">
                <File size={22} className=" text-white" weight="bold" />
              </div>
            </Marker>
          ))}

          <NavigationControl position="bottom-right" />
        </Map>
      ) : (
        <></>
      )}
    </>
  );
}

export function renderToDOM(container) {
  render(<MainMapDocuments />, container);
}
