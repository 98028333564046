import { GET_RECENT_ACTIVITY_FAILED, GET_RECENT_ACTIVITY_REQ, GET_RECENT_ACTIVITY_SUCC } from "../constants/getRecentActivityConsts";

export const getRecentActivityReducer = (state={activities :[],allData:null, loading:true, error:null}, action)=> {
    switch(action.type){
        case GET_RECENT_ACTIVITY_REQ:
            return {loading :true, ...state}

        case GET_RECENT_ACTIVITY_SUCC:
            return{
                loading: false,
                activities: action.payload,
                allData:action.allData
            }
        
        case GET_RECENT_ACTIVITY_FAILED:
            return{
                loading:false,
                error:action.payload
            }
        
        default:
            return state;
    }
}