import classNames from "classnames";
import { Plus } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import Role from "./Role";
import SelectComp from "./SlectCompUserScren";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  getAdminPermissions,
  getUserPermissions,
  inviteUser,
  setUserPermissions,
} from "../../../../api/users/inviteUsers";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies } from "../../../../redux/actions/companies/getCompanies";
import { Country, State, City } from "country-state-city";
import { upload } from "../../../../api/users/fileUpload";
import { addCompanyToUser } from "../../../../api/users/users";
import { EMPLOYESS_LIST_OPEN } from "../../../../redux/constants/AdminConsts/LeftNavAdminConst";
import { useNavigate } from "react-router-dom";
const validate = (values) => {
  let error = {};

  if (!values.firstName) {
    error.firstName = "enter your first name ";
  }
  if (!values.lastName) {
    error.lastName = "enter your last name ";
  }
  if (!values.email) {
    error.email = "enter your first name ";
  }
  // if (!values.phone) {
  //   error.phone = "enter your phone number ";
  // }
  // if (!values.address.street1) {
  //   error.street1 = "enter street1 ";
  // }
  // if (!values.address.city) {
  //   error.city = "select your city ";
  // }
  // if (!values.address.state) {
  //   error.state = "select your state ";
  // }
  // if (!values.address.country) {
  //   error.country = "select your country  ";
  // }
  // if (!values.address.postCode) {
  //   error.country = "enter your postcode  ";
  // }
  // if (!values.firstName){
  //     error.firstName="enter your first name "
  // }
  //   if (!values.firstName){
  //     error.firstName="enter your first name "
  // }

  return error;
};
const AddNewEmployee = () => {
  // upload Image
  const [permissionsAdmin, setPermissionsAdmin] = useState([]);
  const [selectedPermissionsAdmin, setselectedPermissionsAdmin] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setselectedPermissions] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const { user } = useSelector((state) => state.me);
  const companyId = user?.companyId?._id;
  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      roleId: "",
      companyId: companyId,
      profilePic: "",
      phone: "",
      address: {
        street1: "",
        street2: "",
        city: "",
        state: "",
        country: "",
      },
      location: {},
    },
    validate,
    onSubmit: async (values) => {
      toast.loading("creating user ");

      try {
        const res = await inviteUser(values);

        const permissions = selectedPermissions.map((val) => {
          return val.value;
        });
        if (values.roleId === "627955212019dc8e78e71ba6") {
          let data2 = {
            userId: res.data._id,
            permissions: [...permissions],
          };

          await setUserPermissions(data2);
        } else {
          const permissions1 = selectedPermissionsAdmin.map((val) => {
            return val.value;
          });
          let data3 = {
            userId: res.data._id,
            permissions: [...permissions1],
          };

          await setUserPermissions(data3);
        }
        toast.dismiss();
        toast.success("user invited successfully");
        formik.resetForm();
      } catch (error) {
        toast.dismiss();
        toast.error(error?.response?.data?.message);
      }
    },
  });

  // for selecting companies

  const [selectedSuppliersArr, setselectedSuppliersArr] = useState([]);

  // adding all companies or selecting companies

  const [value, setValue] = useState("all_company");
  const changeHandler = (e) => {
    setValue(e.target.value);
  };

  const { companies } = useSelector((state) => state.compaines);

  const navigate = useNavigate();
  const inviteUserHandler = async () => {
    try {
      toast.loading("creating user ");

      let finalObject = {};

      Object.entries(formik.values).forEach(([key, value]) => {
        if (value !== "") {
          finalObject[key] = value;
        }
      });

      let res = await inviteUser(finalObject);

      const permissions = selectedPermissions.map((val) => {
        return val.value;
      });
      if (formik.values.roleId === "627955212019dc8e78e71ba6") {
        let data2 = {
          userId: res.data._id,
          permissions: [...permissions],
        };

        await setUserPermissions(data2);
      } else {
        const permissions1 = selectedPermissionsAdmin.map((val) => {
          return val.value;
        });
        let data3 = {
          userId: res.data._id,
          permissions: [...permissions1],
        };

        const compAccessIds = selectedSuppliersArr.map((s) => {
          return s._id;
        });

        let compAccessData = {
          userId: res.data._id,
          companyIds: [...compAccessIds],
        };

        await setUserPermissions(data3);

        if (value !== "all_company") {
          await addCompanyToUser(compAccessData);
        }

        const allCompanyIds = companies?.map((s) => {
          return s._id;
        });

        let allCompanyIdsData = {
          userId: res.data._id,
          companyIds: [...allCompanyIds],
        };

        if (value === "all_company") {
          await addCompanyToUser(allCompanyIdsData);
        }
      }
      toast.dismiss();
      toast.success("user invited successfully");
      dispatch({ type: EMPLOYESS_LIST_OPEN });
      navigate(`/admin/employees`);
      formik.resetForm();
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const getPermissins = async () => {
      try {
        dispatch(getCompanies());
        const res = await getAdminPermissions();

        const filterd = res.data.map((item) => {
          return {
            value: item._id,
            label: item.name,
          };
        });

        setPermissions([...filterd]);
        const res2 = await getUserPermissions();

        const filterd2 = res2.data.map((item) => {
          return {
            value: item._id,
            label: item.name,
          };
        });
        setPermissionsAdmin([...filterd2]);
      } catch (err) {
        toast.error(err.response.data?.message);
      }
    };

    getPermissins();
  }, []);

  useEffect(() => {
    const call = async () => {
      if (!selectedFile) {
        setPreview(undefined);
        return;
      }
      const url = await upload(selectedFile);

      formik.values.profilePic = url;
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
    };
    call();
  }, [selectedFile]);

  const uploadImg = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  // state country

  const stateOptions = [
    { label: "England", value: "ENG" },
    { label: "Scotland", value: "SCT" },
    { label: "Wales", value: "WLS" },
    { label: "Northern Ireland", value: "NIR" },
  ];

  const [selectedState, setSelectedState] = useState();
  const cities = City.getCitiesOfState("GB", selectedState);
  let citiesOptions = cities.map((s) => {
    return { value: s.name, label: s.name };
  });

  return (
    <>
      <div className="grid grid-cols-2 mt-10 gap-4">
        <div className="bg-white text-theme-black rounded-lg p-6">
          {/* profile pic */}
          <div className="text-sm ">
            <p className="font-medium">Profile Picture</p>
            <input
              accept=".png,.jpg,.jpeg,.svg"
              onChange={uploadImg}
              type="file"
              id="fileUp"
              style={{ display: "none" }}
            />
            <div className="flex">
              <label className="cursor-pointer" htmlFor="fileUp">
                <div className="mt-4 w-14 h-14 p-[1px] border border-theme-green rounded-full">
                  {preview ? (
                    <img
                      src={preview}
                      className="h-full w-full rounded-full object-cover"
                      alt=""
                    />
                  ) : (
                    <div className="w-full rounded-full h-full bg-[#3231320D] flex justify-center items-center">
                      <Plus size={12} />
                    </div>
                  )}
                </div>
              </label>
            </div>
          </div>

          {/* name adn all */}
          <div className="grid grid-cols-2 pt-7 gap-x-4 gap-y-8">
            <div className="grid gap-2">
              <p className="font-medium text-sm">First Name</p>
              <input
                type="text"
                value={formik.values.firstName}
                name={"firstName"}
                onChange={formik.handleChange}
                placeholder="Enter user's first name"
                className={classNames(
                  "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                  "bg-[#0000000D]"
                )}
              />
              {formik.errors.firstName && (
                <span className="text-xs text-red-600">
                  {formik.errors.firstName}{" "}
                </span>
              )}
            </div>

            <div className="grid gap-2">
              <p className="font-medium text-sm"> Last Name</p>
              <input
                type="text"
                value={formik.values.lastName}
                name={"lastName"}
                onChange={formik.handleChange}
                placeholder="Enter user's last name"
                className={classNames(
                  "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                  "bg-[#0000000D]"
                )}
              />
              {formik.errors.lastName && (
                <span className="text-xs text-red-600">
                  {formik.errors.lastName}{" "}
                </span>
              )}
            </div>

            <div className="grid gap-2">
              <p className="font-medium text-sm">Email</p>
              <input
                type="text"
                value={formik.values.email}
                name={"email"}
                onChange={formik.handleChange}
                placeholder="Enter user's email"
                className={classNames(
                  "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                  "bg-[#0000000D]"
                )}
              />
              {formik.errors.email && (
                <span className="text-xs text-red-600">
                  {formik.errors.email}{" "}
                </span>
              )}
            </div>

            <div className="grid gap-2">
              <p className="font-medium text-sm">Mobile Number</p>
              <input
                type="text"
                value={formik.values.phone}
                name={"phone"}
                onChange={formik.handleChange}
                placeholder="Enter user's mobile number"
                className={classNames(
                  "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                  "bg-[#0000000D]"
                )}
              />
              {formik.errors.phone && (
                <span className="text-xs text-red-600">
                  {formik.errors.phone}{" "}
                </span>
              )}
            </div>

            <div className="grid gap-2">
              <p className="font-medium text-sm">Address Line 1</p>
              <input
                type="text"
                value={formik.values.address.street1}
                name={"address.street1"}
                onChange={formik.handleChange}
                placeholder="Enter user's address line 1"
                className={classNames(
                  "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                  "bg-[#0000000D]"
                )}
              />
              {formik.errors.street1 && (
                <span className="text-xs text-red-600">
                  {formik.errors.street1}{" "}
                </span>
              )}
            </div>

            <div className="grid gap-2">
              <p className="font-medium text-sm">Address Line 2</p>
              <input
                type="text"
                value={formik.values.address.street2}
                name={"address.street2"}
                onChange={formik.handleChange}
                placeholder="Enter user's address line 2"
                className={classNames(
                  "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                  "bg-[#0000000D]"
                )}
              />
            </div>

            <div className="grid gap-2">
              <p className="font-medium text-sm">State</p>
              <SelectComp
                onChange={(val) => {
                  formik.setFieldValue("address.state", val.label);
                  setSelectedState(val.value);
                }}
                value={selectedState}
                options={stateOptions}
              />
              {formik.errors.state && (
                <span className="text-xs text-red-600">
                  {formik.errors.state}{" "}
                </span>
              )}
            </div>

            <div className="grid gap-2">
              <p className="font-medium text-sm">City</p>
              <SelectComp
                onChange={(val) =>
                  formik.setFieldValue("address.city", val.value)
                }
                value={formik.values.address.city}
                options={citiesOptions}
              />
              {formik.errors.city && (
                <span className="text-xs text-red-600">
                  {formik.errors.city}{" "}
                </span>
              )}
            </div>

            {/* <div className="grid gap-2">
              <p className="font-medium text-sm">Post Code</p>

              <input
                type="text"
                value={formik.values.address.state}
                name={"address.state"}
                onChange={formik.handleChange}
                placeholder="Enter user's post code "
                className={classNames(
                  "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                  "bg-[#0000000D]"
                )}
              />

              {formik.errors.state && (
                <span className="text-xs text-red-600">
                  {formik.errors.state}{" "}
                </span>
              )}
            </div> */}

            <div className="grid gap-2">
              <p className="font-medium text-sm">Country</p>
              <SelectComp
                onChange={(val) =>
                  formik.setFieldValue("address.country", val.value)
                }
                value={"United Kingdom"}
                options={[{ label: "United Kingdom", value: "United Kingdom" }]}
              />
            </div>
          </div>
        </div>

        {/* 2nd half */}
        <div className="bg-white text-theme-black rounded-lg p-6">
          <Role
            changeHandler={changeHandler}
            value={value}
            selectedSuppliersArr={selectedSuppliersArr}
            setselectedSuppliersArr={setselectedSuppliersArr}
            invite={inviteUserHandler}
            permissionsAdmin={permissionsAdmin}
            selectedPermissionsAdmin={selectedPermissionsAdmin}
            setselectedPermissionsAdmin={setselectedPermissionsAdmin}
            permissions={permissions}
            selectedPermissions={selectedPermissions}
            setselectedPermissions={setselectedPermissions}
            formik={formik}
          />
        </div>
      </div>
    </>
  );
};

export default AddNewEmployee;
