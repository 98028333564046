import { GET_SINGLE_SUPPLIERS_FAIL, GET_SINGLE_SUPPLIERS_REQ, GET_SINGLE_SUPPLIERS_SUCC, HIDE_SINGLE_SUPPLIERS } from "../../constants/suppliers/Suppliers"

export const singleSupplierReducer = (state={supplier : {}, loading:true, errro:null, open:false}, action)=> {
    switch(action.type){
        case GET_SINGLE_SUPPLIERS_REQ:
            return {loading :true, ...state}

        case GET_SINGLE_SUPPLIERS_SUCC:
            return{
                loading: false,
                supplier: action.payload,
                open:true
            }
        
        case GET_SINGLE_SUPPLIERS_FAIL:
            return{
                loading:false,
                error:action.payload
            }

        case HIDE_SINGLE_SUPPLIERS:
            return{
                open:false,
                supplier:undefined
            }
        
        default:
            return state;
    }
}