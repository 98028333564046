/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import ShowCase from "./comps/ShowCase";
import TopLinks from "./comps/TopLinks";
import CreateCompany from "./Create";
import InviteSupplier from "./Invite";
import SingleCompany from "./singleCompany";

const MainDiv = styled.div`
  height: calc(100vh - 57px);
`;
const Companies = () => {
  const dispatch = useDispatch();

  const { companies } = useSelector((state) => state.AdminLeftNav);
  const { open, company } = useSelector((state) => state.singleCompanyAdmin);

  const [showEditScreen, setEditScreen] = useState(false);

  const saveEdit = () => {
    setEditScreen(false);
  };

  const cancelEdit = () => {
    setEditScreen(false);
  };

  return (
    <>
      <MainDiv className="px-[32px] py-8 overflow-y-auto">
        <TopLinks
          showEditScreen={showEditScreen}
          backToSingleAsset={() => setEditScreen(false)}
          editScreenHandler={() => setEditScreen(true)}
        />
        {/* {companies.list && !open && <ShowCase />} */}
        {/* {companies.invite && <InviteSupplier />} */}
        {/* {companies.add && <CreateCompany />} */}
        {/* {open && companies.list && <SingleCompany edit={showEditScreen} setEdit={setEditScreen} />} */}

        <Routes>
          <Route path="/" element={<ShowCase />} />
          <Route path="/invite" element={<InviteSupplier />} />
          <Route path="/addNew" element={<CreateCompany />} />
          <Route
            path="/:id"
            element={
              <SingleCompany edit={showEditScreen} setEdit={setEditScreen} />
            }
          />
        </Routes>
      </MainDiv>
    </>
  );
};

export default Companies;
