import axios from "axios";
import { serialize } from "../../libs/serialize";
import { accessToken } from "../../libs/token";

export const getUsersApi = async (companyId, status) => {
  const uri = serialize({ companyId, status });
  return axios.get(
    `${process.env.REACT_APP_API_URL}/users?page=1&limit=100&${uri}`,
    await accessToken()
  );
};

export const reInviteUser = async (userId) => {
  const obj = { userId: userId };
  return axios.post(
    `${process.env.REACT_APP_API_URL}/users/reinvite`,
    obj,
    await accessToken()
  );
};

export const getAllUserApiBySandip = async (
  companyId = "",
  status,
  page,
  limit,
  q,
  roleId
) => {
  const uri = serialize({ page, limit, status, q, companyId, roleId });

  return axios.get(
    `${process.env.REACT_APP_API_URL}/users?${uri}`,
    await accessToken()
  );
};

export const getUsersProjectApi = async (userID) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/users/projects?userId=${userID}`,
    await accessToken()
  );
};

export const getUsersProjectApiBySandip = async (userId, page, limit, q) => {
  const uri = serialize({ userId, page, limit, q });
  return axios.get(
    `${process.env.REACT_APP_API_URL}/users/projects?${uri}`,
    await accessToken()
  );
};

export const getProjectApi = async (projectId) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/project/${projectId}`,
    await accessToken()
  );
};

// http://localhost:4000/v1/users?page=1&limit=10&companyId=qsdasdf&status=active
export const getAdminUsersApi = async () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/users`,
    await accessToken()
  );
};
export const getLoggedInUserApi = async () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/users/me`,
    await accessToken()
  );
};

export const getUserApi = async (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    await accessToken()
  );
};

export const addProjectToUser = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/users/projects`,
    data,
    await accessToken()
  );
};

export const addCompanyToUser = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/users/companyaccess`,
    data,
    await accessToken()
  );
};

export const getCompanyAccess = async (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/users/companyaccess?userId=${id}`,
    await accessToken()
  );
};

export const updateCompanyToUser = async (data) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/users/companyaccess`,
    data,
    await accessToken()
  );
};

export const getSpecificUserPermissions = async (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/users/permissions?userId=${id}`,
    await accessToken()
  );
};
