import { getAllSupplortApi } from "../../../api/users/support";
import {
  GET_ALL_SUPPORT_FAILED,
  GET_ALL_SUPPORT_REQ,
  GET_ALL_SUPPORT_SUCC,
} from "../../constants/support/getAllSupportConsts";

export const getAllSupportsAction =
  ({
    companyId = "",
    limit = 5,
    page = 1,
    q = "",
    status = "",
    fromDate = "",
    toDate = "",
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_SUPPORT_REQ });
      const res = await getAllSupplortApi(
        page,
        limit,
        companyId,
        status,
        q,
        fromDate,
        toDate
      );

      dispatch({
        type: GET_ALL_SUPPORT_SUCC,
        payload: res.data.docs,
        allProjectDetails: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_SUPPORT_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
