import { CLOSE_SINGLE_COMPANY_ADMIN, GET_SINGLE_COMPANY_FAIL_ADMIN, GET_SINGLE_COMPANY_REQ_ADMIN, GET_SINGLE_COMPANY_SUCC_ADMIN } from "../../constants/AdminConsts/SingleComapnyAdminConst"

export const singleCompanyAdminReducer = (state={company : {}, loading:true, open:false, errro:null}, action)=> {
        switch(action.type){
            case GET_SINGLE_COMPANY_REQ_ADMIN:
                return {loading :true, ...state}

            case GET_SINGLE_COMPANY_SUCC_ADMIN:
                return{
                    loading: false,
                    company: action.payload,
                    open:true
                }
            
            case GET_SINGLE_COMPANY_FAIL_ADMIN:
                return{
                    loading:false,
                    error:action.payload
                }
            
            case CLOSE_SINGLE_COMPANY_ADMIN:
                return{
                    loading:false,
                    open:false,
                    company: undefined,

                }
                
            
            default:
                return state;
        }
}