export const OPEN_CREATE_NEW_SUPPORT_TICKET = 'CREATE_NEW_SUPPORT_TICKET'
export const CLOSE_CREATE_NEW_SUPPORT_TICKET ='CLOSE_CREATE_NEW_SUPPORT_TICKET'

export const createNewSupportTicketReducer = (state={opencreateNewSupportTicket:false}, action) => {
    switch(action.type){
        case OPEN_CREATE_NEW_SUPPORT_TICKET:
            return{
                opencreateNewSupportTicket:true
            }
        case CLOSE_CREATE_NEW_SUPPORT_TICKET:
            return{
                opencreateNewSupportTicket:false
            }
        default:
            return state;
        
    }
}