import { ArrowRight } from 'phosphor-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const SingleProject = ({p}) => {

    
    const navigate = useNavigate()
  return (
    <>
        <div className='flex border-b border-bottom-border px-6 py-5'>
            <div className='w-1/6 font-semibold '>
                <p className='text-xs '>ID</p>
                <p className='text-very-tiny opacity-50'>{p.uid}</p>
            </div>  

            <div className='w-1/2 '>
                <p className='text-xs font-semibold '>{p?.name}</p>
                <p className='text-very-tiny opacity-50 font-medium'>{p?.projectDetails?.address}</p>
            </div>  

            <div className='w-1/6  font-semibold '>
                <p className='text-xs'>Status</p>
                <p className='text-very-tiny opacity-50 capitalize'>{p?.status}</p>
            </div>  

            <div className='text-xs flex flex-1 justify-end font-medium'>
                <ArrowRight onClick={ () => navigate(`/projects/${p._id}`)} className='bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded' size={28} weight='light'/>
            </div>
        </div>
    </>
  )
}

export default SingleProject