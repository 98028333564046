import {
  GET_ASSET_DOCUMENTS_FAIL,
  GET_ASSET_DOCUMENTS_REQ,
  GET_ASSET_DOCUMENTS_SUCC,
} from "../../constants/assets/getDocumentOfTheAsset";
import {
  GET_ASSETS_FAIL,
  GET_ASSETS_SUCC,
  GET_ASSETS_REQ,
  GET_ASSETS_PAGINATION,
  GET_ASSETS_TYPE,
  GET_ASSETS_STATUS,
} from "../../constants/assets/SingleAssetOpener";

export const assetsReducer = (
  state = {
    assets: [],
    assetType: "",
    assetStatus: "",
    count: 5,
    loading: true,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case GET_ASSETS_REQ:
      return { loading: true, ...state };

    case GET_ASSETS_SUCC:
      return {
        loading: false,
        assets: action.payload,
        pagination: action.pagnationDetails,
        count: state.count,
        assetType: state.assetType,
        assetStatus: state.assetStatus,
      };

    case GET_ASSETS_FAIL:
      return {
        loading: false,
        error: action.payload,
        count: state.count,
        assetType: state.assetType,
        assetStatus: state.assetStatus,
      };

    case GET_ASSETS_PAGINATION:
      return {
        count: Number(action.data),
        assets: state.assets,
        pagination: state.pagination,
        assetType: state.assetType,
        assetStatus: state.assetStatus,
      };
    case GET_ASSETS_TYPE:
      return {
        count: Number(state.count),
        assets: state.assets,
        pagination: state.pagination,
        assetType: action.data,
        assetStatus: state.assetStatus,
      };
    case GET_ASSETS_STATUS:
      return {
        count: Number(state.count),
        assets: state.assets,
        pagination: state.pagination,
        assetType: state.assetType,
        assetStatus: action.data,
      };
    default:
      return state;
  }
};

export const getAssetDocumentsReducer = (
  state = { docs: [], loading: false, error: null },
  action
) => {
  switch (action.type) {
    case GET_ASSET_DOCUMENTS_REQ:
      return { loading: true, ...state };

    case GET_ASSET_DOCUMENTS_SUCC:
      return {
        loading: false,
        docs: action.payload,
      };

    case GET_ASSET_DOCUMENTS_FAIL:
      return {
        loading: false,
        docs: action.payload,
      };

    default:
      return state;
  }
};
