import {
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDocsAction } from "../../../../redux/actions/documents/getAllDoc";

const Pagination = ({ limit, setLimit }) => {
  const { totalCount } = useSelector((state) => state.getAllDoc);

  // const noOfPages = Math.trunc(totalCount?.count/limit) + 1

  const noOfPages =
    totalCount?.count % totalCount?.limit !== 0
      ? totalCount?.count / totalCount?.limit + 1
      : totalCount?.count / totalCount?.limit;

  const [page, setPage] = useState(1);
  const [hasPrevPage, setHavePrevPage] = useState(false);
  const [hasNextPage, setHaveNextPage] = useState(true);
  const { docs, loading } = useSelector((state) => state.getAllDoc);

  useEffect(() => {
    if (page === 1) {
      setHavePrevPage(false);
      setHaveNextPage(true);
    }
    if (page === noOfPages) {
      if (page !== 1) setHavePrevPage(true);
      setHaveNextPage(false);
    }

    if (page < noOfPages && page > 1) {
      setHavePrevPage(true);
      setHaveNextPage(true);
    }
  }, [page, noOfPages]);

  const dispatch = useDispatch();

  //

  const prevPageOnClick = () => {
    if (hasPrevPage === true) {
      dispatch(getAllDocsAction({ limit: limit, page: page - 1 }));
      setPage(page - 1);
    }
  };

  const nextPageOnClick = () => {
    if (hasNextPage === true) {
      dispatch(getAllDocsAction({ limit: limit, page: page + 1 }));
      setPage(page + 1);
    }
  };

  const lastPageOnClick = () => {
    dispatch(getAllDocsAction({ limit: limit, page: noOfPages }));
    setPage(noOfPages);
  };

  const firstPageOnClick = () => {
    dispatch(getAllDocsAction({ limit: limit, page: 1 }));
    setPage(1);
  };

  return (
    <>
      <div className="mt-3 bg-white rounded-md">
        <div className="px-6 flex justify-between items-center py-5">
          <div className="flex gap-1">
            <CaretDoubleLeft
              onClick={firstPageOnClick}
              className="p-1.5 border-[0.5px] border-bottom-border cursor-pointer bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded"
              size={26}
              weight="bold"
            />
            <CaretLeft
              onClick={prevPageOnClick}
              className="p-1.5 border-[0.5px] border-bottom-border cursor-pointer bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded"
              size={26}
              weight="bold"
            />

            {hasPrevPage && (
              <p
                onClick={prevPageOnClick}
                className="pt-[5px] leading-2 cursor-pointer  pb-0.5 px-2.5 cursor-pointer w-7 text-center rounded font-medium  border-[0.5px] border-[#00000029] text-very-tiny"
              >
                {page - 1}
              </p>
            )}
            <p className="pt-[5px] leading-2 cursor-pointer  bg-theme-green cursor-pointer  pb-0.5 w-7 text-center px-2.5 rounded hover:bg-theme-green font-medium hover:border-[#00000029] border-[0.5px] border-theme-green text-very-tiny">
              {page}
            </p>
            {hasNextPage && (
              <p
                onClick={nextPageOnClick}
                className="pt-[5px] leading-2 cursor-pointer pb-0.5 px-2.5 cursor-pointer w-7 text-center hover:bg-theme-green rounded font-medium  border-[0.5px] border-[#00000029] text-very-tiny"
              >
                {page + 1}
              </p>
            )}

            <CaretRight
              onClick={nextPageOnClick}
              className="p-1.5 border-[0.5px] border-bottom-border cursor-pointer bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded"
              size={26}
              weight="bold"
            />
            <CaretDoubleRight
              onClick={lastPageOnClick}
              className="p-1.5 border-[0.5px] border-bottom-border cursor-pointer bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded"
              size={26}
              weight="bold"
            />
          </div>

          <div className="flex text-very-tiny gap-4 items-center">
            {totalCount && (
              <p>
                Displaying {(totalCount?.page - 1) * limit + 1} -{" "}
                {(totalCount?.page - 1) * limit + docs?.length} of{" "}
                {totalCount?.count}
              </p>
            )}

            <div className="py-1 bg-[#f7f6f6] rounded-md px-1.5 border border-[rgba(0, 0, 0, 0.1)]">
              <select
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                className="h-7 bg-[#f7f6f6] focus:outline-none"
                name=""
                id=""
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pagination;
