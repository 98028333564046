import { MagnifyingGlass } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAssetsApi } from "../../../api/users/asset";
import { getCompaniesApi } from "../../../api/users/company";
import { getAllDocs } from "../../../api/users/document";
import { getAllProjectsApi } from "../../../api/users/projects";
import { Filter } from "../../../styles/FilterButton";
import SingleAsset from "../../components/AdvancedSearch/comps/SingleAsset";
import SingleDocument from "../../components/AdvancedSearch/comps/SingleDocument";
import SingleProject from "../../components/AdvancedSearch/comps/SingleProject";
import Loading from "../../components/loading/Loading";
import { Assests } from "../../fake/fakeAssets";
import { FakeProjects } from "../../fake/fakeProject";
import { FakeSuppliers } from "../../fake/fakeSuppliers";
import { documents } from "../../fake/faleDoc";

import SingleSupplier from "./comps/SingleSupplier";
const ShowCase = () => {
  const [active, setActive] = useState({
    everything: false,
    assets: true,
    project: false,
    doc: false,
    sup: false,
  });

  const searchValue = useLocation().search.split("=")[1];

  const navigate = useNavigate("");
  const [inputValue, setInputvalue] = useState("");

  useEffect(() => {
    setInputvalue(searchValue);
  }, [searchValue]);

  // search

  const { user } = useSelector((state) => state.me);

  const [result, setResult] = useState([]);

  const assetsResults = useRef();
  const documentsResults = useRef();
  let projectsResults = useRef();
  let companiesResults = useRef();

  const [loadingAssets, setLoadingAssets] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingDocs, setLoadingDocs] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);

  const assetsSearchAPIHandle = async () => {
    try {
      setLoadingAssets(true);
      const res = await getAssetsApi("", 250, 1, "", "", inputValue);
      setLoadingAssets(false);
      assetsResults.current = res.data.docs;
    } catch (error) { }
  };

  const documentSearchAPIHandle = async () => {
    try {
      setLoadingDocs(true);

      const res = await getAllDocs(
        1,
        250,
        "",
        "",
        "",
        inputValue,
        1,
        user.companyId._id,
        []
      );
      setLoadingDocs(false);
      documentsResults.current = res.data.docs;
    } catch (error) { }
  };

  const projectSearchAPIHandle = async () => {
    try {
      setLoadingProjects(true);
      const res = await getAllProjectsApi("", 250, 1, inputValue, "", []);
      setLoadingProjects(false);
      projectsResults.current = res.data.docs;
    } catch (error) { }
  };

  const companySearchAPIHandle = async () => {
    try {
      setLoadingCompanies(true);
      const res = await getCompaniesApi(250, 1, inputValue);
      setLoadingCompanies(false);
      companiesResults.current = res.data.docs;
    } catch (error) { }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputValue.trim() !== "") {
        assetsSearchAPIHandle();
        documentSearchAPIHandle();
        projectSearchAPIHandle();
        companySearchAPIHandle();
      }
    }, 300);
    return () => clearTimeout(timer);
  }, [inputValue]);

  useEffect(() => {
    if (active.project) {
      setResult(projectsResults.current);
    }

    if (active.doc) {
      setResult(documentsResults.current);
    }

    if (active.assets) {
      setResult(assetsResults.current);
    }

    if (active.companies) {
      setResult(companiesResults.current);
    }

    if (inputValue.trim(" ") === "") {
      setResult([]);
    }
  }, [
    inputValue,
    active,
    projectsResults.current,
    documentsResults.current,
    assetsResults.current,
    companiesResults.current,
  ]);

  let loading =
    loadingAssets || loadingCompanies || loadingProjects || loadingDocs;

  return (
    <>
      <div className="bg-white mt-8 rounded-lg py-8 pb-16">
        <div className="flex  px-6 justify-between items-center">
          <div className="relative text-very-tiny w-1/3">
            <input
              value={inputValue}
              onChange={(e) => setInputvalue(e.target.value)}
              placeholder="Search for Project Detail, Owner or Location"
              className="w-full bg-[#f7f6f6] border-[1.5px] focus:outline-none rounded-md border-[rgba(0, 0, 0, 0.1)] py-2.5 px-4"
              type="text"
            />
            <MagnifyingGlass
              className="absolute right-6 opacity-20 top-1/2 -translate-y-1/2"
              size={14}
            />
          </div>
          <div className="flex text-very-tiny gap-4 items-center">
            {/* <Filter onClick={() => setActive({everything:true, assets:false, project:false, doc:false,sup:false})} active={active.everything}>Everything</Filter> */}
            <Filter
              onClick={() =>
                setActive({
                  everything: false,
                  assets: true,
                  project: false,
                  doc: false,
                  sup: false,
                })
              }
              active={active.assets}
            >
              Assets
            </Filter>
            <Filter
              onClick={() =>
                setActive({
                  everything: false,
                  assets: false,
                  project: true,
                  doc: false,
                  sup: false,
                })
              }
              active={active.project}
            >
              Projects
            </Filter>
            <Filter
              onClick={() =>
                setActive({
                  everything: false,
                  assets: false,
                  project: false,
                  doc: true,
                  sup: false,
                })
              }
              active={active.doc}
            >
              Documents
            </Filter>
            <Filter
              onClick={() =>
                setActive({
                  everything: false,
                  assets: false,
                  project: false,
                  doc: false,
                  sup: true,
                })
              }
              active={active.sup}
            >
              Companies
            </Filter>
          </div>
        </div>

        <p className="text-xs px-6 py-4 border-b pb-8 font-medium">
          {result?.length} results found
        </p>

        <div>
          {(active.everything || active.assets) && (
            <>
              {loadingAssets ? (
                <Loading loading={true} />
              ) : (
                assetsResults.current?.map((asset) => {
                  return <SingleAsset admin key={asset._id} asset={asset} />;
                })
              )}
            </>
          )}

          {(active.everything || active.project) && (
            <>
              {loadingProjects ? (
                <Loading loading={true} />
              ) : (
                projectsResults.current?.map((p) => {
                  return <SingleProject admin p={p} />;
                })
              )}
            </>
          )}

          {(active.everything || active.doc) && (
            <>
              {loadingDocs ? (
                <Loading loading={true} />
              ) : (
                documentsResults.current !== 0 &&
                documentsResults.current?.map((d) => {
                  return <SingleDocument admin d={d} />;
                })
              )}
            </>
          )}

          {(active.everything || active.sup) && (
            <>
              {loadingCompanies ? (
                <Loading loading={true} />
              ) : (
                companiesResults.current.length !== 0 &&
                companiesResults.current?.map((p) => {
                  return <SingleSupplier p={p} />;
                })
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ShowCase;
