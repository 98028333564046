import classNames from "classnames";
import { ArrowRight } from "phosphor-react";
import React, { useEffect, useState } from "react";
import DocumentsDropDown from "../../../../components/Projects/comps/DocumentsDropDown";
import { FakeProjects } from "../../../../fake/fakeProject";
import { FakeSuppliers } from "../../../../fake/fakeSuppliers";
import { documents } from "../../../../fake/faleDoc";
import SupplierDropDown from "../../../../components/Projects/comps/SupplierDropDown";
import { useDispatch, useSelector } from "react-redux";
import { getAllDocsAction } from "../../../../../redux/actions/documents/getAllDoc";
import { getSuppliersOfAProjectAction } from "../../../../../redux/actions/suppliers/geSupplierOfAProject";
import { getUsersProjectApi } from "../../../../../api/users/users";
import { getSingleProjectData } from "../../../../../api/users/projects";
import Loading from "../../../../components/loading/Loading";
import { useNavigate } from "react-router-dom";

const AllProjects = ({ userData }) => {
  const [loading, setLoading] = useState(false);
  const [assetsDropDown, setassetsDropDown] = useState(false);
  const [documentsDropDown, setdocumentsDropDown] = useState(false);
  const [suppliersDropDown, setsuppliersDropDown] = useState(false);
  const [clickedId, setClickedId] = useState(null);
  const [projects, setProjects] = useState([]);
  const { user } = useSelector((state) => state.me);
  const { assets: assetsOfAProject, loading: ProjectDropDownLoading } =
    useSelector((state) => state.getAssetsOFAProject);
  const { suppliers, loading: suppliersDropDownLOading } = useSelector(
    (state) => state.getSuppliersOFAProject
  );
  const dispatch = useDispatch();

  const documentsDropDownOpener = (s) => {
    setClickedId(s._id);
    setdocumentsDropDown(s._id === clickedId ? !documentsDropDown : true);
    setsuppliersDropDown(false);
    setassetsDropDown(false);
    dispatch(getAllDocsAction({}));
  };

  const suppliersDropDownOpener = (p) => {
    dispatch(getSuppliersOfAProjectAction(p._id));
    setClickedId(p._id);
    setdocumentsDropDown(false);
    setassetsDropDown(false);
    setsuppliersDropDown(p._id === clickedId ? !suppliersDropDown : true);
  };
  const fetchAllData = async () => {
    setLoading(true);

    const ids = await getUsersProjectApi(userData._id);
    setLoading(false);

    setProjects(ids.data.docs);
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const navigate = useNavigate();

  const openSingleProject = (p) => {
    navigate(`/admin/projects/${p._id}`);
  };
  const { docs } = useSelector((state) => state.getAllDoc);

  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : projects.length === 0 ? (
        <div className="text-center py-4 text-xs">No Projects found</div>
      ) : (
        projects?.map((s, index) => {
          let p = s.projectId;
          return (
            <div key={index}>
              <div
                className={classNames(
                  "flex py-4 px-6",
                  (assetsDropDown || documentsDropDown || suppliersDropDown) &&
                    clickedId === p._id
                    ? "bg-theme-light-green border-b border-t"
                    : "border-b border-bottom-border",
                  ""
                )}
              >
                <div className="w-[7%] text-xs font-medium">
                  <p className="uppercase">id</p>
                  <p className="opacity-50">{p?.uid}</p>
                </div>
                <div className="w-[18%] text-xs gap-2">
                  <p className="font-medium">{p?.name}</p>
                  <p className="text-theme-black opacity-50 font-medium">
                    {p?.address?.city}, {p.address?.state}
                  </p>
                </div>

                <div className="flex w-[15%] items-center text-xs gap-2">
                  <div className="h-full">
                    {/* <img className='w-8 p-[1px] border border-theme-green rounded-full' src="/images/user1.jpeg" alt="" /> */}
                    <p className="w-9 h-9 tracking-wide flex justify-center items-center text-white font-bold text-[18px] bg-theme-green p-0.5 border border-theme-green rounded-full">
                      {p.companyId.name.split(" ")[0][0]}
                      {p.companyId.name.split(" ")[1]
                        ? p.companyId.name.split(" ")[1][0]
                        : ""}
                    </p>
                  </div>
                  <div className="">
                    <p className="font-medium">{p?.companyId?.name}</p>
                    <p className="uppercase text-very-tiny">
                      <span>ID</span> -{" "}
                      <span className="underline text-blue-400">
                        {p?.companyId?.uid}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="flex w-[25%] items-center justify-center text-xs gap-6">
                  <div className="text-xs ">
                    <p className="font-medium">Status</p>
                    <p
                      className={classNames(
                        " cursor-pointer font-medium leading-3",
                        p?.status === "Approved"
                          ? "text-theme-green"
                          : "text-theme-black opacity-50"
                      )}
                    >
                      {p?.status}
                    </p>
                  </div>

                  <div className="flex flex-col ">
                    <p className="leading-3 mb-1">
                      Progress{" "}
                      <span className="text-theme-black opacity-50">
                        ({p?.progress?.total + "/" + p.progress?.completed})
                      </span>
                    </p>
                    <div className="relative w-full h-2 rounded bg-theme-light-green ">
                      <div
                        className={classNames(
                          "absolute h-2 bg-theme-green rounded",
                          ``
                        )}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className=" w-[10%] text-xs">
                  <div className="">
                    <p className="leading-3">Suppliers</p>
                    <p
                      onClick={() => {}}
                      className="text-theme-black opacity-50 cursor-pointer leading-3"
                    >
                      {p?.stats?.suppliers}{" "}
                      <i
                        className={classNames(
                          "fa-solid ml-1 ",
                          suppliersDropDown && clickedId === p._id
                            ? "fa-angle-up"
                            : "fa-angle-down"
                        )}
                      ></i>
                    </p>
                  </div>
                </div>

                <div className=" w-[10%] text-xs">
                  <div className="">
                    <p className="leading-3">Documents</p>
                    <p
                      onClick={() => {}}
                      className="text-theme-black opacity-50 cursor-pointer leading-3"
                    >
                      {p?.stats?.documents}{" "}
                      <i
                        className={classNames(
                          "fa-solid ml-1 ",
                          documentsDropDown && clickedId === p._id
                            ? "fa-angle-up"
                            : "fa-angle-down"
                        )}
                      ></i>
                    </p>
                  </div>
                </div>

                <div className="text-xs flex flex-1 justify-end font-medium">
                  <ArrowRight
                    onClick={() => openSingleProject(p)}
                    className="bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
                    size={28}
                    weight="light"
                  />
                </div>
              </div>

              {suppliersDropDown && clickedId === p._id && (
                <div className="max-h-60 overflow-y-auto">
                  {suppliers?.length !== 0 ? (
                    suppliers?.map((s, idx) => {
                      return <SupplierDropDown s={s} key={idx} />;
                    })
                  ) : (
                    <p className="text-center pt-6">No Supplier found</p>
                  )}
                </div>
              )}

              {documentsDropDown && clickedId === p._id && (
                <div className="max-h-60 overflow-y-auto">
                  {docs && docs.length === 0 ? (
                    <p className="text-center pt-12">No Document found</p>
                  ) : (
                    docs?.map((s, index) => {
                      return <DocumentsDropDown s={s} key={index} />;
                    })
                  )}
                </div>
              )}
            </div>
          );
        })
      )}
    </>
  );
};

export default AllProjects;
