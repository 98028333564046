import classNames from "classnames";
import { Formik } from "formik";
import {
  Buildings,
  CaretDown,
  Check,
  MagnifyingGlass,
  Users,
  X,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies } from "../../../../redux/actions/companies/getCompanies";
import SingleSupplier from "../../../components/Projects/comps/singleProjectComps/projectLinks/SingleSupplier";

import SelectComp from "./SlectCompUserScren";

const Role = ({
  invite,
  permissionsAdmin,
  setselectedPermissionsAdmin,
  selectedPermissionsAdmin,
  permissions,
  formik,
  setselectedPermissions,
  selectedPermissions,
  selectedSuppliersArr,
  setselectedSuppliersArr,
  changeHandler,
  value,
}) => {
  const Options = [
    { value: "627955212019dc8e78e71ba6", label: "Apex Admin" },
    { value: "627955212019dc8e78e71ba7", label: "Apex Manager" },
  ];

  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompanies(20, 1, search));
  }, [search]);

  const { companies } = useSelector((state) => state.compaines);

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    formik.setFieldValue("roleId", selectedOption?.value);
  }, [selectedOption]);

  // for permission of apex admin

  const selectAll = () => {
    if (selectedPermissions.length === permissions.length) {
      setselectedPermissions([]);
    } else {
      setselectedPermissions(permissions);
    }
  };

  const selectHandler = (p) => {
    let newArr = [...selectedPermissions];
    const contains = newArr.filter((a) => a.value === p.value);
    if (contains.length === 1) {
      newArr = newArr.filter((a) => a.value !== p.value);
    } else {
      newArr = [...selectedPermissions, p.value];
    }

    setselectedPermissions([...selectedPermissions, p]);
  };

  // for permissions of apex backend manager

  const selectAllBacked = () => {
    if (permissionsAdmin.length === selectedPermissionsAdmin.length) {
      setselectedPermissionsAdmin([]);
    } else {
      setselectedPermissionsAdmin(permissionsAdmin);
    }
  };

  const selectHandlerBacked = (p) => {
    let newArr = [...selectedPermissionsAdmin];
    const contains = newArr.filter((a) => a.value === p.value);
    if (contains.length === 1) {
      newArr = newArr.filter((a) => a.value !== p.value);
    } else {
      newArr = [...selectedPermissionsAdmin, p];
    }

    setselectedPermissionsAdmin(newArr);
  };

  //
  const [showDropDown, setShowDropDown] = useState(false);

  const showDropDownFunc = () => {
    setShowDropDown(!showDropDown);
  };

  const selectedSupplier = (p) => {
    if (!selectedSuppliersArr.includes(p)) {
      setselectedSuppliersArr([...selectedSuppliersArr, p]);
    }

    setShowDropDown(false);
  };

  const removeSupplier = (s) => {
    let newArr = [...selectedSuppliersArr];
    let newArr2 = newArr.filter((p) => p._id !== s._id);
    setselectedSuppliersArr(newArr2);
  };

  //

  return (
    <>
      <div className="grid gap-2">
        <p className="font-medium text-sm">Role</p>
        <SelectComp
          value={formik.values.roleId}
          onChange={(e) => setSelectedOption(e)}
          options={Options}
        />
      </div>

      {/* apex admin */}
      <div className="mt-2">
        {selectedOption && selectedOption.value === Options[0].value && (
          <div>
            <p className="text-sm">Permissions</p>

            <div className="grid gap-3 mt-3">
              <div className="flex items-center gap-3">
                <Check
                  onClick={selectAll}
                  size={18}
                  className={classNames(
                    "text-white border border-[#3231324D] rounded p-[2px] cursor-pointer",
                    selectedPermissions.length === permissions.length
                      ? "bg-[#3231324D]"
                      : "bg-white"
                  )}
                />
                <p className="text-xs text-theme-black">All</p>
              </div>
              {permissions.map((p, index) => {
                return (
                  <div key={index} className="flex items-center gap-3">
                    <Check
                      onClick={() => selectHandler(p)}
                      size={18}
                      className={classNames(
                        "text-white border border-[#3231324D] rounded p-[2px] cursor-pointer",
                        selectedPermissions.includes(p)
                          ? "bg-[#3231324D]"
                          : "bg-white"
                      )}
                    />
                    <p className="text-xs text-theme-black">{p.label}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* backedn admin */}

        {selectedOption && selectedOption.value === Options[1].value && (
          <div>
            <div className="flex justify-start py-4 gap-4">
              <div class="flex  gap-1 items-center">
                <input
                  onChange={changeHandler}
                  type="radio"
                  name="radio"
                  checked={value === "all_company"}
                  value={"all_company"}
                  id="radio1"
                />
                <label className="text-xs" for="radio1">
                  All Companies
                </label>
              </div>
              <div class="flex gap-1  items-center">
                <input
                  onChange={changeHandler}
                  type="radio"
                  name="radio"
                  checked={value === "select_company"}
                  value={"select_company"}
                  id="radio2"
                />
                <label className="text-xs" for="radio2">
                  Select Company
                </label>
              </div>
            </div>

            {value === "select_company" && (
              <div className=" relative my-2">
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onClick={showDropDownFunc}
                  placeholder={
                    showDropDown ? "Select Existing Company" : "Add Company"
                  }
                  type="text"
                  className={classNames(
                    "w-full border border-[#00000033] bg-[#0000000D] cursor-pointer rounded-t-md focus:outline-none py-3 text-very-tiny",
                    showDropDown ? "px-4" : "px-[36px] rounded-b-md"
                  )}
                />
                {!showDropDown && (
                  <Users
                    size={16}
                    weight="bold"
                    className="absolute text-theme-black opacity-80 left-3 top-[13px]"
                  />
                )}
                {showDropDown ? (
                  <MagnifyingGlass
                    size={14}
                    className="absolute text-theme-black opacity-80 right-3 top-[14px]"
                  />
                ) : (
                  <CaretDown
                    size={14}
                    className="absolute text-theme-black opacity-80 right-3 top-[14px]"
                  />
                )}
                {showDropDown && (
                  <div className="border-b absolute z-[99] border-x w-full cursor-pointer min-h-64 overflow-y-auto border-[#00000033]">
                    {companies.map((p, index) => {
                      return (
                        <SingleSupplier
                          onClick={() => selectedSupplier(p)}
                          key={index}
                          s={p}
                        />
                      );
                    })}
                  </div>
                )}
                {
                  <div className=" mt-6 rounded-lg">
                    <p className="opacity-50 pb-3 text-xs">Added company:</p>
                    <div className="grid grid-cols-2 gap-2 pr-12">
                      {selectedSuppliersArr.map((s, idx) => {
                        return (
                          <div
                            key={idx}
                            className="bg-white border border-[#EFEBE9] rounded-lg"
                          >
                            <p className="text-xs font-medium bg-[#DAD2D2] p-2 rounded-t-lg flex justify-between items-center">
                              Company {idx + 1}
                              <X
                                onClick={() => removeSupplier(s)}
                                className="cursor-pointer"
                                size={12}
                              />
                            </p>
                            <div className="p-2">
                              <p className="text-xs py-1">
                                UID:{" "}
                                <span className="opacity-50">ID {s.uid}</span>
                              </p>
                              <p className="text-xs py-1">
                                Name:{" "}
                                <span className="opacity-50">{s.name}</span>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                }
              </div>
            )}
            <p className="text-sm mt-5">Permissions</p>

            <div className="grid gap-3 mt-3">
              <div className="flex items-center gap-3">
                <Check
                  onClick={selectAllBacked}
                  size={18}
                  className={classNames(
                    "text-white border border-[#3231324D] rounded p-[2px] cursor-pointer",
                    selectedPermissionsAdmin.length === permissionsAdmin.length
                      ? "bg-[#3231324D]"
                      : "bg-white"
                  )}
                />
                <p className="text-xs text-theme-black">All</p>
              </div>
              {permissionsAdmin.map((p, index) => {
                return (
                  <div key={index} className="flex items-center gap-3">
                    <Check
                      onClick={() => selectHandlerBacked(p)}
                      size={18}
                      className={classNames(
                        "text-white border border-[#3231324D] rounded p-[2px] cursor-pointer",
                        selectedPermissionsAdmin.includes(p)
                          ? "bg-[#3231324D]"
                          : "bg-white"
                      )}
                    />
                    <p className="text-xs text-theme-black">{p.label}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <button
          type="button"
          onClick={() => {
            invite();
          }}
          className="w-full mt-12 font-medium text-xs  py-2 bg-[#2ED9C3]"
        >
          Create New Apex Employee
        </button>
      </div>
    </>
  );
};

export default Role;
