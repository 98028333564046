import { MagnifyingGlass } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllEmployeesAction } from "../../../../redux/actions/users/getAllEmployeesAction";
import { EMPLOYESS_ADD_OPEN } from "../../../../redux/constants/AdminConsts/LeftNavAdminConst";
import { GET_ALL_EMPLOYEES_RESET } from "../../../../redux/constants/users/getAllEmployees";
import Pagination from "./Pagination";
import Table from "./Table";

const ShowCase = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.me);

  const [dropDown, setDropDown] = useState("");

  const [limit, setLimit] = useState(5);

  const [search, setSearch] = useState("");
  const { employees, paginationDetails } = useSelector(
    (state) => state.getAllEmployees
  );

  const [roleIdDropDown, setRoleIdDropDown] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        getAllEmployeesAction({
          limit,
          q: search,
          status: dropDown,
          page: 1,
          companyId: user?.companyId._id,
          roleId: roleIdDropDown,
        })
      );
    }, 300);
    return () => clearTimeout(timer);
  }, [dropDown, user, limit, dispatch, search, roleIdDropDown]);

  useEffect(() => {
    dispatch({ type: GET_ALL_EMPLOYEES_RESET });
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <div className="bg-white min-h-[80vh] mt-8 rounded-lg py-8 pb-16">
        <div className="flex  px-6 items-center justify-between">
          <div className="flex ml-6 text-xs font-semibold">
            <div className="px-3 border-r">
              <select
                value={dropDown}
                onChange={(e) => setDropDown(e.target.value)}
                className="focus:outline-none "
                name=""
                id=""
              >
                <option value="">All Employees</option>
                <option value="active">Active Employees</option>
                <option value="suspended">Suspended Employees</option>
                <option value="pending">Invitation Pending</option>
              </select>
            </div>

            <div className="px-3 border-r">
              <select
                value={roleIdDropDown}
                onChange={(e) => setRoleIdDropDown(e.target.value)}
                className="focus:outline-none "
                name=""
                id=""
              >
                <option value="">All Employees</option>
                <option value="627955212019dc8e78e71ba6">Apex Admins</option>
                <option value="627955212019dc8e78e71ba7">Apex Manager</option>
              </select>
            </div>
          </div>

          <button
            onClick={() => {
              dispatch({ type: EMPLOYESS_ADD_OPEN });
              navigate("addNew");
            }}
            className="px-5 uppercase border font-semibold border-theme-green hover:border-black text-xs py-2 rounded-md font-medium cursor-pointer select-none hover:bg-theme-green bg-theme-light-green text-theme-green hover:text-black"
          >
            create new employee
          </button>
        </div>

        {/* Input and display */}

        <div className="flex  px-6 justify-between items-center mt-12">
          <div className="relative text-very-tiny w-1/3">
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search for Employee by Name"
              className="w-full bg-[#f7f6f6] border-[1.5px] focus:outline-none rounded-md border-[rgba(0, 0, 0, 0.1)] py-2.5 px-4"
              type="text"
            />
            <MagnifyingGlass
              className="absolute right-6 opacity-20 top-1/2 -translate-y-1/2"
              size={14}
            />
          </div>
          <div className="flex text-very-tiny gap-4 items-center">
            {/* <p>Displaying {paginationDetails?.totalDocs>limit? limit:  paginationDetails?.totalDocs} of {paginationDetails?.totalDocs}</p> */}
            {paginationDetails && (
              <p>
                Displaying {(paginationDetails?.page - 1) * limit + 1} -{" "}
                {(paginationDetails?.page - 1) * limit +
                  paginationDetails?.docs?.length}{" "}
                of {paginationDetails?.totalDocs}
              </p>
            )}

            <div className="py-1 bg-[#f7f6f6] rounded-md px-1.5 border border-[rgba(0, 0, 0, 0.1)]">
              <select
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                className="h-7 bg-[#f7f6f6] focus:outline-none"
                name=""
                id=""
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </select>
            </div>
          </div>
        </div>

        <Table limit={limit} search={search} dropDown={dropDown} />
      </div>
      <Pagination limit={limit} setLimit={setLimit} />
    </>
  );
};

export default ShowCase;
