export const serialize = function (obj) {
  var str = [];
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value !== "string") {
      str.push(`${key}=${value}`);
    } else {
      if (value.trim(" ") !== "") {
        str.push(`${key}=${value}`);
      }
    }
  });
  return str.join("&");
};

export const removeEmptyString = function (obj) {
  Object.entries(obj).forEach(([key, value]) => {
    //
    // if (Array.isArray(value)) {
    //   value.map(v => {
    //     str.push(`${key}=${v}`);
    //   });
    // } else if (typeof value === 'string') {
    //   if (value.trim() !== '') {
    //     str.push(`${key}=${value}`);
    //   }
    // }
    if (typeof value === "string" && value.trim() === "") {
      delete obj[key];
    }
    if (typeof value === "object") {
      Object.entries(obj[key]).forEach(([key2, value2]) => {
        if (typeof value2 === "string" && value2.trim() === "") {
          delete obj[key][key2];
        }
      });
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
  });
  return obj;
};
