import classNames from "classnames";
import { ArrowRight } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAssetsProjectsApi } from "../../../../api/users/asset";
import ProjectsDropDown from "../../../components/Assets/comps/ProjectsDropDown";
import { documents } from "../../../fake/faleDoc";

const SingleAsset = ({ asset, admin }) => {
  const [assetId, setAssetId] = useState();
  const [projectsDropDown, setProjectsDropDown] = useState(false);
  const [clickedId, setClickedId] = useState("");

  const projectsDropDownOpener = (s) => {
    setClickedId(s._id);
    setProjectsDropDown(s._id === clickedId ? !projectsDropDown : true);
    setAssetId(s._id);
  };

  const [projectOfAAsset, setProjectOfAAsset] = useState();

  const fucn = async () => {
    try {
      const response = await getAssetsProjectsApi(assetId);
      setProjectOfAAsset(response.data.docs);
    } catch (error) {}
  };

  useEffect(() => {
    if (assetId) fucn();
  }, [assetId]);

  const navigate = useNavigate();
  const [clickedIdProject, setClickedIdProject] = useState("");

  const redirectFunc = (asset) => {
    if (admin) {
      navigate(`/admin/assets/${asset._id}`);
    } else {
      navigate(`/assets/${asset._id}`);
    }
  };
  return (
    <>
      <div>
        <div
          className={classNames(
            "px-6 py-5 border-b border-color-border flex",
            projectsDropDown && clickedId === asset._id
              ? "bg-theme-light-green border-b border-t"
              : "border-b border-bottom-border",
            ""
          )}
        >
          <div className="w-[10%] flex pl-3">
            <p className="px-5 text-center text-xs py-2 rounded-full bg-theme-green">
              Assets
            </p>
          </div>
          <div className="w-[8%] text-xs font-medium">
            <p className="uppercase">id</p>
            <p className="opacity-50 text-theme-black">{asset.uid}</p>
          </div>

          <div className="flex w-[20%] pr-4 text-xs gap-2">
            <div className="h-full">
              <img src="images/charging-station.png" alt="" />
            </div>
            <div className="">
              <p className="font-medium">{asset?.name}</p>
              <p>{asset?.alias}</p>
            </div>
          </div>

          <div className="w-[25%] text-xs pr-4">
            <p className="font-medium">{asset?.assetType?.name}</p>
            <div className="flex gap-4">
              <p className="text-theme-black opacity-50">
                {asset?.assetType?.id}
              </p>
              {/* <p className='uppercase text-theme-green font-semibold'>{asset.details.where}</p> */}
            </div>
          </div>

          <div className="text-xs w-[16%]">
            <p className="font-medium">Status</p>
            <p className="italic font-light">{asset.status}</p>
          </div>

          <div className=" w-[9%] text-xs">
            <div className="">
              <p className="leading-3">Projects</p>
              <p
                onClick={() => projectsDropDownOpener(asset)}
                className="text-theme-black opacity-50 cursor-pointer leading-3"
              >
                {asset?.stats.projects}{" "}
                <i
                  className={classNames(
                    "fa-solid ml-1 ",
                    projectsDropDown && clickedId === asset._id
                      ? "fa-angle-up"
                      : "fa-angle-down"
                  )}
                ></i>
              </p>
            </div>
          </div>

          <div className="text-xs flex flex-1 justify-end font-medium">
            <ArrowRight
              onClick={() => redirectFunc(asset)}
              className="bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
              size={28}
              weight="light"
            />
          </div>
        </div>

        {projectsDropDown && clickedId === asset._id && (
          <div>
            {projectOfAAsset?.map((project, index) => {
              return (
                <ProjectsDropDown
                  clickedId={clickedIdProject}
                  setClickedId={setClickedIdProject}
                  project={project}
                  key={index}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default SingleAsset;
