import { ArrowRight } from 'phosphor-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const SingleSupplier = ({ s, onClick }) => {

    const navigate = useNavigate()
    return (
        <>
            <div onClick={onClick} className='flex border-b justify-between  bg-white border-bottom-border px-6 py-5'>
            <div className='flex space-x-3 w-1/2'>
                    <div className='w-1/6 font-semibold '>
                        <p className='text-xs '>ID</p>
                        <p className='text-very-tiny opacity-50'>{s?.uid}</p>
                    </div>

                    <div className='w-2/3 text-center'>
                        <div className='flex flex-1 gap-2   items-center'>
                            {/* <div>
                                <img src={s?.profilePic ? s?.profilePic : "images/user1.jpeg"} className='w-8 p-0.5 border border-theme-green rounded-full' alt="" />
                            </div> */}
                            <div>
                                <p className='font-medium  text-xs'>{s?.name}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-xs w-1/6 flex flex-1 justify-end font-medium'>
                    <ArrowRight onClick={() => navigate(`/suppliers/${s._id}`)} className='bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded' size={28} weight='light' />
                </div>
            </div>
        </>
    )
}

export default SingleSupplier