import { getAssetsApi, getAssetsProjectsApi } from "../../../api/users/asset";
import { getUserApi } from "../../../api/users/users";
import {
  GET_ASSETS_FAIL,
  GET_ASSETS_REQ,
  GET_ASSETS_SUCC,
  GET_ASSETS_PAGINATION,
  GET_ASSETS_TYPE,
  GET_ASSETS_STATUS,
} from "../../constants/assets/SingleAssetOpener";

export const getAssets =
  ({
    companyId = "",
    limit = 5,
    page = 1,
    assetType = "",
    assetStatus = "",
    callBack = () => {},
    search = "",
    excludedIds,
    projectId = "",
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_ASSETS_REQ });

      const res = await getAssetsApi(
        companyId,
        limit,
        page,
        assetType,
        assetStatus,
        search,
        excludedIds,
        projectId
      );
      dispatch({
        type: GET_ASSETS_SUCC,
        payload: res.data.docs,
        pagnationDetails: res.data,
      });
      callBack();
    } catch (error) {
      dispatch({
        type: GET_ASSETS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const setCount = (value) => async (dispatch) => {
  dispatch({
    type: GET_ASSETS_PAGINATION,
    data: value,
  });
};

export const setAssetType = (value) => async (dispatch) => {
  dispatch({
    type: GET_ASSETS_TYPE,
    data: value,
  });
};
export const setAssetStatus = (value) => async (dispatch) => {
  dispatch({
    type: GET_ASSETS_STATUS,
    data: value,
  });
};
