import classNames from "classnames";
import { BellRinging } from "phosphor-react";
import React, { useState } from "react";
import Switch from "react-switch";

const Settings = () => {
  const [newActChecked, setNewActChecked] = useState(false);
  const [adminUpdate, setAdminUpdate] = useState(false);
  const [newdoc, setnewdoc] = useState(false);

  const handleChangeNewAct = () => {
    setNewActChecked(!newActChecked);
  };

  // change pass
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [repeatePass, setRepeatePass] = useState("");
  return (
    <>
      <div className="grid h-full grid-cols-2">
        <div className=" h-full">
          <div className="mt-16 px-8">
            <div className="flex items-center text-xs justify-between">
              <p className="font-semibold opacity-80">Email Notifications</p>
              <BellRinging size={16} weight="bold" />
            </div>

            <div className="grid gap-1 pt-2">
              <div className="flex bg-[#0000001A] p-4 rounded-t-md text-xs justify-between items-center">
                <p className="opacity-80">All New Activity</p>
                <Switch
                  handleDiameter={14}
                  height={16}
                  width={32}
                  onColor="#6ABEAE"
                  onChange={handleChangeNewAct}
                  checked={newActChecked}
                />
              </div>

              <div className="flex bg-[#0000001A] p-4  text-xs justify-between items-center">
                <p className="opacity-80">Admin Updates</p>
                <Switch
                  handleDiameter={14}
                  height={16}
                  width={32}
                  onColor="#6ABEAE"
                  onChange={() => setAdminUpdate(!adminUpdate)}
                  checked={adminUpdate}
                />
              </div>

              <div className="flex bg-[#0000001A] p-4 rounded-b-md text-xs justify-between items-center">
                <p className="opacity-80">New Document</p>
                <Switch
                  handleDiameter={14}
                  height={16}
                  width={32}
                  onColor="#6ABEAE"
                  onChange={() => setnewdoc(!newdoc)}
                  checked={newdoc}
                />
              </div>
            </div>
          </div>
        </div>

        {/* change pass */}
        {/* <div>
                <div className='mt-16  px-12 pb-8 border-opacity-10 grid gap-2 text-xs'>
                    <p className='text-start'>Change Password</p>
                    <div className='gap-1 grid'>
                        <input placeholder='Old Password' onChange={(e) => setOldPass(e.target.value)} value={oldPass}  className={classNames('w-full p-4 bg-[#0000001A] focus:outline-none rounded-md bg-black bg-opacity-5')} type="text" />
                        <input placeholder='New Password' onChange={(e) => setNewPass(e.target.value)} value={newPass}  className={classNames('w-full p-4 bg-[#0000001A] focus:outline-none rounded-md bg-black bg-opacity-5')} type="text" />
                        <input placeholder='Repeate Password' onChange={(e) => setRepeatePass(e.target.value)} value={repeatePass}  className={classNames('w-full p-4 bg-[#0000001A] focus:outline-none rounded-md  bg-black bg-opacity-5')} type="text" />
                    </div>
                </div>
                <div className='flex justify-center pt-8'>
                    <button className='bg-theme-light-green border-2 border-theme-green text-theme-green px-12 py-2 rounded-md hover:bg-theme-green hover:text-theme-black font-semibold'>Save</button>
                </div>
            </div> */}
      </div>
    </>
  );
};

export default Settings;
