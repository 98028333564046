import { CLOSE_SINGLE_PROJECT_ADMIN, GET_SINGLE_PROJECT_FAIL_ADMIN, GET_SINGLE_PROJECT_REQ_ADMIN, GET_SINGLE_PROJECT_SUCC_ADMIN } from "../../constants/AdminConsts/ProjectsAdminConst"

export const singleProjectAdminReducer = (state={project : {}, loading:true, open:false, errro:null}, action)=> {
        switch(action.type){
            case GET_SINGLE_PROJECT_REQ_ADMIN:
                return {loading :true, ...state}

            case GET_SINGLE_PROJECT_SUCC_ADMIN:
                return{
                    loading: false,
                    project: action.payload,
                    open:true
                }
            
            case GET_SINGLE_PROJECT_FAIL_ADMIN:
                return{
                    loading:false,
                    error:action.payload
                }
            
            case CLOSE_SINGLE_PROJECT_ADMIN:
                return{
                    loading:false,
                    open:false,
                    project: undefined,

                }
                
            
            default:
                return state;
        }
}