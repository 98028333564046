import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FakeSuppliers } from "../../../fake/fakeSuppliers";
import { getCompanies } from "../../../../redux/actions/companies/getCompanies";
import { useNavigate } from "react-router-dom";
// import { getCompanies } from '../../../../redux/actions/companies/getCompanies';

const TopClients = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompanies(5, 1));
  }, []);

  const { companies } = useSelector((state) => state.compaines);

  const navigate = useNavigate();
  return (
    <>
      <div className="py-6 bg-white rounded-md  suppplier">
        <div className="flex px-6 items-center pb-4 border-b justify-between">
          <p className="text-sm font-bold">Top Clients</p>
        </div>

        {companies?.map((p, index) => {
          return (
            <div
              onClick={() => navigate(`/admin/companies/${p._id}`)}
              key={index}
            >
              <div
                className={classNames(
                  "flex items-center py-4 hover:bg-[#E5E5E5] hover:bg-opacity-40 px-6",
                  "border-b border-bottom-border",
                  "cursor-pointer"
                )}
              >
                <div className="flex w-[90%] items-center text-xs gap-2">
                  <div className="text-very-tiny font-medium">
                    <div className="flex gap-2 items-center">
                      <div>
                        {/* <img src={`${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${p.profilePic}`} className='w-6 p-0.5 border border-theme-green rounded-full' alt="pf" /> */}
                        <p className="w-8 h-8 tracking-wide flex justify-center items-center text-white font-bold text-[18px] bg-theme-green p-0.5 border border-theme-green rounded-full">
                          {p.name.split(" ")[0][0]}
                          {p.name.split(" ")[1] ? p.name.split(" ")[1][0] : ""}
                        </p>
                      </div>
                      <div>
                        <p className="font-medium text-xs leading-3">
                          {p.name}
                        </p>
                        {/* <p className='opacity-60 leading-3'>{p.companyAdmin}(client admin)</p> */}
                        {p.address && (
                          <p className=" font-normal leading-3  opacity-50">
                            {p.address.city}, {p.address.state},{" "}
                            {p.address.country}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" flex-1 text-very-tiny">
                  <div className="">
                    <p className="leading-3">Projects</p>
                    <p className="text-theme-black opacity-50 cursor-pointer leading-3">
                      {p.stats.projects}{" "}
                      <i
                        className={classNames(
                          "fa-solid ml-1 ",
                          "fa-angle-down"
                        )}
                      ></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TopClients;
