import {
  GET_ALL_EMPLOYEES_FAIL,
  GET_ALL_EMPLOYEES_REQ,
  GET_ALL_EMPLOYEES_RESET,
  GET_ALL_EMPLOYEES_SUCC,
} from "../../constants/users/getAllEmployees";

export const getAllEmployeesReducer = (
  state = { employees: [], loading: false, error: null, allProjectDetails: {} },
  action
) => {
  switch (action.type) {
    case GET_ALL_EMPLOYEES_REQ:
      return {
        loading: true,
        ...state,
      };

    case GET_ALL_EMPLOYEES_SUCC:
      return {
        loading: false,
        employees: action.payload,
        paginationDetails: action.allProjectDetails,
      };

    case GET_ALL_EMPLOYEES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case GET_ALL_EMPLOYEES_RESET:
      return {
        loading: true,
        employees: [],
      };

    default:
      return state;
  }
};
