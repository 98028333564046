import { GET_ALL_SUPPORT_FAILED, GET_ALL_SUPPORT_REQ, GET_ALL_SUPPORT_SUCC } from "../../constants/support/getAllSupportConsts";


export const getAllSUppoortReducer = (state={supports: [], loading:true, error:null, pagination:{}}, action) => {
    switch(action.type){
        case GET_ALL_SUPPORT_REQ:
            return{
                loading:true,
                ...state
            }

        case GET_ALL_SUPPORT_SUCC:
            return{
                loading:false,
                supports:action.payload,
                pagination:action.allProjectDetails
            }


        case GET_ALL_SUPPORT_FAILED:
        return{
            loading:false,
            error:action.payload
        }

        default:
            return state;
    }
}