import classNames from 'classnames';
import { DownloadSimple } from 'phosphor-react';
import React from 'react'
import { documents } from '../../../../fake/faleDoc';

const AllDocs = () => {


  return (
    <>
{
            documents.map((d, index) => {
                return <div key={index}>
                    <div className={classNames('flex py-4 px-6', 'border-b border-bottom-border', '')}>
                        <div className='w-[25%] text-xs font-medium'>
                            <p className='uppercase'>id</p>
                            <p className='opacity-50'>{d.uid}</p>
                        </div>

                        <div className='w-[35%] text-xs gap-2'>
                            <p className=' leading-3'>Lorem Ipsum Pack</p>
                            <p className='leading-4 font-light'><span className='opacity-50 mr-1'>{d.details.docName.split('.')[0]}</span> <span className='uppercase text-blue-300  underline'>{d.details.docName.split('.')[1]}</span></p>
                        </div>

                        <div className='text-xs w-[25%]'>
                            <p className=''>Last Edit</p>
                            <p className='opacity-50 font-semibold capitalize'>{d.lastEdit}</p>
                        </div>


                        <div className='text-xs flex flex-1 gap-2 justify-end  font-medium'>
                            <DownloadSimple className='bg-#F7F6F6  p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded' size={30} />
                        </div>
                    </div>

                </div>
            })
        }
    </>
  )
}

export default AllDocs