import classNames from 'classnames'
import { CaretRight } from 'phosphor-react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DASHBOARD_OPEN } from '../../../../redux/constants/AdminConsts/LeftNavAdminConst'
import { CLOSE_ADD_NEW_SUBSCRIPTION } from '../../../../redux/reducers/Admin/AddNewSubscriptionReducer'

const TopLinks = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const clickedOnDashBoard = () => {
        dispatch({type: DASHBOARD_OPEN})
        navigate('/')
      }

      const {openAddNewSubscription} = useSelector(state => state.addNewSubscription)

  return (
    <>
        <div className='flex items-center'>
            <p onClick={clickedOnDashBoard} className='text-link-green text-very-tiny font-medium cursor-pointer'>Dashboard</p>
            <p onClick={() => dispatch({type:CLOSE_ADD_NEW_SUBSCRIPTION})} className={classNames('text-very-tiny font-medium flex items-center', (openAddNewSubscription)&&"text-theme-green")}><CaretRight color='#000' className='mx-1.5' size={10}  /> <span className={classNames('cursor-pointer', )}>My Workspace</span> </p>
            {openAddNewSubscription &&  <p className={classNames('text-very-tiny font-medium flex items-center',)}><CaretRight color='#000' className='mx-1.5' size={10}  /> <span className={classNames('cursor-pointer', )}>Add Subscription Plan</span> </p>}
        </div>
    </>
  )
}

export default TopLinks