/* eslint-disable no-unused-vars */
import { MagnifyingGlass, Plus } from "phosphor-react";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { addAssetToTheDocumentApi } from "../../../../api/users/document";
import { getSingleDocumentAssets } from "../../../../redux/actions/documents/getAssetOfADocument";
import { Filter } from "../../../../styles/FilterButton";
import { Assests } from "../../../fake/fakeAssets";
import { FakeProjects } from "../../../fake/fakeProject";
import { FakeSuppliers } from "../../../fake/fakeSuppliers";
import AddProjectToAsset from "../../Assets/comps/singleAssetComps/AddProjectToAsset";
import SingleProject from "../../Assets/comps/singleAssetComps/assetLinks/SingleProject";
import Loading from "../../loading/Loading";
import AddAssetToProject from "../../Projects/comps/singleProjectComps/AddAssetsToProject";
import SingleAsset from "../../Projects/comps/singleProjectComps/projectLinks/SingleAsset";
import SingleSupplier from "../../Projects/comps/singleProjectComps/projectLinks/SingleSupplier";

const AssetsProjectsAndCompanies = ({ isAdmin }) => {
  const [active, setActive] = useState({
    assets: true,
    projects: false,
    companies: false,
  });
  const selectedProject = useSelector((state) => state.singleProject).project;

  const [addNewAsset, setAddNewAsset] = useState(false);

  const addNewAssetToDoc = () => {
    setAddNewAsset(true);
  };

  const closeAddNewAssetTab = () => {
    setAddNewAsset(false);
  };

  const [addNewProject, setAddNewProject] = useState(false);

  const addNewProjectToDoc = () => {
    setAddNewProject(true);
  };

  const closeAddNewProjectTab = () => {
    setAddNewProject(false);
  };

  const { assets: allAssetsOfTheDoc, loading } = useSelector(
    (state) => state.getAssetsOfADocument
  );

  const dispatch = useDispatch();

  const docID = useLocation().pathname.split("/")[2];

  console.log("docID", docID);

  useEffect(() => {
    dispatch(getSingleDocumentAssets({ page: 1, limit: 500, docId: docID }));
  }, []);

  const [assetsToAdd, setAssetsToAdd] = useState([]);
  const { document } = useSelector((state) => state.singleDocument);

  const addAssetHandler = async () => {
    try {
      let assetIds = assetsToAdd?.map((a) => {
        return a._id;
      });
      toast.loading("Adding Asset to the document");
      await addAssetToTheDocumentApi({
        assetIds: [...assetIds],
        documentId: docID,
      });
      dispatch(getSingleDocumentAssets({ page: 1, limit: 500, docId: docID }));

      toast.success("Asset added successfully");
      toast.dismiss();
    } catch (error) {
      toast.dismiss();

      toast.error("something went wrong. Please try again.");
    }
  };
  return (
    <>
      {!(addNewAsset || addNewProject) && (
        <div className="bg-white rounded-md">
          <div className="px-10 py-[26px]  bg-[#F7F6F6] border-b border-bottom-border rounded-t-md">
            <div className="flex gap-2 justify-between">
              <Filter
                onClick={() =>
                  setActive({ assets: true, projects: false, companies: false })
                }
                active={active.assets}
              >
                Assets
              </Filter>
              {/* <Filter
                onClick={() =>
                  setActive({ assets: false, projects: true, companies: false })
                }
                active={active.projects}
              >
                Projects
              </Filter>
              <Filter
                onClick={() =>
                  setActive({ assets: false, projects: false, companies: true })
                }
                active={active.companies}
              >
                Companies
              </Filter> */}
            </div>
          </div>

          {active.assets && !loading ? (
            <div>
              {allAssetsOfTheDoc?.docs?.map((p, index) => {
                return <SingleAsset key={index} p={p} />;
              })}
              {!isAdmin && (
                <div className="px-6 flex w-full justify-end mt-6">
                  <Plus
                    onClick={addNewAssetToDoc}
                    className="bg-[#00000033] p-1 cursor-pointer text-white border border-transparent hover:bg-[#DDDDDD] hover:text-black hover:border-[#00000033] rounded"
                    size={28}
                    weight="light"
                  />
                </div>
              )}
            </div>
          ) : (
            <Loading loading />
          )}

          {/* {active.projects && (
            <div>
              {FakeProjects.map((p, index) => {
                return <SingleProject key={index} p={p} />;
              })}
              <div className="px-6 flex w-full justify-end mt-6">
                <Plus
                  onClick={addNewProjectToDoc}
                  className="bg-[#00000033] p-1 cursor-pointer text-white border border-transparent hover:bg-[#DDDDDD] hover:text-black hover:border-[#00000033] rounded"
                  size={28}
                  weight="light"
                />
              </div>
            </div>
          )} */}

          {/* {active.companies && (
            <div className="">
              {FakeSuppliers.map((s, idx) => {
                return <SingleSupplier s={s} key={idx} />;
              })}
            </div>
          )} */}
        </div>
      )}
      {addNewAsset && (
        <AddAssetToProject
          assetsToAdd={assetsToAdd}
          setAssetsToAdd={setAssetsToAdd}
          existingAssets={allAssetsOfTheDoc?.docs}
          purpose={"document"}
          closeAddNewProjectTab={closeAddNewAssetTab}
          addAssetsHandler={addAssetHandler}
          projectId={document?.projectId?._id}
        />
      )}
      {addNewProject && (
        <AddProjectToAsset
          purpose={"document"}
          closeAddNewProjectTab={closeAddNewProjectTab}
        />
      )}
    </>
  );
};

export default AssetsProjectsAndCompanies;
