import { CLOSE_SINGLE_EMPLOYEE_ADMIN, GET_SINGLE_EMPLOYEE_FAIL_ADMIN, GET_SINGLE_EMPLOYEE_REQ_ADMIN, GET_SINGLE_EMPLOYEE_SUCC_ADMIN } from "../../constants/AdminConsts/EmployeesAdminConst";

export const singleEmployeeAdminReducer = (state={employee : {}, loading:true, open:false, errro:null}, action)=> {
        switch(action.type){
            case GET_SINGLE_EMPLOYEE_REQ_ADMIN:
                return {loading :true, ...state}

            case GET_SINGLE_EMPLOYEE_SUCC_ADMIN:
                return{
                    loading: false,
                    employee: action.payload,
                    open:true
                }
            
            case GET_SINGLE_EMPLOYEE_FAIL_ADMIN:
                return{
                    loading:false,
                    error:action.payload
                }
            
            case CLOSE_SINGLE_EMPLOYEE_ADMIN:
                return{
                    loading:false,
                    open:false,
                    employee: undefined,

                }
                
            
            default:
                return state;
        }
}