/* eslint-disable no-unused-vars */
import { MagnifyingGlass, Plus } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Filter } from "../../../../styles/FilterButton";
import SingleComp from "../../../components/Assets/comps/singleAssetComps/assetLinks/SingleComp";
import SingleDoc from "../../../components/Assets/comps/singleAssetComps/assetLinks/SingleDoc";
import AddAssetToProject from "../../../components/Projects/comps/singleProjectComps/AddAssetsToProject";
import SingleAsset from "../../../components/Projects/comps/singleProjectComps/projectLinks/SingleAsset";
import { Assests } from "../../../fake/fakeAssets";
import { FakeProjects } from "../../../fake/fakeProject";
import { FakeSuppliers } from "../../../fake/fakeSuppliers";
import { documents } from "../../../fake/faleDoc";

import AddDocument from "../../../components/Supplier/SingleSupplier/AddDocument";
import { getAssetsOfAProjectAction } from "../../../../redux/actions/asset/getAssetOfAProject";
import { getSuppliersOfAProjectAction } from "../../../../redux/actions/suppliers/geSupplierOfAProject";
import { getAllDocsAction } from "../../../../redux/actions/documents/getAllDoc";
import { toast } from "react-toastify";
import { addAssetToProjectApi } from "../../../../api/users/projects";
import SingleSupplier from "../../../components/Projects/comps/singleProjectComps/projectLinks/SingleSupplier";
import { downloadDoc } from "../../../../api/users/fileUpload";
import { useLocation } from "react-router-dom";

const AssetsDocAndSupplies = () => {
  const [active, setActive] = useState({
    assets: true,
    doc: false,
    suppliers: false,
  });
  const selectedAsset = useSelector((state) => state.singleAssetAdmin).asset;

  const [addNewProject, setAddNewProject] = useState(false);

  const addNewProjectToAsset = () => {
    setAddNewProject(true);
  };

  const [addNewDoc, setAddNewDoc] = useState(false);

  const closeAddNewProjectTab = () => {
    setAddNewProject(false);
    setAddNewDoc(false);
  };

  const addNewDocTo = () => {
    setAddNewDoc(true);
  };

  // integration
  const dispatch = useDispatch();

  const location = useLocation().pathname;

  const projectId = location.split("/")[3];

  const { open, project } = useSelector((state) => state.singleProjectAdmin);

  const { suppliers, loading: suppliersDropDownLOading } = useSelector(
    (state) => state.getSuppliersOFAProject
  );

  const { assets: assetsOfAProject, loading: ProjectDropDownLoading } =
    useSelector((state) => state.getAssetsOFAProject);

  const { docs } = useSelector((state) => state.getAllDoc);

  useEffect(() => {
    dispatch(getAssetsOfAProjectAction(projectId));
    dispatch(getSuppliersOfAProjectAction(projectId));
  }, [addNewProject]);

  const [docSearchInput, setDocSearchInput] = useState("");

  useEffect(() => {
    dispatch(getAllDocsAction({ q: docSearchInput, projectId: projectId }));
  }, [dispatch, docSearchInput]);

  const [assetsToAdd, setAssetsToAdd] = useState([]);

  const addAssetsHandler = async () => {
    try {
      toast.loading("Adding assets");
      let arrAssets = assetsToAdd.map((e) => {
        return e._id;
      });

      let allAssetsToAdd = {
        assetIds: [...arrAssets],
        projectId: projectId,
      };

      await addAssetToProjectApi(allAssetsToAdd);
      setAssetsToAdd([]);
      toast.dismiss();
      toast.success("Asset Added");

      setAddNewProject(false);

      // const res = await
    } catch (e) {
      toast.dismiss();

      toast.error(e.response.data.message);
    }
  };
  const downloadDocumnet = async (d) => {
    const url = await downloadDoc(d.data);
    window.open(url, "_blank");
  };

  return (
    <>
      {!(addNewProject || addNewDoc) && (
        <div className=" bg-white rounded-md">
          <div className="px-10 py-8  bg-[#F7F6F6] border-b border-bottom-border rounded-t-md">
            <div className="flex gap-2 justify-between">
              <Filter
                onClick={() =>
                  setActive({ assets: true, doc: false, comp: false })
                }
                active={active.assets}
              >
                Assets
              </Filter>
              <Filter
                onClick={() =>
                  setActive({ assets: false, doc: true, comp: false })
                }
                active={active.doc}
              >
                Documents
              </Filter>
              <Filter
                onClick={() =>
                  setActive({ assets: false, doc: false, comp: true })
                }
                active={active.comp}
              >
                Suppliers
              </Filter>
            </div>
          </div>

          {active.assets && (
            <div>
              {assetsOfAProject.length !== 0 &&
                assetsOfAProject !== undefined &&
                assetsOfAProject?.map((p, index) => {
                  return <SingleAsset key={index} p={p} />;
                })}
              {/* <div className='px-6 flex w-full justify-end mt-6'>
                            <Plus onClick={addNewProjectToAsset} className='bg-[#00000033] p-1 cursor-pointer text-white border border-transparent hover:bg-[#DDDDDD] hover:text-black hover:border-[#00000033] rounded' size={28} weight='light'/>
                        </div> */}
            </div>
          )}
          {active.doc && (
            <div className="relative h-[85%]">
              {docs?.map((d, index) => {
                return (
                  <SingleDoc
                    download={() => downloadDocumnet(d)}
                    key={index}
                    d={d}
                  />
                );
              })}
              {/* <div className='px-6 flex w-full justify-end mt-6'>
                        <Plus onClick={addNewDocTo} className='bg-[#00000033] p-1 cursor-pointer text-white border border-transparent hover:bg-[#DDDDDD] hover:text-black hover:border-[#00000033] rounded' size={28} weight='light'/>
                    </div> */}
            </div>
          )}

          {active.suppliers && (
            <div className="">
              {suppliers.length !== 0 &&
                assetsOfAProject !== undefined &&
                suppliers.map((s, idx) => {
                  return <SingleSupplier s={s} key={idx} />;
                })}
            </div>
          )}
        </div>
      )}
      {addNewProject && (
        <AddAssetToProject
          assetsToAdd={assetsToAdd}
          setAssetsToAdd={setAssetsToAdd}
          purpose={"project"}
          addAssetsHandler={addAssetsHandler}
          closeAddNewProjectTab={closeAddNewProjectTab}
        />
      )}
      {addNewDoc && (
        <AddDocument
          purpose={"supplier"}
          closeAddNewDocTab={closeAddNewProjectTab}
        />
      )}
    </>
  );
};

export default AssetsDocAndSupplies;
