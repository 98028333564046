import { Navigate, Route } from "react-router-dom";
import { getCurrentUser } from "./cognito";

import React from "react";

function PrivateRoute({ children }) {
  let auth = getCurrentUser();

  return auth ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
