import { CaretDoubleLeft, CaretDoubleRight, CaretLeft, CaretRight } from 'phosphor-react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAssets, setCount } from '../../../../redux/actions/asset/assetAction';

const Pagination = () => {
    const { pagination,count,assets,assetType,assetStatus } = useSelector((state) => state.assets);
    const { user } = useSelector((state) => state.me);
   
   
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getAssets({limit: count,page:1,assetType: assetType, assetStatus:assetStatus}));
    }, [count]);

    const {companyId} = useSelector(state=> state.selectCompany)

  return (
    <>
        <div className="mt-3 bg-white rounded-md">
        <div className="px-6 flex justify-between items-center py-5">
          <div className="flex gap-1">
            <CaretDoubleLeft
             onClick={() => {
                dispatch(getAssets({companyId:companyId,limit:count,page:1,assetType,assetStatus}))
              }}
              className="p-1.5 border-[0.5px] cursor-pointer border-bottom-border bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded"
              size={26}
              weight="bold"
            />
            <CaretLeft
            onClick={() => {
                if (pagination?.hasPrevPage === true){
                    dispatch(getAssets({companyId:companyId,limit:count,page:pagination?.prevPage,assetType,assetStatus}));
                }
               
              }}
              className="p-1.5 border-[0.5px] cursor-pointer border-bottom-border bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded"
              size={26}
              weight="bold"
            />
            {pagination?.hasPrevPage === true && (
              <p
                onClick={() => {
                  dispatch(getAssets({companyId:companyId,limit:count,page:pagination?.prevPage,assetType,assetStatus}));
                }}
                className="pt-[5px] leading-2 cursor-pointer bg-theme-light-green pb-0.5 w-7 text-center px-2.5 rounded hover:bg-theme-green font-medium hover:border-[#00000029] border-[0.5px] border-theme-green text-very-tiny"
              >
                {pagination?.prevPage}
              </p>
            )}
            {
              <p    className="pt-[5px] cursor-pointer leading-2 bg-theme-green pb-0.5 px-2.5 w-7 text-center rounded font-medium  border-[0.5px] border-[#00000029] text-very-tiny">
                {pagination?.page}
              </p>
            }
            {pagination?.hasNextPage === true && (
              <p   
              onClick={() => {
                dispatch(getAssets({companyId:companyId,limit:count,page:pagination?.nextPage,assetType,assetStatus}));
              }}
              className="pt-[5px] leading-2 cursor-pointer bg-theme-light-green pb-0.5 w-7 text-center px-2.5 rounded hover:bg-theme-green font-medium hover:border-[#00000029] border-[0.5px] border-theme-green text-very-tiny">
                {pagination?.nextPage}
              </p>
            )}
            <CaretRight
            onClick={() => {
                if (pagination?.hasNextPage === true){
                    dispatch(getAssets({companyId:companyId,limit:count,page:pagination?.nextPage,assetType,assetStatus}));
                }
               
              }}
              className="p-1.5 border-[0.5px] cursor-pointer border-bottom-border bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded"
              size={26}
              weight="bold"
            />
            <CaretDoubleRight
            onClick={() => {
                dispatch(getAssets({companyId: companyId,limit: count, page:pagination?.totalPages,assetType,assetStatus}));
              }}
              className="p-1.5 border-[0.5px] cursor-pointer border-bottom-border bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded"
              size={26}
              weight="bold"
            />
          </div>

          <div className="flex text-very-tiny gap-4 items-center">
            {pagination && <p>Displaying {((pagination?.page - 1) * count ) + 1} - {(pagination?.page - 1) * count + pagination?.docs?.length} of {pagination?.totalDocs}</p>}
            <div className="py-1 bg-[#f7f6f6] rounded-md px-1.5 border border-[rgba(0, 0, 0, 0.1)]">
              <select
              onChange={(e)=>{dispatch(setCount(e.target.value))}}  
                value={count}
                className="h-7 bg-[#f7f6f6] focus:outline-none"
                name=""
                id=""
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Pagination