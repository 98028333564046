import classNames from "classnames";
import { ArrowRight } from "phosphor-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const DocumentsDropDown = ({ s, onClick, notExpandable }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="">
        <div
          className={classNames(
            "flex py-5 border-b border-color-border bg-very-light-gray px-6"
          )}
        >
          <div className="w-1/6 text-xs font-medium">
            <p className="uppercase">id</p>
            <p className="opacity-50 text-theme-black">{s.uid}</p>
          </div>

          <div className="w-1/6 text-xs font-medium">
            <div className="">
              <p className="">Version</p>
              <p className="text-theme-black opacity-50 cursor-pointer ">
                {s.version}{" "}
                {!notExpandable && (
                  <i
                    className={classNames("fa-solid ml-1 ", "fa-angle-down")}
                  ></i>
                )}
              </p>
            </div>
          </div>

          <div className="w-3/12 text-xs font-medium">
            <div className="flex gap-2 items-center">
              <div>
                <p className="font-medium ">{s.name}</p>
                {s.type && (
                  <p className="leading-4 font-light">
                    <span className="opacity-50 mr-1">{s.type.type}</span>{" "}
                    <span className="uppercase text-blue-300 font-medium underline">
                      {s.type.code}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="w-4/12 text-xs font-medium">
            <div className="">
              <p className="">Status</p>
              <p
                className={classNames(
                  " cursor-pointer ",
                  s.status === "Approved"
                    ? "text-theme-green"
                    : "text-theme-black opacity-50"
                )}
              >
                {s.status}
              </p>
            </div>
          </div>

          <div className="text-xs flex-1 flex justify-end font-medium">
            <ArrowRight
              onClick={() => navigate(`/documents/${s._id}`)}
              className="bg-#F7F6F6 p-2 cursor-pointer rounded border"
              size={28}
              weight="light"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentsDropDown;
