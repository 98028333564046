import { GET_SUPPLIERS_FAIL, GET_SUPPLIERS_REQ, GET_SUPPLIERS_SUCC,   } from "../../constants/suppliers/Suppliers"

export const  SuppliersReducer = (state={suppliers : [], loading:true, errro:null }, action)=> {
    switch(action.type){
        case GET_SUPPLIERS_REQ:
            return {loading :true, ...state}

        case GET_SUPPLIERS_SUCC:
            return{
                loading: false,
                suppliers: action.payload,
                open:true,
                pagination:action.pagnationDetails,
            }
        
        case GET_SUPPLIERS_FAIL:
            return{
                loading:false,
                error:action.payload,
                suppliers: []
            }

         
        
        default:
            return state;
    }
}