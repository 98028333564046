import classNames from "classnames";
import { ArrowRight } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { getCompanyAccess } from "../../../../../api/users/users";
import Loading from "../../../../components/loading/Loading";

const AllCompanies = ({
  userData,
  accesssibleCompanies,
  setAccessibleCompanies,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const func = async () => {
      try {
        setLoading(true);
        const res = await getCompanyAccess(userData._id);
        setLoading(false);
        setAccessibleCompanies(res.data);
      } catch (err) {
        setLoading(false);
      }
    };
    func();
  }, []);

  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        accesssibleCompanies?.map((p, index) => {
          return (
            <div key={index}>
              <div
                className={classNames(
                  "flex items-center py-4 px-6",
                  "border-b border-bottom-border",
                  ""
                )}
              >
                <div className="w-[12%] text-xs font-medium">
                  <p className="uppercase">id</p>
                  <p className="opacity-50">{p?.uid}</p>
                </div>

                <div className="flex w-[32%] items-center text-xs gap-2">
                  <div className="text-xs font-medium">
                    <div className="flex gap-2 items-center">
                      <div>
                        {/* <img src={`${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${p.profilePic}`} className='w-8 p-0.5 border border-theme-green rounded-full' alt="" /> */}
                        <p className="w-9 h-9 tracking-wide flex justify-center items-center text-white font-bold text-[18px] bg-theme-green p-0.5 border border-theme-green rounded-full">
                          {p.name.split(" ")[0][0]}
                          {p.name.split(" ")[1] ? p.name.split(" ")[1][0] : ""}
                        </p>
                      </div>
                      <div>
                        <p className="font-medium ">{p?.name}</p>
                        {p?.companyAdmin && (
                          <p className="opacity-60">
                            {p?.companyAdmin}(company admin)
                          </p>
                        )}
                        {p.address && (
                          <p className=" font-normal  opacity-50">
                            {p?.address?.street1
                              ? p?.address?.street1 + ","
                              : ""}{" "}
                            {p?.address?.street2
                              ? p?.address?.street2 + ","
                              : ""}{" "}
                            {p?.address?.city ? p?.address?.city + "," : ""}{" "}
                            {p?.address?.state ? p?.address?.state + "," : ""}
                            {p?.address?.country ? p?.address?.country : ""}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {p?.domain && (
                  <div className="w-[18%] text-xs gap-2">
                    <p className="font-medium">Domain</p>
                    <p className="text-theme-black opacity-50 font-medium">
                      {p?.domain}
                    </p>
                  </div>
                )}

                {/* <div className=' w-[12%] text-xs'>
                                <div className=''>
                                    <p className='leading-3'>Assets</p>
                                    <p onClick={() => assetDropDonwOPener(p)} className='text-theme-black opacity-50 cursor-pointer leading-3'>{Assests.length} <i className={classNames("fa-solid ml-1 ", (assetsDropDown && clickedId === p._id) ? "fa-angle-up" : 'fa-angle-down')}></i></p>
                                </div>
                            </div> */}

                {p.stats && (
                  <div className=" w-[16%] text-xs">
                    <div className="">
                      <p className="leading-3">Projects</p>
                      <p className="text-theme-black opacity-50 cursor-pointer leading-3">
                        {p.stats?.projects}{" "}
                      </p>
                    </div>
                  </div>
                )}

                {p.stats && (
                  <div className=" w-[16%] text-xs">
                    <div className="">
                      <p className="leading-3">Documents</p>
                      <p className="text-theme-black opacity-50 cursor-pointer leading-3">
                        {p.stats?.documents}{" "}
                      </p>
                    </div>
                  </div>
                )}

                <div className="text-xs flex flex-1 justify-end font-medium">
                  <ArrowRight
                    className="bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
                    size={28}
                    weight="light"
                  />
                </div>
              </div>
            </div>
          );
        })
      )}
    </>
  );
};

export default AllCompanies;
