import styled from "styled-components";

export const MapFilter = styled.div`
${
    props => props.active ? `
    z-index:999;
    &{
        p{
            opacity:100
            }
        svg {
        opacity:100
        }
    }

    ` : `

    `
    }
        &:hover{
        z-index:999;
        &{
            p{
                opacity:100
            }
            svg {
                opacity:100
            }
        }
    }
`