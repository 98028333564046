import classNames from 'classnames'
import { CaretRight } from 'phosphor-react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CLOSE_ADD_NEW_NOTIFICATIONS } from '../../../redux/reducers/Admin/CreateNewNotificatioReducer'

const TopLink = () => {
    const {openAddNewNotifications} = useSelector(state => state.addNewNotifications)
    const dispatch = useDispatch()
    const navigate = useNavigate()

  return (
    <>
        <div className='flex items-center'>
            <p onClick={() => navigate('/')} className='text-link-green text-very-tiny font-medium cursor-pointer'>Dashboard</p>
            <p onClick={() => dispatch({type: CLOSE_ADD_NEW_NOTIFICATIONS})} className={classNames('text-very-tiny font-medium flex items-center',)}><CaretRight color='#000' className='mx-1.5' size={10}  /> <span className={classNames('cursor-pointer',openAddNewNotifications&& "text-theme-green" )}>Notifications</span> </p>
           { openAddNewNotifications&& <p className={classNames('text-very-tiny font-medium flex items-center',)}><CaretRight color='#000' className='mx-1.5' size={10}  /> <span className={classNames('cursor-pointer')}>Create New Notifications</span> </p>}
        </div>
    </>
  )
}

export default TopLink