
import axios from "axios";
import { serialize } from "../../libs/serialize";
import { accessToken } from "../../libs/token";

export const getAllNotifications = async (page, limit, userId) => {
    const uri = serialize({page, limit, userId})
    return axios.get(`${process.env.REACT_APP_API_URL}/notification?${uri}`, await accessToken());
};

export const updateReadStatus = async (data) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/notification`, data, await accessToken());

}

export const createNewNotifications = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/notification`, data, await accessToken());

}