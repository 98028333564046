import classNames from "classnames";
import { Buildings, CaretDown, MagnifyingGlass, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAssets } from "../../../../../redux/actions/asset/assetAction";
import { Assests } from "../../../../fake/fakeAssets";
import Loading from "../../../loading/Loading";
import SingleAsset from "./projectLinks/SingleAsset";

const AddAssetToProject = ({
  existingAssets,
  closeAddNewProjectTab,
  purpose,
  addAssetsHandler,
  assetsToAdd,
  setAssetsToAdd,
  projectId,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const tobeExcludedIds = existingAssets.map((a) => {
    return a._id;
  });

  const dispatch = useDispatch();
  const showDropDownFunc = () => {
    setShowDropDown(!showDropDown);
  };

  const [selectedAssetsArr, setselectedAssetsArr] = useState(assetsToAdd);

  const selectedProjects = (p) => {
    if (!selectedAssetsArr.includes(p)) {
      setselectedAssetsArr([...selectedAssetsArr, p]);
    }

    setShowDropDown(false);
  };

  const removeProject = (s) => {
    let newArr = [...selectedAssetsArr];
    let newArr2 = newArr.filter((p) => p.id !== s.id);
    setselectedAssetsArr(newArr2);
  };

  useEffect(() => {
    setAssetsToAdd([...selectedAssetsArr]);
  }, [selectedAssetsArr]);

  const [search, setSearch] = useState("");
  const { companyId } = useSelector((state) => state.selectCompany);

  useEffect(() => {
    if (companyId) {
      const timer = setTimeout(() => {
        dispatch(
          getAssets({
            companyId: companyId,
            limit: 10,
            page: 1,
            search,
            excludedIds: tobeExcludedIds,
            projectId: projectId ? projectId : "",
          })
        );
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [search, companyId, dispatch]);

  const { assets, loading } = useSelector((state) => state.assets);

  return (
    <>
      <div className="bg-[#EFEBE9] flex-1 relative rounded-md ">
        <div className="px-6 flex">
          <p className="bg-theme-black rounded-b-md text-white px-5 text-very-tiny font-medium py-1.5">
            Asset Status
          </p>
        </div>

        <div className="mt-16 ">
          <p className="text-theme-black text-xs text-center font-medium">
            Add an existing asset to the {purpose}
          </p>

          <div className="mx-6 relative my-2">
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onClick={showDropDownFunc}
              placeholder={
                showDropDown ? "Select Existing Assets" : "Add Asset"
              }
              type="text"
              className={classNames(
                "w-full border border-[#00000033] bg-white cursor-pointer rounded-t-md focus:outline-none py-3 text-very-tiny",
                showDropDown ? "px-4" : "px-[36px] rounded-b-md"
              )}
            />
            {!showDropDown && (
              <Buildings
                size={16}
                weight="bold"
                className="absolute text-theme-black opacity-80 left-3 top-[13px]"
              />
            )}
            {showDropDown ? (
              <MagnifyingGlass
                size={14}
                className="absolute text-theme-black opacity-80 right-3 top-[14px]"
              />
            ) : (
              <CaretDown
                size={14}
                className="absolute text-theme-black opacity-80 right-3 top-[14px]"
              />
            )}
            {showDropDown && (
              <div className="border-b min-h-[10rem] bg-white  absolute z-[99] w-full cursor-pointer max-h-64 overflow-y-auto border-[#00000033]">
                {loading ? (
                  <Loading loading />
                ) : assets.length === 0 ? (
                  <p className="text-center pt-12">No result found</p>
                ) : (
                  assets.map((p, index) => {
                    return (
                      <SingleAsset
                        onClick={() => selectedProjects(p)}
                        key={index}
                        p={p}
                      />
                    );
                  })
                )}
              </div>
            )}
            <div className="grid grid-cols-2 gap-2 mt-6 rounded-lg">
              {selectedAssetsArr?.map((s, idx) => {
                return (
                  <div key={idx} className="bg-white rounded-lg">
                    <p className="text-xs font-medium bg-[#DAD2D2] p-2 rounded-t-lg flex justify-between items-center">
                      Item {idx + 1}
                      <X
                        onClick={() => removeProject(s)}
                        className="cursor-pointer"
                        size={12}
                      />
                    </p>
                    <div className="p-2">
                      <p className="text-xs py-1">
                        UID: <span className="opacity-50">ID {s.uid}</span>
                      </p>
                      <p className="text-xs py-1">
                        Owner:{" "}
                        <span className="opacity-50">{s.companyId.name}</span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="absolute flex w-full px-3 gap-2 bottom-3">
          <button
            onClick={closeAddNewProjectTab}
            className="flex-1 w-1/2 py-2 bg-transparent border-2 border-bottom-border uppercase text-sm font-bold rounded-lg hover:bg-white hover:border-[#00000066]"
          >
            <span className="opacity-40">Cancel</span>
          </button>
          <button
            onClick={addAssetsHandler}
            className="flex-1 w-1/2 py-2 bg-theme-light-green text-theme-green border-2 border-theme-green uppercase text-sm font-bold rounded-lg hover:bg-theme-green hover:text-black"
          >
            <span className="">Save</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default AddAssetToProject;
