export const OPEN_ADD_NEW_SUBSCRIPTION = 'ADD_NEW_SUBSCRIPTION'
export const CLOSE_ADD_NEW_SUBSCRIPTION ='CLOSE_ADD_NEW_SUBSCRIPTION'

export const addNewSubscriptionReducer = (state={openAddNewSubscription:false}, action) => {
    switch(action.type){
        case OPEN_ADD_NEW_SUBSCRIPTION:
            return{
                openAddNewSubscription:true
            }
        case CLOSE_ADD_NEW_SUBSCRIPTION:
            return{
                openAddNewSubscription:false
            }
        default:
            return state;
        
    }
}