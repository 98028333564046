import { ADVANCED_SEARCH_OPEN, ASSETS_OPEN, DASHBOARD_OPEN, DOCUMENTS_OPEN, GLOBAL_OPEN, NOTIFICATIONS_OPEN, PROFILE_OPEN, PROJECTS_OPEN, SUPPLIERS_OPEN, SUPPORT_CHAT_OPEN, WORKSPACE_OPEN } from "../constants/LeftNavConstant"

const defaultState = {
    global: false,
    dashboard:true,
    assets: false,
    projects: false,
    documents: false,
    suppliers: false,
    profile:false,
    workspace:false,
    notifications:false,
    support:false,
    advancedSearch:false
}


export const LeftNavRedcer = (state= defaultState, action) => {
    switch(action.type){
        case GLOBAL_OPEN:
            return {
                global: true,
                dashboard:false,
                assets: false,
                projects: false,
                documents: false,
                suppliers: false,
                profile:false,
                workspace:false,
                notifications:false,
                support:false,
                advancedSearch:false
            }

        case DASHBOARD_OPEN:
            return {
                global: false,
                dashboard:true,
                assets: false,
                projects: false,
                documents: false,
                suppliers: false,
                profile:false,
                workspace:false,
                notifications:false,
                support:false,
                advancedSearch:false
            }

        case ASSETS_OPEN:
            return {
                global: false,
                dashboard:false,
                assets: true,
                projects: false,
                documents: false,
                suppliers: false,
                profile:false,
                workspace:false,
                notifications:false,
                support:false,
                advancedSearch:false
            }
        
        case PROJECTS_OPEN:
            return {
                global: false,
                dashboard:false,
                assets: false,
                projects: true,
                documents: false,
                suppliers: false,
                profile:false,
                workspace:false,
                notifications:false,
                support:false,
                advancedSearch:false
            }

        case DOCUMENTS_OPEN:
            return {
                global: false,
                dashboard:false,
                assets: false,
                projects: false,
                documents: true,
                suppliers: false,
                profile:false,
                workspace:false,
                notifications:false,
                support:false,
                advancedSearch:false
            }

        case SUPPLIERS_OPEN:
            return {
                global: false,
                dashboard:false,
                assets: false,
                projects: false,
                documents: false,
                suppliers: true,
                profile:false,
                workspace:false,
                notifications:false,
                support:false,
                advancedSearch:false
            }
        
        case PROFILE_OPEN:
            return{
                global: false,
                dashboard:false,
                assets: false,
                projects: false,
                documents: false,
                suppliers: false,
                profile:true,
                workspace:false,
                notifications:false,
                support:false,
                advancedSearch:false
            }

        case WORKSPACE_OPEN:
            return{
                global: false,
                dashboard:false,
                assets: false,
                projects: false,
                documents: false,
                suppliers: false,
                profile:false,
                workspace:true,
                notifications:false,
                support:false,
                advancedSearch:false
            }

        case NOTIFICATIONS_OPEN:
            return{
                global: false,
                dashboard:false,
                assets: false,
                projects: false,
                documents: false,
                suppliers: false,
                profile:false,
                workspace:false,
                notifications:true,
                support:false,
                advancedSearch:false
            }

            case SUPPORT_CHAT_OPEN:
                return{
                    global: false,
                    dashboard:false,
                    assets: false,
                    projects: false,
                    documents: false,
                    suppliers: false,
                    profile:false,
                    workspace:false,
                    notifications:false,
                    support:true,
                    advancedSearch:false
                }
            
                case ADVANCED_SEARCH_OPEN:
                    return{
                        global: false,
                        dashboard:false,
                        assets: false,
                        projects: false,
                        documents: false,
                        suppliers: false,
                        profile:false,
                        workspace:false,
                        notifications:false,
                        support:false,
                        advancedSearch:true
                    }
        
        default:
            return state
    }
}