export const OPEN_ADD_NEW_NOTIFICATIONS = 'ADD_NEW_NOTIFICATIONS'
export const CLOSE_ADD_NEW_NOTIFICATIONS ='CLOSE_ADD_NEW_NOTIFICATIONS'

export const addNewNotificationsReducer = (state={openAddNewNotifications:false}, action) => {
    switch(action.type){
        case OPEN_ADD_NEW_NOTIFICATIONS:
            return{
                openAddNewNotifications:true
            }
        case CLOSE_ADD_NEW_NOTIFICATIONS:
            return{
                openAddNewNotifications:false
            }
        default:
            return state;
        
    }
}