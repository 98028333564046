import { getCompaniesApi } from "../../../api/users/company";
import {
  GET_COMPANIES_FAIL,
  GET_COMPANIES_REQ,
  GET_COMPANIES_SUCC,
} from "../../constants/companies/companiesConst";
export const getCompanies =
  (limit = 5, page = 1, q = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_COMPANIES_REQ });
      const res = await getCompaniesApi(limit, page, q);

      dispatch({
        type: GET_COMPANIES_SUCC,
        payload: res.data.docs,
        pagnationDetails: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_COMPANIES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
