import {
  ArrowDown,
  CaretDown,
  NotePencil,
  Plus,
  Trash,
  X,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addAssetTypeApi,
  deleteAssetTypeApi,
  editAssetTypeApi,
  getAssetsOptions,
} from "../../../api/users/asset";
import {
  createSingleCtegoryApi,
  createSingleSubCtegoryApi,
  deleteSingleCategoryApi,
  deleteSingleSubCategoryApi,
  editSingleCategoryApi,
  editSingleSubCategoryApi,
  getCategoriesApi,
  getSubCategoriesApi,
} from "../../../api/users/category";
import { upload } from "../../../api/users/fileUpload";
import { getAllCategoriesAction } from "../../../redux/actions/categories/getAllCategories";
import { getAllSubCategoriesAction } from "../../../redux/actions/categories/getAllSubCategories";

const AssetType = () => {
  const [openModal, setOpenModal] = useState(false);

  const [category, setcategory] = useState("");

  // api for cateogories

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCategoriesAction());
  }, []);

  const { categories } = useSelector((state) => state.getCategories);
  const { subcategories } = useSelector((state) => state.getAllSubCategories);

  const createCategory = () => {
    setOpenModal(true);
  };

  const addCategoryHandler = async () => {
    try {
      toast.loading("Creating Category");
      await createSingleCtegoryApi({ name: category });
      toast.dismiss();
      toast.success("Sucessfully Created");
      dispatch(getAllCategoriesAction());
      setOpenModal(false);
      setcategory("");
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  const deleteCategory = async (b) => {
    try {
      toast.loading("Deleting the category");

      await deleteSingleCategoryApi(b._id);
      toast.dismiss();

      toast.success(`Deleted the categoty of name ${b.name}`);

      // so that it loads the latest data
      dispatch(getAllCategoriesAction());
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  const [editcatOpenModal, setEditCatOpenModal] = useState(false);
  const [tobeEdittedName, setTobeEdditedName] = useState();
  const [tobeEdittedId, setTobeEdittedId] = useState("");
  const editCategoryOpener = (b) => {
    setTobeEdditedName(b.name);
    setEditCatOpenModal(true);
    setTobeEdittedId(b._id);
  };

  const editCategoryHandler = async (b) => {
    try {
      toast.loading("Saving the edited Category");

      await editSingleCategoryApi(tobeEdittedId, { name: tobeEdittedName });
      toast.dismiss();

      toast.success("Saved the edited category");

      setEditCatOpenModal(false);
      // so that it loads the latest data

      dispatch(getAllCategoriesAction());
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  const [catId, setCatId] = useState("");
  const [showSub, setShowSub] = useState(false);

  const showSubCategory = (b) => {
    dispatch(getAllSubCategoriesAction(b._id));
    setCatId(b._id);
    setShowSub(catId === b._id ? !showSub : true);
  };

  // add subCategory

  const [catIdOfSubCat, setCatIdOfSubCat] = useState("");
  const [subCategory, setsubCategory] = useState("");
  const [openSubCategoryModal, setOpenSubCategoryModal] = useState(false);

  const addSubCategoryHandler = async (b) => {
    try {
      toast.loading("Creating Category");
      await createSingleSubCtegoryApi({
        categoryId: catIdOfSubCat,
        name: subCategory,
      });
      toast.dismiss();
      toast.success("Added Subcategory");
      dispatch(getAllSubCategoriesAction());
      setOpenSubCategoryModal(false);
      setcategory("");
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  const deleteSubCategory = async (s) => {
    try {
      toast.loading("Deleting the Subcategory");

      await deleteSingleSubCategoryApi(s._id);
      toast.dismiss();

      toast.success(`Deleted the subcategoty of name ${s.name}`);

      // so that it loads the latest data
      dispatch(getAllSubCategoriesAction());
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  const [editSubcatOpenModal, setEditSubCatOpenModal] = useState(false);
  const [tobeEdittedSubName, setTobeEdditedSubName] = useState();
  const [tobeEdittedSubId, setTobeEdittedSubId] = useState("");

  const editSubCategoryOpener = (s) => {
    setTobeEdditedSubName(s.name);
    setEditSubCatOpenModal(true);
    setTobeEdittedSubId(s._id);
  };

  const editSubCategoryHandler = async () => {
    try {
      toast.loading("Saving the edited subcategory");

      await editSingleSubCategoryApi(tobeEdittedSubId, {
        name: tobeEdittedSubName,
      });
      toast.dismiss();

      toast.success("Saved the edited subcategory");

      setEditSubCatOpenModal(false);
      // so that it loads the latest data

      dispatch(getAllSubCategoriesAction());
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  // asset type
  const [openAssetTypeModel, setOpenAssetTYpeModal] = useState(false);
  const [assetOptions, setAssetOptions] = useState();

  const [assetName, setAssetName] = useState("");
  const [assetID, setAssetID] = useState("");
  const [categoryOFAsset, setcategoryOFAsset] = useState("");
  const [subcategoryOFAsset, setsubcategoryOFAsset] = useState("");
  const [icon, setICon] = useState("");

  useEffect(() => {
    if (categoryOFAsset) {
      dispatch(getAllSubCategoriesAction(categoryOFAsset));
    }
  }, [categoryOFAsset]);

  const [catOptions, setCatOptions] = useState();
  const [subcatOptions, setsubCatOptions] = useState();

  useEffect(() => {
    if (categories) {
      let options = categories.map((c) => {
        return { value: c._id, label: c.name };
      });
      setcategory(options);
    }
  }, [categories]);

  useEffect(() => {
    if (categoryOFAsset) {
    }
  }, []);

  const fetchAssetOptions = async () => {
    try {
      const res = await getAssetsOptions();
      setAssetOptions(res.data.docs);
    } catch (error) { }
  };

  useEffect(() => {
    fetchAssetOptions();
  }, []);

  const addAssetTypeHandler = async () => {
    try {
      const data =
        subcategoryOFAsset === ""
          ? {
            name: assetName,
            id: assetID,
            categoryId: categoryOFAsset,
            icon: icon,
          }
          : {
            name: assetName,
            id: assetID,
            categoryId: categoryOFAsset,
            subCategoryId: subcategoryOFAsset,
            icon: icon,
          };
      toast.loading("Creating Asset Type");
      await addAssetTypeApi(data);
      toast.dismiss();
      toast.success("Sucessfully Created");
      fetchAssetOptions();
      setOpenAssetTYpeModal(false);
      setAssetName("");
      setAssetID("");
      setcategoryOFAsset("");
      setsubcategoryOFAsset("");
      setICon();
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  const [deleteConfirmationAssetType, setDeleteConfirmationAssetType] =
    useState(false);
  const [tobeDeletedAssetType, setTOBeDeletedAssetType] = useState("");

  const removeAssetType = async () => {
    try {
      toast.loading("Deleting the Asset Type");

      await deleteAssetTypeApi(tobeDeletedAssetType);
      toast.dismiss();

      toast.success(`Deleted the Asset Type.`);

      setTOBeDeletedAssetType("");
      setDeleteConfirmationAssetType(false);

      // so that it loads the latest data
      fetchAssetOptions();
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  const [tobeEditAssetType, setTobeEditAssetType] = useState("");
  const [editAssetOpenModal, setEditAssetOpenModal] = useState(false);
  const [edittedassetName, setedittedAssetName] = useState("");
  const [edittedassetID, setedittedAssetID] = useState("");
  const [edittedcategoryOFAsset, setedittedcategoryOFAsset] = useState("");
  const [edittedsubcategoryOFAsset, setedittedsubcategoryOFAsset] =
    useState("");
  const [edittedIconOFAsset, setedittedIconOFAsset] = useState("");

  const openAssetEdit = (b) => {
    setEditAssetOpenModal(true);
    setedittedAssetName(b.name);
    setedittedAssetID(b.id);
    setedittedcategoryOFAsset(b.categoryId._id);
    setedittedsubcategoryOFAsset(b.subCategoryId._id);
    setTobeEditAssetType(b._id);
    setedittedIconOFAsset(b.icon ? b.icon : "");
  };

  useEffect(() => {
    if (edittedcategoryOFAsset !== "") {
      dispatch(getAllSubCategoriesAction(edittedcategoryOFAsset));
    }
  }, [edittedcategoryOFAsset]);

  const editAssetTypeHandler = async () => {
    try {
      toast.loading("Saving the edited Asset Type");

      const data =
        edittedcategoryOFAsset !== ""
          ? {
            name: edittedassetName,
            id: edittedassetID,
            categoryId: edittedcategoryOFAsset,
            subCategoryId: edittedsubcategoryOFAsset,
            icon: edittedIconOFAsset,
          }
          : {
            name: edittedassetName,
            id: edittedassetID,
            categoryId: edittedcategoryOFAsset,
            icon: edittedIconOFAsset,
          };
      await editAssetTypeApi(tobeEditAssetType, data);
      toast.dismiss();

      toast.success("Saved the edited Asset Type");

      setEditAssetOpenModal(false);
      // so that it loads the latest data

      fetchAssetOptions();
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  // uploadImage

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  useEffect(() => {
    const call = async () => {
      if (!selectedFile) {
        setPreview(undefined);
        return;
      }
      toast.loading("Uploading asset icon");
      const url = await upload(selectedFile);
      if (editAssetOpenModal) {
        setedittedIconOFAsset(url);
      } else {
        setICon(url);
      }
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      toast.dismiss();
    };
    call();
  }, [selectedFile]);

  const uploadImg = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  return (
    <>
      <div className="grid grid-cols-2 min-h-[80vh] mt-8 p-6 rounded-lg bg-white rounded-md gap-6">
        <div className="bg-[#EFEBE9] rounded-md">
          <button
            onClick={createCategory}
            className="border border-theme-green rounded flex gap-2 px-3 py-2 text-xs items-center text-theme-green mt-4 ml-4"
          >
            <Plus size={16} /> Categories
          </button>
          <div className="mt-6 px-4 grid gap-2">
            {categories?.map((b, index) => {
              return (
                <div className="rounded-md bg-white border p-2">
                  <div
                    className="flex items-center justify-between"
                    key={index}
                  >
                    <p className="text-sm">{b.name}</p>
                    <div className="flex items-center gap-2">
                      <Plus
                        onClick={() => {
                          setOpenSubCategoryModal(true);
                          setCatIdOfSubCat(b._id);
                        }}
                        color="#323132B2"
                        weight="bold"
                        size={16}
                      />
                      <NotePencil
                        color="#323132B2"
                        onClick={() => editCategoryOpener(b)}
                        weight="bold"
                        className="cursor-pointer"
                        size={16}
                      />
                      <CaretDown
                        color="#323132B2"
                        onClick={() => showSubCategory(b)}
                        weight="bold"
                        className="cursor-pointer"
                        size={16}
                      />
                      <Trash
                        color="#323132B2"
                        onClick={() => deleteCategory(b)}
                        weight="bold"
                        className="cursor-pointer"
                        size={16}
                      />
                    </div>
                  </div>
                  {catId === b._id &&
                    showSub &&
                    (subcategories?.length === 0 ? (
                      <p className="text-xs pt-1.5 mt-1.5 border-t">
                        No Subcategory found.
                      </p>
                    ) : (
                      subcategories?.map((s, index) => {
                        return (
                          <div className="pt-1.5 mt-1.5 border-t" key={index}>
                            <div className="flex items-center justify-between">
                              <p className="text-sm">{s.name}</p>
                              <div className="flex items-center gap-2">
                                <NotePencil
                                  color="#323132B2"
                                  onClick={() => editSubCategoryOpener(s)}
                                  weight="bold"
                                  className="cursor-pointer"
                                  size={16}
                                />
                                <Trash
                                  color="#323132B2"
                                  onClick={() => deleteSubCategory(s)}
                                  weight="bold"
                                  className="cursor-pointer"
                                  size={16}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ))}
                </div>
              );
            })}
          </div>
        </div>

        {/* asset type  */}

        <div className="bg-[#EFEBE9] rounded-md">
          <button
            onClick={() => setOpenAssetTYpeModal(true)}
            className="border border-theme-green rounded flex gap-2 px-3 py-2 text-xs items-center text-theme-green mt-4 ml-4"
          >
            <Plus size={16} /> Asset Type
          </button>
          <div className="mt-6 px-4 grid grid-cols-2 gap-2 rounded-md">
            {assetOptions?.map((b, index) => {
              return (
                <div className="rounded-lg bg-white border">
                  <div className="bg-white rounded-lg">
                    <div className="text-xs font-medium bg-[#DAD2D2] p-2 rounded-t-lg flex justify-between items-center">
                      <p>Item {index + 1}</p>
                      <div className="flex items-center gap-2">
                        <NotePencil
                          onClick={() => openAssetEdit(b)}
                          color="#323132B2"
                          weight="bold"
                          className="cursor-pointer"
                          size={16}
                        />
                        <Trash
                          color="#323132B2"
                          onClick={() => {
                            setDeleteConfirmationAssetType(true);
                            setTOBeDeletedAssetType(b._id);
                          }}
                          weight="bold"
                          className="cursor-pointer"
                          size={16}
                        />
                      </div>
                    </div>

                    {b.icon && (
                      <div className="mt-5 ml-5">
                        <img
                          src={`${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${b.icon}`}
                          className="w-12 h-12 rounded-full object-cover"
                          alt=""
                        />{" "}
                      </div>
                    )}
                    <div className="py-3 m-3 border-b mb-3">
                      <p className="text-xs">
                        {b.categoryId?.name} | {b.subCategoryId?.name}{" "}
                      </p>
                    </div>

                    <div className="flex px-3 pb-5 text-xs justify-between items-start">
                      <p className="w-3/5 font-medium">{b.name}</p>
                      <p className="flex-1">{b.id}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* add modal */}
      {openModal && (
        <div
          onClick={() => setOpenModal(false)}
          className="fixed w-[100vw] h-[100vh] top-0 left-0 z-[9999] bg-black bg-opacity-20"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute w-[25rem] p-4 bg-white rounded-md border-[3px] border-theme-black border-opacity-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <div className="mt-4  pb-4 border-b">
              <p className="font-medium text-xs">Add Category</p>
              <input
                value={category}
                placeholder="Category Name"
                onChange={(e) => setcategory(e.target.value)}
                type="text"
                className="w-full focus:outline-none text-xs mt-3 px-4 py-2 border border-black-10 rounded"
              />
            </div>

            <div className="py-4 grid gap-4 grid-cols-2">
              <button
                onClick={() => setOpenModal(false)}
                className="py-2 bg-[#F7F6F6] rounded-md hover:bg-[#0000001a] hover:border-[#00000033] border border-black10 text-xs text-theme-black text-opacity-80"
              >
                {" "}
                No
              </button>
              <button
                onClick={addCategoryHandler}
                className="py-2 bg-theme-green text-xs rounded-md"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {/* edit modal */}
      {editcatOpenModal && (
        <div
          onClick={() => setEditCatOpenModal(false)}
          className="fixed w-[100vw] h-[100vh] top-0 left-0 z-[9999] bg-black bg-opacity-20"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute w-[25rem] p-4 bg-white rounded-md border-[3px] border-theme-black border-opacity-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <div className="mt-4  pb-4 border-b">
              <p className="font-medium text-xs">Edit Category</p>
              <input
                value={tobeEdittedName}
                placeholder="Category Name"
                onChange={(e) => setTobeEdditedName(e.target.value)}
                type="text"
                className="w-full focus:outline-none text-xs mt-3 px-4 py-2 border border-black-10 rounded"
              />
            </div>

            <div className="py-4 grid gap-4 grid-cols-2">
              <button
                onClick={() => setEditCatOpenModal(false)}
                className="py-2 bg-[#F7F6F6] rounded-md hover:bg-[#0000001a] hover:border-[#00000033] border border-black10 text-xs text-theme-black text-opacity-80"
              >
                {" "}
                No
              </button>
              <button
                onClick={editCategoryHandler}
                className="py-2 bg-theme-green text-xs rounded-md"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {/* add sub modal */}
      {openSubCategoryModal && (
        <div
          onClick={() => setOpenModal(false)}
          className="fixed w-[100vw] h-[100vh] top-0 left-0 z-[9999] bg-black bg-opacity-20"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute w-[25rem] p-4 bg-white rounded-md border-[3px] border-theme-black border-opacity-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <div className="mt-4  pb-4 border-b">
              <p className="font-medium text-xs">Add SubCategory</p>
              <input
                value={subCategory}
                placeholder="Category Name"
                onChange={(e) => setsubCategory(e.target.value)}
                type="text"
                className="w-full focus:outline-none text-xs mt-3 px-4 py-2 border border-black-10 rounded"
              />
            </div>

            <div className="py-4 grid gap-4 grid-cols-2">
              <button
                onClick={() => setOpenSubCategoryModal(false)}
                className="py-2 bg-[#F7F6F6] rounded-md hover:bg-[#0000001a] hover:border-[#00000033] border border-black10 text-xs text-theme-black text-opacity-80"
              >
                {" "}
                No
              </button>
              <button
                onClick={addSubCategoryHandler}
                className="py-2 bg-theme-green text-xs rounded-md"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {/* edit sub modal */}
      {editSubcatOpenModal && (
        <div
          onClick={() => setEditSubCatOpenModal(false)}
          className="fixed w-[100vw] h-[100vh] top-0 left-0 z-[9999] bg-black bg-opacity-20"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute w-[25rem] p-4 bg-white rounded-md border-[3px] border-theme-black border-opacity-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <div className="mt-4  pb-4 border-b">
              <p className="font-medium text-xs">Edit Category</p>
              <input
                value={tobeEdittedSubName}
                placeholder="Category Name"
                onChange={(e) => setTobeEdditedSubName(e.target.value)}
                type="text"
                className="w-full focus:outline-none text-xs mt-3 px-4 py-2 border border-black-10 rounded"
              />
            </div>

            <div className="py-4 grid gap-4 grid-cols-2">
              <button
                onClick={() => setEditSubCatOpenModal(false)}
                className="py-2 bg-[#F7F6F6] rounded-md hover:bg-[#0000001a] hover:border-[#00000033] border border-black10 text-xs text-theme-black text-opacity-80"
              >
                {" "}
                No
              </button>
              <button
                onClick={editSubCategoryHandler}
                className="py-2 bg-theme-green text-xs rounded-md"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {/* add asset type modal */}
      {openAssetTypeModel && (
        <div
          onClick={() => setOpenAssetTYpeModal(false)}
          className="fixed w-[100vw] h-[100vh] top-0 left-0 z-[9999] bg-black bg-opacity-20"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute w-[25rem] p-4 bg-white rounded-md border-[3px] border-theme-black border-opacity-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <div className="mt-4  pb-4 border-b">
              <p className="font-medium text-xs">Add Asset List</p>

              {/* icon */}
              <div className=" mt-5">
                {/* <p className="text-[12px]">Asset Icon</p> */}
                <input
                  accept=".png,.jpg,.jpeg,.svg"
                  onChange={uploadImg}
                  type="file"
                  id="fileUp"
                  style={{ display: "none" }}
                />
                <div className="flex">
                  <label className="cursor-pointer" htmlFor="fileUp">
                    <div className="mt-2 w-12 h-12 p-[1px] border border-theme-green rounded-full">
                      {preview ? (
                        <img
                          src={preview}
                          className="h-full w-full rounded-full object-cover"
                          alt=""
                        />
                      ) : edittedIconOFAsset ? (
                        <img
                          src={`${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${edittedIconOFAsset}`}
                          className="h-full w-full rounded-full object-cover"
                          alt=""
                        />
                      ) : (
                        <div className="w-full rounded-full h-full bg-[#3231320D] flex justify-center items-center">
                          <Plus size={12} />
                        </div>
                      )}
                    </div>
                  </label>
                </div>
              </div>

              {/* icon end */}
              <input
                value={assetName}
                placeholder="Asset Name"
                onChange={(e) => setAssetName(e.target.value)}
                type="text"
                className="w-full focus:outline-none text-xs mt-3 px-4 py-2 border border-black-10 rounded"
              />
              <input
                value={assetID}
                placeholder="Asset ID"
                onChange={(e) => setAssetID(e.target.value)}
                type="text"
                className="w-full focus:outline-none text-xs mt-3 px-4 py-2 border border-black-10 rounded"
              />

              <div className="border-t mt-4">
                <select
                  onChange={(e) => setcategoryOFAsset(e.target.value)}
                  value={categoryOFAsset}
                  className="w-full focus:outline-none text-xs mt-3 px-4 py-2 border border-black-10 rounded"
                  name=""
                  id=""
                >
                  <option value="">Select Category</option>
                  {categories?.map((c, index) => {
                    return (
                      <option key={index} value={c._id}>
                        {c.name}
                      </option>
                    );
                  })}
                </select>

                {subcategories?.length !== 0 && categoryOFAsset !== "" && (
                  <select
                    value={subcategoryOFAsset}
                    onChange={(e) => setsubcategoryOFAsset(e.target.value)}
                    className="w-full focus:outline-none text-xs mt-3 px-4 py-2 border border-black-10 rounded"
                    name=""
                    id=""
                  >
                    <option value="">Select Subcategory</option>

                    {subcategories?.map((c, index) => {
                      return (
                        <option key={index} value={c._id}>
                          {c.name}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
            </div>

            <div className="py-4 grid gap-4 grid-cols-2">
              <button
                onClick={() => setOpenAssetTYpeModal(false)}
                className="py-2 bg-[#F7F6F6] rounded-md hover:bg-[#0000001a] hover:border-[#00000033] border border-black10 text-xs text-theme-black text-opacity-80"
              >
                {" "}
                No
              </button>
              <button
                onClick={addAssetTypeHandler}
                className="py-2 bg-theme-green text-xs rounded-md"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {/* edit asset type modal */}
      {editAssetOpenModal && (
        <div
          onClick={() => setOpenAssetTYpeModal(false)}
          className="fixed w-[100vw] h-[100vh] top-0 left-0 z-[9999] bg-black bg-opacity-20"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute w-[25rem] p-4 bg-white rounded-md border-[3px] border-theme-black border-opacity-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <div className="mt-4  pb-4 border-b">
              <p className="font-medium text-xs">Edit Asset List</p>
              {/* icon */}
              <div className=" mt-5">
                {/* <p className="text-[12px]">Asset Icon</p> */}
                <input
                  accept=".png,.jpg,.jpeg,.svg"
                  onChange={uploadImg}
                  type="file"
                  id="fileUp"
                  style={{ display: "none" }}
                />
                <div className="flex">
                  <label className="cursor-pointer" htmlFor="fileUp">
                    <div className="mt-2 w-12 h-12 p-[1px] border border-theme-green rounded-full">
                      {preview ? (
                        <img
                          src={preview}
                          className="h-full w-full rounded-full object-cover"
                          alt=""
                        />
                      ) : (
                        <div className="w-full rounded-full h-full bg-[#3231320D] flex justify-center items-center">
                          <Plus size={12} />
                        </div>
                      )}
                    </div>
                  </label>
                </div>
              </div>

              {/* icon end */}
              <input
                value={edittedassetName}
                placeholder="Asset Name"
                onChange={(e) => setedittedAssetName(e.target.value)}
                type="text"
                className="w-full focus:outline-none text-xs mt-3 px-4 py-2 border border-black-10 rounded"
              />
              <input
                value={edittedassetID}
                placeholder="Asset ID"
                onChange={(e) => setedittedAssetID(e.target.value)}
                type="text"
                className="w-full focus:outline-none text-xs mt-3 px-4 py-2 border border-black-10 rounded"
              />

              <div className="border-t mt-4">
                <select
                  onChange={(e) => setedittedcategoryOFAsset(e.target.value)}
                  value={edittedcategoryOFAsset}
                  className="w-full focus:outline-none text-xs mt-3 px-4 py-2 border border-black-10 rounded"
                  name=""
                  id=""
                >
                  <option value="">Select Category</option>
                  {categories?.map((c, index) => {
                    return (
                      <option key={index} value={c._id}>
                        {c.name}
                      </option>
                    );
                  })}
                </select>

                {edittedcategoryOFAsset !== "" && (
                  <select
                    value={edittedsubcategoryOFAsset}
                    onChange={(e) =>
                      setedittedsubcategoryOFAsset(e.target.value)
                    }
                    className="w-full focus:outline-none text-xs mt-3 px-4 py-2 border border-black-10 rounded"
                    name=""
                    id=""
                  >
                    <option value="">Select Subcategory</option>

                    {subcategories?.map((c, index) => {
                      return (
                        <option key={index} value={c._id}>
                          {c.name}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
            </div>

            <div className="py-4 grid gap-4 grid-cols-2">
              <button
                onClick={() => setEditAssetOpenModal(false)}
                className="py-2 bg-[#F7F6F6] rounded-md hover:bg-[#0000001a] hover:border-[#00000033] border border-black10 text-xs text-theme-black text-opacity-80"
              >
                {" "}
                No
              </button>
              <button
                onClick={editAssetTypeHandler}
                className="py-2 bg-theme-green text-xs rounded-md"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {/* delete Confirmation for asset Type */}
      {deleteConfirmationAssetType && (
        <div
          onClick={() => setDeleteConfirmationAssetType(false)}
          className="fixed w-[100vw] h-[100vh] top-0 left-0 z-[9999] bg-black bg-opacity-20"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute w-[25rem] p-4 bg-white rounded-md border-[3px] border-theme-black border-opacity-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <div className="mt-4  pb-4 border-b">
              <p className="font-medium text-xs">
                Are you sure you want to delete this Asset Type? It can't be
                undone.
              </p>
            </div>

            <div className="py-4 grid gap-4 grid-cols-2">
              <button
                onClick={() => setDeleteConfirmationAssetType(false)}
                className="py-2 bg-[#F7F6F6] rounded-md hover:bg-[#0000001a] hover:border-[#00000033] border border-black10 text-xs text-theme-black text-opacity-80"
              >
                {" "}
                Back
              </button>
              <button
                onClick={removeAssetType}
                className="py-2 bg-theme-green text-xs rounded-md"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AssetType;
