/* eslint-disable no-unused-vars */
import classNames from "classnames";
import { CaretRight, NotePencil } from "phosphor-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CLOSE_SINGLE_EMPLOYEE_ADMIN } from "../../../../redux/constants/AdminConsts/EmployeesAdminConst";
import {
  DASHBOARD_OPEN,
  EMPLOYESS_LIST_OPEN,
} from "../../../../redux/constants/AdminConsts/LeftNavAdminConst";

const TopLinks = ({ showEditScreen, backToSingleAsset, editScreenHandler }) => {
  const dispatch = useDispatch();
  const { open, employee } = useSelector((state) => state.singleEmployeeAdmin);
  const { employess } = useSelector((state) => state.AdminLeftNav);

  const navigate = useNavigate();

  const location = useLocation().pathname;

  const addNew = location.split("/")[3] === "addNew";
  const singleEmployeeOpened =
    location.split("/")[3] !== undefined && location.split("/")[3] !== "addNew";
  return (
    <>
      <div className="flex items-center">
        <p
          onClick={() => {
            dispatch({ type: DASHBOARD_OPEN });
            navigate("/");
          }}
          className="text-link-green text-very-tiny font-medium cursor-pointer"
        >
          Dashboard
        </p>
        <p className="text-very-tiny font-medium flex items-center">
          <CaretRight className="mx-1.5" size={10} />{" "}
          <span
            onClick={() => {
              dispatch({ type: CLOSE_SINGLE_EMPLOYEE_ADMIN });
              dispatch({ type: EMPLOYESS_LIST_OPEN });
              navigate("/admin/employees");
            }}
            className={classNames(
              "cursor-pointer",
              (addNew || singleEmployeeOpened) && "text-link-green"
            )}
          >
            Employees
          </span>{" "}
        </p>

        {singleEmployeeOpened && (
          <div className={classNames("flex", !showEditScreen && "gap-3")}>
            <p className="text-very-tiny font-medium flex items-center">
              <CaretRight className="mx-1.5" size={10} />
              <span
                onClick={backToSingleAsset}
                className={classNames(
                  "cursor-pointer",
                  showEditScreen && "text-link-green"
                )}
              >
                {employee?.data?.firstName} {employee?.data?.lastName}
              </span>
            </p>
          </div>
        )}
        {addNew && (
          <p className="text-very-tiny font-medium flex items-center">
            <CaretRight className="mx-1.5" size={10} />{" "}
            <span className={classNames("cursor-pointer")}>
              Add new employee
            </span>{" "}
          </p>
        )}
      </div>
    </>
  );
};

export default TopLinks;
