import { FakeProjects } from "./fakeProject";
import { users } from "./fakeUser";
import { documents } from "./faleDoc";



export const FakeSuppliers = [
    {
        id:1,
        uid:'APC10',
        supplierDetails:{
            imgUrl:'images/user1.jpeg',
            companyName:'Sherlock Piping Ltd',
            address:"121, Baker St., London XY1 2Z",
        },
        companyAdmin: 'Sherlock Holmes',
        domain:"sherlockpiping.com"
        // projects:FakeProjects,
        // documents:documents,
        // users:users,

    },
    {
        id:2,
        uid:'APC11',
        supplierDetails:{
            imgUrl:'images/user1.jpeg',
            companyName:'John Constructions',
            address:"121, Baker St., London XY1 2Z",
        },
        companyAdmin: 'John Doe',
        domain:'jhoncons.com'
        // projects:FakeProjects,
        // documents:documents,
        // users:users,

    },
    {
        id:3,
        uid:'APC12',
        supplierDetails:{
            imgUrl:'images/user1.jpeg',
            companyName:'Valerie Piping Ltd',
            address:"121, Baker St., London XY1 2Z",
        },
        companyAdmin: 'Valerie Cooper',
        domain:"valerie.com"
        // projects:FakeProjects,
        // documents:documents,
        // users:users,

    }
]
