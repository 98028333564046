import {
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies } from "../../../../redux/actions/companies/getCompanies";
import { getSuppliers } from "../../../../redux/actions/suppliers/getSuppliers";

const Pagination = ({ limit, setLimit }) => {
  const { pagination } = useSelector((state) => state.suppliers);

  const { user } = useSelector((state) => state.me);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSuppliers(user.companyId._id, limit));
  }, [limit]);
  return (
    <>
      <div className="mt-3 bg-white rounded-md">
        <div className="px-6 flex justify-between items-center py-5">
          <div className="flex gap-1">
            <CaretDoubleLeft
              onClick={() => {
                dispatch(getSuppliers(user.companyId._id, limit, 1));
              }}
              className="p-1.5 border-[0.5px] border-bottom-border cursor-pointer bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded"
              size={26}
              weight="bold"
            />
            <CaretLeft
              onClick={() => {
                if (pagination?.hasPrevPage === true) {
                  dispatch(
                    getSuppliers(
                      user.companyId._id,
                      limit,
                      pagination?.prevPage
                    )
                  );
                }
              }}
              className="p-1.5 border-[0.5px] border-bottom-border cursor-pointer bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded"
              size={26}
              weight="bold"
            />
            {pagination?.hasPrevPage === true && (
              <p
                onClick={() => {
                  dispatch(
                    getSuppliers(
                      user.companyId._id,
                      limit,
                      pagination?.prevPage
                    )
                  );
                }}
                className="pt-[5px] cursor-pointer leading-2 bg-theme-light-green pb-0.5 w-7 text-center px-2.5 rounded hover:bg-theme-green font-medium hover:border-[#00000029] border-[0.5px] border-theme-green text-very-tiny"
              >
                {pagination?.prevPage}
              </p>
            )}
            {
              <p className="pt-[5px] cursor-pointer leading-2 bg-theme-green pb-0.5 px-2.5 w-7 text-center rounded font-medium  border-[0.5px] border-[#00000029] text-very-tiny">
                {pagination?.page}
              </p>
            }
            {pagination?.hasNextPage === true && (
              <p
                onClick={() => {
                  dispatch(
                    getSuppliers(
                      user.companyId._id,
                      limit,
                      pagination?.nextPage
                    )
                  );
                }}
                className="pt-[5px] cursor-pointer leading-2 bg-theme-light-green pb-0.5 w-7 text-center px-2.5 rounded hover:bg-theme-green font-medium hover:border-[#00000029] border-[0.5px] border-theme-green text-very-tiny"
              >
                {pagination?.nextPage}
              </p>
            )}
            <CaretRight
              onClick={() => {
                if (pagination?.hasNextPage === true) {
                  dispatch(
                    getSuppliers(
                      user.companyId._id,
                      limit,
                      pagination?.nextPage
                    )
                  );
                }
              }}
              className="p-1.5 border-[0.5px] border-bottom-border cursor-pointer bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded"
              size={26}
              weight="bold"
            />
            <CaretDoubleRight
              onClick={() => {
                dispatch(
                  getSuppliers(
                    user.companyId._id,
                    limit,
                    pagination?.totalPages
                  )
                );
              }}
              className="p-1.5 border-[0.5px] border-bottom-border cursor-pointer bg-[#f6f7f7] text-[#aaaaaa] hover:text-black hover:bg-[#00000029] hover:border-[#00000029] rounded"
              size={26}
              weight="bold"
            />
          </div>

          <div className="flex text-very-tiny gap-4 items-center">
            {pagination && (
              <p>
                Displaying {(pagination?.page - 1) * limit + 1} -{" "}
                {(pagination?.page - 1) * limit + pagination?.docs?.length} of{" "}
                {pagination?.totalDocs}
              </p>
            )}
            <div className="py-1 bg-[#f7f6f6] rounded-md px-1.5 border border-[rgba(0, 0, 0, 0.1)]">
              <select
                onChange={(e) => setLimit(e.target.value)}
                value={limit}
                className="h-7 bg-[#f7f6f6] focus:outline-none"
                name=""
                id=""
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pagination;
