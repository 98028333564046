import { NotePencil, Power } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { upload } from "../../../../api/users/fileUpload";
import { updateUser } from "../../../../api/users/inviteUsers";
import { signOut } from "../../../../libs/cognito";
import { getLoggedInUser } from "../../../../redux/actions/me/user";

const AccountDetails = () => {
  const { user } = useSelector((state) => state.me);

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    const call = async () => {
      if (!selectedFile) {
        setPreview(undefined);
        return;
      }
      const url = await upload(selectedFile);

      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      try {
        toast.loading("Updating Profile Pic");
        await updateUser(user._id, { profilePic: url });
        dispatch(getLoggedInUser());
        toast.dismiss();
        toast.success("Image Updated Successfully.");
      } catch (er) {
        toast.error(er);
      }
    };
    call();
  }, [selectedFile, dispatch]);

  const uploadImg = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  // useEffect(()=>{
  //     dispatch(getLoggedInUser())
  //     },[selectedFile])

  return (
    <>
      <div className="grid justify-items-center py-12 gap-4">
        {/* <img src="/images/user1.jpeg" className='w-14 p-[2px] rounded-full border border-theme-green' alt="" /> */}

        <div className="relative">
          <input
            accept=".png,.jpg,.jpeg,.svg"
            onChange={uploadImg}
            type="file"
            id="fileUp"
            style={{ display: "none" }}
          />
          <label htmlFor="fileUp">
            <img
              src={
                preview
                  ? preview
                  : `${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${user.profilePic}`
              }
              className="p-[1px] cursor-pointer rounded-full border h-11 w-11 object-cover border-theme-green"
              alt=""
            />
            {/* <div className="flex cursor-pointer absolute top-0 left-0 rounded-full w-full h-full justify-center items-center bg-black bg-opacity-20">
                    <NotePencil className="text-white " size={16} />
                  </div> */}
          </label>
        </div>
        <div className="grid justify-items-center text-xs font-semibold">
          <p>
            {user.firstName} {user.lastName}
          </p>
          <p className="opacity-50">{user.companyId.name}</p>
        </div>
      </div>

      <div className="grid gap-2 pb-4">
        <div className="bg-[#F7F6F6]  py-[20px] px-4 flex justify-between  rounded-md items-center border">
          <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
            Account Role
          </p>
          <p className="text-xs font-bold capitalize">{user.roleId.name}</p>
        </div>
      </div>

      <div className="flex justify-center py-12">
        <button
          onClick={() => {
            signOut();
          }}
          className="grid justify-items-center bg-[#F7F6F6] rounded-lg p-4 text-[10px] gap-1"
        >
          <Power size={16} />
          LOG OUT
        </button>
      </div>

      <p className="text-center py-12 text-xs">
        Copyright {new Date().getFullYear()} APEX INFORMATICS LTD.
      </p>
    </>
  );
};

export default AccountDetails;
