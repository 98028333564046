import { GET_SINGLE_DOCUMENT_FAIL, GET_SINGLE_DOCUMENT_REQ, GET_SINGLE_DOCUMENT_SUCC, HIDE_SINGLE_DOCUMENT } from "../../constants/documents/SingleDocumentOpener";
import { CLOSE_SINGLE_VERSION, OPEN_SINGLE_VERSION_FAIL, OPEN_SINGLE_VERSION_REQ, OPEN_SINGLE_VERSION_SUCC } from "../../constants/documents/VersionOpenerConsts";

export const singleDocumentReducer = (state={document : {}, loading:true, errro:null, open:false}, action)=> {
    switch(action.type){
        case GET_SINGLE_DOCUMENT_REQ:
            return {loading :true, ...state}

        case GET_SINGLE_DOCUMENT_SUCC:
            return{
                loading: false,
                document: action.payload,
                open:true
            }
        
        case GET_SINGLE_DOCUMENT_FAIL:
            return{
                loading:false,
                error:action.payload
            }

        case HIDE_SINGLE_DOCUMENT:
            return{
                open:false,
                document:undefined
            }
        
        default:
            return state;
    }
}

export const opneSingleVersionReducer = (state={version:{}, open:false},action) => {
    switch (action.type){
        case OPEN_SINGLE_VERSION_REQ:
            return{
                ...state
            }
        
        case OPEN_SINGLE_VERSION_SUCC:
            return{
                version: action.payload,
                open:true
            }

        case OPEN_SINGLE_VERSION_FAIL:
            return{
                version:action.payload,
                open:false
            }
        
        case CLOSE_SINGLE_VERSION:
            return{
                version:undefined,
                open:false
            }
        default:
            return state;
    }
}


