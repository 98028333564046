import { getAllDocs } from "../../../api/users/document";
import {
  GET_ALL_DOCS_FAIL,
  GET_ALL_DOCS_REQ,
  GET_ALL_DOCS_SUCC,
} from "../../constants/documents/getAllDocumnets";

export const getAllDocsAction =
  ({
    page = 1,
    limit = 5,
    status = "",
    type = "",
    projectId = "",
    q = "",
    order = 1,
    companyId = "",
    projectIds = [],
    userId = "",
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_DOCS_REQ });
      const res = await getAllDocs(
        page,
        limit,
        status,
        type,
        projectId,
        q,
        order,
        companyId,
        projectIds,
        userId
      );

      dispatch({
        type: GET_ALL_DOCS_SUCC,
        payload: res.data.docs,
        totalCount: res.data.totalCount[0],
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_DOCS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
