export const GLOBAL_OPEN = "GLOBAL_OPEN"

export const DASHBOARD_OPEN = "DASHBOARD_OPEN"

export const ASSETS_OPEN = "ASSETS_OPEN"

export const PROJECTS_OPEN = "PROJECTS_OPEN"

export const DOCUMENTS_OPEN = "DOCUMENTS_OPEN"

export const SUPPLIERS_OPEN = "SUPPLIERS_OPEN"

export const PROFILE_OPEN = "PROFILE_OPEN"

export const WORKSPACE_OPEN = "WORKSPACE_OPEN"

export const NOTIFICATIONS_OPEN = 'NOTIFICATIONS_OPEN'

export const SUPPORT_CHAT_OPEN = 'SUPPORT_CHAT_OPEN'

export const ADVANCED_SEARCH_OPEN = 'ADVANCED_SEARCH_OPEN'
