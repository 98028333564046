import { GET_ALL_DOCS_REQ, GET_ALL_DOCS_SUCC } from "../../constants/documents/getAllDocumnets";
import { GET_ALL_PROJECTS_FAIL } from "../../constants/projects/getAllProjects";

export const getAllDocReducer = (state={docs:[],totalCount:{}, loading:false, error:null}, action) => {
   switch(action.type){
       case GET_ALL_DOCS_REQ:
           return {
               loading:true
           }
        case GET_ALL_DOCS_SUCC:
            return{
                loading:false,
                docs:action.payload,
                totalCount:action.totalCount
            }

        case GET_ALL_PROJECTS_FAIL:
            return{
                loading:false,
                error:action.payload
            }

        default:
            return state;
   }
}