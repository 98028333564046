import React, { useEffect, useRef, useState } from "react";
import { X } from "phosphor-react";
import Loading from "../loading/Loading";
import classNames from "classnames";

function DocumentPopup({ documentUrl, closeModal, tryAgain }) {
  const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
    documentUrl
  )}&embedded=true`;

  let type = documentUrl.split("response-content-type=")[1].split("%2F")[1];
  let isImage =
    documentUrl.split("response-content-type=")[1].split("%2F")[1] === "png" ||
    documentUrl.split("response-content-type=")[1].split("%2F")[1] === "jpg" ||
    documentUrl.split("response-content-type=")[1].split("%2F")[1] === "jpeg";

  // loading for 10 sec
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoading(false);
    }, 10000); // 10 seconds

    return () => clearTimeout(timeout);
  }, []);

  const iframeRef = useRef(null);
  const [isIframeEmpty, setIsIframeEmpty] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const iframeDoc = iframeRef.current.contentDocument;
      if (iframeDoc.body.innerHTML === "") {
        setIsIframeEmpty(true);
      } else {
        setIsIframeEmpty(false);
      }
    }, 9000); // 9 seconds

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <div className="popup">
        <div className="fixed top-0 left-0 h-screen w-screen flex items-center justify-center">
          {isImage ? (
            <div className="w-1/2 relative bg-white h-5/6">
              <div
                className={classNames(
                  "w-full h-full bg-white",
                  showLoading ? "visible" : "hidden"
                )}
              >
                <Loading loading />
              </div>
              <img
                className={classNames(
                  "object-contain w-full h-full",
                  showLoading ? "hidden" : "visible"
                )}
                src={documentUrl}
                alt=""
              />
              <button
                onClick={closeModal}
                className="absolute z-[99] top-0 right-0 cursor-pointer text-white bg-black"
              >
                <X size={40} />
              </button>
            </div>
          ) : (
            <div className="w-1/2 h-5/6 relative">
              {
                <>
                  <div
                    className={classNames(
                      "w-full h-full bg-white",
                      showLoading ? "visible" : "hidden"
                    )}
                  >
                    <Loading loading />
                  </div>

                  <iframe
                    ref={iframeRef}
                    sandbox="allow-scripts allow-same-origin"
                    className={classNames(
                      "w-full h-full",
                      showLoading || isIframeEmpty ? "hidden" : "visible"
                    )}
                    src={viewerUrl}
                    title="Document"
                  />

                  {isIframeEmpty && (
                    <div className="w-full h-full bg-white">
                      <div className=" pt-20 flex flex-col justify-between items-center">
                        <p className="text-center">
                          Failed to load the document
                        </p>
                        <button
                          onClick={() => {
                            tryAgain();
                            // setIsIframeEmpty(false);
                            // setShowLoading(true);
                          }}
                          className="px-5 mt-5 text-sm py-2 border rounded"
                        >
                          Try again
                        </button>
                      </div>
                    </div>
                  )}
                </>
              }

              <button
                onClick={closeModal}
                className="absolute z-[99] top-3 right-3 cursor-pointer text-white bg-black"
              >
                <X size={40} />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DocumentPopup;
