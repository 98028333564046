/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Filter } from "../../../styles/FilterButton";
import AssetType from "./AssetType";
import DocumentType from "./DocumentType";

const MainDiv = styled.div`
  height: calc(100vh - 57px);
`;
const AdminOptions = () => {
  const [active, setActive] = useState({ asset: true, doc: false });
  const [assetTypes, setasseTypes] = useState([]);
  const [docTypes, setdocTypes] = useState([]);
  const { adminOptions } = useSelector((state) => state.AdminLeftNav);

  return (
    <>
      <MainDiv className="px-[32px] py-8 overflow-y-auto">
        <div className="flex gap-2">
          <Filter
            onClick={() => setActive({ asset: true, doc: false })}
            active={active.asset}
          >
            Asset Types
          </Filter>
          <Filter
            onClick={() => setActive({ asset: false, doc: true })}
            active={active.doc}
          >
            Document Types
          </Filter>
        </div>
        {active.asset && <AssetType />}
        {active.doc && <DocumentType />}
      </MainDiv>
    </>
  );
};
export default AdminOptions;
