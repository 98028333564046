import { getSingleSupplierApi } from "../../../api/users/company";
import {
  GET_SINGLE_COMPANY_FAIL_ADMIN,
  GET_SINGLE_COMPANY_REQ_ADMIN,
  GET_SINGLE_COMPANY_SUCC_ADMIN,
} from "../../constants/AdminConsts/SingleComapnyAdminConst";

export const openSingleEmployeeAdminAction =
  (id, compaines) => async (dispatch) => {
    try {
      dispatch({ type: GET_SINGLE_COMPANY_REQ_ADMIN });

      const res = await getSingleSupplierApi(id);

      dispatch({
        type: GET_SINGLE_COMPANY_SUCC_ADMIN,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SINGLE_COMPANY_FAIL_ADMIN,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
