/* eslint-disable no-unused-vars */
import classNames from 'classnames'
import { CaretRight, NotePencil } from 'phosphor-react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { CLOSE_SINGLE_ASSET_ADMIN } from '../../../../redux/constants/AdminConsts/AssetsAdminConst'
import { ASSETS_LIST_VIEW_OPEN, DASHBOARD_OPEN } from '../../../../redux/constants/AdminConsts/LeftNavAdminConst'

const TopLinks = ({showEditScreen, backToSingleAsset, editScreenHandler}) => {

    const dispatch = useDispatch()
    const {open, asset} = useSelector(state => state.singleAssetAdmin)
    const {  assets } = useSelector(state => state.AdminLeftNav)

    const navigate = useNavigate()

    const singleAssetOpened = useLocation().pathname.split('/')[3] !== undefined

  return (
    <>
        <div className='flex items-center'>
                <p onClick={() => {dispatch({type: DASHBOARD_OPEN}); navigate('/')}} className='text-link-green text-very-tiny font-medium cursor-pointer'>Dashboard</p>
                <p className='text-very-tiny font-medium flex items-center'><CaretRight className='mx-1.5' size={10}  /> <span onClick={() => {dispatch({type:CLOSE_SINGLE_ASSET_ADMIN}); dispatch({type:ASSETS_LIST_VIEW_OPEN}); navigate('/admin/assets')}} className={classNames('cursor-pointer', singleAssetOpened && "text-link-green")}>Assets</span> </p>

                { singleAssetOpened && <div className={classNames('flex',!showEditScreen && 'gap-3')}>
                        <p className='text-very-tiny font-medium flex items-center'><CaretRight className='mx-1.5' size={10}  /> 
                          <span onClick={backToSingleAsset} className={classNames('cursor-pointer', showEditScreen && "text-link-green")}>{asset?.name}</span> 
                        </p>
                        {/* edit screen  */}
                        {/* { (showEditScreen? 
                          <p className='text-very-tiny font-medium flex items-center'><CaretRight className='mx-1.5' size={10}  /> <span className={classNames('cursor-pointer',)}>Edit</span> </p>
                        :
                        <div className='bg-white p-[2px] rounded-full'>
                          <div onClick={editScreenHandler} active className='text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] py-1.5 px-3 rounded-full items-center gap-2'>
                              <NotePencil size={12} className='text-[#323132b3]' weight="bold" />
                              <p className=' text-[#323132b3]'>Edit</p>
                          </div>
                        </div>)
                        } */}
                </div>}
                {/* {assets.add && <p className='text-very-tiny font-medium flex items-center'><CaretRight className='mx-1.5' size={10}  /> <span  className={classNames('cursor-pointer')}>Create new asset</span> </p>} */}

            </div>
    </>
  )
}

export default TopLinks