import React from 'react'
import { useNavigate } from 'react-router-dom'

const UserSignUp = () => {
    const navigate = useNavigate()
  return (
    <>
        <div className='flex justify-center flex-col items-center w-[100vw] h-[100vh]'>
            <div >
                <img className='w-[14rem]' src="/images/ApexLogo_PNG.png" alt="" />
            </div>
            <div className='bg-white mt-3 w-1/4 rounded shadow-md p-4'>
                <p className='font-semibold text-center mt-3 text-[#000000CC]'>Sign up your account</p>

                <div className='grid text-xs pt-6 gap-2'>
                    <input placeholder='Email' type="text" className='focus:outline-none p-3 bg-[#FAFBFC] border rounded' />
                    <input placeholder='Password' type="password" className='focus:outline-none p-3 bg-[#FAFBFC] border rounded' />
                    <input placeholder='Repeate Password' type="text" className='focus:outline-none p-3 bg-[#FAFBFC] border rounded' />
                </div>

                <div className='flex mt-4 justify-center text-xs items-center'>
                    <p>Already have an account? <span onClick={() => navigate('/login')} className='text-[#2ED9C3] cursor-pointer'>Login here</span></p>
                </div>
                    <button className='w-full mt-5 font-medium text-xs py-2 bg-[#2ED9C3]'>Sign up</button>
            </div>

            <div className='mt-4 items-center text-xs text-[#00000099] flex gap-2 justify-center'>
                <p className='cursor-pointer'>Privacy Policy</p>
                <div className='w-[2px] h-[2px] rounded-full bg-black'></div>
                <p className='cursor-pointer'>Terms of service</p>
            </div>
        </div>
    </>
  )
}

export default UserSignUp