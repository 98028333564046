import { getAllUserApiBySandip } from "../../../api/users/users";
import {
  GET_ALL_EMPLOYEES_FAIL,
  GET_ALL_EMPLOYEES_REQ,
  GET_ALL_EMPLOYEES_SUCC,
} from "../../constants/users/getAllEmployees";

export const getAllEmployeesAction =
  ({ companyId = "", limit = 5, page = 1, q = "", status = "", roleId = "" }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_EMPLOYEES_REQ });
      const res = await getAllUserApiBySandip(
        companyId,
        status,
        page,
        limit,
        q,
        roleId
      );

      dispatch({
        type: GET_ALL_EMPLOYEES_SUCC,
        payload: res.data.docs,
        allProjectDetails: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_EMPLOYEES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
