import classNames from 'classnames'
import { ArrowRight, MagnifyingGlass } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatAMPM } from '../../../../libs/formatAMPM'
import { getConversationsAction } from '../../../../redux/actions/getSingleCoversationsAction'
import { getAllSupportsAction } from '../../../../redux/actions/support/getAllSupportAction'
import { OPEN_CREATE_NEW_SUPPORT_TICKET } from '../../../../redux/reducers/Admin/CreateNewHelpTicket'
import Loading from '../../../components/loading/Loading'
import Pagination from './Pagination'

const ShowCase = ({ setSelectedSupportId }) => {
    const dispatch = useDispatch()
    const { support } = useSelector(state => state.AdminLeftNav)

    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState(new Date().toISOString().substring(0, 10))
    const [limit, setLimit] = useState(5)

    const [search, setSearch] = useState('')

    const [status, setStatus] = useState('')


    useEffect(() => {
        dispatch(getAllSupportsAction({ fromDate, toDate, status, q: search, limit }))
    }, [fromDate, toDate, dispatch, status, search, limit])

    const { supports, loading, pagination } = useSelector(state => state.getAllSUppoort)

    const openSingleConversationHandler = (n) => {
        dispatch(getConversationsAction({ supportId: n._id, subject: n.subject }))
        setSelectedSupportId(n._id)
    }

    const openCreateNewTicket = () => {
        dispatch({ type: OPEN_CREATE_NEW_SUPPORT_TICKET })
    }
    return (
        <>
            <div className='bg-white mt-8 min-h-[80vh] rounded-lg py-8 pb-16'>
                <div className='flex px-6 justify-between items-center'>
                    <div className='flex gap-3 items-center'>
                        <div className='text-xs'>
                            <p className='text-xs'>From</p>
                            <input value={fromDate} onChange={(e) => setFromDate(e.target.value)} className='border focus:outline-none px-2 py-1 rounded border-black-10' type="date" />
                        </div>
                        <div className='text-xs'>
                            <p className='text-xs'>To</p>
                            <input value={toDate} onChange={(e) => setToDate(e.target.value)} className='border focus:outline-none px-2 py-1 rounded border-black-10' type="date" />
                        </div>

                        <select value={status} onChange={(e) => setStatus(e.target.value)} className='text-xs focus:outline-none ml-4 mt-4' name="" id="">
                            <option value="">Ticket status</option>
                            <option value="Awaiting support reply">Awaiting support reply</option>
                            <option value="Awaiting user reply">Awaiting user reply</option>
                            <option value="Closed">Closed</option>
                        </select>
                    </div>

                    {support.dev && <button onClick={openCreateNewTicket} className='px-4 uppercase border font-semibold border-theme-green hover:border-black text-xs py-2 mt-2 rounded-md font-medium cursor-pointer select-none hover:bg-theme-green bg-theme-light-green text-theme-green hover:text-black'>create new ticket</button>}

                </div>


                {/* Input and display */}

                <div className='flex border-b pb-6  px-6 justify-between items-center mt-12'>
                    <div className='relative text-very-tiny w-1/3'>
                        <input value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search for Project Detail, Owner or Location' className='w-full bg-[#f7f6f6] border-[1.5px] focus:outline-none rounded-md border-[rgba(0, 0, 0, 0.1)] py-2.5 px-4' type="text" />
                        <MagnifyingGlass className='absolute right-6 opacity-20 top-1/2 -translate-y-1/2' size={14} />
                    </div>
                    <div className='flex text-very-tiny gap-4 items-center'>
                        <p>Displaying {limit} of {pagination.totalDocs}</p>
                        <div className='py-1 bg-[#f7f6f6] rounded-md px-1.5 border border-[rgba(0, 0, 0, 0.1)]'>
                            <select value={limit} onChange={(e) => setLimit(e.target.value)} className='h-7 bg-[#f7f6f6] focus:outline-none' name="" id="">
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                            </select>
                        </div>
                    </div>
                </div>


                {
                    loading ? <Loading loading={loading} /> :
                        supports?.map((n, index) => {
                            const dateObj = new Date(n.createdAt)
                            const time = formatAMPM(dateObj)
                            return <div key={index} className={classNames('flex text-xs px-6 py-4 border-b items-center', n.status !== "Awaiting support reply" ? "text-opacity-50 teaxt-theme-black" : "font-semibold")}>
                                <div className='w-1/6'>
                                    <p>{dateObj.getDate() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getFullYear()}</p>
                                    <p>{time}</p>
                                </div>

                                <div className='w-[14%] flex items-center gap-3'>
                                    {n.ticketType}
                                </div>

                                <div className='flex-1'>
                                    {n.subject}
                                </div>

                                <div className={classNames('w-1/6', n.status === "Awaiting support reply" ? "text-[#1464F6]" : n.status === 'Awaiting user reply' ? "text-[#FF3823]" : 'text-[#72BB53]')}>
                                    {n.status}
                                </div>

                                <div className='flex cursor-pointer justify-end p-2 items-center  bg-[#F7F6F6] rounded-md'>
                                    <ArrowRight onClick={() => openSingleConversationHandler(n)} className='text-[#32313280]' size={18} />
                                </div>

                            </div>
                        })
                }




            </div>
            <Pagination limit={limit} setLimit={setLimit} />

        </>
    )
}

export default ShowCase