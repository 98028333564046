import classNames from "classnames";
import { CaretDown, Check, File, MagnifyingGlass, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createNewNotifications } from "../../../api/users/notifications";
import { getUsersApi } from "../../../api/users/users";
import { getUsers } from "../../../redux/actions/users/getUsers";
import { CLOSE_ADD_NEW_NOTIFICATIONS } from "../../../redux/reducers/Admin/CreateNewNotificatioReducer";
import { users } from "../../fake/fakeUser";

const CreateNewNotifications = () => {
  // add projects

  const [showDropDown, setShowDropDown] = useState(false);

  const showDropDownFunc = () => {
    setShowDropDown(!showDropDown);
  };

  const [selectedUsersArr, setselectedUsersArr] = useState([]);

  const selectedProjects = (p) => {
    if (!selectedUsersArr.includes(p)) {
      setselectedUsersArr([...selectedUsersArr, p]);
    }

    setShowDropDown(false);
  };

  const removeProject = (s) => {
    let newArr = [...selectedUsersArr];
    let newArr2 = newArr.filter((p) => p.id !== s.id);
    setselectedUsersArr(newArr2);
  };

  // for seleceting people
  const permissions = [
    "All Clients",
    "All Company Admins",
    "All Project Admins",
    "All Users",
  ];

  const [selectedPermissions, setselectedPermissions] = useState([]);

  const selectHandler = (p) => {
    let newArr = [...selectedPermissions];
    const contains = newArr.filter((a) => a === p);
    if (contains.length === 1) {
      newArr = newArr.filter((a) => a !== p);
    } else {
      newArr = [...selectedPermissions, p];
    }

    setselectedPermissions(newArr);
  };

  // uploadPdf
  const [selectedFile, setSelectedFile] = useState([]);

  const uploadPdf = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile([...selectedFile, e.target.files[0]]);
  };

  const removeDoc = (f) => {
    let newArr = [...selectedFile];
    let newArr2 = newArr.filter((p) => p.name !== f.name);
    setSelectedFile(newArr2);
  };

  const dispatch = useDispatch();
  const closeAddNewNotificationsTab = () => {
    dispatch({ type: CLOSE_ADD_NEW_NOTIFICATIONS });
  };

  const [content, setContent] = useState("");

  const AddNewNotifications = async () => {
    try {
      let userIds = selectedUsersArr.map((u) => {
        return u._id;
      });

      let peoples = [...selectedPermissions];
      toast.loading("Creating notification");
      if (userIds.length !== 0) {
        await createNewNotifications({ userIds, content });
      }
      if (userIds.length === 0) {
        await createNewNotifications({ peoples, content });
      }
      toast.dismiss();
      toast.success("Notification Created");
      dispatch({ type: CLOSE_ADD_NEW_NOTIFICATIONS });
    } catch (err) {
      toast.dismiss();
      toast.error(err);
    }
  };

  //

  const { user } = useSelector((state) => state.me);

  // const {companyId} = useSelector(state=> state.selectCompany)

  const [allUsers, setAllUsers] = useState([]);
  useEffect(() => {
    const func = async () => {
      try {
        const res = await getUsersApi(user.companyId._id);
        setAllUsers(res.data.docs);
      } catch (err) {}
    };
    func();
  }, []);

  return (
    <>
      <div className="grid grid-cols-2 min-h-[80vh] mt-8 gap-6">
        <div className="bg-white rounded-md">
          {/*  */}
          <div className="p-6 mt-4">
            <div>
              <p className="font-medium text-sm">
                Search individual users to send Notification to
              </p>
              <div className=" relative my-2">
                <input
                  onClick={showDropDownFunc}
                  placeholder={
                    showDropDown ? "Select the users" : "Select the users"
                  }
                  type="text"
                  className={classNames(
                    "w-full border border-[#00000033] bg-white cursor-pointer rounded-t-md focus:outline-none py-3 text-very-tiny",
                    showDropDown ? "px-4" : "px-4 rounded-b-md"
                  )}
                />

                {showDropDown ? (
                  <MagnifyingGlass
                    size={14}
                    className="absolute text-theme-black opacity-80 right-3 top-[14px]"
                  />
                ) : (
                  <CaretDown
                    size={14}
                    className="absolute text-theme-black opacity-80 right-3 top-[14px]"
                  />
                )}
                {showDropDown && (
                  <div className="border-b absolute z-[99] w-full cursor-pointer h-36 overflow-y-auto border-[#00000033]">
                    {allUsers?.map((u, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => selectedProjects(u)}
                          className="p-3 bg-white border-x border-black-10 border-b"
                        >
                          <div className="flex items-center text-theme-black text-xs bg-white justify-between">
                            <div className="flex items-center text-xs gap-2">
                              <div className="h-full">
                                <img
                                  className="w-8 p-[1px] border border-theme-green rounded-full"
                                  src={
                                    !user.profilePic
                                      ? `/images/profile.svg`
                                      : `${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${user.profilePic}`
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="">
                                <p className="font-medium">
                                  {u.firstName} {u.lastName} ({u.roleId.name})
                                </p>
                                <p className=" opacity-50 text-very-tiny">
                                  {u.email}
                                </p>
                              </div>
                            </div>
                            <p>{u.uid}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="grid content-start hide-scroll relative gap-2 mt-6 rounded-lg">
                  {selectedUsersArr.map((s, idx) => {
                    return (
                      <div key={idx} className="bg-white rounded-md">
                        <div className="text-very-tiny font-medium border border-black-10 p-2 rounded-md flex justify-between items-center">
                          <div className="flex items-center  gap-2">
                            <div className="h-full">
                              <img
                                className="w-7 p-[1px] border border-theme-green rounded-full"
                                src={
                                  !user.profilePic
                                    ? "/images/user1.jpeg"
                                    : `${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${user.profilePic}`
                                }
                                alt=""
                              />
                            </div>
                            <div className="">
                              <p className="font-medium">
                                {s.firstName} {s.lastName} ({s.roleId.name})
                              </p>
                              <p className="opacity-50 text-very-tiny">
                                {s.email}
                              </p>
                            </div>
                          </div>
                          <X
                            onClick={() => removeProject(s)}
                            className="cursor-pointer"
                            size={12}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="mt-12 text-theme-black">
              <p className="text-center pb-12">OR</p>
              <p className="font-medium text-sm">
                Search individual users to send Notification to
              </p>
              <div className="grid gap-2 mt-6">
                {permissions.map((p, index) => {
                  return (
                    <div
                      key={index}
                      className="flex px-3 py-2 border rounded items-center gap-3"
                    >
                      <Check
                        onClick={() => selectHandler(p)}
                        size={18}
                        className={classNames(
                          "text-white border border-[#3231324D] rounded p-[2px] cursor-pointer",
                          selectedPermissions.includes(p)
                            ? "bg-[#3231324D]"
                            : "bg-white"
                        )}
                      />
                      <p className="text-xs text-theme-black">{p}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/*  */}
        </div>

        {/* right side */}

        <div className="bg-white p-6 rounded-md">
          {/* <div className='grid gap-2'>
                        <p className='font-medium text-sm'>Notification Subject</p>
                        <input type="text" placeholder="Write Subject here" className={classNames('focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs' ,"bg-[#0000000D]")} />
                    </div>

                    <div className='grid gap-2 mt-12'>
                       <input accept='.doc,.pdf,.docx,.pages' id='fileInput' onChange={uploadPdf} name='fileInput' type="file" style={{display:'none'}} />

                        <p className='font-medium text-sm'>Attach Files (upto 5)</p>
                        <label htmlFor="fileInput">
                           <div className={classNames('focus:outline-none px-4 p-3 w-full text-center flex justify-center rounded-lg  border border-black-10 text-xs gap-2' ,"bg-[#0000000D]")}><File size={16}/> Attach File(max 5mb)</div>
                        </label>
                    </div>

                    <div className='grid grid-cols-5 mt-6 gap-3'>
                        {
                            selectedFile.map((f, index) => {
                                return <p key={index} className='gap-3 text-sm px-3 py-1 flex items-center border border-border-input justify-between rounded-md bg-very-light-gray'>{f.name.length>10 ? f.name.substr(0, 10) + "..." : f.name} <X onClick={() => removeDoc(f)} size={14}/></p>
                            })
                        }
                    </div> */}

          <div className="grid gap-2">
            <p className="font-medium text-sm">Notification Details</p>
            <textarea
              type="text"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              rows={14}
              placeholder="Write notiffication details"
              className={classNames(
                "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                "bg-[#0000000D]"
              )}
            ></textarea>
          </div>

          <div className="grid gap-3 mt-12">
            <button
              onClick={closeAddNewNotificationsTab}
              className="flex-1 w-full py-2 bg-transparent border-2 border-bottom-border uppercase text-sm font-bold rounded-lg hover:bg-white hover:border-[#00000066]"
            >
              <span className="opacity-40">CANCEL</span>
            </button>
            <button
              onClick={AddNewNotifications}
              className="flex-1 w-full py-2 bg-theme-light-green text-theme-green border-2 border-theme-green uppercase text-sm font-bold rounded-lg hover:bg-theme-green hover:text-black"
            >
              <span className="">SEND NOTIFICATIONS</span>
            </button>
          </div>
        </div>

        {/* End right side */}
      </div>
    </>
  );
};

export default CreateNewNotifications;
