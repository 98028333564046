import classNames from "classnames";
import { ArrowRight, DownloadSimple, Eye } from "phosphor-react";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { downloadDoc, previewDoc } from "../../../../api/users/fileUpload";
import {
  openSingleDocumentAction,
  opneSingleVersion,
} from "../../../../redux/actions/documents/SingleDocumentAction";
import { Assests } from "../../../fake/fakeAssets";
import { FakeProjects } from "../../../fake/fakeProject";

const VersionsDropDown = ({ document, v, previewDocument }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openSingleVerison = () => {
    navigate(`/documents/${document._id}`);
    dispatch(openSingleDocumentAction(document._id));

    dispatch(opneSingleVersion(v));
  };

  const downloadDocument = async (d) => {
    const url = await downloadDoc(d.data);

    window.open(url, "_blank");
  };

  //   const previewDocument = async(d) => {
  //       const url = await previewDoc(d.data);
  //
  //       window.open(url, "_blank");
  //   }

  return (
    <>
      <div
        className={classNames(
          "flex py-4 px-6 bg-[#F7F7F7]",
          "border-b border-bottom-border",
          ""
        )}
      >
        <div className="w-[17%] text-xs font-medium">
          <p className="uppercase">id</p>
          <p className="opacity-50">{v.uid}</p>
        </div>

        <div className="w-[13%] text-xs gap-2">
          <p className="">Version</p>
          <p className="text-theme-black opacity-50 cursor-pointer leading-3">
            {v.version}
          </p>
        </div>

        <div className="w-[15%] text-xs gap-2">
          <p className=" leading-3">{v.name}</p>
          {v.type && (
            <p className="leading-4 font-light">
              <span className="opacity-50 mr-1">{v.type.type}</span>{" "}
              <span className="uppercase text-blue-300  underline">
                {v.type.code}
              </span>
            </p>
          )}
        </div>

        <div className="text-xs w-[15%]">
          <p className="">Status</p>
          <p
            className={classNames(
              " cursor-pointer  leading-3",
              v.status === "Approved"
                ? "text-theme-green"
                : "text-theme-black opacity-50"
            )}
          >
            {v.status}
          </p>
        </div>

        <div className="text-xs w-[16%]">
          <p className="">Last Edit</p>
          <p className="opacity-50 font-semibold capitalize">
            {v.updatedAt.substring(0, 10).replaceAll("-", "/")}
          </p>
        </div>
        {/* <div className=' w-[10%] text-xs'>
                            <div className=''>
                                <p className='leading-3 '>Assets</p>
                                <p className='text-theme-black opacity-50 cursor-pointer'>{Assests.length} <i className={classNames("fa-solid ml-1 ",  'fa-angle-down')}></i></p>
                            </div>
                        </div>

                        <div className=' w-[10%] text-xs'>
                            <div className=''>
                                <p className='leading-3 '>Projects</p>
                                <p className='text-theme-black opacity-50 cursor-pointer'>{FakeProjects.length} <i className={classNames("fa-solid ml-1 ",  'fa-angle-down')}></i></p>
                            </div>
                        </div>

                        <div className=' w-[10%] text-xs'>
                            <div className=''>
                                <p className='leading-3 '>People</p>
                                <p  className='text-theme-black opacity-50 cursor-pointer'>{v.people.sharev.length} <i className={classNames("fa-solid ml-1 ",  'fa-angle-down')}></i></p>
                            </div>
                        </div> */}

        <div className="text-xs flex flex-1 gap-2 mr-3 justify-end font-medium">
          <DownloadSimple
            onClick={() => downloadDocument(v)}
            className="bg-#F7F6F6  p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
            size={30}
          />
          <Eye
            onClick={() => previewDocument(v)}
            className="bg-#F7F6F6 p-2  cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
            size={30}
          />
          <ArrowRight
            onClick={openSingleVerison}
            className="bg-#F7F6F6 p-2  cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
            size={30}
          />
        </div>
      </div>
    </>
  );
};

export default VersionsDropDown;
