import { CLOSE_ALL_CONVERSATIONS, GET_ALL_CONVERSATIONS_FAIL, GET_ALL_CONVERSATIONS_REQ, GET_ALL_CONVERSATIONS_SUCC } from "../constants/singleConversation";



export const getConversationsReducer = (state={allConversations :[], loading:true, error:null, open:false, subject:""}, action)=> {
    switch(action.type){
        case GET_ALL_CONVERSATIONS_REQ:
            return {loading :true, ...state}

        case GET_ALL_CONVERSATIONS_SUCC:
            return{
                loading: false,
                allConversations: action.payload,
                open:true,
                subject:action.subject
            }
        
        case GET_ALL_CONVERSATIONS_FAIL:
            return{
                loading:false,
                error:action.payload,
                open:false
            }

        case CLOSE_ALL_CONVERSATIONS:
            return{
                loading:false,
                allConversations:null,
                open:false,
            }
        
        default:
            return state;
    }
}