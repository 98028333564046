import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateUser } from "../../../../api/users/inviteUsers";

const Profile = ({
  edit,
  email,
  fName,
  setFName,
  lName,
  setLName,
  Account,
}) => {
  return (
    <>
      <div>
        <div className="border grid grid-cols-2 border-opacity-10 ">
          <div className="flex gap-3 border-r border-b border-black border-opacity-10 text-xs p-6 items-center">
            <p className=" w-[20%]">Email</p>
            <input
              defaultValue={email}
              disabled={true}
              className={classNames(
                "w-[75%] py-3 focus:outline-none px-3",
                edit ? " bg-black bg-opacity-5" : "bg-white"
              )}
              type="text"
            />
            <div className="w-[5%]">
              {/* <NotePencil className='cursor-pointer'  size={18} /> */}
            </div>
          </div>

          <div className="flex gap-3 border-b border-black border-opacity-10 text-xs p-6 items-center">
            <p className=" w-[20%]">First Name</p>
            <input
              value={fName}
              onChange={(e) => setFName(e.target.value)}
              disabled={!edit}
              className={classNames(
                "w-[75%] py-3 focus:outline-none px-3",
                edit ? " bg-black bg-opacity-5" : "bg-white"
              )}
              type="text"
            />
            <div className="w-[5%]">
              {/* <NotePencil className='cursor-pointer'  size={18} /> */}
            </div>
          </div>

          <div className="flex gap-3 border-r border-black border-opacity-10 text-xs p-6 items-center">
            <p className=" w-[20%]">Last Name</p>
            <input
              value={lName}
              onChange={(e) => setLName(e.target.value)}
              disabled={!edit}
              className={classNames(
                "w-[75%] py-3 focus:outline-none px-3",
                edit ? " bg-black bg-opacity-5" : "bg-white"
              )}
              type="text"
            />
            <div className="w-[5%]">
              {/* <NotePencil className='cursor-pointer'  size={18} /> */}
            </div>
          </div>

          <div className="flex gap-3 text-xs p-6 items-center">
            <p className=" w-[20%]">Account</p>
            <input
              value={Account}
              disabled={true}
              className={classNames(
                "w-[75%] py-3 focus:outline-none px-3",
                edit ? " bg-black bg-opacity-5" : "bg-white"
              )}
              type="text"
            />
            <div className="w-[5%]">
              {/* <NotePencil className='cursor-pointer'  size={18} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
