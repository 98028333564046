export const getTimeFromISO = (dateString) => {
    const dateObj = new Date(dateString);
    const hour = dateObj.getUTCHours();
    const minute = dateObj.getUTCMinutes();

    if(minute<9){
        return `${hour}:0${minute}`
    }else{
        return `${hour}:${minute}`

    }

}