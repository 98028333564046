import { getDashBoardData } from "../../api/users/dashboard";
import {
  GET_DASHBOARD_STATS_FAIL,
  GET_DASHBOARD_STATS_REQ,
  GET_DASHBOARD_STATS_SUCC,
} from "../constants/dashboard";

export const getDashBoardDataAction =
  ({
    arr = ["documents", "suppliers", "assets", "projects"],
    fromDate = "",
    toDate = "",
    companyId = "",
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_DASHBOARD_STATS_REQ });

      const res = await getDashBoardData(arr, fromDate, toDate, companyId);

      dispatch({
        type: GET_DASHBOARD_STATS_SUCC,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_DASHBOARD_STATS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
