import { GET_PROJECTS_OF_A_USER_FAIL, GET_PROJECTS_OF_A_USER_REQ, GET_PROJECTS_OF_A_USER_SUCC } from "../../constants/projects/getProjectOfAUserConsts";
import {  getUsersProjectApiBySandip } from "../../../api/users/users";

export const getProjectsOfAUserAction = ({userId="", limit = 5, page = 1, q=""}) => async(dispatch) => {
    try{
        dispatch({type: GET_PROJECTS_OF_A_USER_REQ})
        const res = await getUsersProjectApiBySandip(userId, page , limit , q)
        const finalData = res.data.docs.map(p => {
            return p.projectId
        })
        dispatch({
            type:GET_PROJECTS_OF_A_USER_SUCC,
            payload:finalData,
            allUserProjectDetails:res.data
        })
    } catch(error){
        dispatch({
            type: GET_PROJECTS_OF_A_USER_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}