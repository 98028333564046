import classNames from "classnames";
import { PaperPlaneRight } from "phosphor-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  sendMessageApi,
  updateSupportTicket,
} from "../../../../api/users/support";
import { getConversationsAction } from "../../../../redux/actions/getSingleCoversationsAction";

const AllSupportMsg = ({ selectedSupportId }) => {
  const { allConversations, subject } = useSelector(
    (state) => state.getConversations
  );

  const { user } = useSelector((state) => state.me);

  const [messageInput, setMessageInput] = useState("");
  const dispatch = useDispatch();

  const sendMessage = async () => {
    try {
      await sendMessageApi({
        description: messageInput,
        supportId: selectedSupportId,
      });
      await updateSupportTicket(selectedSupportId, {
        status: "Awaiting user reply",
      });
      dispatch(
        getConversationsAction({
          supportId: selectedSupportId,
          subject: subject,
        })
      );
      setMessageInput("");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div className="bg-white mt-8 h-[80vh] px-10 rounded-lg py-8 pb-16">
        <div className="h-[50vh] overflow-y-auto hide-scroll ">
          {allConversations?.map((c, index) => {
            let mess = allConversations[allConversations.length - index - 1];
            return (
              <div
                key={index}
                className={classNames(
                  "w-full flex mb-5",
                  mess.createdBy._id === user._id
                    ? "justify-end items-center"
                    : ""
                )}
              >
                <p
                  className={classNames(
                    "w-2/5  p-4 rounded-[22px]",
                    mess.createdBy._id !== user._id
                      ? "bg-theme-green text-white"
                      : "bg-[#0000001A]"
                  )}
                >
                  {mess.message}
                </p>
              </div>
            );
          })}
        </div>

        <div className="relative">
          <textarea
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            className="bg-bottom-border w-full pr-20 focus:outline-none p-4 rounded-lg"
            name=""
            id=""
            rows={5}
          ></textarea>
          <PaperPlaneRight
            onClick={sendMessage}
            className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
            color="#32313280"
            size={32}
            weight="fill"
          />
        </div>
      </div>
    </>
  );
};

export default AllSupportMsg;
