/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable no-unused-vars */
import {
  ArrowRight,
  Buildings,
  FunnelSimple,
  ListBullets,
  MapTrifold,
  X,
} from "phosphor-react";
import React, { useState } from "react";
import { MapFilter } from "../../../../styles/MapFilter";
import MainMapDocuments from "./Map";
import mapboxgl from "mapbox-gl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MapView = ({ list, listViewFunc, mapViewFunc }) => {
  const [activeFilter, setActiveFilter] = useState({ map1: true, map2: false });
  const [vertical, setVertial] = useState(true);
  const [showFilters, setShowFilters] = useState(false);

  const { docTypes } = useSelector((state) => state.getDocType);
  const [docStatus, setDocStatus] = useState("");
  const [docType, setDocType] = useState("");
  const [documentRightDropDown, setDocumentRightDropDown] = useState(false);
  const { open, document } = useSelector((state) => state.singleDocument);
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex  absolute z-[9999]  left-10 top-10">
        <div className="flex p-1 items-center  gap-1 bg-white shadow-xl rounded-full">
          <MapFilter
            active={false}
            onClick={listViewFunc}
            className="rounded-l-full"
          >
            <div
              active
              className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] py-2 px-4 rounded-l-full items-center gap-2"
            >
              <p className="opacity-30">List</p>
              <ListBullets size={12} className="opacity-30" weight="bold" />
            </div>
          </MapFilter>

          <MapFilter
            className="rounded-r-full"
            active={true}
            onClick={listViewFunc}
          >
            <div className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] rounded-r-full py-2 px-4 items-center gap-2">
              <MapTrifold size={12} className="opacity-30" weight="bold" />
              <p className="opacity-30">Map</p>
            </div>
          </MapFilter>
        </div>
        <div className="flex relative ml-2">
          <div className="bg-white p-1 z-[999] shadow-lg rounded-full">
            <div
              onClick={() => setShowFilters(!showFilters)}
              className="bg-[#EBEBEB]  cursor-pointer py-2 px-[8px] h-full flex items-center justify-center  rounded-full"
            >
              {!showFilters ? (
                <FunnelSimple className="opacity-30" size={16} weight="bold" />
              ) : (
                <X className="opacity-30" size={16} weight="bold" />
              )}
            </div>
          </div>

          {showFilters && (
            <div className="absolute h-full shadow-lg rounded-full bg-white p-1 left-0">
              <div className="flex text-xs bg-[#F6F5F5] rounded-full pl-8  h-full font-semibold">
                <div className="px-3 border-r flex items-center">
                  <select
                    value={docStatus}
                    onChange={(e) => setDocStatus(e.target.value)}
                    className="min-w-[98px bg-transparent opacity-60 font-semibold  h-5 focus:outline-none"
                    placeholder="Asset Type"
                    name=""
                    id=""
                  >
                    <option value="">Document Status</option>
                    <option value="New">New</option>
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                  </select>
                </div>
                {/* 
                          <div className='px-3 flex items-center'>
                              <select value={docType} onChange={(e) => setDocType(e.target.value)} className='min-w-[98px] bg-transparent opacity-60 font-semibold  h-5 focus:outline-none' placeholder='Asset Type' name="" id="">
                                <option value="">Document Type</option>
                                {
                                    docTypes?.map((d, index) => {
                                        return <option value={d._id}>{d.type}</option>
                                    })
                                }
                            </select>
                          </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
      <MainMapDocuments
        setDocumentRightDropDown={setDocumentRightDropDown}
        status={docStatus}
        threeD={vertical}
        map={activeFilter}
      />

      <div className="flex absolute top-10 p-1 bg-white gap-1 right-10 shadow-xl rounded-full">
        <MapFilter
          active={activeFilter.map1}
          onClick={() => setActiveFilter({ map1: true, map2: false })}
          className="rounded-l-full"
        >
          <div
            active
            className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] py-2 px-4 rounded-l-full items-center gap-2"
          >
            <MapTrifold size={12} className="opacity-30" weight="bold" />
            <p className="opacity-30">Map 1</p>
          </div>
        </MapFilter>

        <MapFilter
          className="rounded-r-full"
          active={activeFilter.map2}
          onClick={() => setActiveFilter({ map1: false, map2: true })}
        >
          <div className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] rounded-r-full py-2 px-4 items-center gap-2">
            <MapTrifold size={12} className="opacity-30" weight="bold" />
            <p className="opacity-30">Map 2</p>
          </div>
        </MapFilter>
      </div>

      {documentRightDropDown && document && (
        <div className="fixed text-xs flex z-[99999999] top-14 py-3 flex-col right-0 w-72 h-full z-50 bg-[#EFEBE9] shadow-md">
          <div className="flex px-3 justify-between">
            <button
              onClick={() => setDocumentRightDropDown(false)}
              className="px-3 py-1.5 border bg-[#F7F6F6] rounded flex items-center gap-2"
            >
              <X size={12} /> close
            </button>
            <button
              onClick={() => {
                setDocumentRightDropDown(false);
                navigate(`/admin/documents/${document._id}`);
              }}
              className="px-3 py-1.5 border bg-[#F7F6F6] rounded flex items-center gap-2"
            >
              {" "}
              view more details <ArrowRight size={12} />
            </button>
          </div>

          <div className="grid gap-3">
            <div className="rounded-md p-3  bg-[#EFEBE9]">
              <div className="bg-[#F7F6F6] text-center py-4 flex flex-col gap-2  rounded-md items-center">
                <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                  Document Name
                </p>
                <p className="text-[10px] font-medium">{document.name}</p>
              </div>

              <div className="bg-[#F7F6F6] mt-3 text-center py-[20px] px-4 flex flex-col gap-2  rounded-md items-center border">
                <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                  ISO19650 Reference
                </p>
                <p className="text-[10px] font-medium">
                  {document.documentCode}
                </p>
              </div>

              <div className="grid grid-cols-2 gap-3 mt-4">
                <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
                  <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                    ID
                  </p>
                  <p className="text-[10px] font-medium">{document.uid}</p>
                </div>
                <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
                  <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                    Document Type
                  </p>
                  <p className="text-[10px] font-medium capitalize">
                    {document.type ? document.type.type : "Not Defined"}
                  </p>
                </div>
                <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
                  <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                    Type Code
                  </p>
                  <p className="text-[10px] font-medium">
                    {document.type ? document.type.code : "Not Defined"}
                  </p>
                </div>
                <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
                  <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                    Functional Breakdown
                  </p>
                  <p className="text-[10px] font-medium capitalize">
                    {document.functionalBreakdown}
                  </p>
                </div>
                <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
                  <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                    Spatial Breakdown
                  </p>
                  <p className="text-[10px] font-medium">
                    {document.spatialBreakdown}
                  </p>
                </div>
                <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
                  <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                    Form
                  </p>
                  <p className="text-[10px] font-medium">{document.form}</p>
                </div>
                <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
                  <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                    Discipline{" "}
                  </p>
                  <p className="text-[10px] font-medium">
                    {document.discipline}
                  </p>
                </div>
                <div className="bg-[#F7F6F6] text-center py-[20px] flex flex-col gap-2  rounded-md items-center border">
                  <p className="font-medium text-very-tiny text-[#00000099] py-1.5 px-4 bg-white rounded-full">
                    Number
                  </p>
                  <p className="text-[10px] font-medium">{2}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MapView;
