import { ArrowLeft } from "phosphor-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addCompany, inviteSupplier } from "../../../../api/users/company";
import { inviteUser } from "../../../../api/users/inviteUsers";
import { COMPANIES_LIST_VIEW_OPEN } from "../../../../redux/constants/AdminConsts/LeftNavAdminConst";
import InviteSupplierTab from "./InviteSupplier";

const InviteSupplier = () => {
  const dispatch = useDispatch();

  const inviteHandler = () => {
    dispatch({ type: COMPANIES_LIST_VIEW_OPEN });
  };
  const { user } = useSelector((state) => state.me);
  const companyId = user?.companyId?._id;
  const [selectedSuppliersArr, setselectedSuppliersArr] = useState([]);
  const [value, setValue] = useState("document");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const submit = async () => {
    // if (value==="document"){
    //   toast.loading("inviting supplier")
    //   const ids = selectedSuppliersArr.map(val => val._id)

    //   if (ids.length > 0) {
    //     try {
    //       let data = {
    //         "supplierIds": [...ids],
    //         "companyId":companyId
    //       }
    //       await inviteSupplier(data)
    //       toast.dismiss()
    //       toast.success("supplier invited successfully")
    //     } catch (err) {
    //       toast.dismiss()
    //       toast.error(err?.response?.data?.message)
    //     }

    //   } else {
    //     toast.dismiss()
    //     toast.error("select atlest one supplier")
    //   }
    // }else {
    toast.loading("inviting supplier");

    if (email && name) {
      try {
        let data = {
          name: name,
        };
        const res = await addCompany(data);
        const data2 = {
          companyId: res.data._id,
          email: email,
          roleId: "627955212019dc8e78e71ba8",
        };
        await inviteUser(data2);
        let data3 = {
          supplierIds: [res.data._id],
          companyId: companyId,
        };
        await inviteSupplier(data3);
        toast.dismiss();
        toast.success("Company invited successfully");
        dispatch({ type: COMPANIES_LIST_VIEW_OPEN });
        navigate("/admin/companies");
      } catch (err) {
        toast.dismiss();
        toast.error(err?.response?.data?.message);
      }
    } else {
      toast.dismiss();
      toast.error("  enter name and email ");
    }
    // }
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="bg-white p-6 mt-8 rounded-lg">
        <button
          onClick={() => navigate("/admin/companies")}
          className="flex-1 w-24 items-center justify-center gap-1 py-2 bg-transparent border-2 border-bottom-border uppercase text-sm font-semibold text-[#00000066] rounded-lg flex hover:bg-white hover:border-[#00000066]"
        >
          <ArrowLeft size={14} weight="bold" />
          Back
        </button>

        <div className="flex items-center px-20 justify-center">
          <InviteSupplierTab
            value={value}
            setValue={setValue}
            email={email}
            setEmail={setEmail}
            name={name}
            setName={setName}
            selectedSuppliersArr={selectedSuppliersArr}
            setselectedSuppliersArr={setselectedSuppliersArr}
            inviteUser={() => {
              submit();
            }}
            closeInviteTab={() => navigate("/admin/companies")}
          />
        </div>

        {/* <div className='flex mt-8 items-center justify-between'>
                <FilterButton onClick={() => dispatch({type: COMPANIES_LIST_VIEW_OPEN})}>CANCEL</FilterButton>
                <FilterButton onClick={()=>{
            
            submit()}}>INVITE</FilterButton>
        </div> */}
      </div>
    </>
  );
};

export default InviteSupplier;
