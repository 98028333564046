import React from "react";
import { getTimeFromISO } from "../../../../libs/getTimeFromISOString";

const PeopleDropDown = ({ d }) => {
  return (
    <>
      {d && (
        <div className=" grid grid-cols-2 bg-[#F7F7F7] border-b">
          <div className="grid grid-cols-2 p-6 border-r-2 gap-6">
            <div>
              <p className="font-semibold text-xs py-2">Uploaded By:</p>
              <div className="w-full p-4 bg-white rounded-lg">
                <div className="flex gap-2 items-center">
                  <img
                    className="w-8 h-8 p-[1px] border-theme-green border rounded-full"
                    src={`${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${d.createdBy?.profilePic}`}
                    alt=""
                  />
                  <div>
                    <p className="text-xs">{d.createdBy?.companyId.name}</p>
                    <p className="text-[10px]">
                      {d.createdBy?.firstName} {d.createdBy?.lastName}
                      <span className="text-[#32313299]">
                        (
                        {d.createdBy?.roleId?.name === "AdminUser"
                          ? "Apex Manager"
                          : d.createdBy?.roleId?.name}
                        )
                      </span>{" "}
                    </p>
                  </div>
                </div>

                <div className="flex items-center gap-2 pt-4 text-xs">
                  <p className="font-semibold">Date and Time:</p>
                  <p className="text-[#323132B2]">
                    {getTimeFromISO(d.createdAt)},{" "}
                    {d.createdAt?.substring(0, 10).replaceAll("-", "/")}
                  </p>
                </div>
              </div>
            </div>

            {/* approved by */}

            {d.statusUpdatedBy && (
              <div>
                <p className="font-semibold text-xs py-2">{d.status} By:</p>
                <div className="w-full p-4 bg-white rounded-lg">
                  <div className="flex gap-2 items-center">
                    <img
                      className="w-8 h-8 p-[1px] border-theme-green border rounded-full"
                      src={`${process.env.REACT_APP_PROFILE_PIC_BASE_URL}/${d.createdBy?.profilePic}`}
                      alt=""
                    />
                    <div>
                      <p className="text-xs">
                        {d.statusUpdatedBy?.companyId.name}
                      </p>
                      <p className="text-[10px]">
                        {d.statusUpdatedBy?.firstName}{" "}
                        {d.statusUpdatedBy?.lastName}
                        <span className="text-[#32313299]">
                          (
                          {d.statusUpdatedBy?.roleId?.name === "AdminUser"
                            ? "Apex Manager"
                            : d.statusUpdatedBy?.roleId?.name}
                          )
                        </span>{" "}
                      </p>
                    </div>
                  </div>

                  {
                    <div className="flex items-center gap-2 pt-4 text-xs">
                      <p className="font-semibold">Date and Time:</p>
                      <p className="text-[#323132B2]">
                        {getTimeFromISO(d.updatedAt)},{" "}
                        {d.updatedAt?.substring(0, 10).replaceAll("-", "/")}
                      </p>
                    </div>
                  }
                </div>
              </div>
            )}
          </div>

          {/* shared by and shared with */}

          <div className="px-6">
            <div className="grid grid-cols-3 pb-3 pt-4 font-semibold text-xs font-semibold border-bottom-border border-b ">
              <p>Shared by</p>
              <p>Shared With</p>
              <p>Date and Time</p>
            </div>

            <div>
              {d.share?.map((p, index) => {
                return (
                  <div key={index} className="grid grid-cols-3 py-2 border-b">
                    <div className="flex gap-2 items-center">
                      <p className="w-7 h-7 tracking-wide flex justify-center items-center text-white font-bold text-[12px] bg-theme-green p-0.5 border border-theme-green rounded-full">
                        {p?.sharedBy.companyId?.name?.split(" ")[0][0]}
                        {p.sharedBy.companyId?.name?.split(" ")[1]
                          ? p.sharedBy.companyId?.name.split(" ")[1][0]
                          : ""}
                      </p>
                      <div>
                        <p className="text-xs">{p.sharedBy.companyId.name}</p>
                        <p className="text-[10px]">
                          {d.sharedBy?.firstName} {d.sharedBy?.lastName}{" "}
                          <span className="text-[#32313299]">
                            ({p.sharedBy.roleId?.name})
                          </span>{" "}
                        </p>
                      </div>
                    </div>

                    <div className="flex gap-2 items-center">
                      <p className="w-7 h-7 tracking-wide flex justify-center items-center text-white font-bold text-[12px] bg-theme-green p-0.5 border border-theme-green rounded-full">
                        {p?.companyId?.name?.split(" ")[0][0]}
                        {p.companyId?.name?.split(" ")[1]
                          ? p.companyId?.name.split(" ")[1][0]
                          : ""}
                      </p>
                      <p className="text-[10px]">{p.companyId?.name}</p>
                    </div>

                    <div className="flex items-center text-[#323132B2] gap-2 text-xs">
                      {p.date.substring(0, 10).replaceAll("-", "/")}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PeopleDropDown;
