export const getLastWeeksDate = () => {
    const now = new Date();
  
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7).toISOString();
  }

  export const getLastMonthsDate = () => {
    const now = new Date();
  
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30).toISOString();
  }

  export  const getLastYearsDate = () => {
    const now = new Date();
  
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 365).toISOString();
  }

  export const getToday = new Date().toISOString()

  export const getYesterDay = () => {
    const now = new Date();
  
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).toISOString();
  }

  export const getDayBeforeYesterDay = () => {
    const now = new Date();
  
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2).toISOString();
  }