/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { ArrowRight, File, Users, Wall, Wrench } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDashBoardDataAdmin } from "../../../../api/users/dashboard";
import { getDashBoardDataAction } from "../../../../redux/actions/getDashBoardStats";
import {
  ASSETS_LIST_VIEW_OPEN,
  COMPANIES_LIST_VIEW_OPEN,
  DOCUMENTS_LIST_OPEN,
  PROJECTS_LIST_OPEN,
} from "../../../../redux/constants/AdminConsts/LeftNavAdminConst";

const Overview = () => {
  const dispatch = useDispatch();

  const [activeAssets, setActiveAssets] = useState({
    day: true,
    week: false,
    year: false,
  });
  const [activeCompanies, setActiveCompanies] = useState({
    day: true,
    week: false,
    year: false,
  });
  const [activeProjects, setActiveProjects] = useState({
    day: true,
    week: false,
    year: false,
  });
  const [activeDocuments, setActiveDocuments] = useState({
    day: true,
    week: false,
    year: false,
  });

  const navigate = useNavigate();

  const getLastWeeksDate = () => {
    const now = new Date();

    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 7
    ).toISOString();
  };

  const getLastMonthsDate = () => {
    const now = new Date();

    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 30
    ).toISOString();
  };

  const getLastYearsDate = () => {
    const now = new Date();

    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 365
    ).toISOString();
  };

  const getYesterdayDate = () => {
    const now = new Date();

    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 1
    ).toISOString();
  };

  const getToday = new Date().toISOString();

  useEffect(() => {
    dispatch(getDashBoardDataAction({ fromDate: "", toDate: getToday }));
  }, []);

  const { stats } = useSelector((state) => state.getDahboardData);

  // companiesStats
  const [companiesStats, setcompaniesStats] = useState();
  useEffect(() => {
    const func = async () => {
      try {
        const res = await getDashBoardDataAdmin(
          "suppliers",
          activeCompanies.day
            ? getYesterdayDate()
            : activeCompanies.week
            ? getLastWeeksDate()
            : getLastMonthsDate(),
          getToday
        );
        setcompaniesStats(res.data.suppliers);
      } catch (err) {}
    };
    func();
  }, [activeCompanies]);

  // assets
  const [assets, setassets] = useState();
  useEffect(() => {
    const func = async () => {
      try {
        const res = await getDashBoardDataAdmin(
          "assets",
          activeAssets.day
            ? getYesterdayDate()
            : activeAssets.week
            ? getLastWeeksDate()
            : getLastMonthsDate(),
          getToday
        );
        setassets(res.data.assets);
      } catch (err) {}
    };
    func();
  }, [activeAssets]);

  // projects
  const [projects, setprojects] = useState();
  useEffect(() => {
    const func = async () => {
      try {
        const res = await getDashBoardDataAdmin(
          "projects",
          activeProjects.day
            ? getYesterdayDate()
            : activeProjects.week
            ? getLastWeeksDate()
            : getLastMonthsDate(),
          getToday
        );
        setprojects(res.data.projects);
      } catch (err) {}
    };
    func();
  }, [activeProjects]);

  // projects
  const [documents, setdocuments] = useState();
  useEffect(() => {
    const func = async () => {
      try {
        const res = await getDashBoardDataAdmin(
          "documents",
          activeDocuments.day
            ? getYesterdayDate()
            : activeDocuments.week
            ? getLastWeeksDate()
            : getLastMonthsDate(),
          getToday
        );
        setdocuments(res.data.documents);
      } catch (err) {}
    };
    func();
  }, [activeDocuments]);

  return (
    <>
      <div className="grid grid-cols-4 gap-6">
        {/* companies */}
        <div className=" bg-white rounded-xl">
          <div
            onClick={() => {
              dispatch({ type: COMPANIES_LIST_VIEW_OPEN });
              navigate("companies");
            }}
            className="py-5 border border-transparent  cursor-pointer relative"
          >
            <div className="flex px-5 justify-between ">
              <div className="rounded-full border-2 border-theme-green">
                <Users
                  className="bg-#c4c4c4 rounded-full p-2.5 m-0.5"
                  size={40}
                />
              </div>
            </div>

            <div className="flex mt-3 px-5 pb-3 border-b justify-between items-end">
              <div>
                <p className="text-sm">TOTAL COMPANIES</p>
                <p className="font-medium  text-theme-green  text-[28px]">
                  {stats?.suppliers}
                </p>
              </div>
              <ArrowRight className="mb-2" size={22} weight="light" />
            </div>
          </div>

          <div className="grid gap-4 pb-6 text-xs justify-items-center">
            <div className="flex text-very-tiny  rounded-md">
              <p
                onClick={() =>
                  setActiveCompanies({ day: true, week: false, year: false })
                }
                className={classNames(
                  "px-5 py-[9px] cursor-pointer rounded-l-md border-l border-y border-theme-green",
                  activeCompanies.day
                    ? "bg-theme-green text-white rounded-l"
                    : "text-theme-green"
                )}
              >
                DAY
              </p>
              <p
                onClick={() =>
                  setActiveCompanies({ day: false, week: true, year: false })
                }
                className={classNames(
                  "px-3 py-[9px] cursor-pointer border border-theme-green",
                  activeCompanies.week
                    ? "bg-theme-green text-white"
                    : "text-theme-green"
                )}
              >
                WEEK
              </p>
              <p
                onClick={() =>
                  setActiveCompanies({ day: false, week: false, year: true })
                }
                className={classNames(
                  "px-3 py-[9px] border-r rounded-r-md border-y border-theme-green cursor-pointer",
                  activeCompanies.year
                    ? "bg-theme-green text-white rounded-r"
                    : "text-theme-green"
                )}
              >
                MONTH
              </p>
            </div>
            <p className="font-medium  text-xs text-theme-black uppercase">
              <span className="text-theme-green text-lg">{companiesStats}</span>{" "}
              Companies Added
            </p>
          </div>
        </div>

        {/* Assets */}
        <div className=" bg-white rounded-xl">
          <div
            onClick={() => {
              dispatch({ type: ASSETS_LIST_VIEW_OPEN });
              navigate("assets");
            }}
            className="py-5 border border-transparent  cursor-pointer relative"
          >
            <div className="flex px-5 justify-between ">
              <div className="rounded-full border-2 border-theme-green">
                <Wall
                  className="bg-#c4c4c4 rounded-full p-2.5 m-0.5"
                  size={40}
                />
              </div>
            </div>

            <div className="flex mt-3 px-5 pb-3 border-b justify-between items-end">
              <div>
                <p className="text-sm">TOTAL ASSETS</p>
                <p className="font-medium  text-theme-green  text-[28px]">
                  {stats?.assets}
                </p>
              </div>
              <ArrowRight className="mb-2" size={22} weight="light" />
            </div>
          </div>

          <div className="grid gap-4 pb-6 text-xs justify-items-center">
            <div className="flex text-very-tiny   rounded-md">
              <p
                onClick={() =>
                  setActiveAssets({ day: true, week: false, year: false })
                }
                className={classNames(
                  "px-5 py-[9px] cursor-pointer border-l rounded-l-md border-y border-theme-green",
                  activeAssets.day
                    ? "bg-theme-green text-white rounded-l"
                    : "text-theme-green"
                )}
              >
                DAY
              </p>
              <p
                onClick={() =>
                  setActiveAssets({ day: false, week: true, year: false })
                }
                className={classNames(
                  "px-3 py-[9px] cursor-pointer border border-theme-green",
                  activeAssets.week
                    ? "bg-theme-green text-white"
                    : "text-theme-green"
                )}
              >
                WEEK
              </p>
              <p
                onClick={() =>
                  setActiveAssets({ day: false, week: false, year: true })
                }
                className={classNames(
                  "px-3 py-[9px] cursor-pointer rounded-r-md border-r border-y border-theme-green",
                  activeAssets.year
                    ? "bg-theme-green text-white rounded-r"
                    : "text-theme-green"
                )}
              >
                MONTH
              </p>
            </div>
            <p className="font-medium  text-xs text-theme-black uppercase">
              <span className="text-theme-green text-lg">{assets}</span> Assets
              Added
            </p>
          </div>
        </div>

        {/* Projects */}
        <div className=" bg-white rounded-xl">
          <div
            onClick={() => {
              dispatch({ type: PROJECTS_LIST_OPEN });
              navigate("projects");
            }}
            className="py-5 border border-transparent  cursor-pointer relative"
          >
            <div className="flex px-5 justify-between ">
              <div className="rounded-full border-2 border-theme-green">
                <Wrench
                  className="bg-#c4c4c4 rounded-full p-2.5 m-0.5"
                  size={40}
                />
              </div>
            </div>

            <div className="flex mt-3 px-5 pb-3 border-b justify-between items-end">
              <div>
                <p className="text-sm">TOTAL PROJECTS</p>
                <p className="font-medium  text-theme-green  text-[28px]">
                  {stats?.projects}
                </p>
              </div>
              <ArrowRight className="mb-2" size={22} weight="light" />
            </div>
          </div>

          <div className="grid gap-4 pb-6 text-xs justify-items-center">
            <div className="flex text-very-tiny  rounded-md">
              <p
                onClick={() =>
                  setActiveProjects({ day: true, week: false, year: false })
                }
                className={classNames(
                  "px-5 py-[9px] cursor-pointer border-l rounded-l-md border-y border-theme-green",
                  activeProjects.day
                    ? "bg-theme-green text-white rounded-l"
                    : "text-theme-green"
                )}
              >
                DAY
              </p>
              <p
                onClick={() =>
                  setActiveProjects({ day: false, week: true, year: false })
                }
                className={classNames(
                  "px-3 py-[9px] cursor-pointer border border-theme-green",
                  activeProjects.week
                    ? "bg-theme-green text-white"
                    : "text-theme-green"
                )}
              >
                WEEK
              </p>
              <p
                onClick={() =>
                  setActiveProjects({ day: false, week: false, year: true })
                }
                className={classNames(
                  "px-3 py-[9px] cursor-pointer  border-r rounded-r-md border-y border-theme-green",
                  activeProjects.year
                    ? "bg-theme-green text-white rounded-r"
                    : "text-theme-green"
                )}
              >
                MONTH
              </p>
            </div>
            <p className="font-medium  text-xs text-theme-black uppercase">
              <span className="text-theme-green text-lg">{projects}</span>{" "}
              Projects Added
            </p>
          </div>
        </div>

        {/* Documents */}
        <div className=" bg-white rounded-xl">
          <div
            onClick={() => {
              dispatch({ type: DOCUMENTS_LIST_OPEN });
              navigate("documents");
            }}
            className="py-5 border border-transparent  cursor-pointer relative"
          >
            <div className="flex px-5 justify-between ">
              <div className="rounded-full border-2 border-theme-green">
                <File
                  className="bg-#c4c4c4 rounded-full p-2.5 m-0.5"
                  size={40}
                />
              </div>
            </div>

            <div className="flex mt-3 px-5 pb-3 border-b justify-between items-end">
              <div>
                <p className="text-sm">TOTAL DOCUMENTS</p>
                <p className="font-medium  text-theme-green  text-[28px]">
                  {stats?.documents}
                </p>
              </div>
              <ArrowRight className="mb-2" size={22} weight="light" />
            </div>
          </div>

          <div className="grid gap-4 pb-6 text-xs justify-items-center">
            <div className="flex text-very-tiny   rounded-md">
              <p
                onClick={() =>
                  setActiveDocuments({ day: true, week: false, year: false })
                }
                className={classNames(
                  "px-5 py-[9px] cursor-pointer border-l rounded-l-md border-y border-theme-green",
                  activeDocuments.day
                    ? "bg-theme-green text-white rounded-l"
                    : "text-theme-green"
                )}
              >
                DAY
              </p>
              <p
                onClick={() =>
                  setActiveDocuments({ day: false, week: true, year: false })
                }
                className={classNames(
                  "px-3 py-[9px] cursor-pointer border border-theme-green",
                  activeDocuments.week
                    ? "bg-theme-green text-white"
                    : "text-theme-green"
                )}
              >
                WEEK
              </p>
              <p
                onClick={() =>
                  setActiveDocuments({ day: false, week: false, year: true })
                }
                className={classNames(
                  "px-3 py-[9px] cursor-pointer border-r rounded-r-md border-y border-theme-green",
                  activeDocuments.year
                    ? "bg-theme-green text-white rounded-r"
                    : "text-theme-green"
                )}
              >
                MONTH
              </p>
            </div>
            <p className="font-medium  text-xs text-theme-black uppercase">
              <span className="text-theme-green text-lg">{documents}</span>{" "}
              Documents Added
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
