import { getCategoriesApi, getSubCategoriesApi } from "../../../api/users/category"
import { GET_ALL_CATEGORIES_FAILED, GET_ALL_CATEGORIES_REQ, GET_ALL_CATEGORIES_SUCC } from "../../constants/categories/Categories"


export const getAllCategoriesAction = () => async(dispatch) => {
    try{
        dispatch({type:GET_ALL_CATEGORIES_REQ})
        const res =  await getCategoriesApi()



        dispatch({
            type:GET_ALL_CATEGORIES_SUCC,
            payload:res.data.docs,

        })

    } catch(error){
        dispatch({
            type: GET_ALL_CATEGORIES_FAILED,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}