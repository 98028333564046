import {
  Buildings,
  ListBullets,
  MagnifyingGlass,
  MapTrifold,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllDocsAction } from "../../../../redux/actions/documents/getAllDoc";
import { getAllDocsTypes } from "../../../../redux/actions/documents/getAllDocTypes";
import {
  DOCUMENTS_CREATE_OPEN,
  DOCUMENTS_LIST_OPEN,
  DOCUMENTS_MAP_OPEN,
} from "../../../../redux/constants/AdminConsts/LeftNavAdminConst";
import { MapFilter } from "../../../../styles/MapFilter";
import Pagination from "./Pagination";
import DocumentsTable from "./Table";

const ShowCase = ({ limit, setLimit }) => {
  const { documents } = useSelector((state) => state.AdminLeftNav);
  const dipstach = useDispatch();

  const navigate = useNavigate();

  const [active, setActive] = useState({ sharedWithMe: true, myDocs: false });

  const [docSearchInput, setDocSearchInput] = useState("");
  const [docType, setDocType] = useState("");
  const [docStatus, setDocStatus] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllDocsAction({
        q: docSearchInput,
        status: docStatus,
        type: docType,
        limit: limit,
      })
    );
  }, [dispatch, docSearchInput, docType, docStatus, limit]);

  useEffect(() => {
    dispatch(getAllDocsTypes());
  }, []);

  const { docTypes } = useSelector((state) => state.getDocType);
  const { docs, loading } = useSelector((state) => state.getAllDoc);

  const { totalCount } = useSelector((state) => state.getAllDoc);

  return (
    <>
      <div className="bg-white mt-8 rounded-lg py-8 pb-16">
        <div className="flex  px-6 items-center justify-between">
          <div className="flex items-center">
            <div className="flex p-1 bg-white gap-1 right-56 shadow-xl rounded-full">
              <MapFilter active={true} className="rounded-l-full">
                <div
                  active
                  className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] py-2 px-4 rounded-l-full items-center gap-2"
                >
                  <p className="opacity-30">List</p>
                  <ListBullets size={12} className="opacity-30" weight="bold" />
                </div>
              </MapFilter>

              <MapFilter
                className="rounded-r-full"
                active={false}
                onClick={() => navigate("/admin/documents/mapview")}
              >
                <div className="text-very-tiny cursor-pointer flex select-none font-semibold bg-[#EBEBEB] rounded-r-full py-2 px-4 items-center gap-2">
                  <MapTrifold size={12} className="opacity-30" weight="bold" />
                  <p className="opacity-30">Map</p>
                </div>
              </MapFilter>
            </div>

            <div className="flex ml-6 text-xs font-semibold">
              <div className="px-3 border-r">
                <select
                  value={docStatus}
                  onChange={(e) => setDocStatus(e.target.value)}
                  className="min-w-[98px] opacity-60 font-semibold  h-5 focus:outline-none"
                  placeholder="Asset Type"
                  name=""
                  id=""
                >
                  <option value="">Document Status</option>
                  <option value="New">New</option>
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                </select>
              </div>
              <div className="px-3">
                <select
                  value={docType}
                  onChange={(e) => setDocType(e.target.value)}
                  className="min-w-[98px] opacity-60 font-semibold  h-5 focus:outline-none"
                  placeholder="Asset Type"
                  name=""
                  id=""
                >
                  <option value="">Document Type</option>
                  {docTypes?.map((d, index) => {
                    return <option value={d._id}>{d.type}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Input and display */}

        <div className="flex  px-6 justify-between items-center mt-12">
          <div className="relative text-very-tiny w-1/3">
            <input
              value={docSearchInput}
              onChange={(e) => setDocSearchInput(e.target.value)}
              placeholder="Search for Document Name or UID"
              className="w-full bg-[#f7f6f6] border-[1.5px] focus:outline-none rounded-md border-[rgba(0, 0, 0, 0.1)] py-2.5 px-4"
              type="text"
            />
            <MagnifyingGlass
              className="absolute right-6 opacity-20 top-1/2 -translate-y-1/2"
              size={14}
            />
          </div>
          <div className="flex text-very-tiny gap-4 items-center">
            {totalCount && (
              <p>
                Displaying {(totalCount?.page - 1) * limit + 1} -{" "}
                {(totalCount?.page - 1) * limit + docs?.length} of{" "}
                {totalCount?.count}
              </p>
            )}

            {/* <p>Displaying {limit} of {totalCount?.count}</p> */}
            <div className="py-1 bg-[#f7f6f6] rounded-md px-1.5 border border-[rgba(0, 0, 0, 0.1)]">
              <select
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                className="h-7 bg-[#f7f6f6] focus:outline-none"
                name=""
                id=""
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </select>
            </div>
          </div>
        </div>

        {/* Table */}

        <>
          <DocumentsTable />
        </>
      </div>
      <Pagination limit={limit} setLimit={setLimit} />
    </>
  );
};

export default ShowCase;
