import classNames from "classnames";
import { useFormik } from "formik";
import {
  Buildings,
  CaretDown,
  File,
  MagnifyingGlass,
  Plus,
  X,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addCompany,
  addCompanyProjects,
  inviteSupplier,
} from "../../../../api/users/company";
import { upload } from "../../../../api/users/fileUpload";
import { inviteUser } from "../../../../api/users/inviteUsers";
import {
  getAllProjects,
  getProjects,
} from "../../../../redux/actions/project/getProjects";
import { COMPANIES_LIST_VIEW_OPEN } from "../../../../redux/constants/AdminConsts/LeftNavAdminConst";
import AddDocumentDropDown from "../../../components/Supplier/SingleSupplier/AddDocumentDropDown";
import SelectComp from "../../../components/Workspace/AddNewUser/SlectCompUserScren";

import { Country, State, City } from "country-state-city";
import { useNavigate } from "react-router-dom";

const validate = (values) => {
  let error = {};

  if (!values.firstName) {
    error.firstName = "enter your first name ";
  }
  if (!values.name) {
    error.name = "enter company name ";
  }
  if (!values.lastName) {
    error.lastName = "enter your last name ";
  }
  if (
    !values.email ||
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    error.email = "enter your email ";
  }

  return error;
};

const CreateCompany = () => {
  const { user } = useSelector((state) => state.me);
  const companyId = user?.companyId?._id;

  // show errors only when button is clicked
  const [showErrors, setShowErrors] = useState(false);

  // upload Image for company
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [selectedProjectsArr, setselectedProjectsArr] = useState([]);

  // upload Image for user

  const [selectedFileUser, setSelectedFileUser] = useState();
  const [previewUser, setPreviewUser] = useState();

  const { projects } = useSelector((state) => state.getAllProjects);

  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    dispatch(getAllProjects({ q: searchInput }));
  }, [searchInput]);

  useEffect(() => {
    dispatch(getProjects());
  }, []);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      domain: "",
      profilePic: "",
      profilePicOfUser: "",
      address: {
        // street1: "",
        // street2: "",
        city: null,
        state: null,
        country: null,
      },
      location: {},
      email: "",
      firstName: "",
      lastName: "",
      projectIds: [],
    },
    validate,

    onSubmit: async (values) => {
      toast.loading("creating company");
      try {
        let data = {
          name: values.name,
          domain: values.domain,
          profilePic: values.profilePic,
          address: values.address,
        };

        Object.keys(data).forEach((key) => {
          if (data[key] === "") {
            delete data[key];
          }
          // for non polluted address

          if (key === "address") {
            Object.keys(data[key]).forEach((key2) => {
              if (data[key][key2] === null) {
                delete data[key][key2];
              }
            });
          }
        });

        const res = await addCompany(data);
        let data2 = {
          lastName: values.lastName,
          firstName: values.firstName,
          email: values.email,
          location: values.location,
          companyId: res.data._id,
          roleId: "627955212019dc8e78e71ba8",
          profilePic: values.profilePicOfUser,
        };

        Object.keys(data2).forEach((key) => {
          if (data2[key] === "") {
            delete data2[key];
          }
        });

        toast.dismiss();
        const res2 = await inviteUser(data2);

        // make everything supplier of apex informatics
        let suppliers = {
          supplierIds: [res.data._id],
          companyId: companyId,
        };
        await inviteSupplier(suppliers);

        const projectIds = selectedProjectsArr.map((data) => {
          return data._id;
        });
        let data3 = {
          projectIds: [...projectIds],
          supplierId: res.data._id,
        };
        toast.dismiss();
        if (selectedProjectsArr.length !== 0) await addCompanyProjects(data3);

        toast.dismiss();
        toast.success("company added  successfully");
        navigate("/admin/companies");
        formik.resetForm();
      } catch (error) {
        toast.dismiss();
        toast.error(error?.response?.data?.message);
      }
    },
  });

  useEffect(() => {
    const call = async () => {
      if (!selectedFile) {
        setPreview(undefined);
        return;
      }
      toast.loading("uploading company logo");
      const url = await upload(selectedFile);
      formik.values.profilePic = url;
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      toast.dismiss();
    };
    call();
  }, [selectedFile]);

  const uploadImg = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  // upload photo for client admin

  useEffect(() => {
    const call = async () => {
      if (!selectedFileUser) {
        setPreviewUser(undefined);
        return;
      }
      toast.loading("uplodaing profile pic");
      const url = await upload(selectedFileUser);
      formik.values.profilePicOfUser = url;
      const objectUrl = URL.createObjectURL(selectedFileUser);
      setPreviewUser(objectUrl);
      toast.dismiss();

      // free memory when ever this component is unmounted
      // return () => URL.revokeObjectURL(objectUrl);
    };
    call();
  }, [selectedFileUser]);

  const uploadImgUser = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFileUser(undefined);
      return;
    }
    setSelectedFileUser(e.target.files[0]);
  };

  // add projects

  const [showDropDown, setShowDropDown] = useState(false);

  const showDropDownFunc = () => {
    setShowDropDown(!showDropDown);
  };

  const selectedProjects = (p) => {
    if (!selectedProjectsArr.includes(p)) {
      setselectedProjectsArr([...selectedProjectsArr, p]);
    }

    setShowDropDown(false);
  };

  const removeProject = (s) => {
    let newArr = [...selectedProjectsArr];
    let newArr2 = newArr.filter((p) => p._id !== s._id);
    setselectedProjectsArr(newArr2);
  };

  // add doc
  const [showDropDownDOC, setShowDropDownDOC] = useState(false);

  const showDropDownFuncDOC = () => {
    setShowDropDownDOC(!showDropDown);
  };

  const [selecteddocsArr, setselecteddocsArr] = useState([]);

  const selecteddocs = (p) => {
    if (!selecteddocsArr.includes(p)) {
      setselecteddocsArr([...selecteddocsArr, p]);
    }

    setShowDropDownDOC(false);
  };

  const removeDoc = (s) => {
    let newArr = [...selecteddocsArr];
    let newArr2 = newArr.filter((p) => p.id !== s.id);
    setselecteddocsArr(newArr2);
  };

  const dispatch = useDispatch();
  const closeAddNewCLientTab = () => {
    navigate("/admin/companies");
  };

  //  country

  const countries = Country.getAllCountries();

  const countriesOption = countries.map((s) => {
    return { value: s.isoCode, label: s.name };
  });

  // state country

  const [selectedCountry, setSelectedCountry] = useState();

  let states = [];
  if (selectedCountry) states = State.getStatesOfCountry(selectedCountry);

  let stateOptions = states?.map((s) => {
    return { value: s.isoCode, label: s.name };
  });

  //   const stateOptions = [
  //     { label: "England", value: "ENG" },
  //     { label: "Scotland", value: "SCT" },
  //     { label: "Wales", value: "WLS" },
  //     { label: "Northern Ireland", value: "NIR" },
  //   ];

  const [selectedState, setSelectedState] = useState();
  let cities = [];
  if (selectedCountry && selectedState)
    cities = City.getCitiesOfState(selectedCountry, selectedState);
  let citiesOptions = cities.map((s) => {
    return { value: s.name, label: s.name };
  });

  return (
    <>
      <div className="bg-white mt-8 px-8 grid grid-cols-3 gap-10 rounded-lg py-8">
        {/* 1st grid */}
        <div className="grid gap-6  content-start">
          <div className="text-sm ">
            <p className="text-sm">Company Logo</p>
            <input
              accept=".png,.jpg,.jpeg,.svg"
              onChange={uploadImg}
              type="file"
              id="fileUp"
              style={{ display: "none" }}
            />
            <div className="flex">
              <label className="cursor-pointer" htmlFor="fileUp">
                <div className="mt-4 w-14 h-14 p-[1px] border border-theme-green rounded-full">
                  {preview ? (
                    <img
                      src={preview}
                      className="h-full w-full rounded-full object-cover"
                      alt=""
                    />
                  ) : (
                    <div className="w-full rounded-full h-full bg-[#3231320D] flex justify-center items-center">
                      <Plus size={12} />
                    </div>
                  )}
                </div>
              </label>
            </div>
          </div>

          <div className="grid gap-2">
            <p className="font-medium text-sm">Name of the Client Company</p>
            <input
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              type="text"
              placeholder="Enter client name"
              className={classNames(
                "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                "bg-[#0000000D]"
              )}
            />
            {showErrors && formik.errors.name && (
              <span className="text-xs text-red-600">
                {formik.errors.name}{" "}
              </span>
            )}
          </div>

          <div className="grid gap-2">
            <p className="font-medium text-sm">Domain of the Client Company</p>
            <input
              type="text"
              name="domain"
              value={formik.values.domain}
              onChange={formik.handleChange}
              placeholder="Enter client domain"
              className={classNames(
                "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                "bg-[#0000000D]"
              )}
            />
            {showErrors && formik.errors.domain && (
              <span className="text-xs text-red-600">
                {formik.errors.domain}{" "}
              </span>
            )}
          </div>

          <div className="grid gap-2">
            <p className="font-medium text-sm">Email of the Client Admin</p>
            <input
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              type="text"
              placeholder="Enter client domain admin email"
              className={classNames(
                "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                "bg-[#0000000D]"
              )}
            />
            {showErrors && formik.errors.email && (
              <span className="text-xs text-red-600">
                {formik.errors.email}{" "}
              </span>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="grid gap-2">
              <p className="font-medium text-sm">State</p>
              {/* {stateOptions && (
                <SelectComp
                  onChange={(val) => {
                    formik.setFieldValue("address.state", val.label);
                    setSelectedState(val.value);
                  }}
                  value={selectedState}
                  options={stateOptions}
                />
              )}
              {showErrors && formik.errors.state && (
                <span className="text-xs text-red-600">
                  {formik.errors.state}{" "}
                </span>
              )} */}
              <input
                name="state"
                value={formik.values.address.state}
                onChange={formik.handleChange}
                type="text"
                placeholder="Enter state"
                className={classNames(
                  "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                  "bg-[#0000000D]"
                )}
              />
            </div>
            <div className="grid gap-2">
              <p className="font-medium text-sm">City</p>
              {/* <SelectComp
                onChange={(val) =>
                  formik.setFieldValue("address.city", val.value)
                }
                value={formik.values.address.city}
                options={citiesOptions}
              />
              {showErrors && formik.errors.city && (
                <span className="text-xs text-red-600">
                  {formik.errors.city}{" "}
                </span>
              )} */}

              <input
                name="city"
                value={formik.values.address.city}
                onChange={formik.handleChange}
                type="text"
                placeholder="Enter city"
                className={classNames(
                  "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                  "bg-[#0000000D]"
                )}
              />
            </div>

            <div className="grid gap-2">
              <p className="font-medium text-sm">Country</p>
              {/* <SelectComp
                onChange={(val) => {
                  formik.setFieldValue("address.country", val.label);
                  setSelectedCountry(val.value);
                }}
                value={"United Kingdom"}
                options={countriesOption}
              />
              {showErrors && formik.errors.country && (
                <span className="text-xs text-red-600">
                  {formik.errors.country}{" "}
                </span>
              )} */}
              <input
                name="country"
                value={formik.values.address.country}
                onChange={formik.handleChange}
                type="text"
                placeholder="Enter country"
                className={classNames(
                  "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                  "bg-[#0000000D]"
                )}
              />
            </div>
          </div>
        </div>

        {/* 2nd grid */}
        <div className="grid  gap-6 content-start	w-full">
          <div className="text-sm ">
            <p className="text-sm">Profile Pic of the client admin</p>
            <input
              accept=".png,.jpg,.jpeg,.svg"
              onChange={uploadImgUser}
              type="file"
              id="fileUpUser"
              style={{ display: "none" }}
            />
            <div className="flex">
              <label className="cursor-pointer" htmlFor="fileUpUser">
                <div className="mt-4 w-14 h-14 p-[1px] border border-theme-green rounded-full">
                  {previewUser ? (
                    <img
                      src={previewUser}
                      className="h-full w-full rounded-full object-cover"
                      alt=""
                    />
                  ) : (
                    <div className="w-full rounded-full h-full bg-[#3231320D] flex justify-center items-center">
                      <Plus size={12} />
                    </div>
                  )}
                </div>
              </label>
            </div>
          </div>

          <div className="grid gap-2">
            <p className="font-medium text-sm">
              First Name of the client Admin
            </p>
            <input
              name={"firstName"}
              onChange={formik.handleChange}
              vlaue={formik.values.firstName}
              type="text"
              placeholder="Enter  client admin first name"
              className={classNames(
                "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                "bg-[#0000000D]"
              )}
            />
            {showErrors && formik.errors.firstName && (
              <span className="text-xs text-red-600">
                {formik.errors.firstName}{" "}
              </span>
            )}
          </div>

          <div className="grid gap-2">
            <p className="font-medium text-sm">Last Name of the client Admin</p>
            <input
              name={"lastName"}
              onChange={formik.handleChange}
              vlaue={formik.values.lastName}
              type="text"
              placeholder="Enter client admin last name"
              className={classNames(
                "focus:outline-none px-4 p-3 w-full rounded border border-black-10 text-xs",
                "bg-[#0000000D]"
              )}
            />
            {showErrors && formik.errors.lastName && (
              <span className="text-xs text-red-600">
                {formik.errors.lastName}{" "}
              </span>
            )}
          </div>
        </div>

        {/* 3rd grid */}

        <div>
          <div className="grid h-[80%] gap-4">
            {/* add project */}
            <div className="bg-[#EFEBE9] flex-1 relative rounded-md ">
              <div className="mt-4">
                <p className="text-theme-black py-4 text-xs text-center font-medium">
                  Add an existing project to Client
                </p>

                <div className="mx-6 relative my-2">
                  <input
                    onClick={showDropDownFunc}
                    onChange={(e) => setSearchInput(e.target.value)}
                    placeholder={
                      showDropDown ? "Select Existing Projects" : "Add Project"
                    }
                    value={searchInput}
                    type="text"
                    className={classNames(
                      "w-full border border-[#00000033] bg-white cursor-pointer rounded-t-md focus:outline-none py-3 text-very-tiny",
                      showDropDown ? "px-4" : "px-[36px] rounded-b-md"
                    )}
                  />
                  {!showDropDown && (
                    <Buildings
                      size={16}
                      weight="bold"
                      className="absolute text-theme-black opacity-80 left-3 top-[13px]"
                    />
                  )}
                  {showDropDown ? (
                    <MagnifyingGlass
                      size={14}
                      className="absolute text-theme-black opacity-80 right-3 top-[14px]"
                    />
                  ) : (
                    <CaretDown
                      size={14}
                      className="absolute text-theme-black opacity-80 right-3 top-[14px]"
                    />
                  )}
                  {showDropDown && (
                    <div className=" absolute z-[99] w-full cursor-pointer h-36 overflow-y-auto border-[#00000033]">
                      {projects.length === 0 ? (
                        <p className="text-xs text-center pt-4">
                          No project found.{" "}
                        </p>
                      ) : (
                        projects?.map((p, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => selectedProjects(p)}
                              className="p-3 bg-white border-x border-black-10 border-b"
                            >
                              <div className="flex items-center text-xs bg-white justify-between">
                                <p>{p.name}</p>
                                <p>{p.uid}</p>
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  )}
                  <div className="grid content-start hide-scroll relative gap-2 mt-6 h-[290px] overflow-y-auto rounded-lg">
                    {selectedProjectsArr.map((s, idx) => {
                      return (
                        <div className="bg-white rounded-md">
                          <p className="text-xs font-medium border border-black-10 p-4 rounded-md flex justify-between items-center">
                            {s.name}
                            <X
                              onClick={() => removeProject(s)}
                              className="cursor-pointer"
                              size={12}
                            />
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid gap-3 mt-4">
            <button
              onClick={closeAddNewCLientTab}
              className="flex-1 w-full py-2 bg-transparent border-2 border-bottom-border uppercase text-sm font-bold rounded-lg hover:bg-white hover:border-[#00000066]"
            >
              <span className="opacity-40">CANCEL</span>
            </button>
            <button
              onClick={() => {
                formik.handleSubmit();
                setShowErrors(true);
              }}
              className="flex-1 w-full py-2 bg-theme-light-green text-theme-green border-2 border-theme-green uppercase text-sm font-bold rounded-lg hover:bg-theme-green hover:text-black"
            >
              <span className="">ADD CLIENT</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCompany;
