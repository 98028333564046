import {
  GET_DOCUMENT_ASSETS_FAIL,
  GET_DOCUMENT_ASSETS_REQ,
  GET_DOCUMENT_ASSETS_SUCC,
} from "../../constants/documents/getDocumentsOfAsset";

export const getAssetsOfADocumentReducer = (
  state = { assets: [], loading: false, error: null },
  action
) => {
  switch (action.type) {
    case GET_DOCUMENT_ASSETS_REQ:
      return { loading: true, ...state };

    case GET_DOCUMENT_ASSETS_SUCC:
      return {
        loading: false,
        assets: action.payload,
      };

    case GET_DOCUMENT_ASSETS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
