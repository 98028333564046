import classNames from "classnames";
import { ArrowRight, DownloadSimple, Eye } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadDoc, previewDoc } from "../../../../api/users/fileUpload";
import { getAllDocsVersions } from "../../../../redux/actions/documents/getAllVersions";
import { opneSingleVersion } from "../../../../redux/actions/documents/SingleDocumentAction";
import Loading from "../../../components/loading/Loading";
import DocumentPopup from "../../../components/random/DocumentPopup";

const VersionHistory = () => {
  const { document } = useSelector((state) => state.adminSingleDocument);
  const dispatch = useDispatch();

  const { versions } = useSelector((state) => state.getAllVersions);

  useEffect(() => {
    dispatch(getAllDocsVersions({ documentId: document._id }));
  }, [document._id, dispatch]);

  const downloadDocument = async (d) => {
    const url = await downloadDoc(d.data);

    window.open(url, "_blank");
  };

  const [previewModal, setPreviewModal] = useState(false);
  const [previewURL, setPreviewURL] = useState();
  const [selectedDoc, setSelectedDoc] = useState();
  const previewDocument = async (d) => {
    setPreviewModal(true);
    const url = await previewDoc(d.data);
    setSelectedDoc(d.data);

    setPreviewURL(url);
  };

  const tryAgain = async () => {
    setPreviewModal(false);
    setPreviewURL();
    const url = await previewDoc(selectedDoc);
    setPreviewModal(true);
    setPreviewURL(url);
  };
  return (
    <>
      <div className="bg-white rounded-md relative">
        <div className="rounded-t-md ">
          <h2 className="font-bold bg-[#F7F6F6] rounded-t-md text-theme-black  text-center py-8 border-b text-sm">
            Version History
          </h2>
          <div className="">
            {versions?.map((v, index) => {
              return (
                <div
                  key={index}
                  className={classNames(
                    "flex py-5 px-6",
                    "border-b border-bottom-border",
                    ""
                  )}
                >
                  <div className="w-[22%] text-xs gap-2">
                    <p className="">Version</p>
                    <p className="text-theme-black opacity-50 cursor-pointer leading-3">
                      {v.version}
                    </p>
                  </div>

                  <div className="text-xs w-[25%]">
                    <p className="">Status</p>
                    <p
                      className={classNames(
                        " cursor-pointer  leading-3",
                        v.status === "Approved"
                          ? "text-theme-green"
                          : "text-theme-black opacity-50"
                      )}
                    >
                      {v.status === "Approved" ? v.status : "Pending"}
                    </p>
                  </div>

                  <div className=" w-[25%] text-xs">
                    <div className="">
                      <p className="leading-3 ">People</p>
                      <p className="text-theme-black opacity-50 cursor-pointer">
                        {v.share.length}{" "}
                      </p>
                    </div>
                  </div>

                  <div className="text-xs flex flex-1 gap-2 justify-center  font-medium">
                    <DownloadSimple
                      onClick={() => downloadDocument(v)}
                      className="bg-#F7F6F6  p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
                      size={30}
                    />
                    <Eye
                      onClick={() => previewDocument(v)}
                      className="bg-#F7F6F6 p-2  cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
                      size={30}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {previewModal && (
        <>
          <div
            onClick={() => {
              setPreviewModal(false);
              setPreviewURL();
            }}
            className="fixed z-[9999999] w-screen h-screen top-0 left-0 bg-black bg-opacity-20"
          >
            {previewURL ? (
              <DocumentPopup
                closeModal={() => {
                  setPreviewModal(false);
                  setPreviewURL();
                }}
                documentUrl={previewURL}
                tryAgain={tryAgain}
              />
            ) : (
              <Loading loading />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default VersionHistory;
