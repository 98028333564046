import { GET_COMPANIES_FAIL,GET_COMPANIES_REQ, GET_COMPANIES_SUCC } from "../../constants/companies/companiesConst";

export const CompaniesReducer = (state={companies : [], loading:false,   error:null  }, action)=> {
    switch(action.type){
        case GET_COMPANIES_REQ:
            return {loading :true, ...state}
        case GET_COMPANIES_FAIL:
            return {error :action.payload, ...state}

        case GET_COMPANIES_SUCC:
            return{
                loading: false,
                companies: action.payload, 
                error:null,
                pagination:action.pagnationDetails,
            }
        default:
            return state;
    }
}