import { getAllProjectsApi, getProjectsApi } from "../../../api/users/projects";
import {
  GET_ALL_PROJECTS_FAIL,
  GET_ALL_PROJECTS_REQUEST,
  GET_ALL_PROJECTS_SUCC,
} from "../../constants/projects/getAllProjects";

import {
  GET_PROEJCTS_FAIL,
  GET_PROEJCTS_REQ,
  GET_PROEJCTS_SUCC,
} from "../../constants/projects/ProjectsConst";
export const getProjects = () => async (dispatch) => {
  try {
    dispatch({ type: GET_PROEJCTS_REQ });
    const res = await getProjectsApi();
    dispatch({
      type: GET_PROEJCTS_SUCC,
      payload: res.data.docs,
    });
  } catch (error) {
    dispatch({
      type: GET_PROEJCTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllProjects =
  ({
    companyId = "",
    limit = 5,
    page = 1,
    q = "",
    status = "",
    excludeIds = [],
    userId = "",
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_PROJECTS_REQUEST });
      const res = await getAllProjectsApi(
        companyId,
        limit,
        page,
        q,
        status,
        excludeIds,
        userId
      );

      dispatch({
        type: GET_ALL_PROJECTS_SUCC,
        payload: res.data.docs,
        allProjectDetails: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_PROJECTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
