import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { openSingleAssetAdminAction } from '../../../../redux/actions/Admin/OpenSingleAssetAdmin'
import AssetDesc from './AssetDesc'
import MapAndOwner from './MapAndOwner'
import ProjectsDocumentsAndSupplier from './ProjectsDocumentsAndSupplier'

const SingleAsset = () => {

  const location = useLocation().pathname
  const {open, asset} = useSelector(state => state.singleAssetAdmin)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(openSingleAssetAdminAction(location.split('/')[3]))
  }, [])
  return (
    <>
        {asset && <div className='grid grid-cols-3 gap-4 mt-4'>
            <MapAndOwner/>
            <AssetDesc/>
            <ProjectsDocumentsAndSupplier/>
        </div>}
    </>
  )
}

export default SingleAsset