import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { openSingleEmployeeAdminAction } from "../../../../redux/actions/Admin/OpenSingleCompanyAdmin";
import AssetsAndProjects from "./AssetsAndProjects";
import Attributes from "./Attributes";

const SingleCompany = ({ edit, setEdit }) => {
  const { company } = useSelector((state) => state.singleCompanyAdmin);

  const location = useLocation().pathname;
  const { suppliers: companies } = useSelector((state) => state.suppliers);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(openSingleEmployeeAdminAction(location.split("/")[3], companies));
  }, [location]);

  return (
    <>
      <div className="flex mt-4 gap-4">
        {company && <Attributes edit={edit} setEdit={setEdit} />}
        {company && <AssetsAndProjects />}
      </div>
    </>
  );
};

export default SingleCompany;
