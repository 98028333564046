import classNames from 'classnames';
import { ArrowRight } from 'phosphor-react';
import React, { useState } from 'react'
import ProjectsDropDown from '../../../../components/Assets/comps/ProjectsDropDown';
import DocumentsDropDown from '../../../../components/Projects/comps/DocumentsDropDown';
import { Assests } from '../../../../fake/fakeAssets';
import { FakeProjects } from '../../../../fake/fakeProject';
import { documents } from '../../../../fake/faleDoc';

const AllAssets = () => {
    const [projectsDropDown, setProjectsDropDown] = useState(false)
    const [documentsDropDown, setdocumentsDropDown] = useState(false);
    const [clickedId, setClickedId] = useState(null)


    const projectsDropDownOpener = (s) => {
        setClickedId(s.id)
        setProjectsDropDown((s.id=== clickedId)? !projectsDropDown : true);
        setdocumentsDropDown(false)
    }

    const documentsDropDownOpener = (s) => {
        setClickedId(s.id)
        setdocumentsDropDown((s.id=== clickedId)? !documentsDropDown : true);
        setProjectsDropDown(false)
    }

    const openSingleAsset = (a) => {

    }

  return (
    <>
        {
                    Assests.map((a, index) => {
                        return <div className='' key={index}>
                                <div className={classNames('flex py-5 px-6', (projectsDropDown || documentsDropDown) && clickedId===a.id ?'bg-theme-light-green border-b border-t': 'border-b border-bottom-border', '')}>
                                    <div className='w-[12%] text-xs font-medium'>
                                        <p className='uppercase'>id</p>
                                        <p className='opacity-50'>{a.uid}</p>
                                    </div>
                                    <div className='flex w-[25%] text-xs gap-2'>
                                        <div className='h-full'>
                                            <img src="images/charging-station.png" alt="" />
                                        </div>
                                        <div className=''>
                                            <p className='font-medium'>{a.assetType}</p>
                                            <p>{a.assetsName}</p>
                                        </div>
                                    </div>


                                    <div className='text-xs w-[25%]'>
                                        <p className='font-medium'>{a.status.statusType}</p>
                                        <p className='italic font-light'>{a.status.stage}</p>
                                    </div>

                                    <div className=' w-[15%] text-xs'>
                                        <div className=''>
                                            <p className='leading-3'>Projects</p>
                                            <p onClick={() => projectsDropDownOpener(a)} className='text-theme-black opacity-50 cursor-pointer leading-3'>{FakeProjects.filter((p) => p.projectDetails.owner === a.assetOwner.name).length} <i className={classNames("fa-solid ml-1 ", (projectsDropDown && clickedId===a.id)?"fa-angle-up": 'fa-angle-down')}></i></p>
                                        </div>
                                    </div>
                                    
                                    <div className=' w-[15%] text-xs'>
                                        <div className=''>
                                            <p className='leading-3'>Documents</p>
                                            <p onClick={() => documentsDropDownOpener(a)} className='text-theme-black opacity-50 cursor-pointer leading-3'>{documents.filter((d) => d.people.uploadedBy.companyName === a.assetOwner.name).length} <i className={classNames("fa-solid ml-1 ", (documentsDropDown && clickedId ===a.id)?"fa-angle-up": 'fa-angle-down')}></i></p>
                                        </div>
                                    </div>

                                    <div className='text-xs flex flex-1 justify-end font-medium'>
                                        <ArrowRight onClick={() => openSingleAsset(a)} className='bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded' size={28} weight='light'/>
                                    </div>
                                </div>

                                {(projectsDropDown) && clickedId===a.id && <div>
                                        {
                                            FakeProjects.filter((p) => p.projectDetails.owner === a.assetOwner.name).map((project, index) => {
                                                return <ProjectsDropDown project={project} key={index}/>
                                            })
                                        }
                                    </div>}

                                    {(documentsDropDown) && clickedId===a.id && <div>
                                        
                                        {
                                          documents.filter((d) => d.people.uploadedBy.companyName === a.assetOwner.name).map((s, index) => {
                                            return <DocumentsDropDown s={s} key={index}/>
                                        })
                                        }
                                    </div>}
                        </div>  
                    })
                }
    </>
  )
}

export default AllAssets