/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import AllSupportMsg from './comps/AllSupportMsg'
import ShowCase from './comps/ShowCase'
import TopLinks from './comps/TopLinks'
import CreateNewTicket from './createNewTicket'

const MainDiv = styled.div`
  height: calc(100vh - 57px);

`

const Support = () => {
  const {opencreateNewSupportTicket} = useSelector(state=> state.createNewSupportTicket)
  const {open} = useSelector(state => state.getConversations)
    
  const [selectedSupportId, setSelectedSupportId] = useState("")

  return (
    <>
        <MainDiv className='px-[32px] py-8 overflow-y-auto'>
            <TopLinks/>
           {!opencreateNewSupportTicket && !open &&  <ShowCase setSelectedSupportId={setSelectedSupportId}/>}
           {opencreateNewSupportTicket && <CreateNewTicket/>}
           {open && <AllSupportMsg selectedSupportId={selectedSupportId}/>}
        </MainDiv>
    </>
  )
}

export default Support