/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import { addNewSubscriptionReducer } from "../redux/reducers/Admin/AddNewSubscriptionReducer";
import { createNewSupportTicketReducer } from "../redux/reducers/Admin/CreateNewHelpTicket";
import { addNewNotificationsReducer } from "../redux/reducers/Admin/CreateNewNotificatioReducer";
import { adminSingleDocumentReducer } from "../redux/reducers/Admin/DocumentsReducers";
import { AdminLeftNavRedcer } from "../redux/reducers/Admin/LeftNavAdmin";
import { singleAssetAdminReducer } from "../redux/reducers/Admin/OpenSingleAssetsAdmin";
import { singleCompanyAdminReducer } from "../redux/reducers/Admin/OpenSingleCompanyAdmin";
import { singleEmployeeAdminReducer } from "../redux/reducers/Admin/OpenSingleEmployeeAdmin";
import { singleProjectAdminReducer } from "../redux/reducers/Admin/OpenSingleProjectAdmin";
import {
  assetsReducer,
  getAssetDocumentsReducer,
} from "../redux/reducers/assets/assetsReducer";
import { singleAssetReducer } from "../redux/reducers/assets/OpenSingleAssetReducer";

import { CompaniesReducer } from "../redux/reducers/compaines/companies";
import { createNewSupportTicketUserReducer } from "../redux/reducers/CreateNewHelpTicket";
import {
  opneSingleVersionReducer,
  singleDocumentReducer,
} from "../redux/reducers/documents/DocumentsReducers";
import { LeftNavRedcer } from "../redux/reducers/LeftNavReducer";
import { singleProjectReducer } from "../redux/reducers/projects/ProjectReducer";
import {
  getAllProjectsReducer,
  ProjectsReducer,
} from "../redux/reducers/projects/ProjectsReducer";
import { singleSupplierReducer } from "../redux/reducers/suppliers/singleSupplierReducer";
import { SuppliersReducer } from "../redux/reducers/suppliers/supplierReducer";
import { addNewUserReducer } from "../redux/reducers/users/AddNewUSer";
import { LoggedInUserReducer } from "../redux/reducers/users/me";
import { singleUserReducer } from "../redux/reducers/users/SingleUserReducer";
import { UsersReducer } from "../redux/reducers/users/users";
import storage from "redux-persist/lib/storage";
import { getDocTypeReducer } from "../redux/reducers/documents/getDocTypesReducer";
import { getAssetsOFAProjectReducer } from "../redux/reducers/assets/getAssetsOfAProjectReducer";
import { getSuppliersOFAProjectReducer } from "../redux/reducers/suppliers/getSuppliersOfAProject";
import { getSingleUserReducer } from "../redux/reducers/users/getSingleUser";
import { getAllDocReducer } from "../redux/reducers/documents/getAllDocReducer";
import { getAllTasksReducer } from "../redux/reducers/projects/getAllTaskReducer";
import { getProjectAdminReducer } from "../redux/reducers/projects/getProjectAdminReducer";
import { getAllVersionsReducer } from "../redux/reducers/documents/getAllVersionsReducer";
import { getSingleDocumentReducer } from "../redux/reducers/documents/getSingleDocumentReducer";
import { getProjectsOfASupplierReducer } from "../redux/reducers/suppliers/getProjectsOfASupplier";
import { getDahboardDataReducer } from "../redux/reducers/dashBoardReducer";
import { getRecentActivityReducer } from "../redux/reducers/getRecentActivityReducer";
import { getCategoriesReducer } from "../redux/reducers/categories/getAllCategoriesReducer";
import { getAllSubCategoriesReducer } from "../redux/reducers/categories/getAllSubCategoryReducer";
import { getConversationsReducer } from "../redux/reducers/getConversationsReducer";
import { getDocumentsOfASupplierReducer } from "../redux/reducers/suppliers/getDocumentsOfAsupplierReducer";
import { getAllEmployeesReducer } from "../redux/reducers/users/getAllEmployeesReducer";
import { getAllSUppoortReducer } from "../redux/reducers/getAllSupport/getAllSupportReducer";
import { selectCompanyReducer } from "../redux/reducers/selectCompanyReducer";
import { getAllProjectsOfAUserReducer } from "../redux/reducers/projects/getProjectsOfAReducer";
import { getAssetsOfADocumentReducer } from "../redux/reducers/documents/getAssetsofADocReducer";

const persistConfig = {
  key: "me",
  storage,
};

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  //  if (Object.keys(injectedReducers).length === 0) {
  //    return state => state;
  //  } else {
  return combineReducers({
    ...injectedReducers,
    LeftNav: LeftNavRedcer,
    singleAsset: singleAssetReducer,
    singleProject: singleProjectReducer,
    singleDocument: singleDocumentReducer,
    openSingleVersion: opneSingleVersionReducer,
    singleSupplier: singleSupplierReducer,
    singleUser: singleUserReducer,
    addNewUser: addNewUserReducer,
    createNewSupportTicketUser: createNewSupportTicketUserReducer,
    users: UsersReducer,
    suppliers: SuppliersReducer,
    projects: ProjectsReducer,
    assets: assetsReducer,
    getAllDoc: getAllDocReducer,
    getAllProjects: getAllProjectsReducer,
    getDocType: getDocTypeReducer,
    getAssetsOFAProject: getAssetsOFAProjectReducer,
    getSuppliersOFAProject: getSuppliersOFAProjectReducer,
    getSingleUser: getSingleUserReducer,
    getAllTasks: getAllTasksReducer,
    getProjectAdmin: getProjectAdminReducer,
    getAllVersions: getAllVersionsReducer,
    getSingleDocument: getSingleDocumentReducer,
    getProjectsOfASupplier: getProjectsOfASupplierReducer,
    getDahboardData: getDahboardDataReducer,
    getRecentActivity: getRecentActivityReducer,
    getConversations: getConversationsReducer,
    getDocumentsOfASupplier: getDocumentsOfASupplierReducer,
    getAllSUppoort: getAllSUppoortReducer,
    selectCompany: selectCompanyReducer,
    getAllProjectsOfAUser: getAllProjectsOfAUserReducer,
    //  admin reducers
    AdminLeftNav: AdminLeftNavRedcer,
    singleAssetAdmin: singleAssetAdminReducer,
    singleProjectAdmin: singleProjectAdminReducer,
    adminSingleDocument: adminSingleDocumentReducer,
    singleEmployeeAdmin: singleEmployeeAdminReducer,
    singleCompanyAdmin: singleCompanyAdminReducer,
    addNewSubscription: addNewSubscriptionReducer,
    addNewNotifications: addNewNotificationsReducer,
    createNewSupportTicket: createNewSupportTicketReducer,
    compaines: CompaniesReducer,
    me: persistReducer(persistConfig, LoggedInUserReducer),
    getCategories: getCategoriesReducer,
    getAllSubCategories: getAllSubCategoriesReducer,
    getAllEmployees: getAllEmployeesReducer,
    getAssetDocuments: getAssetDocumentsReducer,
    getAssetsOfADocument: getAssetsOfADocumentReducer,
  });
}
//  }
