import { getProjectsOfASupplier } from "../../../api/users/company";
import {
  GET_PROJECTS_OF_A_SUPPLIER_FAIL,
  GET_PROJECTS_OF_A_SUPPLIER_REQ,
  GET_PROJECTS_OF_A_SUPPLIER_SUCC,
} from "../../constants/suppliers/getProjectOfASupplier";

export const getProjectOfASupplierAction =
  (supplierId, companyId = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_PROJECTS_OF_A_SUPPLIER_REQ });
      const res = await getProjectsOfASupplier(supplierId, 25, 1, companyId);
      dispatch({
        type: GET_PROJECTS_OF_A_SUPPLIER_SUCC,
        payload: res.data.docs,
      });
    } catch (error) {
      dispatch({
        type: GET_PROJECTS_OF_A_SUPPLIER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
