import { Plus } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setUserProject } from "../../../../api/users/inviteUsers";
import {
  addCompanyToUser,
  updateCompanyToUser,
} from "../../../../api/users/users";
import { Filter } from "../../../../styles/FilterButton";
import AllAssets from "./comps/AllAssets";
import AllCompanies from "./comps/AllCompanies";
import AllDocs from "./comps/AllDocs";
import AllProjects from "./comps/AllProjects";
import GrantAccessTab from "./comps/GrantAccessTab";
import GrantAccessTabCompanies from "./comps/GrantAccessTabCompanies";
import UserPermissions from "./comps/UserPermissions";

const AssetProjectAndDocuments = () => {
  const [active, setActive] = useState({
    assets: false,
    projects: true,
    documents: false,
    companies: false,
    permissions: false,
  });

  const { open, employee } = useSelector((state) => state.singleEmployeeAdmin);

  const [openGranstAccessTab, setOpenGrantAccessTab] = useState(false);

  const hideGrantAccess = () => {
    setOpenGrantAccessTab(false);
  };

  const [selectedProjectsArr, setselectedProjectsArr] = useState([]);
  const saveProjects = async () => {
    try {
      const prIds = selectedProjectsArr.map((s) => {
        return s._id;
      });
      let data4 = {
        userId: employee.data._id,
        projectIds: [...prIds],
      };
      toast.loading("Adding projects to the user");
      await setUserProject(data4);
      toast.dismiss();

      toast.success("Projects added");

      setOpenGrantAccessTab(false);
    } catch (err) {
      toast.dismiss();
      toast.error("some error occurred. Please try again.");
    }
  };

  // grant access company

  const [grantAccessToAnotherCompanyTab, setGrantAccessToAnotherCompanyTab] =
    useState(false);
  const [selectedCompaniesArr, setselectedCompaniesArr] = useState([]);
  const [accesssibleCompanies, setAccessibleCompanies] = useState();

  const { companies } = useSelector((state) => state.compaines);

  const hideGrantAccessCompaniesTab = () => {
    setGrantAccessToAnotherCompanyTab(false);
  };
  useEffect(() => {
    if (accesssibleCompanies) {
      const compIds = accesssibleCompanies?.map((s) => {
        return s._id;
      });

      let commonCompanies = compIds?.map((c) => {
        return companies?.filter((comp) => comp._id === c)[0];
      });
      setselectedCompaniesArr([...commonCompanies]);
    }
  }, [accesssibleCompanies, companies]);

  const saveCompanies = async () => {
    try {
      const compIds = selectedCompaniesArr.map((s) => {
        return s._id;
      });
      let data4 = {
        userId: employee.data._id,
        companyIds: [...compIds],
      };
      toast.loading("Adding companies to the user");
      await updateCompanyToUser(data4);
      toast.dismiss();

      toast.success("Companies added");

      setGrantAccessToAnotherCompanyTab(false);
    } catch (err) {
      toast.dismiss();
      toast.error("some error occurred. Please try again.");
    }
  };
  return (
    <>
      <div className="rounded-lg border-2 border-black-10">
        <div className="flex p-6 border-b justify-between">
          <div className="flex items-center gap-4">
            {/* <Filter onClick={() => setActive({assets:true, projects:false, documents: false})} active={active.assets}>Assets</Filter> */}
            <Filter
              onClick={() =>
                setActive({ assets: false, projects: true, documents: false })
              }
              active={active.projects}
            >
              Projects
            </Filter>
            <Filter
              onClick={() =>
                setActive({
                  assets: false,
                  projects: false,
                  documents: false,
                  companies: true,
                })
              }
              active={active.companies}
            >
              Companies
            </Filter>
            <Filter
              onClick={() =>
                setActive({
                  assets: false,
                  projects: false,
                  documents: false,
                  companies: false,
                  permissions: true,
                })
              }
              active={active.permissions}
            >
              Permissions
            </Filter>
            {/* <Filter onClick={() => setActive({assets:false, projects:false, documents: true})} active={active.documents}>Documents</Filter> */}
          </div>
          {active.projects &&
            (openGranstAccessTab ? (
              <div className="flex gap-3 items-center">
                <button
                  onClick={saveProjects}
                  className="text-opacity-70 text-theme-black gap-3 bg-[#0000001A] rounded-md flex items-center gap-3 px-4 py-2 text-xs border border-black-10"
                >
                  SAVE
                </button>
                <button
                  onClick={hideGrantAccess}
                  className="text-opacity-70 text-theme-black gap-3 bg-[#0000001A] rounded-md flex items-center gap-3 px-4 py-2 text-xs border border-black-10"
                >
                  CANCEL
                </button>
              </div>
            ) : (
              <button
                onClick={() => setOpenGrantAccessTab(true)}
                className="text-opacity-70 text-theme-black gap-3 bg-[#0000001A] rounded-md flex items-center gap-3 px-4 py-2 text-xs border border-black-10"
              >
                Grant Access to another project <Plus size={14} />
              </button>
            ))}

          {active.companies &&
            (grantAccessToAnotherCompanyTab ? (
              <div className="flex gap-3 items-center">
                <button
                  onClick={saveCompanies}
                  className="text-opacity-70 text-theme-black gap-3 bg-[#0000001A] rounded-md flex items-center gap-3 px-4 py-2 text-xs border border-black-10"
                >
                  SAVE
                </button>
                <button
                  onClick={hideGrantAccessCompaniesTab}
                  className="text-opacity-70 text-theme-black gap-3 bg-[#0000001A] rounded-md flex items-center gap-3 px-4 py-2 text-xs border border-black-10"
                >
                  CANCEL
                </button>
              </div>
            ) : (
              <button
                onClick={() => setGrantAccessToAnotherCompanyTab(true)}
                className="text-opacity-70 text-theme-black gap-3 bg-[#0000001A] rounded-md flex items-center gap-3 px-4 py-2 text-xs border border-black-10"
              >
                Grant Access to another company <Plus size={14} />
              </button>
            ))}
        </div>

        {active.assets && <AllAssets />}
        {active.projects && !openGranstAccessTab && (
          <AllProjects userData={employee.data} />
        )}
        {active.documents && <AllDocs />}
        {active.companies && !grantAccessToAnotherCompanyTab && (
          <AllCompanies
            accesssibleCompanies={accesssibleCompanies}
            setAccessibleCompanies={setAccessibleCompanies}
            userData={employee.data}
          />
        )}

        {active.permissions && <UserPermissions userData={employee.data} />}

        {openGranstAccessTab && active.projects && (
          <GrantAccessTab
            selectedProjectsArr={selectedProjectsArr}
            setselectedProjectsArr={setselectedProjectsArr}
          />
        )}
        {grantAccessToAnotherCompanyTab && active.companies && (
          <GrantAccessTabCompanies
            selectedCompaniesArr={selectedCompaniesArr}
            setselectedCompaniesArr={setselectedCompaniesArr}
          />
        )}
      </div>
    </>
  );
};

export default AssetProjectAndDocuments;
