import { getUsersApi } from "../../../api/users/users";
import { users } from "../../../app/fake/fakeUser";
import {
  GET_ALL_USERS,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_REQ,
} from "../../constants/users/Users";

export const getUsers =
  (companyId = "", status = false) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_USERS_REQ });
      const res = await getUsersApi(companyId, status);

      dispatch({
        type: GET_ALL_USERS,
        payload: res.data.docs,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_USERS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
