/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import AdminOptions from "../../adminComps/AdminOptions";
import AdvancedSearch from "../../adminComps/AdvancedSearch";
import AssetsAdmin from "../../adminComps/Assets";
import Companies from "../../adminComps/Companies";
import DashBoard from "../../adminComps/Dashboard";
import Documents from "../../adminComps/Documents";
import Employess from "../../adminComps/Employees";
import ISO from "../../adminComps/ISO";
import LeftNavAdmin from "../../adminComps/Leftnav";
import MyAccount from "../../adminComps/MyAccount";
import Notifications from "../../adminComps/Notifications";
import ProjectsAdmin from "../../adminComps/Projects";
import Support from "../../adminComps/Support";
import TopNavAdmin from "../../adminComps/TopNav";
import { Routes, Route } from "react-router-dom";

const MainDiv = styled.div`
  ${
    "" /* margin-top:57px;
margin-left:76px; */
  }
  width: calc(100vw - 76px);
  height: calc(100vh - 57px);
  box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
  -o-box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.25);
`;

const AdminHomePage = () => {
  const {
    dashboard,
    companies,
    assets,
    documents,
    projects,
    employess,
    iso,
    profile,
    adminOptions,
    notifications,
    advancedSearch,
    support,
  } = useSelector((state) => state.AdminLeftNav);

  let isCompaniesActie;
  if (companies)
    isCompaniesActie = companies.list || companies.invite || companies.add;

  let isAssetsActive;
  if (assets) isAssetsActive = assets.list || assets.map || assets.add;

  let isprojectsActive;
  if (projects)
    isprojectsActive = projects.list || projects.map || projects.add;

  let isdocumentsActive;
  if (documents)
    isdocumentsActive = documents.list || documents.map || documents.add;

  let isemployessActive;
  if (employess) isemployessActive = employess.add || employess.list;

  let isSupportActive;
  if (support) isSupportActive = support.dev || support.user;

  const { user } = useSelector((state) => state.me);

  const navigate = useNavigate();

  useEffect(() => {
    if (user.roleId.name !== "Admin") {
      navigate("/");
    }
  }, []);

  const location = useLocation();

  return (
    <>
      <div className="lg:block hidden">
        <TopNavAdmin />
        <div className="flex z-10 ">
          <LeftNavAdmin />
          <MainDiv className="flex-1 relative  bg-#dddddd shadow-inner">
            <Routes>
              <Route exact path="/" element={<DashBoard />} />
              <Route path="companies/*" element={<Companies />} />
              <Route path="assets/*" element={<AssetsAdmin />} />
              <Route path="projects/*" element={<ProjectsAdmin />} />
              <Route path="documents/*" element={<Documents />} />
              <Route path="employees/*" element={<Employess />} />
              <Route path="notifications/*" element={<Notifications />} />
              <Route path="profile/*" element={<MyAccount />} />
              <Route path="adminOptions/*" element={<AdminOptions />} />
              <Route path="support/*" element={<Support />} />
              <Route path="advancedSearch/*" element={<AdvancedSearch />} />
            </Routes>
            {/* {dashboard && <DashBoard/>}
              {isCompaniesActie && <Companies/>}
              {isAssetsActive && <AssetsAdmin/>}
              {isprojectsActive && <ProjectsAdmin/>}
              {isdocumentsActive && <Documents/>}
              {isemployessActive && <Employess/>}
              {iso && <ISO/>}
              {notifications && <Notifications/>}
              {profile && <MyAccount/>}
              {adminOptions && <AdminOptions/>}
              {advancedSearch && <AdvancedSearch/>}
              {isSupportActive && <Support/>} */}
          </MainDiv>
        </div>
      </div>

      <div className="lg:hidden bg-[#F7F7F7] h-[100vh] w-[100vw] block">
        <div className="flex flex-col justify-center items-center text-center pt-6 font-medium  px-3">
          <div className="flex justify-center ">
            <img className="w-40" src="images/ApexLogo_Name_PNG.png" alt="" />
          </div>

          <p className="mt-5 w-[22rem]">
            <span className="text-theme-green">Apex Informatics </span> software
            is not compatible for mobile use. Please use it in a desktop screen.
          </p>
        </div>
        <div className=" flex jsutify-center absolute bottom-3 flex-col items-center">
          <p className="text-sm">Let's build it together</p>
          <img className="w-68" src="images/sky.png" alt="" />
        </div>
      </div>
    </>
  );
};

export default AdminHomePage;
