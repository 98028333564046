import classNames from 'classnames'
import { ArrowRight } from 'phosphor-react'
import React from 'react'
import { Assests } from '../../../fake/fakeAssets'
import { FakeSuppliers } from '../../../fake/fakeSuppliers'
import { documents } from '../../../fake/faleDoc'

const ClientDropDown = ({p}) => {
  return (
    <>
        <div >
                            <div className={classNames('flex bg-very-light-gray items-center py-4 px-6', 'border-b border-bottom-border', '')}>
                                <div className='w-[12%] text-xs font-medium'>
                                    <p className='uppercase'>id</p>
                                    <p className='opacity-50'>{p.uid}</p>
                                </div>

                                <div className='flex w-[28%] items-center text-xs gap-2'>
                                    <div className='text-xs font-medium'>
                                        <div className='flex gap-2 items-center'>
                                            <div>
                                                <img src={p.supplierDetails.imgUrl} className='w-8 p-0.5 border border-theme-green rounded-full' alt="" />
                                            </div>
                                            <div>
                                                <p className='font-medium '>{p.supplierDetails.companyName}</p>
                                                <p className='opacity-60'>{p.companyAdmin}(company admin)</p>
                                                <p className=' font-normal  opacity-50'>{p.supplierDetails.address}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-[18%] text-xs gap-2'>
                                    <p className='font-medium'>Domain</p>
                                    <p className='text-theme-black opacity-50 font-medium'>{p.domain}</p>
                                </div>


                                <div className=' w-[12%] text-xs'>
                                    <div className=''>
                                        <p className='leading-3'>Assets</p>
                                        <p className='text-theme-black opacity-50 cursor-pointer leading-3'>{Assests.length} <i className={classNames("fa-solid ml-1 ", 'fa-angle-down')}></i></p>
                                    </div>
                                </div>

                                <div className=' w-[12%] text-xs'>
                                    <div className=''>
                                        <p className='leading-3'>Projects</p>
                                        <p className='text-theme-black opacity-50 cursor-pointer leading-3'>{FakeSuppliers.length} <i className={classNames("fa-solid ml-1 ",  'fa-angle-down')}></i></p>
                                    </div>
                                </div>

                                <div className=' w-[12%] text-xs'>
                                    <div className=''>
                                        <p className='leading-3'>Documents</p>
                                        <p className='text-theme-black opacity-50 cursor-pointer leading-3'>{documents.length} <i className={classNames("fa-solid ml-1 ",  'fa-angle-down')}></i></p>
                                    </div>
                                </div>

                                <div className='text-xs flex flex-1 justify-end font-medium'>
                                    <ArrowRight className='bg-#F7F6F6 p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded' size={28} weight='light'/>
                                </div>
                            </div>

                            
                        </div>
    </>
  )
}

export default ClientDropDown