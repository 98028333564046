/* eslint-disable no-unused-vars */
import classNames from "classnames";
import { ArrowRight, DownloadSimple, Eye } from "phosphor-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSingleDocumentAdminAction } from "../../../../redux/actions/Admin/SingleDocumentAction";
import ProjectsDropDown from "../../../components/Assets/comps/ProjectsDropDown";
import PeopleDropDown from "../../../components/Documents/ListComps/PeopleDropDown";
import VersionsDropDown from "../../../components/Documents/ListComps/VersionsDropDown";
import AssetsDropDown from "../../../components/Projects/comps/AssetsDropDown";
import { Assests } from "../../../fake/fakeAssets";
import { FakeProjects } from "../../../fake/fakeProject";
import { documents } from "../../../fake/faleDoc";
import { getSingleDocumentAction } from "../../../../redux/actions/documents/getSingleDocumentAction";
import Loading from "../../../components/loading/Loading";
import { useNavigate } from "react-router-dom";
import { downloadDoc, previewDoc } from "../../../../api/users/fileUpload";
import DocumentPopup from "../../../components/random/DocumentPopup";
import { getAllDocsVersions } from "../../../../redux/actions/documents/getAllVersions";
import { getSingleDocumentAssets } from "../../../../redux/actions/documents/getAssetOfADocument";
// import { getSingleDocumentAction } from '../../../../redux/actions/documents/getSingleDocumentAction'
//
const DocumentsTable = () => {
  const [versionDropDown, setVersionDropDown] = useState(false);

  const [assetsDropDown, setassetsDropDown] = useState(false);
  const [projectsDropDown, setprojectsDropDown] = useState(false);
  const [peopleDropDown, setpeopleDropDown] = useState(false);
  const [clickedId, setClickedId] = useState(null);
  const dispatch = useDispatch();

  const { docs, loading } = useSelector((state) => state.getAllDoc);

  const { versions } = useSelector((state) => state.getAllVersions);

  const { document } = useSelector((state) => state.getSingleDocument);

  const versionsDropDownHandler = (d) => {
    setClickedId(d._id);
    setVersionDropDown(!versionDropDown);
    dispatch(getAllDocsVersions({ documentId: d._id }));
    setassetsDropDown(false);
    setpeopleDropDown(false);
    setprojectsDropDown(false);
  };

  const assetDropDonwOPener = (d) => {
    setClickedId(d._id);
    dispatch(getSingleDocumentAssets({ page: 1, limit: 500, docId: d._id }));
    setVersionDropDown(false);
    setassetsDropDown(!assetsDropDown);
    setpeopleDropDown(false);
    setprojectsDropDown(false);
  };

  const projectsDropDownOpener = (d) => {
    setClickedId(d._id);
    setVersionDropDown(false);
    setassetsDropDown(false);
    setpeopleDropDown(false);
    setprojectsDropDown(!projectsDropDown);
  };

  const peopleDropDonwOpener = (d) => {
    dispatch(getSingleDocumentAction(d._id));

    setClickedId(d._id);
    setVersionDropDown(false);
    setassetsDropDown(false);
    setpeopleDropDown(!peopleDropDown);
    setprojectsDropDown(false);
  };

  const navigate = useNavigate();

  const openSingleDocHandler = (d) => {
    dispatch(openSingleDocumentAdminAction(d._id));
    navigate(`${d._id}`);
  };

  const downloadDocument = async (d) => {
    const url = await downloadDoc(d.data);

    window.open(url, "_blank");
  };

  const [previewModal, setPreviewModal] = useState(false);
  const [previewURL, setPreviewURL] = useState();
  const [selectedDoc, setSelectedDoc] = useState();
  const previewDocument = async (d) => {
    setPreviewModal(true);
    const url = await previewDoc(d.data);
    setSelectedDoc(d.data);

    setPreviewURL(url);
  };

  const tryAgain = async () => {
    setPreviewModal(false);
    setPreviewURL();
    setPreviewModal(false);
    const url = await previewDoc(selectedDoc);
    setPreviewModal(true);
    setPreviewURL(url);
  };

  const { assets: allAssetsOfTheDoc, loading: assetDropDownLoading } =
    useSelector((state) => state.getAssetsOfADocument);

  return (
    <>
      <div className="flex  text-xs font-semibold px-6 mt-6 border-bottom-border border-b">
        <div className="w-[10%] opacity-50 py-6">UID</div>
        <div className="w-[9%] opacity-50 py-6">Version</div>
        <div className="w-[20%] opacity-50 py-6">Document Details</div>
        <div className="w-[8%] opacity-50 py-6 ">Status</div>
        <div className="w-[10%] opacity-50 py-6">Last Edit</div>
        <div className="w-[10%] opacity-50 py-6">Assets</div>
        <div className="w-[14%] opacity-50 py-6">Project</div>
        <div className="w-[7%] opacity-50 py-6">People</div>
        <div className=" opacity-50 py-6 text-center flex-1">View</div>
      </div>

      {loading ? (
        <Loading loading={loading} />
      ) : (
        docs?.map((d, index) => {
          return (
            <div key={index}>
              <div
                className={classNames(
                  "flex py-4 px-6",
                  (assetsDropDown ||
                    projectsDropDown ||
                    peopleDropDown ||
                    versionDropDown) &&
                    clickedId === d._id
                    ? "bg-theme-light-green border-b border-t"
                    : "border-b border-bottom-border",
                  ""
                )}
              >
                <div className="w-[10%] text-xs font-medium">
                  <p className="uppercase">id</p>
                  <p className="opacity-50">{d.uid}</p>
                </div>

                <div className="w-[9%] text-xs gap-2">
                  <p className="">Version</p>
                  <p
                    onClick={() => versionsDropDownHandler(d)}
                    className="text-theme-black opacity-50 cursor-pointer "
                  >
                    {d.version}{" "}
                    <i
                      className={classNames(
                        "fa-solid ml-1 ",
                        versionDropDown && clickedId === d._id
                          ? "fa-angle-up"
                          : "fa-angle-down"
                      )}
                    ></i>
                  </p>
                </div>

                <div className="w-[20%] text-xs gap-2">
                  <p className="">{d.name}</p>
                  {d.type && (
                    <p className=" ">
                      <span className="opacity-50 mr-1 font-light">
                        {d.type.type}
                      </span>
                      <span className="uppercase opacity-50">
                        ({d.type.code})
                      </span>
                    </p>
                  )}
                  {
                    <p className="text-[11px] mr-1 opacity-50  font-light">
                      {d.documentCode}
                    </p>
                  }
                </div>

                <div className="text-xs w-[8%]">
                  <p className="">Status</p>
                  <p
                    className={classNames(
                      " cursor-pointer  ",
                      d.status === "Approved"
                        ? "text-theme-green"
                        : "text-theme-black opacity-50"
                    )}
                  >
                    {d.status}
                  </p>
                </div>

                <div className="text-xs w-[10%]">
                  <p className="">Last Edit</p>
                  <p className="opacity-50 font-semibold capitalize">
                    {d.updatedAt.substring(0, 10).replaceAll("-", "/")}
                  </p>
                </div>

                <div className=" w-[10%] text-xs">
                  <div className="">
                    <p className=" ">Assets</p>
                    <p
                      onClick={() => assetDropDonwOPener(d)}
                      className="text-theme-black opacity-50 cursor-pointer"
                    >
                      {d?.stats?.assets || 0}{" "}
                      <i
                        className={classNames(
                          "fa-solid ml-1 ",
                          assetsDropDown && clickedId === d._id
                            ? "fa-angle-up"
                            : "fa-angle-down"
                        )}
                      ></i>
                    </p>
                  </div>
                </div>

                <div className=" w-[14%] text-xs">
                  <div className="">
                    {/* <p className=' '>Projects</p> */}
                    <p
                      onClick={() =>
                        navigate(`/admin/projects/${d.projectId._id}`)
                      }
                      className="text-blue-500  underline cursor-pointer"
                    >
                      {d.projectId?.name}{" "}
                    </p>
                  </div>
                </div>

                <div className=" w-[7%] text-xs">
                  <div className="">
                    <p className=" ">People</p>
                    <p
                      onClick={() => peopleDropDonwOpener(d)}
                      className="text-theme-black opacity-50 cursor-pointer"
                    >
                      {d.share.length}{" "}
                      <i
                        className={classNames(
                          "fa-solid ml-1 ",
                          peopleDropDown && clickedId === d._id
                            ? "fa-angle-up"
                            : "fa-angle-down"
                        )}
                      ></i>
                    </p>
                  </div>
                </div>

                <div className="text-xs flex flex-1 gap-2 justify-center  font-medium">
                  <DownloadSimple
                    onClick={() => downloadDocument(d)}
                    className="bg-#F7F6F6  p-2 cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
                    size={30}
                  />
                  <Eye
                    onClick={() => previewDocument(d)}
                    className="bg-#F7F6F6 p-2  cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
                    size={30}
                  />
                  <ArrowRight
                    onClick={() => openSingleDocHandler(d)}
                    className="bg-#F7F6F6 p-2  cursor-pointer border hover:bg-[#0000001a] hover:border-[#00000033] rounded"
                    size={30}
                  />
                </div>
              </div>

              {/* Drop Downs */}
              {versionDropDown && clickedId === d._id && (
                <div>
                  {versions?.map((v, index) => {
                    if (v !== versions[versions.length - 1])
                      return (
                        <VersionsDropDown
                          previewDocument={previewDocument}
                          key={index}
                          document={d}
                          v={v}
                        />
                      );
                  })}
                </div>
              )}
              {assetsDropDown && clickedId === d._id && (
                <div className="h-60 overflow-y-auto">
                  {assetDropDownLoading ? (
                    <Loading loading />
                  ) : allAssetsOfTheDoc?.docs?.length === 0 ? (
                    <p className="text-center py-3">No Asset found</p>
                  ) : (
                    allAssetsOfTheDoc?.docs?.map((asset, index) => {
                      return (
                        <AssetsDropDown
                          onClick={() => navigate(`/admin/assets/${asset._id}`)}
                          asset={asset}
                          key={index}
                        />
                      );
                    })
                  )}
                </div>
              )}

              {projectsDropDown && clickedId === d._id && (
                <div className="h-60 overflow-y-auto">
                  {FakeProjects.map((project, index) => {
                    return <ProjectsDropDown project={project} key={index} />;
                  })}
                </div>
              )}

              {peopleDropDown && clickedId === d._id && (
                <PeopleDropDown d={document} />
              )}
            </div>
          );
        })
      )}
      {previewModal && (
        <>
          <div
            onClick={() => {
              // setPreviewModal(false);
              // setPreviewURL();
            }}
            className="fixed z-[9999999] w-screen h-screen top-0 left-0 bg-black bg-opacity-20"
          >
            {previewURL && (
              <DocumentPopup
                closeModal={() => {
                  setPreviewModal(false);
                  setPreviewURL();
                }}
                documentUrl={previewURL}
                tryAgain={tryAgain}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DocumentsTable;
