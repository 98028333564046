import * as React from "react";

import { render } from "react-dom";
import Map, { Marker, NavigationControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Pin from "./pin";
import axios from "axios";

const TOKEN =
  "pk.eyJ1Ijoic2FuZHktNzciLCJhIjoiY2wwajI3MmNwMDFsbjNlcnY0bjJtdXJtdCJ9.yDqEh7ba7bWcDlSn3Dx87Q"; // Set your mapbox token here

export default function App({
  marker,
  onMarkerDrag,
  onMarkerDragStart,
  onMarkerDragEnd,
  formik,
}) {
  const initialViewState = {
    longitude: marker.longitude,
    latitude: marker.latitude,
    zoom: 16,
  };

  const mapRef = React.useRef();

  React.useEffect(() => {
    const func = async () => {
      if (mapRef.current) {
        mapRef.current.setCenter([marker.longitude, marker.latitude]);

        let { data } = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${marker.longitude},${marker.latitude}.json?types=address&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
        );

        formik.setFieldValue("address.street1", data.features[0].text);
        formik.setFieldValue(
          "address.street2",
          data.features[0].context[data.features[0].context.length - 4].text
        );
        formik.setFieldValue(
          "address.city",
          data.features[0].context[data.features[0].context.length - 3].text
        );
        formik.setFieldValue(
          "address.state",
          data.features[0].context[data.features[0].context.length - 2].text
        );
        formik.setFieldValue(
          "address.country",
          data.features[0].context[data.features[0].context.length - 1].text
        );
      }
    };
    func();
  }, [marker, mapRef]);
  return (
    <>
      <Map
        initialViewState={initialViewState}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken={TOKEN}
        ref={mapRef}
      >
        <Marker
          longitude={marker.longitude}
          latitude={marker.latitude}
          anchor="bottom"
          draggable
          onDragStart={onMarkerDragStart}
          // onDrag={onMarkerDrag}
          onDragEnd={onMarkerDragEnd}
        >
          <Pin size={20} />
        </Marker>

        <NavigationControl />
      </Map>
    </>
  );
}

export function renderToDom(container) {
  render(<App />, container);
}
