/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* global window */
import React, { useState, useEffect } from "react";
import { render } from "react-dom";
import { Map, Marker, NavigationControl } from "react-map-gl";
import { AmbientLight, PointLight, LightingEffect } from "@deck.gl/core";
import DeckGL from "@deck.gl/react";
import { PolygonLayer, IconLayer } from "@deck.gl/layers";
import {
  Buildings as BuildingIcon,
  FunnelSimple,
  ListBullets,
  MapTrifold,
  Wrench,
  X,
} from "phosphor-react";
import Buildings from "../../../components/Global/map/buildings.json";

import mapboxgl from "mapbox-gl";
import { useDispatch, useSelector } from "react-redux";
import { getLocationsOfAssets } from "../../../../api/users/asset";
import { openSingleAssetActionUpdated } from "../../../../redux/actions/asset/SingleAssetAction";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

// Source data CSV
const DATA_URL = {
  BUILDINGS: Buildings,
  // 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/trips/buildings.json', // eslint-disable-line
  // TRIPS: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/trips/trips-v7.json' // eslint-disable-line
};

const ambientLight = new AmbientLight({
  color: [0, 132, 255],
  intensity: 1.0,
});

const pointLight = new PointLight({
  color: [0, 132, 255],
  intensity: 2.0,
  position: [-74.05, 40.7, 8000],
});

const lightingEffect = new LightingEffect({ ambientLight, pointLight });

const material = {
  ambient: 0.1,
  diffuse: 0.6,
  shininess: 32,
  specularColor: [60, 64, 70],
};

const DEFAULT_THEME = {
  buildingColor: [0, 94, 255],
  material,
  effects: [lightingEffect],
};
//  12.9716
//  77.5946

const INITIAL_VIEW_STATE = {
  latitude: 51.507322,
  longitude: -0.127647,
  zoom: 12,
  pitch: 45,
  bearing: 0,
};

const MAP_STYLE1 = "mapbox://styles/mapbox/streets-v11";
const MAP_STYLE2 = "mapbox://styles/mapbox/light-v10";

const landCover = [
  [
    [-74.0, 40.7],
    [-74.02, 40.7],
    [-74.02, 40.72],
    [-74.0, 40.72],
  ],
];

export default function MainMapAssets({
  buildings = DATA_URL.BUILDINGS,
  initialViewState = INITIAL_VIEW_STATE,
  theme = DEFAULT_THEME,
  loopLength = 1800, // unit corresponds to the timestamp in source data
  animationSpeed = 1,
  map,
  threeD,
  status,
  setAssetRightDropDown,
}) {
  const [time, setTime] = useState(0);
  const [data, setData] = useState([]);
  const [animation] = useState({});
  const animate = () => {
    setTime((t) => (t + animationSpeed) % loopLength);
    animation.id = window.requestAnimationFrame(animate);
  };

  useEffect(() => {
    animation.id = window.requestAnimationFrame(animate);
    return () => window.cancelAnimationFrame(animation.id);
  }, [animation]);

  const layers = [
    // This is only needed when using shadow effects
    new PolygonLayer({
      id: "ground",
      data: landCover,
      getPolygon: (f) => f,
      stroked: false,
      getFillColor: [0, 0, 0, 0],
    }),
    new PolygonLayer({
      id: "buildings",
      data: buildings,
      extruded: true,
      wireframe: false,
      stroked: true,
      opacity: 0.6,
      getPolygon: (f) => f.polygon,
      // getElevation: f => 0,
      getElevation: 90,
      getFillColor: [107, 112, 120],
      material: theme.material,
    }),
  ];

  const layers2 = [
    // This is only needed when using shadow effects
    new PolygonLayer({
      id: "ground",
      data: landCover,
      getPolygon: (f) => f,
      stroked: false,
      getFillColor: [0, 0, 0, 0],
    }),
    new PolygonLayer({
      id: "buildings",
      data: buildings,
      extruded: true,
      wireframe: false,
      stroked: true,
      opacity: 1,
      getPolygon: (f) => f.polygon,
      getElevation: 0,
      getFillColor: theme.buildingColor,
      material: theme.material,
    }),
  ];

  //   useEffect(() => {

  //      const func = async() => {
  //        const res = await getLocationsOfProjects(INITIAL_VIEW_STATE.longitude, INITIAL_VIEW_STATE.latitude, 500, status)
  //        setLocationss(res.data)
  //        mapRef.current.on('move', () => {
  //
  //        });

  //      }
  //      func();

  //  }, [locations, status]);

  // const ICON_MAPPING = {
  //   marker: { x: 0, y: 0, width: 128, height: 128, mask: true }
  // };
  // const layer = new IconLayer({
  //   id: 'icon-layer',
  //   data,
  //   pickable: true,
  //   // iconAtlas and iconMapping are required
  //   // getIcon: return a string
  //   iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
  //   iconMapping: ICON_MAPPING,
  //   getIcon: d => 'marker',

  //   sizeScale: 15,
  //   getPosition: d => d.coordinates,
  //   getSize: d => 5,
  //   getColor: d => [Math.sqrt(d.exits), 140, 0]
  // });

  // integration

  const mapRef = React.useRef();
  const [locationss, setLocationss] = useState([]);

  useEffect(() => {
    const func = async () => {
      const res = await getLocationsOfAssets(
        INITIAL_VIEW_STATE.longitude,
        INITIAL_VIEW_STATE.latitude,
        500,
        status,
        ""
      );
      setLocationss(res.data);
    };
    func();
  }, [status]);

  const dispatch = useDispatch();

  return (
    // <DeckGL
    //   layers={threeD ? layers : layers2}
    //   effects={theme.effects}
    //   initialViewState={INITIAL_VIEW_STATE}
    //   controller={true}
    // >
    <Map
      initialViewState={INITIAL_VIEW_STATE}
      style={{ height: "calc(100vh - 57px)" }}
      ref={mapRef}
      reuseMaps
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      mapStyle={map.map1 ? MAP_STYLE1 : MAP_STYLE2}
    >
      {locationss?.map((city, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={city.location.coordinates[0]}
          latitude={city.location.coordinates[1]}
          anchor="bottom"
          onClick={(e) => {
            // If we let the click event propagates to the map, it will immediately close the popup
            e.originalEvent.stopPropagation();
            setAssetRightDropDown(true);
            dispatch(openSingleAssetActionUpdated(city._id));

            // setPopupInfo(city);
          }}
        >
          <div className="p-2 rounded-full bg-theme-black ">
            <BuildingIcon size={22} className=" text-white" weight="bold" />
          </div>
        </Marker>
      ))}

      <NavigationControl
        style={{ marginBottom: 20, marginRight: 40 }}
        position="bottom-right"
      />
    </Map>

    // </DeckGL>
  );
}
