
export const documents = [
    {
        id:1,
        uid:'APC10',
        versions:[1.0, 1.1, 1.2, 1.3],
        details:{
            docName:'Co_30_13.pdf',
            docId: 'Co_30_13',
            type:'pdf'
        },
        status:"Work in Progress (Pending)",
        lastEdit: '20/03/2022',
        // assets:Assests,
        // projects:FakeProjects,
        people:{
            uploadedBy:{
                imgUrl:"images/user1.jpeg",
                companyName:'Sherlock Piping Ltd',
                userName:'Rober Fox',
                date:'18:00, 14/02/2022'
            },
            approvedBy:{
                imgUrl:"images/user1.jpeg",
                companyName:'Sherlock Piping Ltd',
                userName:'Rober Fox',
                date:'18:00, 14/02/2022'
            },
            shared:[
                {
                    sharedBy:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                        userName:'Esther Howard',
                    },
                    sharedWith:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                    },
                    date:'18:00, 14/02/2022'
                },
                {
                    sharedBy:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                        userName:'Esther Howard',
                    },
                    sharedWith:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                    },
                    date:'18:00, 14/02/2022'
                }
            ]
        },


    },
    {
        id:2,
        uid:'APC10',
        versions:[1.0, 1.1, 1.2, 1.3],
        details:{
            docName:'Co_30_13.pdf',
        },
        status:"Approved",
        lastEdit: '20/03/2022',
        // assets:Assests,
        // projects:FakeProjects,
        people:{
            uploadedBy:{
                imgUrl:"images/user1.jpeg",
                companyName:'Sherlock Piping Ltd',
                userName:'Rober Fox',
                date:'18:00, 14/02/2022'
            },
            approvedBy:{
                imgUrl:"images/user1.jpeg",
                companyName:'Sherlock Piping Ltd',
                userName:'Rober Fox',
                date:'18:00, 14/02/2022'
            },
            shared:[
                {
                    sharedBy:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                        userName:'Esther Howard',
                    },
                    sharedWith:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                    },
                    date:'18:00, 14/02/2022'
                },
                {
                    sharedBy:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                        userName:'Esther Howard',
                    },
                    sharedWith:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                    },
                    date:'18:00, 14/02/2022'
                }
            ]
        },
    },
    {
        id:3,
        uid:'APC10',
        versions:[1.0, 1.1, 1.2, 1.3],
        details:{
            docName:'Co_30_13.doc',
        },
        status:"Approved",
        lastEdit: '20/03/2022',
        // assets:Assests,
        // projects:FakeProjects,
        people:{
            uploadedBy:{
                imgUrl:"images/user1.jpeg",
                companyName:'Sherlock Piping Ltd',
                userName:'Rober Fox',
                date:'18:00, 14/02/2022'
            },
            approvedBy:{
                imgUrl:"images/user1.jpeg",
                companyName:'Sherlock Piping Ltd',
                userName:'Rober Fox',
                date:'18:00, 14/02/2022'
            },
            shared:[
                {
                    sharedBy:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                        userName:'Esther Howard',
                    },
                    sharedWith:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                    },
                    date:'18:00, 14/02/2022'
                },
                {
                    sharedBy:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                        userName:'Esther Howard',
                    },
                    sharedWith:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                    },
                    date:'18:00, 14/02/2022'
                }
            ]
        }
    },
    {
        id:4,
        uid:'APC10',
        versions:[1.0, 1.1, 1.2, 1.3],
        details:{
            docName:'Co_30_13.pdf',
        },
        status:"Approved",
        lastEdit: '20/03/2022',
        // assets:Assests,
        // projects:FakeProjects,
        people:{
            uploadedBy:{
                imgUrl:"images/user1.jpeg",
                companyName:'Sherlock Piping Ltd',
                userName:'Rober Fox',
                date:'18:00, 14/02/2022'
            },
            approvedBy:{
                imgUrl:"images/user1.jpeg",
                companyName:'Sherlock Piping Ltd',
                userName:'Rober Fox',
                date:'18:00, 14/02/2022'
            },
            shared:[
                {
                    sharedBy:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                        userName:'Esther Howard',
                    },
                    sharedWith:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                    },
                    date:'18:00, 14/02/2022'
                },
                {
                    sharedBy:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                        userName:'Esther Howard',
                    },
                    sharedWith:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                    },
                    date:'18:00, 14/02/2022'
                }
            ]
        }
    },
    {
        id:5,
        uid:'APC10',
        versions:[1.0, 1.1, 1.2, 1.3],
        details:{
            docName:'Co_30_13.pdf',
        },
        status:"Approved",
        lastEdit: '20/03/2022',
        // assets:Assests,
        // projects:FakeProjects,
        people:{
            uploadedBy:{
                imgUrl:"images/user1.jpeg",
                companyName:'John Constructions',
                userName:'Rober Fox',
                date:'18:00, 14/02/2022'
            },
            approvedBy:{
                imgUrl:"images/user1.jpeg",
                companyName:'Sherlock Piping Ltd',
                userName:'Rober Fox',
                date:'18:00, 14/02/2022'
            },
            shared:[
                {
                    sharedBy:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                        userName:'Esther Howard',
                    },
                    sharedWith:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                    },
                    date:'18:00, 14/02/2022'
                },
                {
                    sharedBy:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                        userName:'Esther Howard',
                    },
                    sharedWith:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                    },
                    date:'18:00, 14/02/2022'
                }
            ]
        }
    },
    {
        id:6,
        uid:'APC10',
        versions:[1.0, 1.1, 1.2, 1.3],
        details:{
            docName:'Co_30_13.pdf',
        },
        status:"Approved",
        lastEdit: '20/03/2022',
        // assets:Assests,
        // projects:FakeProjects,
        people:{
            uploadedBy:{
                imgUrl:"images/user1.jpeg",
                companyName:'Valerie Fittings Inc',
                userName:'Rober Fox',
                date:'18:00, 14/02/2022'
            },
            approvedBy:{
                imgUrl:"images/user1.jpeg",
                companyName:'Sherlock Piping Ltd',
                userName:'Rober Fox',
                date:'18:00, 14/02/2022'
            },
            shared:[
                {
                    sharedBy:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                        userName:'Esther Howard',
                    },
                    sharedWith:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                    },
                    date:'18:00, 14/02/2022'
                },
                {
                    sharedBy:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                        userName:'Esther Howard',
                    },
                    sharedWith:{
                        imgUrl:"images/user1.jpeg",
                        companyName:'Sherlock Piping Ltd',
                    },
                    date:'18:00, 14/02/2022'
                }
            ]
        }
    }
]