import axios from "axios";
import { accessToken } from "../../libs/token";
import { v4 as uuidv4 } from "uuid";

const CONTENT_TYPE_MAP = {
  "pdf": "application/pdf",
  "excel": "application/excel",
  "msword": "application/msword",
  "zip": "application/zip",
  "jpeg": "image/jpeg",
  "jpg": "image/jpg",
  "png": "image/png",
  "mov": "video/mov",
  "MOV": "video/mov",
  "mp4": "video/mp4",
}

export const downloadUrl = async (filename, type, contentType) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/presigned/upload?fileName=${filename}&type=${type}&contentType=${contentType}`,
    await accessToken()
  );
};

export const uploadUrl = async (url, data) => {
  return axios.put(`${url}`, data, {
    withCredentials: true,
  });
};

const downloadDocApi = async (fileName, contentType) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL
    }/presigned/download?fileName=${fileName}&type=documents&contentDisposition=attachment&contentType=${contentType}`,
    await accessToken()
  );
};

const previewDocApi = async (fileName, contentType) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL
    }/presigned/download?fileName=${fileName}&type=documents&contentDisposition=attachment&contentType=${contentType}`,
    await accessToken()
  );
};

export const upload = async (file) => {
  try {
    const profileName = uuidv4() + "." + file.type.split("/")[1];
    const res = await downloadUrl(profileName, "profiles", file.type);

    await uploadUrl(res.data.url, file);

    return profileName;
  } catch (error) { }
};

export const uploadDoc = async (file) => {
  try {
    const docName = uuidv4() + "." + file.type.split("/")[1];
    const res = await downloadUrl(docName, "documents", file.type);

    await uploadUrl(res.data.url, file);

    return docName;
  } catch (error) { }
};

export const downloadDoc = async (name) => {
  try {
    let file_ext = name.split(".")[1];
    let file_content_type = CONTENT_TYPE_MAP[file_ext];

    const res = await downloadDocApi(name, file_content_type);

    return res.data.url;
  } catch (error) { }
};

export const previewDoc = async (name) => {
  try {
    let file_ext = name.split(".")[1];
    let file_content_type = CONTENT_TYPE_MAP[file_ext];

    const res = await previewDocApi(name, file_content_type);

    return res.data.url;
  } catch (errr) { }
};

export const uploadSupport = async (file) => {
  try {
    const supportFileName = uuidv4() + "." + file.type.split("/")[1];
    const res = await downloadUrl(supportFileName, "supports", file.type);

    await uploadUrl(res.data.url, file);

    return supportFileName;
  } catch (error) { }
};
