import {
  getDocumentsOfAAsset,
  getSingleAssetApi,
} from "../../../api/users/asset";
import {
  GET_ASSET_DOCUMENTS_FAIL,
  GET_ASSET_DOCUMENTS_REQ,
  GET_ASSET_DOCUMENTS_SUCC,
} from "../../constants/assets/getDocumentOfTheAsset";
import {
  GET_SINGLE_ASSET_FAIL,
  GET_SINGLE_ASSET_REQ,
  GET_SINGLE_ASSET_SUCC,
} from "../../constants/assets/SingleAssetOpener";

export const openSingleAssetActionUpdated = (data) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_ASSET_REQ });

    const res = await getSingleAssetApi(data);

    dispatch({
      type: GET_SINGLE_ASSET_SUCC,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_ASSET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSIngleAssetDocuments =
  ({ page, limit, uid = "", name = "", assetId }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_ASSET_DOCUMENTS_REQ });

      const res = await getDocumentsOfAAsset({
        page,
        limit,
        uid,
        name,
        assetId,
      });

      dispatch({
        type: GET_ASSET_DOCUMENTS_SUCC,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ASSET_DOCUMENTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
