import axios from "axios";
import { accessToken } from "../../libs/token";

export const inviteUser = async(data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/users/invite`, data,await accessToken());
};
export const updateUser = async(id,data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/users/${id} `, data,await accessToken());
};

export const deleteUser = async(data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/users/invite`, data,await accessToken());
};

export const getUserPermissions = async( ) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/company/users/permissions`,await accessToken());
};

export const setUserPermissions = async(data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/users/permissions`,data,await accessToken());
};

export const getAdminPermissions = async( ) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/company/admin/permissions`,await accessToken());
};

export const setUserProject = async(data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/users/projects`,data,await accessToken());
};

export const putUserPermissions = async(data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/users/permissions`,data,await accessToken());
};