
import { getUserApi } from "../../../api/users/users";
import { GET_SINGLE_EMPLOYEE_FAIL_ADMIN, GET_SINGLE_EMPLOYEE_REQ_ADMIN, GET_SINGLE_EMPLOYEE_SUCC_ADMIN } from "../../constants/AdminConsts/EmployeesAdminConst";

export const openSingleEmployeeAdminAction = (id, edit) => async(dispatch) => {
    try{
        dispatch({type: GET_SINGLE_EMPLOYEE_REQ_ADMIN})

 
        const res = await getUserApi(id)

        dispatch({
                type: GET_SINGLE_EMPLOYEE_SUCC_ADMIN,
                payload:{data:res.data, edit:edit}
            })
    } catch(error){
        dispatch({
            type: GET_SINGLE_EMPLOYEE_FAIL_ADMIN,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message 
        })
    }
}